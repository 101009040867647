<template>
  <div class="wallpaper hfull">
    <!-- Header -->
    <div class="header py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">Publikasi</h1>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col md="12">
          <div class="card bg-white border-0">
            <div class="p-4">
              <h2 class="text-justify">Tabel Unduh Publikasi</h2>
            </div>
            <div class="m-10">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Judul</th>
                      <th>Dokumen</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in tableFile" :key="data.id">
                      <td>{{ index +1 }}</td>
                      <td>{{ data.title }}</td>
                      <td><a :href="getBlobsFile(data.attachment_file_id)" target="blank"><button class="btn btn-primary">Download</button></a> </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import API from "@/api/base_url.js";
import baseURL from "@/api/base_url_backend.js";


export default {
  name: "lock",
  data() {
    return {
      model: {
        password: "",
      },
      tableFile: [
        
      ],
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    getBlobsFile(filename) {
      let url = `${baseURL}blobs/download/${filename}`
      return url
    },
    getData() {
      API.get('publications')
        .then(({data}) => {
          if (data.data !== null) {
            this.tableFile = data.data
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
       
        })
    }
  },
};
</script>
<style>
.wallpaper {  
  background-image: url("../../../public/img/theme/img-background-full.png");
  background-size: cover;
  background-attachment: fixed;

}

.table td, .table th, .el-table td, .el-table th {
  white-space: normal;
}

</style>

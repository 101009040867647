<template>
    <div class="wallpaper hfull">
      <!-- Header -->
      <div class="header py-7 py-lg-8 pt-lg-9">
        <b-container>
          <div class="header-body text-center mb-3">
            <b-row class="justify-content-center">
              <b-col xl="5" lg="6" md="8" class="px-5">
                <h1 class="text-white">BEBAN PENCEMARAN</h1>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>
      <!-- Page content -->
      <b-container class="mt--8 pb-5">
        <b-row class="justify-content-center">
          <b-col md="12">
            <div class="card bg-white border-0">
              <div class="p-4" style="display:flex">
                <!-- Year -->
                <div style="display: flex;flex-direction: column;width: 180px; margin-right: 10px;">
                    <label><small>Status Pendaftaran</small></label>
                    <el-select class="select-primary pagination-select" v-model="yearSelected"  placeholder="Tahun" @change="getDataByFilter()">
                    <el-option
                        class="select-primary"
                        v-for="item in ['2014','2015','2016']"
                        :key="item"
                        :label="item"
                        :value="item"
                    >
                    </el-option>
                    </el-select>
                </div>

                <!-- Provinsi -->
                <div style="display: flex;flex-direction: column;width: 180px; margin-right: 10px;">
                    <label><small>Provinsi</small></label>
                    <base-input name="Provinsi">
                        <el-select
                        placeholder="Provinsi"
                        v-model="form.umum.compProvince"
                        @change="getCityDepentOnProvince(form.umum.compProvince)" filterable>
                        <el-option
                            v-for="option in provideSelect.provinsi"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id"
                        ></el-option>
                        </el-select>
                    </base-input>
                </div>               
                <!-- Kabupaten Kota -->
                <div style="display: flex;flex-direction: column;width: 180px; margin-right: 10px;">
                    <label><small>Kabupaten / Kota</small></label>
                    <base-input name="Kabupaten / Kota">
                        <el-select v-model="form.umum.compCity" filterable placeholder="Kab/Kot" @change="getDataByFilter()">
                        <el-option
                            v-for="option in provideSelect.kabupatenKota"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id"
                        ></el-option>
                        </el-select>
                    </base-input>
                </div>
                <!-- Jenis Industri -->
                <div style="display: flex;flex-direction: column;width: 180px; margin-right: 10px;">
                    <label><small>Jenis Industri</small></label>
                    <base-input name="Jenis Industri" >
                        <el-select v-model="form.umum.compType" placeholder="Jenis Industri" @change="getDataByFilter()">
                        <el-option
                            v-for="option in provideSelect.companyType"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id"
                        ></el-option>
                        </el-select>
                    </base-input>
                </div>
                <div style="display: flex;flex-direction: column; margin-right: 10px;" v-if="isInnerData">
                    <label><small>Aksi</small></label>
                    <button class="btn btn-danger" @click="resetRow">RESET</button>
                </div>

              </div>
              <div class="m-10">
                  <table class="table table-sm table-hover">
                    <thead class="thead-dark">
                      <tr>
                        <th rowspan="2" class="text-white">NO</th>
                        <th rowspan="2" class="text-white text-center">Provinsi</th>
                        <th colspan="4" class="text-center text-white">BEBAN EMISI TON/TAHUN</th>
                      </tr>
                      
                      <tr>
                        <th colspan="1" class="text-white">PH</th>
                        <th colspan="1" class="text-white">COD</th>
                        <th colspan="1" class="text-white">TSS</th>
                        <th colspan="1" class="text-white">NH3N</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="cursor: pointer;" v-for="(item, idx) in listData.body" :key="idx" @click="getMultiRow(item)">
                        <td>{{ idx + 1 }}</td>
                        <td>{{ item.provinceName }}</td>
                        <td>{{ item.ph }}</td>
                        <td>{{ item.cod }}</td>
                        <td>{{ item.tss }}</td>
                        <td>{{ item.nh3n }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                        <tr class="bg-secondary">
                            <td></td>
                            <td class="text-white">TOTAL BEBAN EMISI</td>
                            <td class="text-white">{{ listData.footer.ph }}</td>
                            <td class="text-white">{{ listData.footer.cod }}</td>
                            <td class="text-white">{{ listData.footer.tss }}</td>
                            <td class="text-white">{{ listData.footer.nh3n }}</td>
                      </tr>
                    </tfoot>
                  </table>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  <script>
  import API from "@/api/base_url.js";
  import { Select, Option } from "element-ui";
  import Swal from "sweetalert2";
  import "sweetalert2/dist/sweetalert2.css";
  
  export default {
    name: "bebanPencemaran",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
    },
    data() {
      return {
        isInnerData: false,
        listData: {
            body: 
                [
                    {
                        provinceName:'Banten',
                        ph:'10',
                        cod:'12',
                        tss:'16',
                        nh3n:'18',
                    },
                    {
                        provinceName:'Sumatera Utara',
                        ph:'20',
                        cod:'22',
                        tss:'26',
                        nh3n:'28',
                    },
                ],
            footer: {
                ph:'0',
                cod:'0',
                tss:'6',
                nh3n:'18',
            }
        },
        listInnerData: {
            body: 
                [
                    {
                        provinceName:'Banten 1',
                        ph:'10',
                        cod:'12',
                        tss:'16',
                        nh3n:'18',
                    },
                    {
                        provinceName:'Banten 2',
                        ph:'20',
                        cod:'22',
                        tss:'26',
                        nh3n:'28',
                    },
                    {
                        provinceName:'Banten 3',
                        ph:'30',
                        cod:'32',
                        tss:'36',
                        nh3n:'38',
                    },
                ],
            footer: {
                ph:'4',
                cod:'5',
                tss:'7',
                nh3n:'8',
            }
        },
        form: {
            umum: {
                compName: null,
                compNameID: null,
                compType: null,
                compCity: null,
                compProvince: null,
            },
        },
        provideSelect: {
            provinsi: [],
            kabupatenKota: [],
            daftarPerusahaan: [],
            tmp: [],
            uploadChoosen: null,
            companyType: [],
        },
        yearSelected: "",
        model: {
          password: "",
        },
        tableFile: [
          
        ],
      };
    },
    mounted() {
    //   this.getData()
      this.getProvince()
      this.getCompanyType()
    },
    methods: {
      getMultiRow(id) {
        console.log('multiRow');
        this.listData = this.listInnerData
        this.isInnerData = true
      },
      resetRow() {
        this.listData = {
            body: 
                [
                    {
                        provinceName:'Banten',
                        ph:'10',
                        cod:'12',
                        tss:'16',
                        nh3n:'18',
                    },
                    {
                        provinceName:'Sumatera Utara',
                        ph:'20',
                        cod:'22',
                        tss:'26',
                        nh3n:'28',
                    },
                ],
            footer: {
                ph:'0',
                cod:'0',
                tss:'6',
                nh3n:'18',
            }
        }

        this.isInnerData = false
      },
      getDataByFilter() {
        console.log('getDataByFilter');
        console.log(this.yearSelected);
        console.log(this.form.umum.compProvince);
        console.log(this.form.umum.compCity);
        console.log(this.form.umum.compType);
      },
      getCompanyType() {
        API.get(`comp-types`)
        .then(({ data: content }) => {
        this.provideSelect.companyType = content.data
        console.log(content.data);
        })
        .catch((err) => {
        Swal.fire("Gagal Mendapatkan Jenis Perusahaan", "", "error");
        });
      },
      getProvince() {
        API.get(`provinces`)
          .then(({ data: content }) => {
          this.provideSelect.provinsi = content.data
          })
          .catch((err) => {
            Swal.fire("Gagal Mendapatkan Data Provinsi", "", "error");
          });
      },
      getCityDepentOnProvince(data) {
        this.form.umum.compCity = []
        this.provideSelect.kabupatenKota = [];
        API.get(`kabkots?prov_id=${data}`)
          .then(({ data: content }) => {
            this.provideSelect.kabupatenKota = content.data;

          })
          .catch((err) => {
            console.log(err);
          });
        console.log(data);
      },
      getData() {
        API.get('publications')
          .then(({data}) => {
            if (data.data !== null) {
              this.tableFile = data.data
            }
          })
          .catch((err) => {
            console.log(err);
            Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
         
          })
      }
    },
  };
  </script>
  <style >
  .wallpaper {  
    background-image: url("../../../public/img/theme/img-background-full.png");
    background-size: cover;
    background-attachment: fixed;
  
  }
  
  .table td, .table th, .el-table td, .el-table th {
    white-space: normal;
  }
  
  </style>
  
<template>
  <div class="content">
    <div>
      <div>
        <el-table
          :data="queriedData"
          row-key="id"
          header-row-class-name="thead-light"
          
        >

        <el-table-column label="No" type="index" width="70" />

          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            v-bind="column"
          >
          </el-table-column>

          <el-table-column min-width="120px" label="Tanggal">
            <div slot-scope="action">
              {{ ConvertDateFunction(action.row.published_at) }}
            </div>
          </el-table-column>

          <el-table-column min-width="750px" label="Keterangan">
            <div slot-scope="action">
              <span v-html="action.row.content"> </span>
            </div>
          </el-table-column>

          <el-table-column min-width="130px" label="Aksi">
            <div slot-scope="action" >
              <base-button
                @click="view(action.row)"
                type="primary"
                size="sm"
                icon
              >
                DETAIL
              </base-button>

              <!-- <base-button
                @click="handleEdit(action.row)"
                type="warning"
                size="sm"
                icon
              >
                EDIT
              </base-button>

              <base-button
                @click="doDelete(action.row)"
                type="danger"
                size="sm"
                icon
              >
                HAPUS
              </base-button> -->
            </div>
          </el-table-column>
        </el-table>
      </div>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

            <span v-if="selectedRows.length">
              &nbsp; &nbsp; {{ selectedRows.length }} rows selected
            </span>
          </p>
        </div>
        <b-col
          cols="6"
          class="mb-3 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </b-col>
        <base-pagination
          class="pagination-no-border"
          :current="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
          @change="paginationChanged($event)"
        >
        </base-pagination>
      </div>
    </div>

    <Modal
      :show="showAnnoncementModal"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showAnnoncementModal = false"
      size="xl"
    >
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <h3>Pengumuman Detail</h3>
          <b-row>
            <b-col xl="12">
              <div class="container">
                <b-card>
                  <template v-slot:header>
                    <!-- content -->
                    <div class="card-body">
                      <div class="row mb-4">
                        <div class="col-lg-2">
                          <strong>Nama</strong>
                        </div>
                        <div class="col-lg-10">
                          <span>{{ name }}</span>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-lg-2">
                          <strong>Dibuat</strong>
                        </div>
                        <div class="col-lg-10">
                          <span>{{ created }}</span>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-lg-2">
                          <strong>Dirubah</strong>
                        </div>
                        <div class="col-lg-10">
                          <span>{{ updated }}</span>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-lg-2">
                          <strong>Pengumuman</strong>
                        </div>
                        <div class="col-lg-10">
                          <span v-html="desc"> </span>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-lg-2">
                          <strong>Lampiran</strong>
                        </div>
                        <div class="col-lg-10">
                          <span>
                            <a :href="`http://${file}`" target="blank" class="text-blue">Lihat Lampiran</a>
                          </span>
                        </div>
                      </div>

                      <!-- <h6 class="surtitle py-2" style="margin-top: 100px">Action</h6>
                        <div style="display: flex">
                          <base-button @click="back()">Download</base-button>
                          <base-button @click="back()" type="primary">Ubah</base-button>
                          <base-button @click="back()" type="danger">Hapus</base-button>
                        </div> -->
                    </div>
                  </template>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="showAnnoncementModal = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

     <!-- Modal untuk Edit pengumuman table -->
     <Modal
      :show="showFormModal"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showFormModal = false"
      size="xl"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div>
            <div class="p-3">
              <h2 class="text-center">Merubah Pengumuman</h2>

              <div class="form-group row">
                <label class="col-lg-12 col-form-label form-control-label">Title</label>
                <div class="col-lg-12">
                  <base-input
                    name="Title"
                    rules="required"
                    v-model="updateTitle"
                  ></base-input>
                </div>
              </div>

              <label class="form-control-label">Isi Pengumuman</label>
              <html-editor v-model="updateContent"></html-editor>

              <base-input
                  rules="required"
                  name="File"
              >
                  <file-input
                  accept="application/pdf"
                  ref="announcement_attachment_file"
                  @change="uploadFile('announcement_attachment_file', 'announcement_attachment_file')"
                  ></file-input>
              </base-input>
                <a :href="`https://${updateFile}`" v-if="updateFile" target="blank" class="mt-2 text-blue">Lihat File Terupload</a>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button class="bg-secondary border-0" @click="doEdit()"> Update </base-button>
            <base-button class="bg-danger border-0" @click="showFormModal = false">Close</base-button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import API from "../../api/base_url"
import { Table, TableColumn, Select, Option } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import clientPaginationMixin from "./PaginatedTables/clientPaginationMixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import FileInput from "@/components/Inputs/FileInput";
import { format } from "date-fns";
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    refresh: {
      type: Function,
      required: true
    }
  },
  mixins: [clientPaginationMixin],
  components: {
    Modal,
    FileInput,
    HtmlEditor,
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      updateTitle: "",
      updateContent: "",
      updateFile: "",
      updateStatus: "",
      updateId: "",
      showFormModal: false,
      file: "",
      desc: "",
      name: "",
      created: "",
      updated: "",
      showAnnoncementModal: false,
      tableColumns: [
        {
          prop: "title",
          label: "Title",
          minWidth: 180,
        },
      ],
      selectedRows: [],
      formData: new FormData()
    };
  },
  methods: {
    uploadFile(category, field) {
          this.formData.append(field, this.$refs[field].files[0]);
          // this.hasUploadAttachment = true
      },
    ConvertDateFunction(theDate) {
      if (!theDate) {
        return "00-00-0000";
      } else {
        let result = format(new Date(theDate), "dd-MM-yyyy");
        return result;
      }
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    handleEdit(data) {
      this.showFormModal = true
      let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
        API.get(`announcements/${data.id}` , {headers})
          .then(({data:content}) => {
            console.log(content.data,'--->');
              this.updateTitle = content.data.title
              this.updateFile = content.data.attachment
              this.updateId = content.data.id
              this.updateStatus = content.data.status
              this.updateContent = content.data.content
            })
          .catch((err) => {
            console.log(err);
      })
    },
    handleDelete(index, row) {
      swal
        .fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: "Yes, delete it!",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            this.deleteRow(row);
            swal.fire({
              title: "Deleted!",
              text: `You deleted ${row.name}`,
              type: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          }
        });
    },
    view(get) {
      this.file = get.attachment;
      this.desc = get.content;
      this.name = get.title;
      this.created = this.ConvertDateFunction(get.published_at);
      this.updated = get.updated_at;
      this.showAnnoncementModal = true;
    },
    doDelete(data) {
      Swal.fire({
        title: 'Hapus Data ini ? ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
          API.delete(`announcements/${data.id}` , {headers})
          .then(({data:content}) => {
            this.refresh()
            Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )

          })
          .catch((err) => {
            console.log(err);
          })

          
        }
      })
    },
    doEdit() {
      let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      this.formData.append("content", this.updateContent);
      this.formData.append("id", this.updateId);
      this.formData.append("title", this.updateTitle);

  

        Swal.fire({
        title: 'Merubah Data ? ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          API.put(`announcements` , this.formData, {headers})
          .then(({data:content}) => {
            Swal.fire('Data berhasil dirubah!','Your file has been edited.','success')

            this.refresh()

            this.updateTitle = ""
            this.updateContent = ""
            this.updateFile = ""
            this.updateStatus = ""
            this.updateId = ""
            this.formData = new FormData()

            this.showFormModal = false
          })
          .catch((err) => {
            console.log(err);
            Swal.fire(
            'Data gagal diEdit!',
            'Your file has not been edited.',
            'error'
            )
        })
        }
      })
      this.showFormModal = false
    }
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>

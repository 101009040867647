<template>
  <div class="wallpaperHome">
    <div class="popup-logo" v-if="isNotification">
      <div class="container-fluid bg-logonotif">
        <div class="btn-closes">
          <button class="btn btn-secondary" @click="closeNotification()">
            X
          </button>
        </div>
        <div class="header pt-5 pb-7">
          <b-container>
            <div class="header-body">
              <b-row align-v="center">
                <b-col lg="5">
                  <div class="pr-5">
                    <h1 class="display-4 text-default font-weight-bold mb-0">
                      Kementerian
                    </h1>
                    <h1
                      class="display-3 text-default mb-0"
                      style="fontweight: 800"
                    >
                      Lingkungan Hidup <br />
                      dan Kehutanan
                    </h1>
                    <h1 class="display-4 text-default font-weight-bold mb-0">
                      Republik Indonesia
                    </h1>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-row class="pt-5">
                    <b-col md="12">
                      <img
                        src="img/theme/img-content-home.png"
                        alt="klhk"
                        style="width: 100%"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-container>
        </div>
      </div>
    </div>
    <!--Header-->

    <!-- Page content -->
    <section style="marginbottom: -30px">
      <b-row class="justify-content-center text-center">
        <b-col md="12">
          <div class="">
            <div class="row align-items-center justify-content-center">
              <div class="col-12" style="margin-top: 100px">
                <div class="card">
                  <div id="map-customx" class="map-canvas"></div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="mt--3">
      <div class="container">
        <label class="text-white">PILIH FILTER</label>
        <base-input>
          <select class="form-control" v-model="filterCompanyType">
            <option value="1">Berdasarkan Tahapan Progress</option>
            <option value="2">Berdasarkan 12 Industri</option>
          </select>
        </base-input>
      </div>
    </section>

    <section class="py-6 bg-white">
      <b-container>
        <b-row  class="row-grid">
          <b-col md="9" class="order-md-2">
            <div v-if="!isChartLoading">
              <div class="container text-center" v-show="filterCompanyType == 1">
                <div class="row align-items-start">
                  <div class="col">
                    <BasePieChart
                      :title="['Jumlah Perusahaan', 'Pendaftaran']"
                      :data="chartData.terdaftar"
                    />
                  </div>
                  <div class="col">
                    <BasePieChart
                      :title="['Jumlah Perusahaan', 'Terkoneksi']"
                      :data="chartData.terkoneksi"
                    />
                  </div>
                </div>
                <div class="row align-items-start">
                  <div class="col">
                    <BasePieChart
                      :title="['Jumlah Perusahaan', 'Pelaporan Progress']"
                      :data="chartData.melapor"
                    />
                  </div>
                  <div class="col">
                    <BasePieChart
                      :title="[
                        'Jumlah Perusahaan Belum',
                        'Melaporkan Progress',
                      ]"
                      :data="chartData.belumMelapor"
                    />
                  </div>
                </div>
              </div>





              <!-- sideb -->
              <div class="container text-center" v-show="filterCompanyType == 2">

                <div class="row align-items-start">
                  <div class="col">
                    <BasePieChart
                      :title="['Industri', 'Rayon']"
                      :data="chartData.rayon"
                    />
                  </div>
                  <div class="col">
                    <BasePieChart
                      :title="['Industri Pulp', '&/atau Paper']"
                      :data="chartData.pulpPaper"
                    />
                  </div>
                </div>
                <div class="row align-items-start">
                  <div class="col">
                    <BasePieChart
                      :title="['Industri Petrokimia', 'Hulu']"
                      :data="chartData.petrokimia"
                    />
                  </div>
                  <div class="col">
                    <BasePieChart
                      :title="['Industri Oleokimia', 'Dasar']"
                      :data="chartData.oleokimia"
                    />
                  </div>
                  <div class="col">
                    <BasePieChart
                      :title="['Industri Minyak', 'Sawit']"
                      :data="chartData.minyakSawit"
                    />
                  </div>
                  <div class="col">
                    <BasePieChart
                      :title="['Pengolahan', 'Minyak Bumi']"
                      :data="chartData.minyakBumi"
                    />
                  </div>
                  <div class="col">
                    <BasePieChart
                      :title="['Eksplorasi dan', 'Produksi Migas']"
                      :data="chartData.migas"
                    />
                  </div>
                  <div class="col">
                    <BasePieChart
                      :title="['Pertambangan', 'Emas dan Tembaga']"
                      :data="chartData.emasTembaga"
                    />
                  </div>
                  <div class="col">
                    <BasePieChart
                      :title="['Pertambangan', 'Batu Bara']"
                      :data="chartData.batuBara"
                    />
                  </div>
                  <div class="col">
                    <BasePieChart
                      :title="['Industri', 'Tekstil']"
                      :data="chartData.tekstil"
                    />
                  </div>
                  <div class="col">
                    <BasePieChart
                      :title="['Pertambangan', 'Nikel']"
                      :data="chartData.nikel"
                    />
                  </div>
                  <div class="col">
                    <BasePieChart
                      :title="['Kawasan', 'Industri']"
                      :data="chartData.industri"
                    />
                  </div>
                </div>
              </div>

            </div>
          </b-col>
          <!-- WARNA -->
          <b-col md="3" class="order-md-1">
            <div class="pr-md-5">
              <h1>Kategori Warna</h1>
              <ul class="list-unstyled mt-5" v-if="filterCompanyType == 1">
                <li class="py-2" v-for="data in companyType" :key="data.id">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge
                        pill
                        :style="{ background: data.color }"
                        class="badge-circle mr-3"
                      >
                        <i
                          class="ni ni-settings-gear-65"
                          style="display: none"
                        ></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0">{{ data.name }}</h4>
                    </div>
                  </div>
                </li>
              </ul>
              <ul class="list-unstyled mt-5" v-if="filterCompanyType == 2">
                <li class="py-2" v-for="data in companyTypeB" :key="data.id">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge
                        pill
                        :style="{ background: data.color }"
                        class="badge-circle mr-3"
                      >
                        <i
                          class="ni ni-settings-gear-65"
                          style="display: none"
                        ></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0">{{ data.name }}</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- modal -->
    <Modal
      :show="showAnnoncementModal"
      modalContentClasses="announcement-modal-background"
      modalClasses="modal-dialog-scrollable"
      @close="showAnnoncementModal = false"
      size="xl">
    <template v-slot:header>
      <div class="mdl-header">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <p>Pengumuman</p>
      </div>
    </template>
    <div>
      <div id="announcement" class="rounded  mdl-contentFront p-3">
        

        <section id="mytable">
            <el-table
              :data="announcementData"
              row-key="id"
              header-row-class-name="thead-light"
            >

              <el-table-column label="No" type="index" width="70" />



              <el-table-column min-width="120px"  label="Tanggal diterbitkan">
                <div slot-scope="action">
                  {{ action.row.publish_date }}
                </div>
              </el-table-column>

              <el-table-column min-width="550px" label="Keterangan">
                <div slot-scope="action">
                  <span v-html="action.row.content"> </span>
                </div>
              </el-table-column>
            </el-table>

            <div class="py-3">

                <el-select
                  class="select-primary pagination-select mr-3"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                  @change="changeSizePage()"
                  >
                  <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>

                <small class="card-category">
                Showing {{  1 }} to {{ announcementData.length }} of {{ pagination.total }} entries
                </small>
            </div>

            <div style="display: flex;justify-content: flex-end;">
              <base-pagination
                  class="pagination-no-border"
                  :current="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="pagination.total"
                  @change="paginationChanged($event)"
              >
              </base-pagination>
            </div>
           </section>




      </div>
    </div>
    <div class="mt-4">
      <div class="row">
        <div class="col text-right">
          <base-button
            class="bg-primary border-0"
            @click="showAnnoncementModal = false">
              Tutup
            </base-button>
        </div>
      </div>
    </div>
    </Modal>

    <!-- modal map -->
    <Modal
      :show="showAnnoncementModalDescription"
      modalContentClasses="mdl-content"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      @close="showAnnoncementModalDescription = false"
      size="xl"
    >
      <template v-slot:header>
        <div class="modal-title">
          <h4 class="mdl-title">Logger ID {{ description.loggerId }}</h4>
        </div>
      </template>
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentsannouncement-desc"
        >
          <div v-if="isLoading">
            <h2 class="text-center">Loading ...</h2>
          </div>
          <div v-else>
            <div>
              <div style="overflow-x: scroll;">
                <table class="table " >
                  <thead>
                    <tr>
                      <th>Nama Industri</th>
                      <th>Jenis Industri</th>
                      <th>Provinsi</th>
                      <th>Kabupaten / Kota</th>
                      <th>Alamat</th>
                      <th>Parameter</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ description.name }}</td>
                      <td>{{ description.type }}</td>
                      <td>{{ description.province }}</td>
                      <td>{{ description.regency }}</td>
                      <td>{{ description.address || "-" }}</td>
                      <td>{{ description.parameter }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr>

              <b-tabs pills>
                <b-tab active>
                  <template v-slot:title>
                    <i class="ni ni-bell-55 mr-2"></i> Logger
                  </template>
                  <p class="p-3">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Ph</th>
                          <th>COD</th>
                          <th>TSS</th>
                          <th>Debit</th>
                          <th>NH3N</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ description.ph }}</td>
                          <td>{{ description.cod }}</td>
                          <td>{{ description.tss }}</td>
                          <td>{{ description.debit }}</td>
                          <td>{{ description.nh3n }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </p>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <i class="ni ni-bell-55 mr-2"></i> Sensor
                  </template>
                  <p class="p-3">
                    Cosby sweater eu banh mi, qui irure terry richardson ex
                  </p>
                </b-tab>

              </b-tabs>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-danger border-0"
              @click="showAnnoncementModalDescription = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { Tooltip } from "element-ui";
import { API_KEY } from "@/views/Maps/API_KEY";
import GoogleMapsLoader from "google-maps";
import {
  tahapanProgressColors as TPColors,
  byIndustryColors as BIColors,
} from "@/data/infografis-colors";

import axios from "axios";
import Modal from "@/components/Modal.vue";
import BasePieChart from "../Components/BasePieChart.vue";
import API from '../../api/base_url';
import PengumumanTable from "../Tables/PengumumanTable.vue";
GoogleMapsLoader.KEY = API_KEY;
import { Select, Option, Table, TableColumn } from "element-ui";
import moment from 'moment'
import { payloadMaps } from "../../dummyAPI/payloadMaps";


export default {
  name: "home-page",
  components: {
    PengumumanTable,
    [Tooltip.name]: Tooltip,
    BasePieChart,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      isLoading: false,
      showAnnoncementModalDescription: false,
      description: {
        loggerId:"",
        name: "",
        type:"",
        address: "",
        province: "",
        regency: "",
        parameter: "",
        ph:"",
        cod:"",
        nh3n:"",
        debit:"",
        tss:""
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 1
      },
      isNotification: true,
      mapData: {},
      announcementData:[],
      filterCompanyType: "1",
      companyType: [
        { id: 1, name: "Industri Rayon", color: "#343A40" },
        { id: 2, name: "Industri Pulp/Kertas", color: "#3D9973" },
        { id: 3, name: "Industri Petrokimia Hulu", color: "#17A2B8" },
        { id: 4, name: "Industri Oleokimia Dasar", color: "#007BFF" },
        { id: 5, name: "Industri Minyak Sawit", color: "#FFC107" },
        { id: 6, name: "Pengolahan Minyak Bumi", color: "#80BDFF" },
        { id: 7, name: "Eksplorasi dan Produksi Migas", color: "#5635DC" },
        { id: 8, name: "Pertambangan Emas dan Tembaga", color: "#CE4418" },
        { id: 9, name: "Pertambangan Batu Bara", color: "#E83E8C" },
        { id: 10, name: "Industri Tekstil", color: "#DC3545" },
        { id: 11, name: "Pertambangan Nikel", color: "#28A745" },
        { id: 12, name: "Kawasan Industri", color: "#6AD182" },
      ],
      companyTypeB: [
        {
          id: 1,
          name: "Terkoneksi",
          value: "Jumlah Total Terkoneksi",
          color: "#28A745",
        },
        {
          id: 2,
          name: "Terdaftar",
          value: "Jumlah Perusahaan Pendaftaran",
          color: "#2F80ED",
        },
        {
          id: 3,
          name: "Melapor",
          value: "Jumlah Perusahaan Pelaporan Progress",
          color: "#EB5757",
        },
        {
          id: 4,
          name: "Belum",
          value: "Jumlah Perusahaan Belum Melaporkan Progress",
          color: "#FFC107",
        },
      ],
      isNotification: true,
      TPColors,
      BIColors,
      chartOptions: {},
      chartData: {},
      isChartLoading: true,
      showAnnoncementModal: true,
    };
  },
  created() {
    // this.getMyMaps();
    this.getChartData();  
    // this.getMyInfoGrafis();
    this.getAnnouncement()
  },
  mounted() {
    this.getMyMaps();
  },
  methods: {
    formatDate(timestamp) {
      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY - HH:mm:ss');
      return humanDate;
    },
    changeSizePage() {
        this.getAnnouncement()
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.getAnnouncement()
    },
    getMyMaps() {
      this.mapData = payloadMaps;
      this.initMap(this.mapData.centerLoc);
      // API.get("fronts/maps", )
      //   .then(({ data }) => {
      //     if (data.data == null) {
      //       this.mapData = [];
      //     } else {
      //       this.mapData = data.data;
      //     }

      //     let dummycenter_loc = {
      //       lat: "-2.4833826",
      //       lng: "117.8902853",
      //     };

      //     GoogleMapsLoader.load((google) => {
      //       this.initMap(google, dummycenter_loc);
      //     });
      //   })
      //   .catch((err) => {
      //     console.log(err,'------->');
      //   });
    },

    // CHART =============================================================================================================
    setChartData(data) {
      return {
        labels: data.map((item) => item.comp_type_name),
        series: data.map((item) => item.count),
        colors: data.map((item) => item.color),
      };
    },
    setByTahapanColor(data) {
      const result = data.map((item) => {
        return {
          ...item,
          color: TPColors.find((color) => color.name === item.comp_type_name).color,
        };
      });
      return this.setChartData(result);
    },
    setByIndustryColor(data) {

      // data.forEach(item => {
      //   if (!item.status) {
      //     item.status = 'belum'
      //   }
      // });



      const result = data.map((item) => {
        if(item.status){
          return {
            comp_type_name: BIColors.find((name) => name.name === item.status).value,
            count: item.count,
            color: BIColors.find((color) => color.name === item.status).color,
          };
        }
      });

      return this.setChartData(result);
    },
    async getChartData() {
      const { data } = await API.get(`front/infographic`);
      this.chartData.terdaftar = this.setByTahapanColor(data.data.by_tahapan.terdaftar);
      this.chartData.terkoneksi = this.setByTahapanColor(data.data.by_tahapan.terkoneksi);
      this.chartData.melapor = this.setByTahapanColor(data.data.by_tahapan.melapor);
      this.chartData.belumMelapor = this.setByTahapanColor(data.data.by_tahapan.belum);

    
      this.chartData.rayon = this.setByIndustryColor(data.data.by_industry.industri_rayon);
      this.chartData.pulpPaper = this.setByIndustryColor(data.data.by_industry.industri_pulp_kertas);
      this.chartData.petrokimia = this.setByIndustryColor(data.data.by_industry.industri_petrokimia_hulu);
      this.chartData.oleokimia = this.setByIndustryColor(data.data.by_industry.industri_oleokimia_dasar);
      this.chartData.minyakSawit = this.setByIndustryColor(data.data.by_industry.industri_minyak_sawit);
      this.chartData.minyakBumi = this.setByIndustryColor(data.data.by_industry.pengolahan_minyak_bumi);
      this.chartData.migas = this.setByIndustryColor(data.data.by_industry.eksplorasi_dan_produksi_migas);
      this.chartData.emasTembaga = this.setByIndustryColor(data.data.by_industry.pertambangan_emas_dan_tembaga);
      this.chartData.batuBara = this.setByIndustryColor(data.data.by_industry.pertambangan_batu_bara);
      this.chartData.tekstil = this.setByIndustryColor(data.data.by_industry.industri_tekstil);
      this.chartData.nikel = this.setByIndustryColor(data.data.by_industry.pertambangan_nikel);
      this.chartData.industri = this.setByIndustryColor(data.data.by_industry.kawasan_industri);
      this.isChartLoading = false;
    },
    getMyInfoGrafis() {
      axios.get('https://api.dev-klhk.rnbteknologiindonesia.com/api/v1/front/infographic')
        .then(({data}) => {
          // console.log(data.data.company_types[0],'yuhuuuuu');
          // for (let i = 0; i < data.data.company_types.length; i++) {
          //   switch (data.data.company_types[i].name) {
          //     case 'Industri Rayon':
          //       this.setByIndustryColor(data.data.company_types[i]);
          //       break;
          //     case 'Industri Pulp/Kertas':
          //       this.chartData.pulpPaper = data.data.company_types[i]
          //       break;
          //     case 'Industri Petrokimia Hulu':
          //       this.chartData.petrokimia = data.data.company_types[i]
          //       break;
          //     case 'Industri Oleokimia Dasar':
          //       this.chartData.oleokimia = data.data.company_types[i]
          //       break;
          //     case 'Industri Minyak Sawit':
          //       this.chartData.minyakSawit = data.data.company_types[i]
          //       break;
          //     case 'Pengolahan Minyak Bumi':
          //       this.chartData.minyakBumi = data.data.company_types[i]
          //       break;
            
          //     default:
          //       break;
          //   }
            
          // }


          this.chartData.terdaftar = this.setByTahapanColor(data.data.by_tahapan.terdaftar);
          this.chartData.terkoneksi = this.setByTahapanColor(data.data.by_tahapan.terkoneksi);
          this.chartData.melapor = this.setByTahapanColor(data.data.by_tahapan.melapor);
          this.chartData.belumMelapor = this.setByTahapanColor(data.data.by_tahapan.belum);

          // console.log(this.setByIndustryColor(data.data.company_types["Industri Rayon"]),'------------>');

          for (let i = 0; i < data.data.company_types.length; i++) {

              switch (data.data.company_types[i].name) {
                case "Industri Rayon":
                  this.setByIndustryColor(data.data.company_types[i])
                  this.chartData.rayon = data.data.company_types[i]
                  break;

                case "Industri Pulp/Kertas":
                  this.chartData.pulpPaper = data.data.company_types[i]
                  // this.setByIndustryColor(data.data.company_types[i])
                  break;

                case "Industri Petrokimia Hulu":
                  console.log(data.data.company_types[i]);
                  // this.setByIndustryColor(data.data.company_types[i])
                  break;

                case "Industri Oleokimia Dasar":
                  console.log(data.data.company_types[i]);
                  // this.setByIndustryColor(data.data.company_types[i])
                  break;

                case "Industri Minyak Sawit":
                  console.log(data.data.company_types[i]);
                  // this.setByIndustryColor(data.data.company_types[i])
                  break;

                case "Pengolahan Minyak Bumi":
                  console.log(data.data.company_types[i]);
                  // this.setByIndustryColor(data.data.company_types[i])
                  break;

                case "Eksplorasi dan Produksi Migasi":
                  console.log(data.data.company_types[i]);
                  // this.setByIndustryColor(data.data.company_types[i])
                  break;

                case "Pertambangan Emas dan Tembaga":
                  console.log(data.data.company_types[i]);
                  // this.setByIndustryColor(data.data.company_types[i])
                  break;

                case "Pertambangan Batu Bara":
                  console.log(data.data.company_types[i]);
                  // this.setByIndustryColor(data.data.company_types[i])
                  break;

                case "Industri Tekstil":
                  console.log(data.data.company_types[i]);
                  // this.setByIndustryColor(data.data.company_types[i])
                  break;

                case "Pertambangan Nikel":
                  console.log(data.data.company_types[i]);
                  // this.setByIndustryColor(data.data.company_types[i])
                  break;

                case "Kawasan Industri":
                  console.log(data.data.company_types[i]);
                  // this.setByIndustryColor(data.data.company_types[i])
                  break;
              
                default:
                  break;
              }
            
          }


          // this.chartData.rayon = this.setByIndustryColor(data.data.company_types["Industri Rayon"]);
          // this.chartData.pulpPaper = this.setByIndustryColor(data.data.company_types["Industri Pulp/Kertas"]);
          // this.chartData.petrokimia = this.setByIndustryColor(data.data.company_types["Industri Petrokimia Hulu"]);
          // this.chartData.oleokimia = this.setByIndustryColor(data.data.company_types["Industri Oleokimia Dasar"]);
          // this.chartData.minyakSawit = this.setByIndustryColor(data.data.company_types["Industri Minyak Sawit"]);
          // this.chartData.minyakBumi = this.setByIndustryColor(data.data.company_types["Pengolahan Minyak Bumi"]);
          // this.chartData.migas = this.setByIndustryColor(data.data.company_types["Eksplorasi dan Produksi Migas"]);
          // this.chartData.emasTembaga = this.setByIndustryColor(data.data.company_types["Pertambangan Emas dan Tembaga"]);
          // this.chartData.batuBara = this.setByIndustryColor(data.data.company_types["Pertambangan Batu Bara"]);
          // this.chartData.tekstil = this.setByIndustryColor(data.data.company_types["Industri Tekstil"]);
          // this.chartData.nikel = this.setByIndustryColor(data.data.company_types["Pertambangan Nikel"]);
          // this.chartData.industri = this.setByIndustryColor(data.data.company_types["Kawasan Industri"]);
          // end
          this.isChartLoading = false;
        })
        .catch((err) => {
          console.log(err);
        })
    },

    
    
    // =======================================================================================================================
    // initMap(google, centerMap) {
    //   let map,
    //     lat = centerMap.lat,
    //     lng = centerMap.lng,
    //     color = "#5e72e4";
    //   map = document.getElementById("map-custom");

    //   let centerLoc = new google.maps.LatLng(lat, lng);
    //   let mapOptions = {
    //     zoom: this.mapData.zoom,
    //     center: centerLoc,
    //     gestureHandling: "greedy",
    //     mapTypeId: google.maps.MapTypeId.ROADMAP,
    //     scrollwheel: false,
    //   };

    //   map = new google.maps.Map(map, mapOptions);
    //   let blueIcon = "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
    //   let redIcon = "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
    //   let orangeIcon =
    //     "http://maps.google.com/mapfiles/ms/icons/orange-dot.png";
    //   let greenIcon = "http://maps.google.com/mapfiles/ms/icons/green-dot.png";

    //   var InfoWindows = new google.maps.InfoWindow({});
    //   //NEW LOGGER MAP
    //   let markerComp = this.mapData.comp;

    //   let compMarker = [];
    //   for (let k = 0; k < markerComp.length; k++) {
    //     let posComp = new google.maps.LatLng(
    //       markerComp[k].coordinate.lat,
    //       markerComp[k].coordinate.lng
    //     );
    //     if (markerComp[k].connected_status != "Terkoneksi") {
    //       compMarker[k] = new google.maps.Marker({
    //         icon: { url: redIcon },
    //         position: posComp,
    //         map: map,
    //         // animation: google.maps.Animation.DROP,
    //         compID: markerComp[k].uuid,
    //         title: markerComp[k].name,
    //       });
    //     } else {
    //       compMarker[k] = new google.maps.Marker({
    //         icon: { url: greenIcon },
    //         position: posComp,
    //         map: map,
    //         // animation: google.maps.Animation.DROP,
    //         compID: markerComp[k].uuid,
    //         title: markerComp[k].name,
    //       });
    //     }

    //     // hover content and title setting
    //     compMarker[k].addListener("click", function () {
    //       InfoWindows.open(map, this);
    //       InfoWindows.setContent(
    //         `<div id="content"><div id="siteNotice"></div><h3 id="firstHeading" class="firstHeading">${markerComp[k].name}</h3><div id="bodyContent"><p><b>Alamat : </b> ${markerComp[k].address}</p> <p>${markerComp[k].province} - ${markerComp[k].regency}</p><p><b>Status : </b>${markerComp[k].connected_status}</p><p><b>Parameter : </b>${markerComp[k].parameter}</p></div></div>`
    //       );
    //     });
    //     // end hover content and title setting

    //     compMarker[k].addListener("click", () => {
    //       // console.log(compMarker[k].compID);
    //       this.insertParamClickMarker(compMarker[k].compID, "comp");
    //     });
    //   }

    //   // CREATE PROV MARKER
    //   // var centerControlDiv = document.createElement("div");
    //   // var centerControl = new this.CenterControl(centerControlDiv, map, this);

    //   // centerControlDiv.index = 1;
    //   // map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);

    //   // var provMarker = [];
    //   // var kabkotMarker = [];
    //   // var compMarker = [];

    //   // if (this.mapData.prov.length > 0) {
    //   //   for (let i = 0; i < this.mapData.prov.length; i++) {
    //   //     let pos = new google.maps.LatLng(
    //   //       this.mapData.prov[i].latlong.lat,
    //   //       this.mapData.prov[i].latlong.lng
    //   //     );
    //   //     provMarker[i] = new google.maps.Marker({
    //   //       icon: { url: orangeIcon },
    //   //       position: pos,
    //   //       map: map,
    //   //       // animation: google.maps.Animation.DROP,
    //   //       provID: this.mapData.prov[i]._id,
    //   //       title: this.mapData.prov[i].provName
    //   //     });
    //   //     provMarker[i].addListener("click", () => {
    //   //       this.insertParamClickMarker(provMarker[i].provID, "prov");
    //   //       let provCenter = new google.maps.LatLng(
    //   //         this.mapData.prov[i].latlong.lat,
    //   //         this.mapData.prov[i].latlong.lng
    //   //       );
    //   //       map.setZoom(10);
    //   //       map.setCenter(provCenter);
    //   //       removeMarker(provMarker);
    //   //       let markerKabkot = this.mapData.kabkot.filter(data => {
    //   //         return data.provID === provMarker[i].provID;
    //   //       });
    //   //       for (let j = 0; j < markerKabkot.length; j++) {
    //   //         let posKabkot = new google.maps.LatLng(
    //   //           markerKabkot[j].latlong.lat,
    //   //           markerKabkot[j].latlong.lng
    //   //         );

    //   //         kabkotMarker[j] = new google.maps.Marker({
    //   //           icon: { url: blueIcon },
    //   //           position: posKabkot,
    //   //           map: map,
    //   //           // animation: google.maps.Animation.DROP,
    //   //           kabkotID: markerKabkot[j]._id,
    //   //           title: markerKabkot[j].kabkotName
    //   //         });
    //   //         kabkotMarker[j].addListener("click", () => {
    //   //           this.insertParamClickMarker(kabkotMarker[j].kabkotID, "kabkot");
    //   //           map.setZoom(14);
    //   //           map.setCenter(posKabkot);
    //   //           removeMarker(kabkotMarker);
    //   //           let markerComp = this.mapData.comp.filter(data => {
    //   //             return data.kabkotID === kabkotMarker[j].kabkotID;
    //   //           });
    //   //           for (let k = 0; k < markerComp.length; k++) {
    //   //             let posComp = new google.maps.LatLng(
    //   //               markerComp[k].latlong.lat,
    //   //               markerComp[k].latlong.lng
    //   //             );
    //   //             compMarker[k] = new google.maps.Marker({
    //   //               icon: { url: greenIcon },
    //   //               position: posComp,
    //   //               map: map,
    //   //               // animation: google.maps.Animation.DROP,
    //   //               compID: markerComp[k]._id,
    //   //               title: markerComp[k].compName
    //   //             });
    //   //             compMarker[k].addListener("click", () => {
    //   //               this.insertParamClickMarker(compMarker[k].compID, "comp");
    //   //             });
    //   //           }
    //   //         });
    //   //       }
    //   //     });
    //   //   }
    //   // } else if (this.mapData.kabkot.length > 0) {
    //   //   for (let j = 0; j < this.mapData.kabkot.length; j++) {
    //   //     let posKabkot = new google.maps.LatLng(
    //   //       this.mapData.kabkot[j].latlong.lat,
    //   //       this.mapData.kabkot[j].latlong.lng
    //   //     );

    //   //     kabkotMarker[j] = new google.maps.Marker({
    //   //       icon: { url: blueIcon },
    //   //       position: posKabkot,
    //   //       map: map,
    //   //       // animation: google.maps.Animation.DROP,
    //   //       kabkotID: this.mapData.kabkot[j]._id,
    //   //       title: this.mapData.kabkot[j].kabkotName
    //   //     });
    //   //     kabkotMarker[j].addListener("click", () => {
    //   //       this.insertParamClickMarker(kabkotMarker[j].kabkotID, "kabkot");
    //   //       map.setZoom(14);
    //   //       map.setCenter(posKabkot);
    //   //       removeMarker(kabkotMarker);
    //   //       let markerComp = this.mapData.comp.filter(data => {
    //   //         return data.kabkotID === kabkotMarker[j].kabkotID;
    //   //       });
    //   //       for (let k = 0; k < markerComp.length; k++) {
    //   //         let posComp = new google.maps.LatLng(
    //   //           markerComp[k].latlong.lat,
    //   //           markerComp[k].latlong.lng
    //   //         );
    //   //         compMarker[k] = new google.maps.Marker({
    //   //           icon: { url: greenIcon },
    //   //           position: posComp,
    //   //           map: map,
    //   //           // animation: google.maps.Animation.DROP,
    //   //           compID: markerComp[k]._id,
    //   //           title: markerComp[k].compName
    //   //         });
    //   //         compMarker[k].addListener("click", () => {
    //   //           this.insertParamClickMarker(compMarker[k].compID, "comp");
    //   //         });
    //   //       }
    //   //     });
    //   //   }
    //   // } else if (this.mapData.comp.length > 0) {
    //   //   for (let k = 0; k < this.mapData.comp.length; k++) {
    //   //     let posComp = new google.maps.LatLng(
    //   //       this.mapData.comp[k].latlong.lat,
    //   //       this.mapData.comp[k].latlong.lng
    //   //     );
    //   //     compMarker[k] = new google.maps.Marker({
    //   //       icon: { url: greenIcon },
    //   //       position: posComp,
    //   //       map: map,
    //   //       // animation: google.maps.Animation.DROP,
    //   //       compID: this.mapData.comp[k]._id,
    //   //       title: this.mapData.comp[k].compName
    //   //     });
    //   //     compMarker[k].addListener("click", () => {
    //   //       this.insertParamClickMarker(compMarker[k].compID, "comp");
    //   //     });
    //   //   }
    //   // }

    //   // function insertMarker(markerName) {
    //   //   for (let i = 0; i < markerName.length; i++) {
    //   //     markerName[i].setMap(map);
    //   //   }
    //   // }

    //   // function removeMarker(markerName) {
    //   //   for (let i = 0; i < markerName.length; i++) {
    //   //     markerName[i].setMap(null);
    //   //   }
    //   // }
    // },
    closeNotification() {
      this.isNotification = false;
    },
    CenterControl(controlDiv, map, state) {
      // Set CSS for the control border.
      var controlUI = document.createElement("div");
      controlUI.style.backgroundColor = "#dd2c00";
      controlUI.style.borderRadius = "3px";
      controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlUI.style.cursor = "pointer";
      controlUI.style.margin = "10px";
      controlUI.style.textAlign = "center";
      controlUI.title = "Click to recenter the map";
      controlDiv.appendChild(controlUI);

      // Set CSS for the control interior.
      var controlText = document.createElement("div");
      controlText.style.color = "#fff";
      controlText.style.fontFamily = "Roboto,Arial,sans-serif";
      controlText.style.fontSize = "16px";
      controlText.style.lineHeight = "40px";
      controlText.style.paddingLeft = "16px";
      controlText.style.paddingRight = "16px";
      controlText.textContent = "Reset Map";
      controlUI.appendChild(controlText);

      // Setup the click event listeners: simply set the map to Chicago.
      controlUI.addEventListener("click", function () {
        state.resetMap();
        // map.setCenter(chicago);
      });
    },
    resetMap() {
      this.initMap(this.mapData.centerLoc);
    },
    async initMap(centerMap) {
      let L = require('leaflet');
      let map,
            lat = centerMap.lat,
            lng = centerMap.lng,
            color = "#5e72e4";
        map = document.getElementById("map-customx")

        let centerLoc = [lat, lng];
        let zoomLevel = this.mapData.zoom;

        
        map = L.map(map, {
            scrollWheelZoom: false
        }).setView(centerLoc, zoomLevel);
       

       
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        let mcg = L.markerClusterGroup({
          chunkedLoading: true,
          spiderfyOnMaxZoom: false,
          iconCreateFunction: function (cluster) {
              let childCount = cluster.getChildCount();
              let c = ' marker-cluster-';
              if (childCount < 10) {
                  c += 'small';
              } else if (childCount < 100) {
                  c += 'medium';
              } else {
                  c += 'large';
              }
              // Ganti ini dengan warna latar belakang yang Anda inginkan
              return new L.DivIcon({
                  html: '<div style="background-color: #618264;color:white;font-weight:bold; border-radius: 50%; width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;"><span>' + childCount + '</span></div>',
                  className: 'marker-cluster' + c,
                  iconSize: new L.Point(60, 60)
              });
          }
        });
        // Data marker
        let markerComp = this.mapData.comp;

        for (let k = 0; k < markerComp.length; k++) {
          if (markerComp[k].latlong.lat) {
              let posComp = [markerComp[k].latlong.lat, markerComp[k].latlong.lng];
              let iconUrl = markerComp[k].activationLogger != "Terkoneksi" ? require('../../../public/img/icons/map/marker-icon-2x-red.png') : require('../../../public/img/icons/map/marker-icon-2x-green.png');
              let icon = L.icon({
                  iconUrl: iconUrl,
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                  popupAnchor: [1, -34],
                  tooltipAnchor: [16, -28],
                  shadowSize: [41, 41]
              });

              let marker = L.marker(posComp, { icon: icon });
              
              // Memisahkan nama parameter dari parameterLogger yang dipisahkan oleh koma
              let parameterNames = markerComp[k].parameterLogger.split(",").filter(param => param.trim() !== ""); // Filter untuk menghapus nilai yang kosong

              // Membuat daftar nama parameter yang dimiliki oleh beban_sensor
              let bebanSensorNames = markerComp[k].beban_sensor.map(sensor => sensor.name);

              // Menemukan nama-nama parameter yang dimiliki oleh kedua daftar
              let commonParameterNames = parameterNames.filter(param => bebanSensorNames.includes(param));

              let parameterString = parameterNames.join(", ");

              // Membuat tabel HTML dari nama parameter yang sama dan hasil perhitungan beban sensor
              let sensorTable = "<table class='table table-striped'><tr><th>Nama Sensor</th><th>Hasil Perhitungan</th></tr>";
              markerComp[k].beban_sensor.forEach(sensor => {
                  if (commonParameterNames.includes(sensor.name)) {
                      sensorTable += `<tr><td>${sensor.name} KG/Jam </td><td>${(sensor.calculation ? sensor.calculation.toFixed(4) : sensor.calculation)}</td></tr>`;
                  }
              });
              sensorTable += "</table>";

              // Tambahkan popup ke marker dengan tambahan informasi beban sensor dalam bentuk tabel
              let popupContent = `<div id="content">
                        <div id="siteNotice"></div>
                        <h3 id="firstHeading" class="firstHeading">${markerComp[k].compName}</h3>
                        <div id="bodyContent">
                            <table>
                              <tr>
                                  <td style="text-align:left; width:30%;"><b>Alamat</b></td>
                                  <td style="vertical-align: middle;">${markerComp[k].compAddress}</td>
                              </tr>
                              <tr>
                                  <td style="text-align:left;"><b>Kabupaten/Kota</b></td>
                                  <td style="vertical-align: middle;">${markerComp[k].kabkotName}</td>
                              </tr>
                              <tr>
                                  <td style="text-align:left;"><b>Provinsi</b></td>
                                  <td style="vertical-align: middle;">${markerComp[k].provName}</td>
                              </tr>
                              <tr>
                                  <td style="text-align:left;"><b>Status</b></td>
                                  <td style="vertical-align: middle;">${markerComp[k].activationLogger}</td>
                              </tr>
                              <tr>
                                  <td style="text-align:left;"><b>Parameter</b></td>
                                  <td style="vertical-align: middle;">${parameterString}</td>
                              </tr>
                              <tr>
                                  <td style="text-align:left;"><b>Beban Sensor</b></td>
                                  <td style="vertical-align: middle;">${sensorTable}</td>
                              </tr>
                            </table>
                        </div>
                    </div>`;

              marker.bindPopup(popupContent);
              mcg.addLayer(marker);
          }
        }
        map.addLayer(mcg);
    },
    getAnnouncement() {
      let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get(`announcements?page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}` , {headers})
        .then(({data}) => {
          if (data.data == null) {
            this.announcementData = []
          } else {
            this.announcementData = data.data
            this.pagination.total = data.meta.pagination.total_items
          }
            this.isLoading = false
        })
        .catch((err) => {
          console.log(err);
      })
    }
  },
};
</script>

<style scoped>
.mdl-contentFront {
  background: #fbcca0;
  background: #fff;
  /* max-height: 90%; */
  height: 550px;
  overflow-y: scroll;
}
.mdl-header {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  column-gap: 1rem;
}
.mdl-header i {
  font-size: 30px;
  color: #00bcd4;
  color: #FFFFFF;
}
.mdl-header p {
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
}
.table-header-class {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
}
</style>

<style>
.popup-logo {
  position: absolute !important;
  background-color: transparent;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  padding: 100px;
}
.bg-logonotif {
  backdrop-filter: blur(3px) saturate(180%);
  -webkit-backdrop-filter: blur(3px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  width: 75vw;
}
.btn-closes {
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
  z-index: 999;
}
.btn-closes button {
  font-size: 20px;
  margin-right: 29px;
}

.map-canvas {
  height: 500px;
}

.announcement-modal-background {
  background: #F9AA61;
}
@media only screen and (max-width: 768px) {
  .map-canvas {
    height: 470px;
  }
  .popup-logo {
    z-index: -1;
  }
}

.wallpaperHome {
  background-image: url("../../../public/img/theme/img-background-full.png");
  background-size: contain;
}
</style>

export const payloadMaps = {
    "zoom": 5,
    "centerLoc": {
        "lat": -2.4833826,
        "lng": 117.8902853
    },
    "comp": [
        {
            "_id": "5f572c1d36b37d2585a64c54",
            "compName": "PT Multi Harapan Utama",
            "compAddress": "Jl. Yos Sudarso RT. 17, Dusun IV, Desa Loa Kulu Kota, Kec. Loa Kulu, Kab. Kutai Kartanegara, Kalimantan Timur",
            "latlong": {
                "lat": -0.536822222222222,
                "lng": 117.032138888889
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede717e18a7a560d399ce9",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede717e18a7a560d399ce9",
                    "name": "TSS",
                    "calculation": 0.8053492445069356
                },
                {
                    "_id": "65ede717e18a7a560d399ce9",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "5f59995c834eec2265d8041f",
            "compName": "PT.Tanjungenim Lestari Pulp and Paper",
            "compAddress": "Desa Banuayu Kecamatan Rambang Dangku Kabupaten Muara Enim Provinsi Sumatra Selatan",
            "latlong": {
                "lat": -3.430139,
                "lng": 104.044056
            },
            "kabkotName": "Kabupaten Muara Enim",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399d22",
                    "name": "COD",
                    "calculation": 11610.364419310346
                },
                {
                    "_id": "65ede718e18a7a560d399d22",
                    "name": "TSS",
                    "calculation": 314.8525091172413
                },
                {
                    "_id": "65ede718e18a7a560d399d22",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "5f619c497c5fc71255fa376b",
            "compName": "PT Adaro Indonesia",
            "compAddress": "desa maburai kecamatan murung pudak",
            "latlong": {
                "lat": -2.14335833333333,
                "lng": 115.508041666667
            },
            "kabkotName": "Kabupaten Tabalong",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "5f6322617c5fc71255fca135",
            "compName": "PT Meares Soputan Mining",
            "compAddress": "Toka Tindung Project Site",
            "latlong": {
                "lat": 1.59923,
                "lng": 125.08778
            },
            "kabkotName": "Kabupaten Minahasa Utara",
            "provName": "Sulawesi Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399d31",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399d31",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399d31",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "5f6951d07c5fc712550ce3f2",
            "compName": "PT. Toba Pulp Lestari, Tbk",
            "compAddress": "Desa Pangombusan",
            "latlong": {
                "lat": 2.4659444,
                "lng": 99.2040138888889
            },
            "kabkotName": "Kabupaten Toba Samosir",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399d3b",
                    "name": "COD",
                    "calculation": 2391.398377167025
                },
                {
                    "_id": "65ede718e18a7a560d399d3b",
                    "name": "TSS",
                    "calculation": 26.36869906155163
                },
                {
                    "_id": "65ede718e18a7a560d399d3b",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "5f6c11a97c5fc71255150758",
            "compName": "PT. Borneo Indobara",
            "compAddress": "Jl. Propinsi Km. 180, RT.02, RW.01, Kecamatan Angsana, Kabupaten Tanah Bumbu, Kalimantan Selatan",
            "latlong": {
                "lat": -3.596722,
                "lng": 115.652306
            },
            "kabkotName": "Kabupaten Tanah Bumbu",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399d41",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399d41",
                    "name": "TSS",
                    "calculation": 2.7811970391666665
                },
                {
                    "_id": "65ede718e18a7a560d399d41",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "5f7140e58ca755794f7a9087",
            "compName": "PT. ASPEX KUMBONG",
            "compAddress": "Jalan Raya Narogong KM. 26 Desa Dayeuh Cileungsi",
            "latlong": {
                "lat": -6.428083,
                "lng": 106.952361
            },
            "kabkotName": "Kabupaten Bogor",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399d49",
                    "name": "COD",
                    "calculation": 1104.9469969335885
                },
                {
                    "_id": "65ede718e18a7a560d399d49",
                    "name": "TSS",
                    "calculation": 413.390863749255
                },
                {
                    "_id": "65ede718e18a7a560d399d49",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "5f8d435e01875227691fe8bf",
            "compName": "PT. Kaltim Prima Coal",
            "compAddress": "Jl. Dr. Soetomo, Sangatta",
            "latlong": {
                "lat": 0.697458,
                "lng": 117.517664
            },
            "kabkotName": "Kabupaten Kutai Timur",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399d69",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399d69",
                    "name": "TSS",
                    "calculation": 81.22419142393593
                },
                {
                    "_id": "65ede718e18a7a560d399d69",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "5fa4be1c111e6c2310b18230",
            "compName": "PT Riau Andalan Pulp and Paper",
            "compAddress": "Jalan Lintas Timur, Pangkalan Kerinci, Kabupaten Pelalawan, Riau 28300",
            "latlong": {
                "lat": 0.43969444444444444,
                "lng": 101.90125
            },
            "kabkotName": "Kabupaten Pelalawan",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399d7a",
                    "name": "COD",
                    "calculation": 52170.30554831322
                },
                {
                    "_id": "65ede718e18a7a560d399d7a",
                    "name": "TSS",
                    "calculation": 14471.26020535728
                },
                {
                    "_id": "65ede718e18a7a560d399d7a",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "5fa4c828111e6c2310b184d3",
            "compName": "PT Asia Pacific Rayon",
            "compAddress": "Jalan Lintas Timur, Pangkalan Kerinci, Kabupaten Pelalawan, Riau 28300",
            "latlong": {
                "lat": 0.4406388888888889,
                "lng": 101.90058333333334
            },
            "kabkotName": "Kabupaten Pelalawan",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399d84",
                    "name": "COD",
                    "calculation": 2562.5044232746163
                },
                {
                    "_id": "65ede718e18a7a560d399d84",
                    "name": "TSS",
                    "calculation": 713.5795464913315
                },
                {
                    "_id": "65ede718e18a7a560d399d84",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "5fa4c8d7111e6c2310b184fe",
            "compName": "PT Kilang Pertamina Internasional Refinery Unit II Dumai",
            "compAddress": "PT Kilang Pertamina Internasional Refinery Unit II Dumai Main Office ",
            "latlong": {
                "lat": 1.6830555555555553,
                "lng": 101.46416666666667
            },
            "kabkotName": "Kota Dumai",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399d95",
                    "name": "COD",
                    "calculation": 256.6071392466221
                },
                {
                    "_id": "65ede718e18a7a560d399d95",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399d95",
                    "name": "NH3N",
                    "calculation": 12.662114006845039
                }
            ]
        },
        {
            "_id": "5fc87ce2111e6c2310b95720",
            "compName": "PT Kideco Jaya Agung",
            "compAddress": "Mine Site, Desa Batu Kajang, Kecamatan Batu Sopang, Kabupaten 76252, Kalimantan Timur, Indonesia",
            "latlong": {
                "lat": -1.897361111,
                "lng": 115.8794444
            },
            "kabkotName": "Kabupaten Paser",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399d99",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399d99",
                    "name": "TSS",
                    "calculation": 256.6269230527675
                },
                {
                    "_id": "65ede718e18a7a560d399d99",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "608124ec338ec715342824d6",
            "compName": "PT PERTAMINA HULU ROKAN - MINAS SIAK",
            "compAddress": "PT CPI - Petapahan Camp",
            "latlong": {
                "lat": 0.681481,
                "lng": 101.119903
            },
            "kabkotName": "Kabupaten Kampar",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399da7",
                    "name": "COD",
                    "calculation": 992.3669386652781
                },
                {
                    "_id": "65ede718e18a7a560d399da7",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399da7",
                    "name": "NH3N",
                    "calculation": 5.491059482222224
                }
            ]
        },
        {
            "_id": "60813876338ec71534282c2e",
            "compName": "PT Pertamina Hulu Rokan (PHR) - Bekasap Rokan",
            "compAddress": "PT CPI Bangko Camp, Bangko",
            "latlong": {
                "lat": 1.52723,
                "lng": 100.998
            },
            "kabkotName": "Kabupaten Rokan Hilir",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399db1",
                    "name": "COD",
                    "calculation": 53.74507981843751
                },
                {
                    "_id": "65ede718e18a7a560d399db1",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399db1",
                    "name": "NH3N",
                    "calculation": 4.987152716736113
                }
            ]
        },
        {
            "_id": "60933de79e8f051e2366ff1b",
            "compName": "PT. Pertamina Hulu Energi Offshore North West Java (PHE ONWJ)",
            "compAddress": "PHE Tower, OPF Balongan, Jalan Wisma Jati, Desa Balongan, Kecamatan Balongan, Kabupaten Indramayu, Provinsi Jawa Barat, 45285",
            "latlong": {
                "lat": -6.36911111111111,
                "lng": 108.384083333333
            },
            "kabkotName": "Kabupaten Indramayu",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399dbf",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399dbf",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399dbf",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "60af0fe15173d010f431029e",
            "compName": "PT. BERAU COAL - SITE LATI",
            "compAddress": "Kecamatan Gunung Tabur dan Kecamatan Sambaliung - Kabupaten Berau, Kalimantan Timur",
            "latlong": {
                "lat": 2.32317,
                "lng": 117.582
            },
            "kabkotName": "Kabupaten Berau",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399dc7",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399dc7",
                    "name": "TSS",
                    "calculation": 0.31215628246593796
                },
                {
                    "_id": "65ede718e18a7a560d399dc7",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "60af106e5173d010f43102e4",
            "compName": "PT. BERAU COAL - SITE SAMBARATA",
            "compAddress": "Tasuk, Kecamatan Gunung Tabur, Kabupaten Berau, Kalimantan Timur",
            "latlong": {
                "lat": 2.164481,
                "lng": 117.410621
            },
            "kabkotName": "Kabupaten Berau",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399dcd",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399dcd",
                    "name": "TSS",
                    "calculation": 5.355005231481482
                },
                {
                    "_id": "65ede718e18a7a560d399dcd",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "60af13315173d010f43103f9",
            "compName": "PT. BERAU COAL - SITE BINUNGAN",
            "compAddress": "Suaran, Kec Sambaliung, Kabupaten Berau, Kalimantan Timur",
            "latlong": {
                "lat": 2.05241667,
                "lng": 117.45833333
            },
            "kabkotName": "Kabupaten Berau",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399dd5",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399dd5",
                    "name": "TSS",
                    "calculation": 11.080028750208053
                },
                {
                    "_id": "65ede718e18a7a560d399dd5",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "60b099d8bf2058712e1016c8",
            "compName": "PT. Trans-Pacific Petrochemical Indotama",
            "compAddress": "Desa Remen-Tasikharjo , Kecamatan Jenu",
            "latlong": {
                "lat": -6.76458333,
                "lng": 111.9605556
            },
            "kabkotName": "Kabupaten Tuban",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399ddb",
                    "name": "COD",
                    "calculation": 45.043926503897545
                },
                {
                    "_id": "65ede718e18a7a560d399ddb",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399ddb",
                    "name": "NH3N",
                    "calculation": 0.21397305833333327
                }
            ]
        },
        {
            "_id": "60bf0561edcb2a54a15ccd7d",
            "compName": "PT. Vale Indonesia Tbk.",
            "compAddress": "Plant Site Sorowako. Kecamatan Nuha, Kabupaten Luwu Timur, Sulawesi Selatan 92984",
            "latlong": {
                "lat": -2.533221,
                "lng": 121.499464
            },
            "kabkotName": "Kabupaten Luwu Timur",
            "provName": "Sulawesi Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399de4",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399de4",
                    "name": "TSS",
                    "calculation": 10105.968884411875
                },
                {
                    "_id": "65ede718e18a7a560d399de4",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "60d9851b3b964f07dc349b4f",
            "compName": "PT. Pertamina (Persero) RU V - Kilang Balikpapan",
            "compAddress": "Jl. Yos Sudarso No. 1 Balikpapan",
            "latlong": {
                "lat": -1.243633,
                "lng": 116.816608
            },
            "kabkotName": "Kota Balikpapan",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399dea",
                    "name": "COD",
                    "calculation": 12.43789669090909
                },
                {
                    "_id": "65ede718e18a7a560d399dea",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399dea",
                    "name": "NH3N",
                    "calculation": 0.013705461818181823
                }
            ]
        },
        {
            "_id": "60e7ae81ec78fc2d94d878e7",
            "compName": "PT. Megalopolis Manunggal Industrial Development (MM2100)",
            "compAddress": "Jalan Sumatra Blok C Kawasan Industri MM2100 Kelurahan Gandasari, Kecamatan Cikarang Barat, Kabupaten Bekasi Provinsi Jawa Barat 17520",
            "latlong": {
                "lat": -6.296833,
                "lng": 107.09972
            },
            "kabkotName": "Kabupaten Bekasi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399dee",
                    "name": "COD",
                    "calculation": 479.8632262344488
                },
                {
                    "_id": "65ede718e18a7a560d399dee",
                    "name": "TSS",
                    "calculation": 58.10657146650836
                },
                {
                    "_id": "65ede718e18a7a560d399dee",
                    "name": "NH3N",
                    "calculation": 1.5561132433083864
                }
            ]
        },
        {
            "_id": "60f1349c5bd37e089effd011",
            "compName": "PT. Lahai Coal",
            "compAddress": "Desa Muara Tuhup, Kec. Laung Tuhup, Kab. Murung Raya",
            "latlong": {
                "lat": -0.321706,
                "lng": 114.825
            },
            "kabkotName": "Kabupaten Murung Raya",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399df3",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399df3",
                    "name": "TSS",
                    "calculation": 7.742032260471631
                },
                {
                    "_id": "65ede718e18a7a560d399df3",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "60f134b75bd37e089effd01f",
            "compName": "PT. Maruwai Coal ",
            "compAddress": "KEC. LAUNG TUHUP, KAB. MURUNG RAYA, PROV. KALIMANTAN TENGAH",
            "latlong": {
                "lat": -0.1129083,
                "lng": 114.886
            },
            "kabkotName": "Kabupaten Murung Raya",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399dfb",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399dfb",
                    "name": "TSS",
                    "calculation": 44.925000000000004
                },
                {
                    "_id": "65ede718e18a7a560d399dfb",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "60f135185bd37e089effd04d",
            "compName": "PT. Jorong Barutama Greston",
            "compAddress": "Jl. A. Yani Km.104 Desa Swarangan RT.07 No.286 Kecamatan Jorong Kabupaten Tanah Laut Kalimantan Selatan",
            "latlong": {
                "lat": -3.904222,
                "lng": 115.017611
            },
            "kabkotName": "Kabupaten Tanah Laut",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e04",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399e04",
                    "name": "TSS",
                    "calculation": 132.64041274114194
                },
                {
                    "_id": "65ede718e18a7a560d399e04",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "61078aa5e129591985ab14ca",
            "compName": "PT. Pertamina (Persero) RU VII - Kasim Sorong",
            "compAddress": "Jl. Raya Kilang No.1, Kasim, Distrik Seget, kabupaten Sorong, Papua Barat",
            "latlong": {
                "lat": -1.317778,
                "lng": 131.020278
            },
            "kabkotName": "Kabupaten Sorong",
            "provName": "Papua Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e08",
                    "name": "COD",
                    "calculation": 0.6488569111111111
                },
                {
                    "_id": "65ede718e18a7a560d399e08",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399e08",
                    "name": "NH3N",
                    "calculation": 0.0024022767222222218
                }
            ]
        },
        {
            "_id": "611c8790e129591985d73f8c",
            "compName": "PT. MALIGI PERMATA INDUSTRIAL ESTATE",
            "compAddress": "GRAHA KIIC",
            "latlong": {
                "lat": -6.35586667,
                "lng": 107.26972222
            },
            "kabkotName": "Kabupaten Karawang",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e0c",
                    "name": "COD",
                    "calculation": 112.48906476361233
                },
                {
                    "_id": "65ede718e18a7a560d399e0c",
                    "name": "TSS",
                    "calculation": 107.83824821976225
                },
                {
                    "_id": "65ede718e18a7a560d399e0c",
                    "name": "NH3N",
                    "calculation": 0.4992429575597887
                }
            ]
        },
        {
            "_id": "611f7344e129591985dcacbc",
            "compName": "PT. Indo-rama Synthetics, Tbk. - Purwakarta",
            "compAddress": "Desa Cibinong Kecamatan Jatiluhur, Kanupaten Purwakarta, Jawa Barat",
            "latlong": {
                "lat": -6.555889,
                "lng": 107.411111
            },
            "kabkotName": "Kabupaten Purwakarta",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e12",
                    "name": "COD",
                    "calculation": 5.574900841898843
                },
                {
                    "_id": "65ede718e18a7a560d399e12",
                    "name": "TSS",
                    "calculation": 4.1819137538510205
                },
                {
                    "_id": "65ede718e18a7a560d399e12",
                    "name": "NH3N",
                    "calculation": 0.8209290328257359
                }
            ]
        },
        {
            "_id": "61246f9ee129591985e819b7",
            "compName": "PT. Medco E dan P Indonesia - Lematang Aset",
            "compAddress": "Dusun Bangunsari Kecamatan Gunung Megang ",
            "latlong": {
                "lat": -3.572639,
                "lng": 103.9204
            },
            "kabkotName": "Kabupaten Muara Enim",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e17",
                    "name": "COD",
                    "calculation": 3.136052898857386
                },
                {
                    "_id": "65ede718e18a7a560d399e17",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399e17",
                    "name": "NH3N",
                    "calculation": 0.0013711260144742454
                }
            ]
        },
        {
            "_id": "6139aa3b6fe7f70fa279ddfd",
            "compName": "PT. Pertamina (Persero) RU II Production Sei Pakning",
            "compAddress": "Jl. Cendana No. 1, Kelurahan Sungai Pakning Kecamatan Bukit Batu ",
            "latlong": {
                "lat": 1.34233333333333,
                "lng": 102.153738888888
            },
            "kabkotName": "Kabupaten Bengkalis",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e21",
                    "name": "COD",
                    "calculation": 2.187880399109195
                },
                {
                    "_id": "65ede718e18a7a560d399e21",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399e21",
                    "name": "NH3N",
                    "calculation": 0.7041908901149424
                }
            ]
        },
        {
            "_id": "613a97076fe7f70fa27bd74e",
            "compName": "Saka Indonesia Pangkah Limited",
            "compAddress": "The Energy, 11-12 Floor, SCBD Lot 11 A, Jl. Jend. Sudirman Kav 52 - 53, Jakarta, 12190",
            "latlong": {
                "lat": -7.10917,
                "lng": 112.61749
            },
            "kabkotName": "Kabupaten Gresik",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e27",
                    "name": "COD",
                    "calculation": 13.894816928440205
                },
                {
                    "_id": "65ede718e18a7a560d399e27",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399e27",
                    "name": "NH3N",
                    "calculation": 0.10180374010714287
                }
            ]
        },
        {
            "_id": "613f4a9a6fe7f70fa286bced",
            "compName": "PT. Mitrabara Adiperdana, Tbk.",
            "compAddress": "Jl. AMD No. 24 RT15 Kelurahan Long Loreh Kecamatan Malinau Selatan, Kalimantan Utara",
            "latlong": {
                "lat": 3.134583,
                "lng": 116.467917
            },
            "kabkotName": "Kabupaten Malinau",
            "provName": "Kalimantan Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e2b",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399e2b",
                    "name": "TSS",
                    "calculation": 80610.50234514568
                },
                {
                    "_id": "65ede718e18a7a560d399e2b",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "614ae061eeda6749facaab66",
            "compName": "PT. South Pacific Viscose",
            "compAddress": "Desa Cicadas Kec. Babakan Cikao",
            "latlong": {
                "lat": -6.495861,
                "lng": 107.395056
            },
            "kabkotName": "Kabupaten Purwakarta",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e30",
                    "name": "COD",
                    "calculation": 2372.1901431944443
                },
                {
                    "_id": "65ede718e18a7a560d399e30",
                    "name": "TSS",
                    "calculation": 2805.322137366666
                },
                {
                    "_id": "65ede718e18a7a560d399e30",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "614c3662eeda6749facb5fe0",
            "compName": "PT. CHANDRA ASRI PETROCHEMICAL, TBK",
            "compAddress": "Jln. Raya Anyer Km. 123, Gunungsugih, Ciwandan, Cilegon",
            "latlong": {
                "lat": -6.03722,
                "lng": 105.93206
            },
            "kabkotName": "Kota Cilegon",
            "provName": "Banten",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e34",
                    "name": "COD",
                    "calculation": 95.92282938515325
                },
                {
                    "_id": "65ede718e18a7a560d399e34",
                    "name": "TSS",
                    "calculation": 39.61525973434867
                },
                {
                    "_id": "65ede718e18a7a560d399e34",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "614df50eeeda6749facc4dab",
            "compName": "ENERGY EQUITY EPIC (SENGKANG) PTY. LTD",
            "compAddress": "Desa Poleonro Kecamatan Gilireng, Jl. Poros Palopo-Anabanua KM. 13,5 Kabupaten Wajo, Sulawesi Selatan",
            "latlong": {
                "lat": -3.9544722,
                "lng": 120.169611
            },
            "kabkotName": "Kabupaten Wajo",
            "provName": "Sulawesi Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e39",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399e39",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399e39",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "615aaf6eeeda6749fad3ae63",
            "compName": "EMP Bentu Ltd.",
            "compAddress": "Gedung Surya Dumai, Lt. 7 Jl. Jenderal Sudirman No. 395 Kota Pekanbaru",
            "latlong": {
                "lat": 0.284,
                "lng": 101.758806
            },
            "kabkotName": "Kota Pekanbaru",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "616525c1eeda6749fad9f019",
            "compName": "PT Indexim Coalindo",
            "compAddress": "PT Indexim Coalindo",
            "latlong": {
                "lat": 1.06494,
                "lng": 117.69056
            },
            "kabkotName": "Kabupaten Kutai Timur",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e4f",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399e4f",
                    "name": "TSS",
                    "calculation": 1.8729387018035033
                },
                {
                    "_id": "65ede718e18a7a560d399e4f",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6170d287eeda6749fae1caee",
            "compName": "PT Chandra Asri Petrochemical Tbk Site Office Pulo Ampel-Serang (dh PT Styrindo Mono Indonesia)",
            "compAddress": "Desa Mangunreja Puloampel, Serang Banten 42456",
            "latlong": {
                "lat": -5.882278,
                "lng": 106.070917
            },
            "kabkotName": "Kabupaten Serang",
            "provName": "Banten",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e53",
                    "name": "COD",
                    "calculation": 3.0398598427032644
                },
                {
                    "_id": "65ede718e18a7a560d399e53",
                    "name": "TSS",
                    "calculation": 2.0482841376306373
                },
                {
                    "_id": "65ede718e18a7a560d399e53",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6170d663eeda6749fae1cd26",
            "compName": "PT. Nikomas Gemilang",
            "compAddress": "Kawasan Nikomas Gemilang, Jalan Raya Serang Km. 71 Desa Tambak, Kel. Tambak, Kec. Kibin, Kab. Serang, Prov. Banten",
            "latlong": {
                "lat": -6.168375,
                "lng": 106.326944
            },
            "kabkotName": "Kabupaten Serang",
            "provName": "Banten",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e57",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399e57",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399e57",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "61777d9ceeda6749fae61590",
            "compName": "PT Surya Pamenang",
            "compAddress": "Jalan Raya Kediri - Kertosono Km 7 Kediri - Jawa Timur",
            "latlong": {
                "lat": -7.7794,
                "lng": 112.0283
            },
            "kabkotName": "Kabupaten Kediri",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e5b",
                    "name": "COD",
                    "calculation": 380.4070413759725
                },
                {
                    "_id": "65ede718e18a7a560d399e5b",
                    "name": "TSS",
                    "calculation": 27.009674208845837
                },
                {
                    "_id": "65ede718e18a7a560d399e5b",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "61834cd9a3f2f747b1c8b585",
            "compName": "PT. Bharinto Ekatama",
            "compAddress": "Office Keladi, Desa Muara Begai, Kecamatan Muara Lawa, Kabupaten Kutai Barat, Kalimantan Timur",
            "latlong": {
                "lat": 0.86027548,
                "lng": 115.43666667
            },
            "kabkotName": "Kabupaten Kutai Barat",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6183798fa3f2f747b1c8d708",
            "compName": " PT ANTAM (Persero) Tbk UBPE Pongkor",
            "compAddress": "PO BOX 1 NANGGUNG, DS. BANTAR KARET, KEC. NANGGUNG, KAB. BOGOR 16650",
            "latlong": {
                "lat": -6.643,
                "lng": 106.567139
            },
            "kabkotName": "Kabupaten Bogor",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede718e18a7a560d399e64",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede718e18a7a560d399e64",
                    "name": "TSS",
                    "calculation": 249.18668350071457
                },
                {
                    "_id": "65ede718e18a7a560d399e64",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "618b7762294c8004f8db5d6d",
            "compName": "PT. KITADIN - EMBALUT",
            "compAddress": "Desa Embalut, Kecamatan Tenggarong Seberang Kabupaten Kutai Kertanegara, Kalimantan Timur 75572",
            "latlong": {
                "lat": -0.3465459,
                "lng": 117.0873403
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "618c8ba6294c8004f8dc20f8",
            "compName": "PT. Pura Nusapersada",
            "compAddress": "Jl. Raya Kudus - Pati Km 12",
            "latlong": {
                "lat": -6.80372,
                "lng": 110.942
            },
            "kabkotName": "Kabupaten Kudus",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399e86",
                    "name": "COD",
                    "calculation": 86.73475559062356
                },
                {
                    "_id": "65ede719e18a7a560d399e86",
                    "name": "TSS",
                    "calculation": 24.202925121593868
                },
                {
                    "_id": "65ede719e18a7a560d399e86",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "618de741294c8004f8dd2398",
            "compName": "PT. Fajar Surya Wisesa, Tbk.",
            "compAddress": "Kampung Gardu Sawah RT 001/1-1, Desa Kalijaya,  Kec. Cikarang Barat",
            "latlong": {
                "lat": -6.27389,
                "lng": 107.122
            },
            "kabkotName": "Kabupaten Bekasi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399e91",
                    "name": "COD",
                    "calculation": 1774.0076563925409
                },
                {
                    "_id": "65ede719e18a7a560d399e91",
                    "name": "TSS",
                    "calculation": 101.91075129148709
                },
                {
                    "_id": "65ede719e18a7a560d399e91",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6193506e294c8004f8e23f72",
            "compName": "PT. KLK Dumai",
            "compAddress": "Komplek Pelindo I",
            "latlong": {
                "lat": 1.68599,
                "lng": 101.448
            },
            "kabkotName": "Kota Dumai",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399ea1",
                    "name": "COD",
                    "calculation": 6.338621368062165
                },
                {
                    "_id": "65ede719e18a7a560d399ea1",
                    "name": "TSS",
                    "calculation": 1.3964971993217437
                },
                {
                    "_id": "65ede719e18a7a560d399ea1",
                    "name": "NH3N",
                    "calculation": 0.07033580582040232
                }
            ]
        },
        {
            "_id": "61b059144ae93e096ad15b43",
            "compName": "PT. Indo-Rama Synthetics, Tbk. - Bandung",
            "compAddress": "JALAN RAYA BATUJAJAR KM 5,5 DESA GIRI ASIH KECAMATAN BATUJAJAR KABUPATEN BANDUNG BARAT",
            "latlong": {
                "lat": -6.895,
                "lng": 107.4947
            },
            "kabkotName": "Kabupaten Bandung Barat",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399ea7",
                    "name": "COD",
                    "calculation": 107.96606150066195
                },
                {
                    "_id": "65ede719e18a7a560d399ea7",
                    "name": "TSS",
                    "calculation": 19.13934031014835
                },
                {
                    "_id": "65ede719e18a7a560d399ea7",
                    "name": "NH3N",
                    "calculation": 0.8700024658564083
                }
            ]
        },
        {
            "_id": "61b30d02a348ec738b7107a0",
            "compName": "PT. Jembayan Muara Bara",
            "compAddress": "Desa Bhuana Jaya, Desa Bukit Pariaman dan Desa Separi",
            "latlong": {
                "lat": -0.1487722438,
                "lng": 117.1818501
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399eab",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399eab",
                    "name": "TSS",
                    "calculation": 156.31991817065042
                },
                {
                    "_id": "65ede719e18a7a560d399eab",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "61bc2f888a21dc2f138ec824",
            "compName": "PT. SELE RAYA MERANGIN DUA",
            "compAddress": "Desa Tanjung Raja, Kec. Rawas Ilir, Kab. Musi Rawas Utara, Provinsi Sumatera Selatan",
            "latlong": {
                "lat": -2.589663,
                "lng": 103.195214
            },
            "kabkotName": "Kabupaten Musi Rawas Utara",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "61cea160e66e6f1a7b3989eb",
            "compName": "Medco Energi Sampang Pty. Ltd.",
            "compAddress": "Onshore Processing Facilities (OPF) Grati, Desa Wates, Kecamatan Lekok, Kabupaten Pasuruan, Provinsi Jawa Timur",
            "latlong": {
                "lat": -7.646,
                "lng": 113.022861
            },
            "kabkotName": "Kabupaten Pasuruan",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399eb2",
                    "name": "COD",
                    "calculation": 0.34613166802198114
                },
                {
                    "_id": "65ede719e18a7a560d399eb2",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399eb2",
                    "name": "NH3N",
                    "calculation": 0.0009746818333333339
                }
            ]
        },
        {
            "_id": "61dcf2a9e66e6f1a7b44d1ad",
            "compName": "PT KARAWANG JABAR INDUSTRIAL ESTATE",
            "compAddress": "Desa Margakaya, Kecamatan Teluk Jambe Barat.",
            "latlong": {
                "lat": -6.36058,
                "lng": 107.25183
            },
            "kabkotName": "Kabupaten Karawang",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399eb6",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399eb6",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399eb6",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "61e4ce96e66e6f1a7b4ac555",
            "compName": "PT. Trubaindo Coal Mining",
            "compAddress": "Camp Adong, Kecamatan Muara Lawa, Provinsi Kalimantan Timur",
            "latlong": {
                "lat": -0.472714,
                "lng": 115.716992
            },
            "kabkotName": "Kabupaten Kutai Barat",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "61ea68f2060763627959fc03",
            "compName": "PT. Aneka Tambang (Persero) Tbk. Unit Bisnis Pertambangan Nikel Pomalaa",
            "compAddress": "Jalan Jendral Ahmad Yani No. 5, Kecamatan Pomalaa, Kab. Kolaka, Sulawesi Tenggara, 93562",
            "latlong": {
                "lat": -4.179122,
                "lng": 121.596667
            },
            "kabkotName": "Kabupaten Kolaka",
            "provName": "Sulawesi Tenggara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399ec6",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399ec6",
                    "name": "TSS",
                    "calculation": 55.67055751388889
                },
                {
                    "_id": "65ede719e18a7a560d399ec6",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "61ee3beb06076362795da4d5",
            "compName": "PT. Indonesia Toray Synthetics",
            "compAddress": "JL. M. Toha KM-1 Pasar Baru 15112 Tangerang",
            "latlong": {
                "lat": -6.163167,
                "lng": 106.622306
            },
            "kabkotName": "Kota Tangerang",
            "provName": "Banten",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "61fa093306076362796a3493",
            "compName": "PT Marunda Graha Mineral ",
            "compAddress": "Jl. H. Agus Salim, No 65, Gondangdia, Menteng, Jakarta Pusat.10350",
            "latlong": {
                "lat": -0.300928,
                "lng": 114.716217
            },
            "kabkotName": "Kabupaten Murung Raya",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399ed7",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399ed7",
                    "name": "TSS",
                    "calculation": 567.6646204166666
                },
                {
                    "_id": "65ede719e18a7a560d399ed7",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "61fb8ace06076362796bac6a",
            "compName": "PT. Kaltim Industrial Estate",
            "compAddress": "Jl. Paku Aji Kav. 79 Komplek Kawasan Industri Kecamatan Bontang Utara ",
            "latlong": {
                "lat": 0.17680556,
                "lng": 117.49116667
            },
            "kabkotName": "Kota Bontang",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399edc",
                    "name": "COD",
                    "calculation": 0.8556703595023073
                },
                {
                    "_id": "65ede719e18a7a560d399edc",
                    "name": "TSS",
                    "calculation": 5.7884572174210165
                },
                {
                    "_id": "65ede719e18a7a560d399edc",
                    "name": "NH3N",
                    "calculation": 0.14982364699465361
                }
            ]
        },
        {
            "_id": "61fb997a06076362796bb9e2",
            "compName": "PT. Indominco Mandiri",
            "compAddress": "Km 10 Bontang - Samarinda, Desa Suka Rahmat, Kecamatan Teluk Pandan",
            "latlong": {
                "lat": 0.08386944,
                "lng": 117.24055556
            },
            "kabkotName": "Kabupaten Kutai Timur",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399ee0",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399ee0",
                    "name": "TSS",
                    "calculation": 896.8026505155473
                },
                {
                    "_id": "65ede719e18a7a560d399ee0",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "61fcce6006076362796ce8f0",
            "compName": "PT. Wilmar Nabati Indonesia Gresik",
            "compAddress": "Jl. Kapten Darmo Sugondo No. 56, Gresik",
            "latlong": {
                "lat": -7.17819,
                "lng": 112.667
            },
            "kabkotName": "Kabupaten Gresik",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399ee5",
                    "name": "COD",
                    "calculation": 104.24622529113043
                },
                {
                    "_id": "65ede719e18a7a560d399ee5",
                    "name": "TSS",
                    "calculation": 45.638798497768114
                },
                {
                    "_id": "65ede719e18a7a560d399ee5",
                    "name": "NH3N",
                    "calculation": 0.7963453279900116
                }
            ]
        },
        {
            "_id": "6209b892ee1d7f0743464240",
            "compName": "PT. Pipit Mutiara Jaya",
            "compAddress": "JL Yos Sudarso RT 14. No 15, Kota Tarakan. Kalimantan Utara",
            "latlong": {
                "lat": 3.567639,
                "lng": 117.205361
            },
            "kabkotName": "Kabupaten Tana Tidung",
            "provName": "Kalimantan Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "62142076ee1d7f0743516f1d",
            "compName": " PT. Bukit Asam Tbk. Unit Pertambangan Tanjung Enim",
            "compAddress": "Jalan Parigi Nomor 1 Kelurahan Tanjung Enim, Kecamatan Lawang Kidul, Kabupaten Muara Enim, Provinsi Sumatera Selatan",
            "latlong": {
                "lat": -3.7371312105496886,
                "lng": 103.79426130515505
            },
            "kabkotName": "Kabupaten Muara Enim",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399eed",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399eed",
                    "name": "TSS",
                    "calculation": 445.180307016299
                },
                {
                    "_id": "65ede719e18a7a560d399eed",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "621edc71ee1d7f07435d63c6",
            "compName": "PT. Energi Sejahtera Mas",
            "compAddress": "Lubuk Gaung, Kecamatan Sungai Sembilan",
            "latlong": {
                "lat": 1.770344,
                "lng": 101.3585
            },
            "kabkotName": "Kota Dumai",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399ef1",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399ef1",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399ef1",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "621ee50cee1d7f07435d6c0b",
            "compName": "PT. Suryacipta Swadaya",
            "compAddress": "The Manor Building, 3rd Floor",
            "latlong": {
                "lat": -6.376783,
                "lng": 107
            },
            "kabkotName": "Kabupaten Karawang",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399ef5",
                    "name": "COD",
                    "calculation": 200.12680620149425
                },
                {
                    "_id": "65ede719e18a7a560d399ef5",
                    "name": "TSS",
                    "calculation": 183.78817085922086
                },
                {
                    "_id": "65ede719e18a7a560d399ef5",
                    "name": "NH3N",
                    "calculation": 8.120828173411176
                }
            ]
        },
        {
            "_id": "621f30b7ee1d7f07435db633",
            "compName": "PT. Dayasa Aria Prima",
            "compAddress": "JALAN RAYA DRIYOREJO KM. 25 DS. DRIYOREJO, KEC. DRIYOREJO, KAB. GRESIK - JAWA TIMUR",
            "latlong": {
                "lat": -7.365,
                "lng": 112.607
            },
            "kabkotName": "Kabupaten Gresik",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399ef9",
                    "name": "COD",
                    "calculation": 1447.8345
                },
                {
                    "_id": "65ede719e18a7a560d399ef9",
                    "name": "TSS",
                    "calculation": 343.47395249999994
                },
                {
                    "_id": "65ede719e18a7a560d399ef9",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62317535ee1d7f07436fee86",
            "compName": "PT. Indomakmur Sawit Berjaya",
            "compAddress": "Desa Rambah",
            "latlong": {
                "lat": 0.9333,
                "lng": 100.3525
            },
            "kabkotName": "Kabupaten Rokan Hulu",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399efe",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399efe",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399efe",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62317bd1ee1d7f07436ff4b1",
            "compName": "PT. Ecogreen Oleochemicals - Batam Plant",
            "compAddress": "JL. RAYA PELABUHAN KABIL KAV NO.1, KELURAHAN KABIL, KECAMATAN NONGSA",
            "latlong": {
                "lat": 1.06583,
                "lng": 104.129
            },
            "kabkotName": "Kota Batam",
            "provName": "Kepulauan Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f04",
                    "name": "COD",
                    "calculation": 297.272862261746
                },
                {
                    "_id": "65ede719e18a7a560d399f04",
                    "name": "TSS",
                    "calculation": 79.7108042604762
                },
                {
                    "_id": "65ede719e18a7a560d399f04",
                    "name": "NH3N",
                    "calculation": 1.0888460000000002
                }
            ]
        },
        {
            "_id": "62344d2bee1d7f074372be12",
            "compName": "PT. Surya Zig Zag",
            "compAddress": "Jalan Raya Kediri – Kertosono KM 7, Kabupaten Kediri",
            "latlong": {
                "lat": -7.756222,
                "lng": 112.027333
            },
            "kabkotName": "Kabupaten Kediri",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f08",
                    "name": "COD",
                    "calculation": 1.9160521462225273
                },
                {
                    "_id": "65ede719e18a7a560d399f08",
                    "name": "TSS",
                    "calculation": 31.2676909785638
                },
                {
                    "_id": "65ede719e18a7a560d399f08",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62394c31ee1d7f074377c36b",
            "compName": "PT BUMI SUKSESINDO",
            "compAddress": "Dusun Pancer, Desa Sumberagung, Kecamatan Pesanggaran",
            "latlong": {
                "lat": -8.598043,
                "lng": 114.045213
            },
            "kabkotName": "Kabupaten Banyuwangi",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f0c",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f0c",
                    "name": "TSS",
                    "calculation": 12.249914490606061
                },
                {
                    "_id": "65ede719e18a7a560d399f0c",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "623952eeee1d7f074377c984",
            "compName": "PT. Sinar Indah Kertas",
            "compAddress": "JL RAYA KUDUS PATI KM 14 ",
            "latlong": {
                "lat": -6.79285,
                "lng": 110.957
            },
            "kabkotName": "Kabupaten Kudus",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f12",
                    "name": "COD",
                    "calculation": 77.02922500000001
                },
                {
                    "_id": "65ede719e18a7a560d399f12",
                    "name": "TSS",
                    "calculation": 54.9396134
                },
                {
                    "_id": "65ede719e18a7a560d399f12",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "623954a1ee1d7f074377cb28",
            "compName": "PT. ALAMJAYA BARA PRATAMA",
            "compAddress": "Office Site  ABP Jl. Yos Sudarso desa Jembyan RT 03  Kec. Loakulu",
            "latlong": {
                "lat": -0.5630743,
                "lng": 117.0137694
            },
            "kabkotName": "Kabupaten Kutai Kertanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f1e",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f1e",
                    "name": "TSS",
                    "calculation": 0.2834075493249837
                },
                {
                    "_id": "65ede719e18a7a560d399f1e",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "623ac772ee1d7f074379816a",
            "compName": "PT. Nusa Halmahera Minerals",
            "compAddress": "Jl Trans Halmahera, Desa Tabobo, Kecamatan Malifut, Kabupaten Halmahera Utara, Provinsi Maluku Utara",
            "latlong": {
                "lat": 1.126913,
                "lng": 127.718468
            },
            "kabkotName": "Kabupaten Halmahera Utara",
            "provName": "Maluku Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "623acd28ee1d7f07437986dd",
            "compName": "PT. Kemilau Rindang Abadi",
            "compAddress": "Desa Mulawarman,Bhuana Jaya,Separi,Suka Maju",
            "latlong": {
                "lat": -0.2269109367391596,
                "lng": 117.10071869194508
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "623ae506ee1d7f0743799f24",
            "compName": "PT. Besland Pertiwi",
            "compAddress": "Wisma Bukit Indah, Blok L",
            "latlong": {
                "lat": -6.43882,
                "lng": 107.45281
            },
            "kabkotName": "Kabupaten Purwakarta",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f3a",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f3a",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f3a",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "623c3491ee1d7f07437b8bb6",
            "compName": "PT. Indotaisei Indah Development",
            "compAddress": "Kawasan Industri Indotaisei Sektor IA Blok B Kalihurip - Cikampek",
            "latlong": {
                "lat": -6.426139,
                "lng": 107.418139
            },
            "kabkotName": "Kabupaten Karawang",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f3e",
                    "name": "COD",
                    "calculation": 120.74321195833335
                },
                {
                    "_id": "65ede719e18a7a560d399f3e",
                    "name": "TSS",
                    "calculation": 24.889215041666674
                },
                {
                    "_id": "65ede719e18a7a560d399f3e",
                    "name": "NH3N",
                    "calculation": 3.3183601666666664
                }
            ]
        },
        {
            "_id": "6242a8a9462c3e3beaebeac6",
            "compName": "Petrogas (Island) Ltd.",
            "compAddress": "Lapangan Matoa, Distrik Salawati Tengah",
            "latlong": {
                "lat": -1.27119444,
                "lng": 130.85977778
            },
            "kabkotName": "Kabupaten Sorong",
            "provName": "Papua Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f4d",
                    "name": "COD",
                    "calculation": 805.2256659788832
                },
                {
                    "_id": "65ede719e18a7a560d399f4d",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f4d",
                    "name": "NH3N",
                    "calculation": 18.604196051362887
                }
            ]
        },
        {
            "_id": "6242abf4462c3e3beaebedb6",
            "compName": "Petrogas (Basin) Ltd.",
            "compAddress": "Kasim Marine Terminal (KMT), Kampung Kasim, Distrik Seget, Kabupaten Sorong, Provinsi Papua Barat",
            "latlong": {
                "lat": -1.31436111,
                "lng": 131.02585889
            },
            "kabkotName": "Kabupaten Sorong",
            "provName": "Papua Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f56",
                    "name": "COD",
                    "calculation": 902.442825844122
                },
                {
                    "_id": "65ede719e18a7a560d399f56",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f56",
                    "name": "NH3N",
                    "calculation": 32.03128075951544
                }
            ]
        },
        {
            "_id": "6242d25f462c3e3beaec1128",
            "compName": "PT. Polychem Indonesia, Tbk",
            "compAddress": "Jalan Raya Bojonegara, Desa Mangunreja, Kecamatan Pulo Ampel, Kabupaten Serang, Provinsi Banten 42456",
            "latlong": {
                "lat": -5.891278,
                "lng": 106.08
            },
            "kabkotName": "Kabupaten Serang",
            "provName": "Banten",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f5e",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f5e",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f5e",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62440e27462c3e3beaed667e",
            "compName": "PT. Mitra Karawangjaya",
            "compAddress": "Kawasan Industri Mitrakarawang, Desa Parungmulya, Kec. Ciampel",
            "latlong": {
                "lat": -6.364476720026411,
                "lng": 107.31251681454924
            },
            "kabkotName": "Kabupaten Karawang",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f63",
                    "name": "COD",
                    "calculation": 118.51200836200717
                },
                {
                    "_id": "65ede719e18a7a560d399f63",
                    "name": "TSS",
                    "calculation": 175.51057504874552
                },
                {
                    "_id": "65ede719e18a7a560d399f63",
                    "name": "NH3N",
                    "calculation": 2.6840419860215046
                }
            ]
        },
        {
            "_id": "62446fd4462c3e3beaedda2c",
            "compName": "PT Pesona Khatulistiwa Nusantara",
            "compAddress": "kantor site",
            "latlong": {
                "lat": 2.798908,
                "lng": 117.53235
            },
            "kabkotName": "Kabupaten Bulungan",
            "provName": "Kalimantan Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f67",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f67",
                    "name": "TSS",
                    "calculation": 15.247256325625296
                },
                {
                    "_id": "65ede719e18a7a560d399f67",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "624a7dd9462c3e3beaf5fc6d",
            "compName": "PT. ADIMITRA BARATAMA NUSANTARA",
            "compAddress": "Kel. Jawa, Kecamatan Sangasanga, Kabupaten Kutai Kartanegara",
            "latlong": {
                "lat": -0.69342,
                "lng": 117.22765
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f73",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f73",
                    "name": "TSS",
                    "calculation": 24.384
                },
                {
                    "_id": "65ede719e18a7a560d399f73",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "624a9829462c3e3beaf61927",
            "compName": "PT. Aneka Tambang (Persero) Tbk. - Unit Bisnis Pertambangan Nikel Maluku Utara (Buli)",
            "compAddress": "PT Antam UBPN Maluku Utara ",
            "latlong": {
                "lat": 0.786333,
                "lng": 128.325472
            },
            "kabkotName": "Kabupaten Halmahera Timur",
            "provName": "Maluku Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f81",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f81",
                    "name": "TSS",
                    "calculation": 0.43732
                },
                {
                    "_id": "65ede719e18a7a560d399f81",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "624befe5462c3e3beaf824c2",
            "compName": "PT. Freeport Indonesia",
            "compAddress": "Main Office Building, Lt 3. Tembagapura ",
            "latlong": {
                "lat": -4.82837,
                "lng": 136.843
            },
            "kabkotName": "Kabupaten Mimika",
            "provName": "Papua",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f85",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f85",
                    "name": "TSS",
                    "calculation": 107.5989692720635
                },
                {
                    "_id": "65ede719e18a7a560d399f85",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "624d1a2d462c3e3beaf9f92e",
            "compName": "PT. Patra SK",
            "compAddress": "JALAN PUTRI TUJUH (AREA PERTAMINA RU II) DUMAI - RIAU 28825",
            "latlong": {
                "lat": 1.68258,
                "lng": 101.476
            },
            "kabkotName": "Kota Dumai",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f89",
                    "name": "COD",
                    "calculation": 69.00126976781999
                },
                {
                    "_id": "65ede719e18a7a560d399f89",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f89",
                    "name": "NH3N",
                    "calculation": 0.4731629723769317
                }
            ]
        },
        {
            "_id": "624fb9ef462c3e3beafe2fde",
            "compName": "PT. Arzara Baraindo Energitama",
            "compAddress": "Desa Bhuana Jaya dan Desa Separi",
            "latlong": {
                "lat": -0.18903705,
                "lng": 117.16094166
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f8e",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f8e",
                    "name": "TSS",
                    "calculation": 822.8238967854124
                },
                {
                    "_id": "65ede719e18a7a560d399f8e",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "625512ab462c3e3bea07aad8",
            "compName": "PT. J Resources Bolaang Mongondow",
            "compAddress": "Desa Motandoi, Kecamatan Pinolosian Timur, Blok Bakan, Kabupaten Bolaang Mongondow dan Kabupaten Bolaang Mongondow Selatan",
            "latlong": {
                "lat": 0.567724,
                "lng": 124.312008
            },
            "kabkotName": "Kabupaten Bolaang Mongondow Selatan",
            "provName": "Sulawesi Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f93",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f93",
                    "name": "TSS",
                    "calculation": 221.81228247793848
                },
                {
                    "_id": "65ede719e18a7a560d399f93",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "625d13d5b4ce584316c795ce",
            "compName": "PT. Mandiri Intiperkasa",
            "compAddress": "Site Krassi",
            "latlong": {
                "lat": 3.701361,
                "lng": 117.247083
            },
            "kabkotName": "Kabupaten Nunukan",
            "provName": "Kalimantan Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399f9b",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede719e18a7a560d399f9b",
                    "name": "TSS",
                    "calculation": 2040.5775225709888
                },
                {
                    "_id": "65ede719e18a7a560d399f9b",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "625d150eb4ce584316c79776",
            "compName": "PT. Suparma, Tbk.",
            "compAddress": "JALAN MASTRIP NO. 856 WARUGUNUNG",
            "latlong": {
                "lat": -7.346361,
                "lng": 112.6724
            },
            "kabkotName": "Kota Surabaya",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399fa2",
                    "name": "COD",
                    "calculation": 850.683381379138
                },
                {
                    "_id": "65ede719e18a7a560d399fa2",
                    "name": "TSS",
                    "calculation": 151.33440520965524
                },
                {
                    "_id": "65ede719e18a7a560d399fa2",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "626617adb4ce584316d55a19",
            "compName": "PT. YKK Zipper Indonesia",
            "compAddress": "Kampung Mariuk Desa Gandasari Kecamatan Cikarang Barat Bekasi 17520",
            "latlong": {
                "lat": -6.2825,
                "lng": 107.0806
            },
            "kabkotName": "Kabupaten Bekasi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399fa6",
                    "name": "COD",
                    "calculation": 3.781278533333333
                },
                {
                    "_id": "65ede719e18a7a560d399fa6",
                    "name": "TSS",
                    "calculation": 1.674906
                },
                {
                    "_id": "65ede719e18a7a560d399fa6",
                    "name": "NH3N",
                    "calculation": 0.26317825
                }
            ]
        },
        {
            "_id": "627ccf5ee7985f5b1151be81",
            "compName": "PT MUARA ALAM SEJAHTERA",
            "compAddress": "Jl. Lintas Sumatera Desa Muara Maung Kec. Merapi Barat Kab. Lahat Provinsi Sumatera Selatan",
            "latlong": {
                "lat": -3.75766666666666,
                "lng": 103.681111111111
            },
            "kabkotName": "Kabupaten Lahat",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede719e18a7a560d399faa",
                    "name": "COD",
                    "calculation": -1.1689400000000008
                },
                {
                    "_id": "65ede719e18a7a560d399faa",
                    "name": "TSS",
                    "calculation": 127.07094127203332
                },
                {
                    "_id": "65ede719e18a7a560d399faa",
                    "name": "NH3N",
                    "calculation": -1.1689400000000008
                }
            ]
        },
        {
            "_id": "627e17a6e7985f5b1153aa6d",
            "compName": "Kangean Energy Indonesia, Ltd.",
            "compAddress": "Desa Pagerungan Besar, Kecamatan Sapeken, Kabupaten Sumenep, Jawa Timur",
            "latlong": {
                "lat": -6.960277777777778,
                "lng": 115.93361111111112
            },
            "kabkotName": "Kabupaten Sumenep",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6287553ce7985f5b1164329a",
            "compName": "PTPN VII Uit Usaha Sungai Niru",
            "compAddress": "Jemenang, Kec. Rambang Dangku, Kab. Muara Enim, Sumatera Selatan",
            "latlong": {
                "lat": -3.48272,
                "lng": 104.033
            },
            "kabkotName": "Kabupaten Muara Enim",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d399fcb",
                    "name": "COD",
                    "calculation": 4.850126444018764
                },
                {
                    "_id": "65ede71ae18a7a560d399fcb",
                    "name": "TSS",
                    "calculation": 3.798922837027586
                },
                {
                    "_id": "65ede71ae18a7a560d399fcb",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "628b080de7985f5b116c68c2",
            "compName": "PT. Ceria Nugraha Indotama",
            "compAddress": "Desa Tolowe Ponre Waru",
            "latlong": {
                "lat": -3.85431,
                "lng": 121.31
            },
            "kabkotName": "Kabupaten Kolaka",
            "provName": "Sulawesi Tenggara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d399fd3",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d399fd3",
                    "name": "TSS",
                    "calculation": 0.02667428571428571
                },
                {
                    "_id": "65ede71ae18a7a560d399fd3",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "628dea15e7985f5b117174d1",
            "compName": "PT. Gistex (Lagadar)",
            "compAddress": "Jalan Nanjung No.82, Kab. Bandung",
            "latlong": {
                "lat": -6.938028,
                "lng": 107.533639
            },
            "kabkotName": "Kabupaten Bandung",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d399fd9",
                    "name": "COD",
                    "calculation": 8.976221166666665
                },
                {
                    "_id": "65ede71ae18a7a560d399fd9",
                    "name": "TSS",
                    "calculation": 2.553396095238095
                },
                {
                    "_id": "65ede71ae18a7a560d399fd9",
                    "name": "NH3N",
                    "calculation": 0.43894655952380957
                }
            ]
        },
        {
            "_id": "62904c71e7985f5b1175ce7b",
            "compName": "PT. Dizamarta Powerindo",
            "compAddress": "Kompleks PLTU Keban Agung",
            "latlong": {
                "lat": -3.75837,
                "lng": 103.64015
            },
            "kabkotName": "Kabupaten Lahat",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "62904db6e7985f5b1175d002",
            "compName": "PT Trisensa Mineral Utama",
            "compAddress": "Jalan Soekarno--Hatta, Km 23 Desa Batuah, Kecamatan Loa Janan, Kabupaten Kutai Kartanegara, Kalimantan Timur.",
            "latlong": {
                "lat": -0.63694,
                "lng": 117.126
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6295b7c1e7985f5b117e91d3",
            "compName": "PT Darmex Oil & Fats",
            "compAddress": "Jl. Raya Bekasi Km 27 Desa Pejuang RT 004 RW 002 Kaliabang Tengah Bekasi Utara Kotamadya Bekasi",
            "latlong": {
                "lat": -6.19908,
                "lng": 106.995
            },
            "kabkotName": "Kota Bekasi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d399ff4",
                    "name": "COD",
                    "calculation": 1.9008195256954545
                },
                {
                    "_id": "65ede71ae18a7a560d399ff4",
                    "name": "TSS",
                    "calculation": 0.9724387353535354
                },
                {
                    "_id": "65ede71ae18a7a560d399ff4",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62986646e7985f5b1182dd28",
            "compName": "PT. Multipersada Gatramegah",
            "compAddress": "Desa Karamuan",
            "latlong": {
                "lat": -0.7789,
                "lng": 114.7481
            },
            "kabkotName": "Kabupaten Barito Utara",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d399ffd",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d399ffd",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d399ffd",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "629879e3e7985f5b1182f56a",
            "compName": "PT. Prima Mulia Sarana Sejahtera",
            "compAddress": "Kecamatan Muara enim",
            "latlong": {
                "lat": -3.80931,
                "lng": 103.749
            },
            "kabkotName": "Kabupaten Muara Enim",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a001",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a001",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a001",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62987dbbe7985f5b1182fa64",
            "compName": "PT. Bintangdelapan Mineral",
            "compAddress": "Jl. Trans Sulawesi, Desa Fatufia, Kecamatan Bahodopi, Kabupaten Morowali, Sulawesi Tengah",
            "latlong": {
                "lat": -2.8968,
                "lng": 122.012
            },
            "kabkotName": "Kabupaten Morowali",
            "provName": "Sulawesi Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a006",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a006",
                    "name": "TSS",
                    "calculation": 54002.26531096058
                },
                {
                    "_id": "65ede71ae18a7a560d39a006",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6299d48ce7985f5b118524b8",
            "compName": "PT. GOLDEN GREAT BORNEO",
            "compAddress": "Desa Prabumenang, Kecamatan Merapi Timur",
            "latlong": {
                "lat": -3.707806,
                "lng": 103.708778
            },
            "kabkotName": "Kabupaten Lahat",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a015",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a015",
                    "name": "TSS",
                    "calculation": 151.45341539314776
                },
                {
                    "_id": "65ede71ae18a7a560d39a015",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "629ed2000432880d6a229e74",
            "compName": "PT Sarana Esa Cita",
            "compAddress": "Dusun Semberang RT 007 / RW 003, Desa Sumber Harapan, Kabupaten Sambas",
            "latlong": {
                "lat": 1.35311,
                "lng": 109.3831
            },
            "kabkotName": "Kabupaten Sambas",
            "provName": "Kalimantan Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a01b",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a01b",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a01b",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62a1b3540432880d6a273bc1",
            "compName": "PT. Ganda Alam Makmur",
            "compAddress": "Jl. Soekarno Hatta RT 02 Dusun Kalimaya Desa Sempayau Kec.Sangkulirang Kab.Kutai Timur",
            "latlong": {
                "lat": 1.017,
                "lng": 117.70572222
            },
            "kabkotName": "Kabupaten Kutai Timur",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a020",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a020",
                    "name": "TSS",
                    "calculation": 0.02154924
                },
                {
                    "_id": "65ede71ae18a7a560d39a020",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62a1b63d0432880d6a2740c3",
            "compName": "PT. Antang Gunung Meratus",
            "compAddress": "Areal PKP2B PT. Antang Gunung Meratus Blok 3 Warutas",
            "latlong": {
                "lat": -2.8604588,
                "lng": 115.27113055
            },
            "kabkotName": "Kabupaten Hulu Sungai Selatan",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "62a6f17b0432880d6a2f4c68",
            "compName": "PT. Semesta Centramas",
            "compAddress": "Hauling Road Balangan Km 75,5, Murung Ilung, Paringin, Balangan",
            "latlong": {
                "lat": -2.3258908333333332,
                "lng": 115.60352833333333
            },
            "kabkotName": "Kabupaten Balangan",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a046",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a046",
                    "name": "TSS",
                    "calculation": 308.7137571777549
                },
                {
                    "_id": "65ede71ae18a7a560d39a046",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62a7fb200432880d6a30cd89",
            "compName": "PT Indo Muro Kencana",
            "compAddress": "Kecamatan Tanah Siang Selatan, Permata Intan dan Sungai Babuat, Kabupaten Murung Raya, Provinsi Kalimantan Tengah",
            "latlong": {
                "lat": -0.67,
                "lng": 114.4175
            },
            "kabkotName": "Kabupaten Murung Raya",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "62a80f210432880d6a30e5b8",
            "compName": "PT. Laskar Semesta Alam",
            "compAddress": "Hauling Road Balangan Km 75,5",
            "latlong": {
                "lat": -2.3304224999999996,
                "lng": 115.63782027777779
            },
            "kabkotName": "Kabupaten Balangan",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a04e",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a04e",
                    "name": "TSS",
                    "calculation": 1608.6918337283564
                },
                {
                    "_id": "65ede71ae18a7a560d39a04e",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62a841e80432880d6a311cc2",
            "compName": "PT. Sinar Indah Kertas - Pati",
            "compAddress": "Jl. Raya Pati-Kudus km 4, Desa Pegandan, Kec. Margorejo, Kab. Pati, Jawa Tengah",
            "latlong": {
                "lat": -6.77271,
                "lng": 110.999
            },
            "kabkotName": "Kabupaten Pati",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a053",
                    "name": "COD",
                    "calculation": 13.06186913505747
                },
                {
                    "_id": "65ede71ae18a7a560d39a053",
                    "name": "TSS",
                    "calculation": 3.8556799590517237
                },
                {
                    "_id": "65ede71ae18a7a560d39a053",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62aad9100432880d6a34dbce",
            "compName": " PT. Pertamina (Persero) RU VI - Kilang Balongan",
            "compAddress": "Jl. Raya Balongan Km. 9 Indramayu ",
            "latlong": {
                "lat": -6.370167,
                "lng": 108.392472
            },
            "kabkotName": "Kabupaten Indramayu",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a057",
                    "name": "COD",
                    "calculation": 293.4977113608398
                },
                {
                    "_id": "65ede71ae18a7a560d39a057",
                    "name": "TSS",
                    "calculation": 74.18215635603333
                },
                {
                    "_id": "65ede71ae18a7a560d39a057",
                    "name": "NH3N",
                    "calculation": 0.2912589912124034
                }
            ]
        },
        {
            "_id": "62aaed000432880d6a34f4ff",
            "compName": "PT. Hengjaya Mineralindo",
            "compAddress": "Desa Tangofa Kecamatan Bungku Pesisir",
            "latlong": {
                "lat": -2.926222,
                "lng": 122.229472
            },
            "kabkotName": "Kabupaten Morowali",
            "provName": "Sulawesi Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a05c",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a05c",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a05c",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62aaf1890432880d6a34faa0",
            "compName": "PT Sago Prima Pratama",
            "compAddress": "Equity tower, 48 Floor SCBD Lot. 9 12190",
            "latlong": {
                "lat": 3.915583,
                "lng": 117.245667
            },
            "kabkotName": "Kabupaten Nunukan",
            "provName": "Kalimantan Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "62b16cb80432880d6a3e5445",
            "compName": "PT. SURABAYA MEKABOX",
            "compAddress": "Jl. Raya Bambe Km. 18",
            "latlong": {
                "lat": -7.35003,
                "lng": 112.654
            },
            "kabkotName": "Kabupaten Gresik",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a068",
                    "name": "COD",
                    "calculation": 547.968978148027
                },
                {
                    "_id": "65ede71ae18a7a560d39a068",
                    "name": "TSS",
                    "calculation": 190.62854301025246
                },
                {
                    "_id": "65ede71ae18a7a560d39a068",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62b1870b0432880d6a3e7853",
            "compName": "PT BHUMI RANTAU ENERGI",
            "compAddress": "Jl. Jend. Sudirman By Pass RT. 01 RW. 02 Desa Bungur Kecamatan Bungur Kabupaten Tapin Provinsi Kalimantan Selatan",
            "latlong": {
                "lat": -2.93514,
                "lng": 115.20732
            },
            "kabkotName": "Kabupaten Tapin",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a07b",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a07b",
                    "name": "TSS",
                    "calculation": 214.41143671879664
                },
                {
                    "_id": "65ede71ae18a7a560d39a07b",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62b189220432880d6a3e7b68",
            "compName": "PT. Indo Bharat Rayon",
            "compAddress": "Desa Cilangkap Kecamatan Babakancikao Kabupaten Purwakarta",
            "latlong": {
                "lat": -6.488223083600905,
                "lng": 107.38500460982323
            },
            "kabkotName": "Kabupaten Purwakarta",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a07f",
                    "name": "COD",
                    "calculation": 2427.318448183907
                },
                {
                    "_id": "65ede71ae18a7a560d39a07f",
                    "name": "TSS",
                    "calculation": 197.19959751724136
                },
                {
                    "_id": "65ede71ae18a7a560d39a07f",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62c3b4904aecf63648d90014",
            "compName": "PT PURA BARUTAMA Unit Paper Mill",
            "compAddress": "Jl. AKBP Agil Kusumadya 203,Desa Jati Kulon, Kec. Jati, Kab. Kudus",
            "latlong": {
                "lat": -6.836564,
                "lng": 110.818169
            },
            "kabkotName": "Kabupaten Kudus",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a086",
                    "name": "COD",
                    "calculation": 118.89198292867003
                },
                {
                    "_id": "65ede71ae18a7a560d39a086",
                    "name": "TSS",
                    "calculation": 25.988961367328315
                },
                {
                    "_id": "65ede71ae18a7a560d39a086",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62cd3b970f1a8501ae9eb69d",
            "compName": "PT. Win Textile",
            "compAddress": "KP. CILEGONG DS. CILEGONG RT. 15 RW. 04 KEC JATILUHUR KAB. PURWAKARTA PROVINSI JAWA BARAT",
            "latlong": {
                "lat": -6.532139,
                "lng": 107.41275
            },
            "kabkotName": "Kabupaten Purwakarta",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a08c",
                    "name": "COD",
                    "calculation": 0.6976553793103448
                },
                {
                    "_id": "65ede71ae18a7a560d39a08c",
                    "name": "TSS",
                    "calculation": 0.0000703448275862069
                },
                {
                    "_id": "65ede71ae18a7a560d39a08c",
                    "name": "NH3N",
                    "calculation": 0.015705889655172417
                }
            ]
        },
        {
            "_id": "62ce36620f1a8501aeb8bfd4",
            "compName": "PT. Jababeka Infrastruktur",
            "compAddress": "Jl. Jababeka XIV Blok J, Kawasan Industri Jababeka, Desa Harjamekar, Kec. Cikarang Utara, Kab. Bekasi, Jawa Barat",
            "latlong": {
                "lat": -6.27361111,
                "lng": 107.12638889
            },
            "kabkotName": "Kabupaten Bekasi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a090",
                    "name": "COD",
                    "calculation": 629.5726708333334
                },
                {
                    "_id": "65ede71ae18a7a560d39a090",
                    "name": "TSS",
                    "calculation": 69.99737708333335
                },
                {
                    "_id": "65ede71ae18a7a560d39a090",
                    "name": "NH3N",
                    "calculation": 4.022579305555555
                }
            ]
        },
        {
            "_id": "62d7483cf0595c550d2204cd",
            "compName": "PT. East Jakarta Industrial Park ",
            "compAddress": "EJIP INDUSTRIAL PARK PLOT 3A, CIKARANG SELATAN BEKASI 17550. INDONESIA",
            "latlong": {
                "lat": -6.33003,
                "lng": 107.104
            },
            "kabkotName": "Kabupaten Bekasi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a094",
                    "name": "COD",
                    "calculation": 82.80990855770453
                },
                {
                    "_id": "65ede71ae18a7a560d39a094",
                    "name": "TSS",
                    "calculation": 39.02107255113892
                },
                {
                    "_id": "65ede71ae18a7a560d39a094",
                    "name": "NH3N",
                    "calculation": 0.012135468197655196
                }
            ]
        },
        {
            "_id": "62d7b20ef0595c550d292b52",
            "compName": "PT. ALNO AGRO UTAMA",
            "compAddress": "DESA NAPAL PUTIH KECAMATAN NAPAL PUTIH",
            "latlong": {
                "lat": -3.133555555555556,
                "lng": 101.87905555555555
            },
            "kabkotName": "Kabupaten Bengkulu Utara",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a098",
                    "name": "COD",
                    "calculation": 4.137342720060606
                },
                {
                    "_id": "65ede71ae18a7a560d39a098",
                    "name": "TSS",
                    "calculation": 1.0901412118521479
                },
                {
                    "_id": "65ede71ae18a7a560d39a098",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62d7c017f0595c550d2a25c8",
            "compName": "PT. Agincourt Resources",
            "compAddress": "Desa Aek Pining, Kecamatan Batangtoru, ",
            "latlong": {
                "lat": 1.50117103,
                "lng": 99.06623691944444
            },
            "kabkotName": "Kabupaten Tapanuli Selatan",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a09d",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a09d",
                    "name": "TSS",
                    "calculation": 199.5837627769167
                },
                {
                    "_id": "65ede71ae18a7a560d39a09d",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62da4a95f0595c550d48930c",
            "compName": " PT. Lanna Harita Indonesia",
            "compAddress": "Jl. Sukalima Desa Sidomulyo Kecamatan Anggana Kabupaten Kutai Kartanegara Provinsi Kalimantan Timur",
            "latlong": {
                "lat": -0.531944,
                "lng": 117.291
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a0a2",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a0a2",
                    "name": "TSS",
                    "calculation": 1.1146043650793653
                },
                {
                    "_id": "65ede71ae18a7a560d39a0a2",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62dfe13ea9be4d01ab41dd8c",
            "compName": "PT Kilang Pertamina Internasional Refinery Unit III Plaju",
            "compAddress": "Jl. Beringin No. 1 Komperta Plaju, Plaju",
            "latlong": {
                "lat": -2.9961,
                "lng": 104.836
            },
            "kabkotName": "Kota Palembang",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a0a6",
                    "name": "COD",
                    "calculation": 228.5646549178353
                },
                {
                    "_id": "65ede71ae18a7a560d39a0a6",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a0a6",
                    "name": "NH3N",
                    "calculation": 50.89018131313218
                }
            ]
        },
        {
            "_id": "62e25548a9be4d01ab610a65",
            "compName": "PT. Servo Lintas Raya",
            "compAddress": "RUKO CITRA DE RESIDENCE NO 21-22, KABUPATEN MUARA ENIM",
            "latlong": {
                "lat": -3.61298,
                "lng": 103.736
            },
            "kabkotName": "Kabupaten Muara Enim",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a0ae",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a0ae",
                    "name": "TSS",
                    "calculation": 33.47862000000001
                },
                {
                    "_id": "65ede71ae18a7a560d39a0ae",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62e4fdf396eb987c4b4a547a",
            "compName": "PT. INDOMINING",
            "compAddress": "Jalan Raya Sangasanga- Pendingin, Kec. Sangasanga Kab. Kutai Kartanegara",
            "latlong": {
                "lat": -0.678611,
                "lng": 117.266833
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a0b8",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a0b8",
                    "name": "TSS",
                    "calculation": 410.0843785258621
                },
                {
                    "_id": "65ede71ae18a7a560d39a0b8",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62ea2ef84738834a14439d40",
            "compName": "PT. Bara Alam Utama",
            "compAddress": "Lebak Budi, Negeri Agung, Ulak Pandan, Tanjung Baru, Merapi",
            "latlong": {
                "lat": -3.77861,
                "lng": 103.659
            },
            "kabkotName": "Kabupaten Lahat",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "62fdfe919be8274c4304a60b",
            "compName": "PT Bumi Merapi Energi",
            "compAddress": "Desa Ulakpandan, Jalan Perangai, Kabupaten Lahat, Sumatera Selatan",
            "latlong": {
                "lat": -3.78444,
                "lng": 103.712
            },
            "kabkotName": "Kabupaten Lahat",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a0be",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a0be",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a0be",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62ff441e92e6b552a127f1e7",
            "compName": "PT. Pertamina Hulu Energi Siak - Lindai",
            "compAddress": "Jalan Senama Nenek / Jalan Lindai Kec. Tapung Hulu Kab. Kampar, Prov. Riau - 28465",
            "latlong": {
                "lat": 0.738944,
                "lng": 101.780433
            },
            "kabkotName": "Kabupaten Kampar",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a0c2",
                    "name": "COD",
                    "calculation": null
                },
                {
                    "_id": "65ede71ae18a7a560d39a0c2",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a0c2",
                    "name": "NH3N",
                    "calculation": 0.1528010000000001
                }
            ]
        },
        {
            "_id": "62ff44fe92e6b552a1280386",
            "compName": "PT. Pertamina Hulu Energi Siak - Batang",
            "compAddress": "Jl. Lintas Sumatera Simpang Batang Kepenghuluan Rantau Bais Kecamatan Tanah Putih Kabupagen Rokan Hilir Prov. Riau",
            "latlong": {
                "lat": 2.3,
                "lng": 101.078
            },
            "kabkotName": "Kabupaten Rokan Hilir",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a0c6",
                    "name": "COD",
                    "calculation": null
                },
                {
                    "_id": "65ede71ae18a7a560d39a0c6",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a0c6",
                    "name": "NH3N",
                    "calculation": 0.4820957749999998
                }
            ]
        },
        {
            "_id": "62ff463a92e6b552a12814ac",
            "compName": "PT. Perkebunan Nusantara II (Persero) - PKS Pagar Merbau",
            "compAddress": "Desa Pagar Merbau III Kecamatan Pagar Merbau Kabupaten Deli Serdang Sumatera Utara",
            "latlong": {
                "lat": 3.527167,
                "lng": 98.9
            },
            "kabkotName": "Kabupaten Deli Serdang",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a0ca",
                    "name": "COD",
                    "calculation": 0.027408452873563225
                },
                {
                    "_id": "65ede71ae18a7a560d39a0ca",
                    "name": "TSS",
                    "calculation": 0.00811686223316913
                },
                {
                    "_id": "65ede71ae18a7a560d39a0ca",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "62ff468b92e6b552a128205a",
            "compName": "PKS Sawit Seberang PT.Perkebunan Nusantara II (PERSERO)",
            "compAddress": "Desa Sawit Seberang Kecamatan Sawit Seberang Kabupaten Langkat Sumatera Utara",
            "latlong": {
                "lat": 3.797222,
                "lng": 98.276667
            },
            "kabkotName": "Kabupaten Langkat",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "63072a3c17a23147991ea39a",
            "compName": "PT. Kuansing Inti Makmur",
            "compAddress": "Desa Tanjung Belit, Kec. Jujuhan",
            "latlong": {
                "lat": -1.39641,
                "lng": 101.723
            },
            "kabkotName": "Kabupaten Bungo",
            "provName": "Jambi",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a0d2",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a0d2",
                    "name": "TSS",
                    "calculation": 0.5537549676454863
                },
                {
                    "_id": "65ede71ae18a7a560d39a0d2",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6307a92b17a231479923cb8e",
            "compName": "PT. Amman Mineral Nusa Tenggara",
            "compAddress": "Site Batu Hijau",
            "latlong": {
                "lat": -8.98236111111111,
                "lng": 116.81317777777778
            },
            "kabkotName": "Kabupaten Sumbawa Barat",
            "provName": "Nusa Tenggara Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a0d6",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a0d6",
                    "name": "TSS",
                    "calculation": 14363906.139838193
                },
                {
                    "_id": "65ede71ae18a7a560d39a0d6",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63102680bb11776a058d7790",
            "compName": "PT. Pertamina EP Asset 3 - Field Jatibarang",
            "compAddress": "Jl Raya Mundu PO BOX 05 KRL Karangampel",
            "latlong": {
                "lat": -6.36722,
                "lng": 108.385
            },
            "kabkotName": "Kabupaten Indramayu",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a0dd",
                    "name": "COD",
                    "calculation": 34.730732241666665
                },
                {
                    "_id": "65ede71ae18a7a560d39a0dd",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a0dd",
                    "name": "NH3N",
                    "calculation": 1.092846598055555
                }
            ]
        },
        {
            "_id": "63106b63bb11776a0590e110",
            "compName": "PT Mifa Bersaudara",
            "compAddress": "Jl. Meulaboh-Tapaktuan KM 8, Desa Peunaga Cut Ujong, Kecamatan Meurebo, Kota Meulaboh, Kabupaten Aceh Barat",
            "latlong": {
                "lat": 4.1970833,
                "lng": 96.23605527777778
            },
            "kabkotName": "Kabupaten Aceh Barat",
            "provName": "Aceh",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "63107de2bb11776a0591c84e",
            "compName": "PT. Papertech Indonesia",
            "compAddress": "Jl. Sanggrahan Gatak No. 23 Mungkid, Kec. Mungkid, Kab. Magelang, Jawa Tengah",
            "latlong": {
                "lat": -7.55111,
                "lng": 110.256
            },
            "kabkotName": "Kabupaten Magelang",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "63107dfcbb11776a0591cc27",
            "compName": "PT. Papertech Indonesia (Subang)",
            "compAddress": "Blok Tarikolot Kp Cipeundeuy",
            "latlong": {
                "lat": -6.504472,
                "lng": 107.5635
            },
            "kabkotName": "Kabupaten Subang",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a0e9",
                    "name": "COD",
                    "calculation": 207.6040857012069
                },
                {
                    "_id": "65ede71ae18a7a560d39a0e9",
                    "name": "TSS",
                    "calculation": 114.9078691035509
                },
                {
                    "_id": "65ede71ae18a7a560d39a0e9",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63108c41bb11776a0592571e",
            "compName": "PT. Banjarsari Pribumi",
            "compAddress": "Desa Banjarsari Kecamatan Merapi Timur Kabupaten Lahat",
            "latlong": {
                "lat": -3.63719,
                "lng": 103.710584
            },
            "kabkotName": "Kabupaten Lahat",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a0f1",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a0f1",
                    "name": "TSS",
                    "calculation": 31.282284
                },
                {
                    "_id": "65ede71ae18a7a560d39a0f1",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6315b3d9bb11776a05c90aa2",
            "compName": "PT. Mitra Puding Mas",
            "compAddress": "Desa Pasar Sebelat, Kecamatan Putri Hijau, Kabupaten. Bengkulu Utara, Bengkulu",
            "latlong": {
                "lat": -3.1330082416534424,
                "lng": 101.6021499633789
            },
            "kabkotName": "Kabupaten Bengkulu Utara",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a0f7",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a0f7",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a0f7",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6316f129bb11776a05dd029a",
            "compName": "PT. Arutmin Indonesia Tambang Asam-Asam",
            "compAddress": "Jalan A. Yani KM 121, RT. 12, Asamasam, Jorong, Kabupaten Tanah Laut",
            "latlong": {
                "lat": -3.891547,
                "lng": 115.117195
            },
            "kabkotName": "Kabupaten Tanah Laut",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6316f15fbb11776a05dd06c5",
            "compName": "PT. Arutmin Indonesia Tambang Batulicin",
            "compAddress": "Jalan Raya Sarongga KM 3,5, Desa Gunung Besar, Kecamatan Simpang Empat, Kabupaten Tanah Bumbu",
            "latlong": {
                "lat": -3.252,
                "lng": 116.029833
            },
            "kabkotName": "Kabupaten Tanah Bumbu",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a100",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a100",
                    "name": "TSS",
                    "calculation": 16.685145493333334
                },
                {
                    "_id": "65ede71ae18a7a560d39a100",
                    "name": "NH3N",
                    "calculation": 8.139955285833333
                }
            ]
        },
        {
            "_id": "6316f174bb11776a05dd0b0f",
            "compName": "PT. Arutmin Indonesia Tambang Kintap",
            "compAddress": "Jalan Pantai RT 05 / RW 01 Desa Mekarsari , Kecamatan Kintap, Kabupaten Tanah Laut Propinsi  Kalimantan Selatan",
            "latlong": {
                "lat": -3.807888,
                "lng": 115.317657
            },
            "kabkotName": "Kabupaten Tanah Laut",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a107",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a107",
                    "name": "TSS",
                    "calculation": 0.5712944782150635
                },
                {
                    "_id": "65ede71ae18a7a560d39a107",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6316f1c2bb11776a05dd0f05",
            "compName": "PT. Arutmin Indonesia NPLCT",
            "compAddress": "Jalan Berangas KM 5,5 Tanjung Pemancingan, Desa Sarang Tiung, Kecamatan Pulau Laut Sigam",
            "latlong": {
                "lat": -3.220307,
                "lng": 116.278671
            },
            "kabkotName": "Kabupaten Kota Baru",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6316f1f0bb11776a05dd146f",
            "compName": "PT. Arutmin Indonesia Tambang Senakin",
            "compAddress": "Jl. Dugan Desa Sembilang  Kec. Kelumpang Tengah  Kab. Kotabaru Prov. Kalimantan Selatan 72111",
            "latlong": {
                "lat": -2.893698,
                "lng": 116.268669
            },
            "kabkotName": "Kabupaten Kota Baru",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a10e",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a10e",
                    "name": "TSS",
                    "calculation": 2.1592571418534487
                },
                {
                    "_id": "65ede71ae18a7a560d39a10e",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63180a7662d22b3284e4779a",
            "compName": "PT. Arutmin Indonesia Tambang Satui",
            "compAddress": "Desa Makmur mulia Kecamatan satui Kabupaten Tanah Bumbu  ",
            "latlong": {
                "lat": -3.780194,
                "lng": 115.387361
            },
            "kabkotName": "Kabupaten Tanah Bumbu",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a112",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a112",
                    "name": "TSS",
                    "calculation": 6.238104013750531
                },
                {
                    "_id": "65ede71ae18a7a560d39a112",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63185ba262d22b3284e87f82",
            "compName": "PT. Daliatex Kusuma",
            "compAddress": "Jl. Moh. Toha No. 307 KM. 7,3 Bandung",
            "latlong": {
                "lat": -6.9805,
                "lng": 107.624528
            },
            "kabkotName": "Kabupaten Bandung",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a116",
                    "name": "COD",
                    "calculation": 232.7338720881226
                },
                {
                    "_id": "65ede71ae18a7a560d39a116",
                    "name": "TSS",
                    "calculation": 80.47130889894638
                },
                {
                    "_id": "65ede71ae18a7a560d39a116",
                    "name": "NH3N",
                    "calculation": 2.798289183908045
                }
            ]
        },
        {
            "_id": "6319868a62d22b3284f5e525",
            "compName": "PT. Kawasan Industri Medan",
            "compAddress": "Wisma PT. Kawasan Industri Medan (Persero) Jl. Pulau Batam No.1 Kompleks KIM Tahap II",
            "latlong": {
                "lat": 3.67611,
                "lng": 98.695
            },
            "kabkotName": "Kabupaten Deli Serdang",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6319989462d22b3284f6e0de",
            "compName": "PT Austindo Nusantara Jaya Agri Siais",
            "compAddress": "Kelurahan Pardomuan, Kecamatan Angkola Selatan, Kabupaten Tapanuli Selatan, Provinsi Sumatera Utara",
            "latlong": {
                "lat": 1.17619,
                "lng": 99.1529
            },
            "kabkotName": "Kabupaten Tapanuli Selatan",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a11b",
                    "name": "COD",
                    "calculation": 173.18815421926163
                },
                {
                    "_id": "65ede71ae18a7a560d39a11b",
                    "name": "TSS",
                    "calculation": 20.69584331341088
                },
                {
                    "_id": "65ede71ae18a7a560d39a11b",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6319a40662d22b3284f7a068",
            "compName": "PT. TUNAS INTI ABADI",
            "compAddress": "JL. PROVINSI BANJARMASIN - BATULICIN KM. 204, DESA SEBAMBAN BARU, KECAMATAN SUNGAI LOBAN",
            "latlong": {
                "lat": -3.55613888888,
                "lng": 115.5947222222
            },
            "kabkotName": "Kabupaten Tanah Bumbu",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a120",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a120",
                    "name": "TSS",
                    "calculation": 25.58337570070881
                },
                {
                    "_id": "65ede71ae18a7a560d39a120",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6319ac8162d22b3284f8659e",
            "compName": "PT. Multimas Nabati Asahan (Kawasan Industri - Plant Serang)",
            "compAddress": "Jalan Raya Bojonegara KM 5 Kavling 162 Desa Terate Kecamatan Kramatwatu",
            "latlong": {
                "lat": -6.0056111,
                "lng": 106.115511
            },
            "kabkotName": "Kabupaten Serang",
            "provName": "Banten",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a126",
                    "name": "COD",
                    "calculation": 11.74599637047043
                },
                {
                    "_id": "65ede71ae18a7a560d39a126",
                    "name": "TSS",
                    "calculation": 15.175990168160787
                },
                {
                    "_id": "65ede71ae18a7a560d39a126",
                    "name": "NH3N",
                    "calculation": 0.4832282427317815
                }
            ]
        },
        {
            "_id": "631abd1562d22b3284047a73",
            "compName": "PT. Bara Tabang",
            "compAddress": "Desa Gunung Sari, Kecamatan Tabang",
            "latlong": {
                "lat": 0.551621,
                "lng": 116.178581
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a12a",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a12a",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a12a",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "631abd5362d22b3284048293",
            "compName": "PT Fajar Sakti Prima",
            "compAddress": "Jl. Wolter Monginsidi No. 17A Kelurahan Timbau, Kecamatan Tenggarong, Kutai Kartanegara",
            "latlong": {
                "lat": 0.5483333,
                "lng": 116.1252777
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "631abd8262d22b32840485db",
            "compName": "PT. Teguh Sinarabadi",
            "compAddress": "Kampung Muara Bunyut RT. 01, Kecamatan Melak",
            "latlong": {
                "lat": -0.392917,
                "lng": 115.737944
            },
            "kabkotName": "Kabupaten Kutai Barat",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a132",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a132",
                    "name": "TSS",
                    "calculation": 0.026492260516835058
                },
                {
                    "_id": "65ede71ae18a7a560d39a132",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "631abdd862d22b3284048f12",
            "compName": "PT. Gunungbayan Pratamacoal",
            "compAddress": "Senior Office, Muara Tae, Kec. Jempang Dan Siluq Ngurai, Kab. Kutai Barat, Kalimantan Timur",
            "latlong": {
                "lat": -0.579,
                "lng": 115.999
            },
            "kabkotName": "Kabupaten Kutai Barat",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a136",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a136",
                    "name": "TSS",
                    "calculation": 4.9307759183634365
                },
                {
                    "_id": "65ede71ae18a7a560d39a136",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "631abe9e62d22b3284049eee",
            "compName": "PT. Perkasa Inakakerta",
            "compAddress": "Jl.Blok Beruang RT.4 RW.1 Dusun Sekurau Bawah",
            "latlong": {
                "lat": -0.752743,
                "lng": 117.624616
            },
            "kabkotName": "Kabupaten Kutai Timur",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a141",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a141",
                    "name": "TSS",
                    "calculation": 12.345639498577953
                },
                {
                    "_id": "65ede71ae18a7a560d39a141",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "631acb5362d22b3284054eb4",
            "compName": "PT. Asmin Bara Bronang",
            "compAddress": "Jalan Angkut Batubara KM. 69 Desa Barunang, Kecamatan Kapuas Tengah, Kabupaten Kapuas, Provinsi Kalimantan Tengah",
            "latlong": {
                "lat": -0.9913888888888889,
                "lng": 114.34033333333333
            },
            "kabkotName": "Kabupaten Kapuas",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a149",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a149",
                    "name": "TSS",
                    "calculation": 2773.974649451149
                },
                {
                    "_id": "65ede71ae18a7a560d39a149",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "631acbe662d22b3284055894",
            "compName": "PT Suprabari Mapanindo Mineral",
            "compAddress": "Desa Lemo I, Kecamatan Teweh Tengah Teweh - Selatan",
            "latlong": {
                "lat": -1.06344,
                "lng": 114.887
            },
            "kabkotName": "Kabupaten Barito Utara",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a151",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a151",
                    "name": "TSS",
                    "calculation": 3584.6477562951336
                },
                {
                    "_id": "65ede71ae18a7a560d39a151",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "631ad44762d22b328405d085",
            "compName": "PT. Wahana Baratama Mining",
            "compAddress": "Komplek Jetty PT.WBM, Desa/Kelurahan Sungai Cuka, Kecamatan Satui, Kabupaten/Kota Tanah Bumbu, Provinsi Kalimantan Selatan,Kode Pos:(72275)",
            "latlong": {
                "lat": -3.72444,
                "lng": 115.337333
            },
            "kabkotName": "Kabupaten Tanah Bumbu",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a15f",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a15f",
                    "name": "TSS",
                    "calculation": 0.5833837562300704
                },
                {
                    "_id": "65ede71ae18a7a560d39a15f",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "631ad46962d22b328405d385",
            "compName": "PT. Firman Ketaun Perkasa",
            "compAddress": "Jl. Sebuluh RT.01 Kampung Muara Bunyut, Kecamatan Melak",
            "latlong": {
                "lat": -0.447,
                "lng": 115.721
            },
            "kabkotName": "Kabupaten Kutai Barat",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a164",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a164",
                    "name": "TSS",
                    "calculation": 1570.209226221043
                },
                {
                    "_id": "65ede71ae18a7a560d39a164",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "631af63362d22b328407d379",
            "compName": "PT. Gag Nikel",
            "compAddress": "Pulau Gag, Distrik Waigeo Barat Kepulauan Raja Ampat",
            "latlong": {
                "lat": -0.4438237222222222,
                "lng": 129.9029776666667
            },
            "kabkotName": "Kabupaten Raja Ampat",
            "provName": "Papua Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a174",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a174",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a174",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "631afa3962d22b32840822ab",
            "compName": "CV. Sungai Indah",
            "compAddress": "Jalan Wayang No. 4 - Kota Bandung",
            "latlong": {
                "lat": -7.053055555555555,
                "lng": 107
            },
            "kabkotName": "Kabupaten Bandung",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "631eb95262d22b32843098fd",
            "compName": "PT Darmex Biofuels",
            "compAddress": "Jl Raya Bekasi KM 27 Desa Pejuang RT 004 RW 002 Kaliabang Tengah Bekasi Utara Kotamadya Bekasi",
            "latlong": {
                "lat": -6.19889,
                "lng": 106.997
            },
            "kabkotName": "Kota Bekasi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a183",
                    "name": "COD",
                    "calculation": 0.0003299438862969635
                },
                {
                    "_id": "65ede71ae18a7a560d39a183",
                    "name": "TSS",
                    "calculation": 0.0017207197295064176
                },
                {
                    "_id": "65ede71ae18a7a560d39a183",
                    "name": "NH3N",
                    "calculation": 0.00006820945405647509
                }
            ]
        },
        {
            "_id": "631ebe5d62d22b328430dda4",
            "compName": "PT. MANAMBANG MUARA ENIM",
            "compAddress": "jalan lintas baturaja-muara enim km 91 Desa Darmo, Tanjung enim, Kec Lawang Kidul, Kab Muara Enim",
            "latlong": {
                "lat": -3.800722,
                "lng": 103.80494
            },
            "kabkotName": "Kabupaten Muara Enim",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a189",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a189",
                    "name": "TSS",
                    "calculation": 20.05946830069786
                },
                {
                    "_id": "65ede71ae18a7a560d39a189",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "631ecdec62d22b328431969b",
            "compName": "PT Sari Dumai Sejati",
            "compAddress": "Jl. Raya Lubuk Gaung, Kelurahan Lubuk Gaung, Kecamatan Sungai Sembilan, Kota Dumai, Provinsi Riau",
            "latlong": {
                "lat": 1.754417,
                "lng": 101.366361
            },
            "kabkotName": "Kota Dumai",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a18f",
                    "name": "COD",
                    "calculation": 18.74440139688116
                },
                {
                    "_id": "65ede71ae18a7a560d39a18f",
                    "name": "TSS",
                    "calculation": 10.648949053779711
                },
                {
                    "_id": "65ede71ae18a7a560d39a18f",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63217296110bec4f64818aa5",
            "compName": "PT. Pulau Mas Texindo",
            "compAddress": "Jalan Nanjung Km 2,2  Desa Lagadar Kecamatan Margaasih Kabupaten Bandung",
            "latlong": {
                "lat": -6.9209444,
                "lng": 107.54163888888888
            },
            "kabkotName": "Kabupaten Bandung",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a194",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a194",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a194",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "632175b1110bec4f6481be15",
            "compName": "PT. Kawasan Industri Wijaya Kusuma",
            "compAddress": "Jl. Raya Semarang - Kendal KM. 12",
            "latlong": {
                "lat": -6.970625,
                "lng": 110.33286944444444
            },
            "kabkotName": "Kota Semarang",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a198",
                    "name": "COD",
                    "calculation": 18.71393190415515
                },
                {
                    "_id": "65ede71ae18a7a560d39a198",
                    "name": "TSS",
                    "calculation": 3.3378
                },
                {
                    "_id": "65ede71ae18a7a560d39a198",
                    "name": "NH3N",
                    "calculation": 0.33155480059903014
                }
            ]
        },
        {
            "_id": "63218bc6110bec4f64833371",
            "compName": "PT. Pelita Agung Agrindustri",
            "compAddress": "Jl. Iskandar Muda No.107, Medan",
            "latlong": {
                "lat": 1.423051,
                "lng": 101.188185
            },
            "kabkotName": "Kabupaten Bengkalis",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a19c",
                    "name": "COD",
                    "calculation": 47.67105375771155
                },
                {
                    "_id": "65ede71ae18a7a560d39a19c",
                    "name": "TSS",
                    "calculation": 3.8754768762878067
                },
                {
                    "_id": "65ede71ae18a7a560d39a19c",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6322de01110bec4f6499546d",
            "compName": "PT. Khotai Makmur Insan Abadi",
            "compAddress": "Jl. Poros Samarinda-Tenggarong RT 16, Dusun Sidomulyo, Desa Bukit Raya, Tenggarong Seberang, Kutai Kartanegara ",
            "latlong": {
                "lat": -0.23014722222222223,
                "lng": 117.14147777777778
            },
            "kabkotName": "Kabupaten Kutai Kertanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a1a0",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a1a0",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a1a0",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6327cead110bec4f64f59752",
            "compName": "PT. Kayan Putra Utama Coal",
            "compAddress": "Desa Separi Kecamatan Tenggarong seberang",
            "latlong": {
                "lat": -0.31625,
                "lng": 117.02894
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6328248f110bec4f640072a4",
            "compName": "PT. Kadya Carakamulia",
            "compAddress": "Kantor Site KCM",
            "latlong": {
                "lat": -3.315473,
                "lng": 115.0491347
            },
            "kabkotName": "Kabupaten Banjar",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "632a9036110bec4f642f67b4",
            "compName": "PT. Bukit Baiduri Energi",
            "compAddress": "Jl. Raya Poros Samarinda – Tenggarong Km.12 RT.16  Desa Bukit Raya, Kecamatan Tenggarong Seberang Kab. Kutai Kartanegara, Kalimantan Timur",
            "latlong": {
                "lat": -0.46925,
                "lng": 117.0772499
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "632a9263110bec4f642fa6b0",
            "compName": "PT. Puradelta Lestari, Tbk.",
            "compAddress": "JLN. TOL JAKARTA CIKAMPEK KM 37, CIKARANG PUSAT KABUPATEN BEKASI",
            "latlong": {
                "lat": -6.93333,
                "lng": 107.867
            },
            "kabkotName": "Kabupaten Bekasi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a1b9",
                    "name": "COD",
                    "calculation": 25.795892243315286
                },
                {
                    "_id": "65ede71ae18a7a560d39a1b9",
                    "name": "TSS",
                    "calculation": 21.476513016128578
                },
                {
                    "_id": "65ede71ae18a7a560d39a1b9",
                    "name": "NH3N",
                    "calculation": 0.234224543198145
                }
            ]
        },
        {
            "_id": "632a96cd110bec4f64303061",
            "compName": "PT. Adiprima Suraprinta",
            "compAddress": "Jalan Raya Wringinanom KM 30, Desa Sumengko, Kecamatan Wringinanom, Kabupaten Gresik, Jawa Timur",
            "latlong": {
                "lat": -7.393725000000001,
                "lng": 112.56697777777778
            },
            "kabkotName": "Kabupaten Gresik",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a1bd",
                    "name": "COD",
                    "calculation": 754.0740053485717
                },
                {
                    "_id": "65ede71ae18a7a560d39a1bd",
                    "name": "TSS",
                    "calculation": 272.1680870898351
                },
                {
                    "_id": "65ede71ae18a7a560d39a1bd",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "632accd2110bec4f6434e4ed",
            "compName": "PT. Perkebunan Nusantara I (Persero) PKS Tanjung Seumantoh",
            "compAddress": "PT. Perkebunan Nusantara I (Persero) PKS Tanjung Seumantoh",
            "latlong": {
                "lat": 4.361611,
                "lng": 98.084618
            },
            "kabkotName": "Kabupaten Aceh Tamiang",
            "provName": "Aceh",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "632acdf2110bec4f6435040e",
            "compName": "PT. Perkebunan Nusantara I (Persero) PKS Pulau Tiga",
            "compAddress": "Kecamatan Tamiang Hulu, Kabupaten Aceh Tamiang, Provinsi Aceh",
            "latlong": {
                "lat": 4.1820982,
                "lng": 97.9351583
            },
            "kabkotName": "Kabupaten Aceh Tamiang",
            "provName": "Aceh",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a1c3",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a1c3",
                    "name": "TSS",
                    "calculation": 0.06053958000000003
                },
                {
                    "_id": "65ede71ae18a7a560d39a1c3",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "632ace60110bec4f64350f93",
            "compName": "PT. Perkebunan Nusantara I (Persero) - PKS COT GIREK",
            "compAddress": "Kecamatan Cot Girek, Kabupaten Aceh Utara, Provinsi Aceh",
            "latlong": {
                "lat": 4.9245406,
                "lng": 97.3702234
            },
            "kabkotName": "Kabupaten Aceh Utara",
            "provName": "Aceh",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "632acf54110bec4f64352b04",
            "compName": "PT. Surabaya Industrial Estate Rungkut (PIER)",
            "compAddress": "Jalan Kraton Industri II No. 1, Kawasan Industri PIER, Kab. Pasuruan",
            "latlong": {
                "lat": -7.614167,
                "lng": 112.819833
            },
            "kabkotName": "Kabupaten Pasuruan",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "632acfdb110bec4f643540a5",
            "compName": "PT Putra Muba Coal",
            "compAddress": "Desa Mekar Jadi Kecamatan Sungai Lilin Kabupaten Musi Banyuasin",
            "latlong": {
                "lat": -2.55274594864757,
                "lng": 104.153021841858
            },
            "kabkotName": "Kabupaten Musi Banyuasin",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a1d2",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a1d2",
                    "name": "TSS",
                    "calculation": 0.13629221552545157
                },
                {
                    "_id": "65ede71ae18a7a560d39a1d2",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "632af3b2110bec4f64381d21",
            "compName": "PT MAHAKAM SUMBER JAYA",
            "compAddress": "Desa Bukit Pariaman, Kecamatan Tenggarong Seberang",
            "latlong": {
                "lat": -0.31130556,
                "lng": 117.04044444
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a1d6",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a1d6",
                    "name": "TSS",
                    "calculation": 19.94828034746823
                },
                {
                    "_id": "65ede71ae18a7a560d39a1d6",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "632af82a110bec4f64388662",
            "compName": "PT. Kalimantan Energi Lestari",
            "compAddress": "Desa Gendang Timburu",
            "latlong": {
                "lat": -2.59428,
                "lng": 115.973
            },
            "kabkotName": "Kabupaten Kota Baru",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ae18a7a560d39a1e0",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ae18a7a560d39a1e0",
                    "name": "TSS",
                    "calculation": 3444.4829472733427
                },
                {
                    "_id": "65ede71ae18a7a560d39a1e0",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "632afb89110bec4f6438cd3d",
            "compName": "PT. Agro Muko -  Mukomuko POM",
            "compAddress": "Desa Teruntung, Kecamatan Teras Terunjam",
            "latlong": {
                "lat": -2.592852,
                "lng": 101.266678
            },
            "kabkotName": "Kabupaten Mukomuko",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "632afc65110bec4f6438e077",
            "compName": "PT. Agro Muko - Bunga Tanjung Palm Oil Mill",
            "compAddress": "Desa Brangan Mulya, Kec. Teramang Jaya",
            "latlong": {
                "lat": -2.72595,
                "lng": 101.375
            },
            "kabkotName": "Kabupaten Mukomuko",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "632d3df4110bec4f646adc06",
            "compName": "PT. Gane Permai Sentosa",
            "compAddress": "Desa Kawasi, Kabupaten Halmahera Selatan, Provinsi Maluku Utara",
            "latlong": {
                "lat": -1.5825,
                "lng": 127.434
            },
            "kabkotName": "Kabupaten Halmahera Selatan",
            "provName": "Maluku Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a1f3",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a1f3",
                    "name": "TSS",
                    "calculation": 2.530573324137931
                },
                {
                    "_id": "65ede71be18a7a560d39a1f3",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "632d45e0110bec4f646ba014",
            "compName": "PT. Berlian Inti Mekar 1",
            "compAddress": "Jl. Lintas Samudra Dusun I Km. 14 Rt. 03 Rw. 01 Desa Danau Rambai Kec. Batang Gangsal Kab. Indragiri Hulu",
            "latlong": {
                "lat": -0.7191833333333333,
                "lng": 102.62824722222221
            },
            "kabkotName": "Kabupaten Indragiri Hulu",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a1f8",
                    "name": "COD",
                    "calculation": 14.753787448496995
                },
                {
                    "_id": "65ede71be18a7a560d39a1f8",
                    "name": "TSS",
                    "calculation": 4.986147547783413
                },
                {
                    "_id": "65ede71be18a7a560d39a1f8",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63301a32110bec4f64abf9e7",
            "compName": "PT. PARISINDO PRATAMA",
            "compAddress": "Gunung Putri - Citeureup, Kabupaten Bogor Kode pos 16810, Jawa Barat ",
            "latlong": {
                "lat": -6.458,
                "lng": 106.905
            },
            "kabkotName": "Kabupaten Bogor",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a1fd",
                    "name": "COD",
                    "calculation": 17.978467482375475
                },
                {
                    "_id": "65ede71be18a7a560d39a1fd",
                    "name": "TSS",
                    "calculation": 21.629374409578546
                },
                {
                    "_id": "65ede71be18a7a560d39a1fd",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63303253110bec4f64ae1f89",
            "compName": "PT. Sampoerna Agro - PKS Selapan Jaya",
            "compAddress": "Jalan Basuki Rahmat No 788, Kota Palembang , Provinsi Sumatera Selatan.",
            "latlong": {
                "lat": -3.70602778,
                "lng": 105.10502778
            },
            "kabkotName": "Kabupaten Ogan Komering Ilir",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a205",
                    "name": "COD",
                    "calculation": 8.325415244658119
                },
                {
                    "_id": "65ede71be18a7a560d39a205",
                    "name": "TSS",
                    "calculation": 2.9466964866666654
                },
                {
                    "_id": "65ede71be18a7a560d39a205",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6330337e110bec4f64ae3ea4",
            "compName": "PT BERLIAN INTI MEKAR",
            "compAddress": "Jalan Raya Perawang Siak Km. 68, Desa Dayun Kecamatan Dayun, Kabupaten Siak, Provinsi Riau",
            "latlong": {
                "lat": 0.64525,
                "lng": 101.973278
            },
            "kabkotName": "Kabupaten Siak",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a20d",
                    "name": "COD",
                    "calculation": 4.837433834921723
                },
                {
                    "_id": "65ede71be18a7a560d39a20d",
                    "name": "TSS",
                    "calculation": 6.631405387569426
                },
                {
                    "_id": "65ede71be18a7a560d39a20d",
                    "name": "NH3N",
                    "calculation": 0.0482218821627328
                }
            ]
        },
        {
            "_id": "63303409110bec4f64ae4fd4",
            "compName": "PT. KARYA SERASI JAYA ABADI PMKS TEBING SYAHBANDAR",
            "compAddress": "JL lintas Pagurawan  Desa Binjai Kecamatan Tebing Syahbandar Kabupaten Serdang Bedagai Provinsi Sumatera Utara ",
            "latlong": {
                "lat": 3.3475,
                "lng": 99.22083333333333
            },
            "kabkotName": "Kabupaten Serdang Bedagai",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6330383e110bec4f64aeb35b",
            "compName": "PT. Bakti Nugraha Yuda",
            "compAddress": "PLTU Baturaja, Desa Tanjung Kemala, Kecamatan Baturaja Timur, Kabupaten Ogan Komering ulu, Sumatera Selatan",
            "latlong": {
                "lat": -4.091448,
                "lng": 104.175939
            },
            "kabkotName": "Kabupaten Ogan Komering Ulu",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "63303a78110bec4f64aeef2f",
            "compName": "PT Multi Tambangjaya Utama",
            "compAddress": "Desa Teluk Betung",
            "latlong": {
                "lat": -1.99008,
                "lng": 114.902
            },
            "kabkotName": "Kabupaten Barito Selatan",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a223",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a223",
                    "name": "TSS",
                    "calculation": 4.711500158312808
                },
                {
                    "_id": "65ede71be18a7a560d39a223",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6330f99d110bec4f64bd4c64",
            "compName": "PT. Hari Sawit Jaya - PMKS Negeri Lama Satu",
            "compAddress": "Desa Sidomulyo, Kecamatan Bilah Hilir, Kabupaten Labuhan Batu, Provinsi Sumatera Utara",
            "latlong": {
                "lat": 2.36378,
                "lng": 100.04
            },
            "kabkotName": "Kabupaten Labuhan Batu",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "633100a6110bec4f64be2b88",
            "compName": "PT. Meridan Sejatisurya Plantations",
            "compAddress": "Kawasan Industri Bangsal Aceh, Kelurahan bangsal Aceh, kecamatan sungai sembilan, Kota dumai ",
            "latlong": {
                "lat": 1.726388888888889,
                "lng": 101.38222222222221
            },
            "kabkotName": "Kota Dumai",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6334399a110bec4f641c37f9",
            "compName": "PT. CIPTA USAHA SEJATI",
            "compAddress": "DESA LUBUK BATU KECAMATAN SIMPANG HILIR KABUPATEN KAYONG UTARA",
            "latlong": {
                "lat": -0.998056,
                "lng": 110.191
            },
            "kabkotName": "Kabupaten Kayong Utara",
            "provName": "Kalimantan Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a22f",
                    "name": "COD",
                    "calculation": 2.355433362221974
                },
                {
                    "_id": "65ede71be18a7a560d39a22f",
                    "name": "TSS",
                    "calculation": 0.011952000000000006
                },
                {
                    "_id": "65ede71be18a7a560d39a22f",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63343a15110bec4f641c471f",
            "compName": "PT. Mitra Aneka Rezeki",
            "compAddress": "Desa Sungai Deras",
            "latlong": {
                "lat": -0.363889,
                "lng": 109.302
            },
            "kabkotName": "Kabupaten Kubu Raya",
            "provName": "Kalimantan Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a233",
                    "name": "COD",
                    "calculation": 25.757812285714287
                },
                {
                    "_id": "65ede71be18a7a560d39a233",
                    "name": "TSS",
                    "calculation": 1.5253007142857142
                },
                {
                    "_id": "65ede71be18a7a560d39a233",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63344b5e110bec4f641d777b",
            "compName": "PT BERLIAN INTI MEKAR - MUARA ENIM ",
            "compAddress": "JALAN NEGARA PALEMBANG - PRABUMULIH KM 54 SUKAMENANG ",
            "latlong": {
                "lat": -3.2264,
                "lng": 104.475
            },
            "kabkotName": "Kabupaten Muara Enim",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a238",
                    "name": "COD",
                    "calculation": 17.235320761179523
                },
                {
                    "_id": "65ede71be18a7a560d39a238",
                    "name": "TSS",
                    "calculation": 0.8547437795141345
                },
                {
                    "_id": "65ede71be18a7a560d39a238",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63344dbe110bec4f641d9c37",
            "compName": "PT MUTIARA UNGGUL LESTARI",
            "compAddress": "JL. LINTAS PEKANBARU-DURI KM. 63, KEL. TELAGA SAM-SAM, KEC. KANDIS, KAB. SIAK, PROV. RIAU",
            "latlong": {
                "lat": 0.84713,
                "lng": 101.29797
            },
            "kabkotName": "Kabupaten Siak",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "633451df110bec4f641ddf7f",
            "compName": "PT. Mutiara Bunda Jaya",
            "compAddress": "Jl. Basuki Rahmat No. 788, Kota Palembang, Provinsi Sumatera Selatan",
            "latlong": {
                "lat": -3.94311111,
                "lng": 105.10802778
            },
            "kabkotName": "Kabupaten Ogan Komering Ilir",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a23e",
                    "name": "COD",
                    "calculation": 17.386246528413796
                },
                {
                    "_id": "65ede71be18a7a560d39a23e",
                    "name": "TSS",
                    "calculation": 11.37234614032184
                },
                {
                    "_id": "65ede71be18a7a560d39a23e",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "633cf1c3d8814a1e69f40bed",
            "compName": "PT. Aek Tarum",
            "compAddress": "Desa Sumber Baru Kecamatan Mesuji Raya, Kabupaten Ogan Komering Ilir",
            "latlong": {
                "lat": -3.83405556,
                "lng": 104.97044444
            },
            "kabkotName": "Kabupaten Ogan Komering Ilir",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a245",
                    "name": "COD",
                    "calculation": 34.26862962444445
                },
                {
                    "_id": "65ede71be18a7a560d39a245",
                    "name": "TSS",
                    "calculation": 12.94136994444445
                },
                {
                    "_id": "65ede71be18a7a560d39a245",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "633d2fb0d8814a1e69f985d7",
            "compName": "PT. Sarana Industama Perkasa",
            "compAddress": "Jl. Access Road Inalum Km 15, Kel. Lalang, Kec. Medang Deras, Kab. Batubara, Provinsi Sumatera Utara",
            "latlong": {
                "lat": 3.378336,
                "lng": 99.430072
            },
            "kabkotName": "Kabupaten Batu Bara",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a24a",
                    "name": "COD",
                    "calculation": 23.41680456145977
                },
                {
                    "_id": "65ede71be18a7a560d39a24a",
                    "name": "TSS",
                    "calculation": 6.050260825331899
                },
                {
                    "_id": "65ede71be18a7a560d39a24a",
                    "name": "NH3N",
                    "calculation": 0.023282812861149426
                }
            ]
        },
        {
            "_id": "633d3bfdd8814a1e69fa5d7b",
            "compName": "PT Insani Baraperkasa",
            "compAddress": "Jl. Pembangunan I No.3, Gambir, Jakarta Pusat, DKI Jakarta",
            "latlong": {
                "lat": -0.634333333,
                "lng": 117.11780455555
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a24e",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a24e",
                    "name": "TSS",
                    "calculation": 63.19642925915378
                },
                {
                    "_id": "65ede71be18a7a560d39a24e",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "633d3e9fd8814a1e69fa955b",
            "compName": "PT. Parna Agromas",
            "compAddress": "Dusun Batu Ampar, Desa Tapang Pulau Kecamatan Belitang Hilir, Kabupaten Sekadau",
            "latlong": {
                "lat": 0.211067,
                "lng": 111.076723
            },
            "kabkotName": "Kabupaten Sekadau",
            "provName": "Kalimantan Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a252",
                    "name": "COD",
                    "calculation": 96.94386110457144
                },
                {
                    "_id": "65ede71be18a7a560d39a252",
                    "name": "TSS",
                    "calculation": 49.46020255942858
                },
                {
                    "_id": "65ede71be18a7a560d39a252",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6343a541d8814a1e695aa592",
            "compName": "PT. Globalindo Agung Lestari",
            "compAddress": "Desa Seriwidadi Kecamatan Mentangai Kabupaten Kapuas Provinsi Kalimantan Tengah",
            "latlong": {
                "lat": -2.58584,
                "lng": 114.5917
            },
            "kabkotName": "Kabupaten Kapuas",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6343d180d8814a1e695ddb74",
            "compName": "PT. DUTA TAMBANG REKAYASA",
            "compAddress": "Jl. Tawakkal No.63 RT 03 Nunukan Timur, Kecamatan Nunukan, Kabupaten Nunukan, Kaltara 77482",
            "latlong": {
                "lat": 4.11806,
                "lng": 117.192
            },
            "kabkotName": "Kabupaten Nunukan",
            "provName": "Kalimantan Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6343d659d8814a1e695e2e57",
            "compName": "PT Bumipalma Lestaripersada Unit Bumipalma",
            "compAddress": "Desa Bagan Jaya Kecamatan Enok",
            "latlong": {
                "lat": -0.603363,
                "lng": 102.985
            },
            "kabkotName": "Kabupaten Indragiri Hilir",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a273",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a273",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a273",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "634e1862d8814a1e69ff278f",
            "compName": "PT. Nippon Shokubai Indonesia",
            "compAddress": "Kawasan Industri Pancapuri Jl. Raya Anyer KM 122 Ciwandan, Kota Cilegon - Banten",
            "latlong": {
                "lat": -6.03581,
                "lng": 105.94
            },
            "kabkotName": "Kota Cilegon",
            "provName": "Banten",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a278",
                    "name": "COD",
                    "calculation": 6.211904233999829
                },
                {
                    "_id": "65ede71be18a7a560d39a278",
                    "name": "TSS",
                    "calculation": 50.64570318757208
                },
                {
                    "_id": "65ede71be18a7a560d39a278",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "634e28d7d8814a1e6900882a",
            "compName": "PT. Karya Mitra Andalan",
            "compAddress": "DUSUN III, DESA PULAU MARIA, KEC. TELUK DALAM, KABUPATEN ASAHAN",
            "latlong": {
                "lat": 2.78438,
                "lng": 99.621575
            },
            "kabkotName": "Kabupaten Asahan",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "636075dad8814a1e6984d99e",
            "compName": "PT. Pinago Utama PMKS",
            "compAddress": "Desa Sugiwaras Kec. Babat Toman ",
            "latlong": {
                "lat": -2.74158,
                "lng": 103.56475
            },
            "kabkotName": "Kabupaten Musi Banyuasin",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a287",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a287",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a287",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63609dfcd8814a1e6989b700",
            "compName": "PT. Hyundai Inti Development",
            "compAddress": "Kawasan Industri Bekasi Internasional Industrial Estate (KI BIIE), Desa Sukaresmi dan Cibatu, Kecamatan Cikarang Selatan, Kabupaten Bekasi, Jawa Barat",
            "latlong": {
                "lat": -6.342444,
                "lng": 107.138881
            },
            "kabkotName": "Kabupaten Bekasi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a28b",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a28b",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a28b",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "636b18ecd8814a1e693cf56f",
            "compName": "PT. Kharisma Inti Usaha",
            "compAddress": "Kecamatan Candi Laras Utara, Tapin Tengah dan Binuang, Kabupaten Tapin Provinsi Kalimantan Selatan",
            "latlong": {
                "lat": -3.004722,
                "lng": 114.92944
            },
            "kabkotName": "Kabupaten Tapin",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a292",
                    "name": "COD",
                    "calculation": 13.184802171649197
                },
                {
                    "_id": "65ede71be18a7a560d39a292",
                    "name": "TSS",
                    "calculation": 1.3659239382058244
                },
                {
                    "_id": "65ede71be18a7a560d39a292",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6371f526d8814a1e69ae093e",
            "compName": "PT. Mega Surya Eratama",
            "compAddress": "Jl. Raya Jasem RT.01 RW.04 Kec. Ngoro",
            "latlong": {
                "lat": -7.52966,
                "lng": 112.61865
            },
            "kabkotName": "Kabupaten Mojokerto",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a29a",
                    "name": "COD",
                    "calculation": 107.90514373739224
                },
                {
                    "_id": "65ede71be18a7a560d39a29a",
                    "name": "TSS",
                    "calculation": 26.601148467994197
                },
                {
                    "_id": "65ede71be18a7a560d39a29a",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6371ff6ad8814a1e69af3aa6",
            "compName": "PT. Kahatex - Cimahi",
            "compAddress": "Kantor Unit Bisnis",
            "latlong": {
                "lat": -6.92917,
                "lng": 107.562
            },
            "kabkotName": "Kota Cimahi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a2a2",
                    "name": "COD",
                    "calculation": 14.015892230252467
                },
                {
                    "_id": "65ede71be18a7a560d39a2a2",
                    "name": "TSS",
                    "calculation": 0.5803091055233989
                },
                {
                    "_id": "65ede71be18a7a560d39a2a2",
                    "name": "NH3N",
                    "calculation": 2.1264963195997533
                }
            ]
        },
        {
            "_id": "63856595cb7de71baff57963",
            "compName": "PT. LDC Indonesia",
            "compAddress": "Jl. Soekarno Hatta KM. 10 LK II RT 023 Way Lunik Panjang Kota Bandar Lampung Lampung 35244",
            "latlong": {
                "lat": -5.45861,
                "lng": 105.31556
            },
            "kabkotName": "Kota Bandar Lampung",
            "provName": "Lampung",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a2aa",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a2aa",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a2aa",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6385af7acb7de71baffd2398",
            "compName": "PT. Saban Sawit Subur",
            "compAddress": "Jalan Pasir Putih, Dusun Semendok, Desa Sejotang, Kecamatan Tayan Hilir, Kab. Sanggau, Kalimantan Barat",
            "latlong": {
                "lat": -0.03719,
                "lng": 110.06386
            },
            "kabkotName": "Kabupaten Sanggau",
            "provName": "Kalimantan Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a2b2",
                    "name": "COD",
                    "calculation": 6.261076386010489
                },
                {
                    "_id": "65ede71be18a7a560d39a2b2",
                    "name": "TSS",
                    "calculation": 3.873200742337988
                },
                {
                    "_id": "65ede71be18a7a560d39a2b2",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6385c962cb7de71baf0043d5",
            "compName": "PT. INTIBENUA PERKASATAMA ",
            "compAddress": "Jalan Raya Lubuk Gaung, Kel. Lubuk Gaung, Kec. Sungai Sembilan",
            "latlong": {
                "lat": 1.76222,
                "lng": 101.369
            },
            "kabkotName": "Kota Dumai",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a2b8",
                    "name": "COD",
                    "calculation": -0.02788422349222223
                },
                {
                    "_id": "65ede71be18a7a560d39a2b8",
                    "name": "TSS",
                    "calculation": 0.9172353746111113
                },
                {
                    "_id": "65ede71be18a7a560d39a2b8",
                    "name": "NH3N",
                    "calculation": 0.38153278654555556
                }
            ]
        },
        {
            "_id": "6386ce9bcb7de71baf130e8d",
            "compName": "PT. SURYA USAHA MANDIRI",
            "compAddress": "JL. TARAJUSARI NO. 8 DESA TARAJUSARI, KEC: BANJARAN, KAB. BANDUNG",
            "latlong": {
                "lat": -7.03969,
                "lng": 107.57739
            },
            "kabkotName": "Kabupaten Bandung",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "638710dacb7de71baf1bea85",
            "compName": "PT. Hindoli A Cargill Company - Mill Sungai Lilin ",
            "compAddress": "Jl. Palembang Jambi Km.110 Desa Teluk Kemang, Kecamatan Sungai Lilin - Sumatera Selatan",
            "latlong": {
                "lat": -2.609952,
                "lng": 104.126959
            },
            "kabkotName": "Kabupaten Musi Banyuasin",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a2be",
                    "name": "COD",
                    "calculation": 294.33341520732455
                },
                {
                    "_id": "65ede71be18a7a560d39a2be",
                    "name": "TSS",
                    "calculation": 46.469108781763055
                },
                {
                    "_id": "65ede71be18a7a560d39a2be",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63bce4597f1369c202886fe5",
            "compName": "BP Berau, Ltd",
            "compAddress": "Kampung Tanah Merah Baru, Desa/Kelurahan Tanah Merah, Kec. Sumuri, Kab. Teluk Bintuni, Provinsi Papua Barat",
            "latlong": {
                "lat": -2.445278,
                "lng": 133.1403
            },
            "kabkotName": "Kabupaten Teluk Bintuni",
            "provName": "Papua",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a2c2",
                    "name": "COD",
                    "calculation": 1.7167823775622606
                },
                {
                    "_id": "65ede71be18a7a560d39a2c2",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a2c2",
                    "name": "NH3N",
                    "calculation": 0.021165297825670507
                }
            ]
        },
        {
            "_id": "63c94bcc5c7da2b9915217e7",
            "compName": "PT. Kawasan Industri Makassar (KIMA)",
            "compAddress": "Jl. Perintis Kemerdekaan KM. 15, Daya, Makassar",
            "latlong": {
                "lat": -5.11183,
                "lng": 119.50461
            },
            "kabkotName": "Kota Makassar",
            "provName": "Sulawesi Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a2ca",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a2ca",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a2ca",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63c950985c7da2b9915217e9",
            "compName": "PT. Multi Prima Entakai",
            "compAddress": "Jl. Kapten Marsan No.16 D Kel. Daratsekip Kec. Pontianak",
            "latlong": {
                "lat": 0.0637222,
                "lng": 110.745
            },
            "kabkotName": "Kabupaten Sanggau",
            "provName": "Kalimantan Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "63d3df0711c2e3bc59720111",
            "compName": "PT. Musim Mas Batam",
            "compAddress": "Jl Raya Pelabuhan CPO Kabil Nongsa, Batam",
            "latlong": {
                "lat": 1.08528,
                "lng": 104.133
            },
            "kabkotName": "Kota Batam",
            "provName": "Kepulauan Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a2d7",
                    "name": "COD",
                    "calculation": 0.00045048305555555555
                },
                {
                    "_id": "65ede71be18a7a560d39a2d7",
                    "name": "TSS",
                    "calculation": 0.00020985964559386972
                },
                {
                    "_id": "65ede71be18a7a560d39a2d7",
                    "name": "NH3N",
                    "calculation": 0.00010169113984674332
                }
            ]
        },
        {
            "_id": "63d3fe3fa2366e1acce66360",
            "compName": " uji coba TTE",
            "compAddress": " uji coba TTE",
            "latlong": {
                "lat": 586131,
                "lng": 2454685
            },
            "kabkotName": "Kabupaten Mukomuko",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,,,",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "63d3ffb6a2366e1acce683a4",
            "compName": "PT Dummy Data",
            "compAddress": "aaaa",
            "latlong": {
                "lat": -0.4752281,
                "lng": 117.14484431
            },
            "kabkotName": "Kabupaten Aceh Timur",
            "provName": "Aceh",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "63d403b3a2366e1acce72f59",
            "compName": "PT. INTAN SEJATI ANDALAN",
            "compAddress": "Jl. Lintas Duri - Dumai Duri XIII",
            "latlong": {
                "lat": 1.438789,
                "lng": 101.283689
            },
            "kabkotName": "Kabupaten Bengkalis",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "63d40528a2366e1acce74a04",
            "compName": "PT KARYADEKA ALAM LESTARI",
            "compAddress": "Ruko Emerald Green BSB Blok A No. 21-22",
            "latlong": {
                "lat": -7.045833,
                "lng": 110.333667
            },
            "kabkotName": "Kota Semarang",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a2df",
                    "name": "COD",
                    "calculation": 1.459154603868182
                },
                {
                    "_id": "65ede71be18a7a560d39a2df",
                    "name": "TSS",
                    "calculation": 3.090130139686039
                },
                {
                    "_id": "65ede71be18a7a560d39a2df",
                    "name": "NH3N",
                    "calculation": 0.37962843401006496
                }
            ]
        },
        {
            "_id": "63d405e8a2366e1acce76956",
            "compName": "Kalrez Petroleum (Seram) Ltd",
            "compAddress": "Jalan. AirportBula, Bula, Kec. Bula, Kabupaten Seram Bagian Timur, Maluku",
            "latlong": {
                "lat": -3.10475,
                "lng": 130.500667
            },
            "kabkotName": "Kabupaten Seram Bagian Timur",
            "provName": "Maluku",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a2e3",
                    "name": "COD",
                    "calculation": 54.24550711416666
                },
                {
                    "_id": "65ede71be18a7a560d39a2e3",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71be18a7a560d39a2e3",
                    "name": "NH3N",
                    "calculation": 1.4315061891666667
                }
            ]
        },
        {
            "_id": "63d4065da2366e1acce77d86",
            "compName": "PT. Papyrus Sakti Paper Mill",
            "compAddress": "JLN RAYA BANJARAN KM. 16,2 BANJARAN-BANDUNG",
            "latlong": {
                "lat": -7.03731,
                "lng": 107.590997
            },
            "kabkotName": "Kabupaten Bandung",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71be18a7a560d39a2ed",
                    "name": "COD",
                    "calculation": 13.398961857142854
                },
                {
                    "_id": "65ede71be18a7a560d39a2ed",
                    "name": "TSS",
                    "calculation": 2.715359428571429
                },
                {
                    "_id": "65ede71be18a7a560d39a2ed",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63d40772a2366e1acce7af5e",
            "compName": "PT. Perkebunan Nusantara XIV (Persero) Pabrik Kelapa Sawit Luwu Unit I",
            "compAddress": "Jalan Poros Sulawesi PKS Luwu Desa Lagego, Kelurahan Lagego, Kecamatan Burau",
            "latlong": {
                "lat": -2.370768,
                "lng": 120.393607
            },
            "kabkotName": "Kabupaten Luwu Timur",
            "provName": "Sulawesi Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "63d9db7ce7443226f782ab54",
            "compName": "PT. Peniti Sungai Purun",
            "compAddress": "Jalan Raya Anjongan KM 58, Desa/Kelurahan Kepayang, Kecamatan Anjongan, Provinsi Kalimantan Barat",
            "latlong": {
                "lat": 0.3037,
                "lng": 109.1746
            },
            "kabkotName": "Kabupaten Mempawah",
            "provName": "Kalimantan Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a2f2",
                    "name": "COD",
                    "calculation": 4.820901830213675
                },
                {
                    "_id": "65ede71ce18a7a560d39a2f2",
                    "name": "TSS",
                    "calculation": 4.147726942008547
                },
                {
                    "_id": "65ede71ce18a7a560d39a2f2",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63db2fb0f280733a28d9aee7",
            "compName": "PT. Loji Kanatama Textile",
            "compAddress": "Jl. Raya Pait Km.10 No.403 Kec. Siwalan",
            "latlong": {
                "lat": -6.89508,
                "lng": 109.59
            },
            "kabkotName": "Kabupaten Pekalongan",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a30f",
                    "name": "COD",
                    "calculation": 12.61527535625
                },
                {
                    "_id": "65ede71ce18a7a560d39a30f",
                    "name": "TSS",
                    "calculation": 3.798754109225834
                },
                {
                    "_id": "65ede71ce18a7a560d39a30f",
                    "name": "NH3N",
                    "calculation": 0.27681515873666673
                }
            ]
        },
        {
            "_id": "63eb46f67f886641a9b4106e",
            "compName": "PT. Dewasutratex - Melong",
            "compAddress": "Jl. Cibaligo Km 2,8 Melong Cimahi",
            "latlong": {
                "lat": -6.91871,
                "lng": 107.55
            },
            "kabkotName": "Kota Cimahi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a316",
                    "name": "COD",
                    "calculation": 56.46646400870945
                },
                {
                    "_id": "65ede71ce18a7a560d39a316",
                    "name": "TSS",
                    "calculation": 3.5148608543094833
                },
                {
                    "_id": "65ede71ce18a7a560d39a316",
                    "name": "NH3N",
                    "calculation": 1.1102970658034483
                }
            ]
        },
        {
            "_id": "63f484797f886641a95a27a1",
            "compName": "PT Supra Bara Energi",
            "compAddress": "Graha Irama Lt 14 unit A-H Jl HR Rasuna Said Blok X-1 Kav 1 & 2. Rt 06, Rw 04, Kuningan Timur Setia Budi, Jakarta Selatan, 12950",
            "latlong": {
                "lat": 2.081088888888889,
                "lng": 117.41201666666667
            },
            "kabkotName": "Kabupaten Berau",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a31c",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a31c",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a31c",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63f71e99e7defd222b0b80ca",
            "compName": "PT. Sukajadi Sawit Mekar",
            "compAddress": "Jalan HM Arsyad Km 42 Desa Bagendang Hulu, Kecamatan Mentaya Hilir Utara Kabupaten Kotawaringin Timur",
            "latlong": {
                "lat": -2.72903,
                "lng": 112.923
            },
            "kabkotName": "Kabupaten Kotawaringin Timur",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a324",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a324",
                    "name": "TSS",
                    "calculation": 0.4677572554254445
                },
                {
                    "_id": "65ede71ce18a7a560d39a324",
                    "name": "NH3N",
                    "calculation": 0.027872179700944452
                }
            ]
        },
        {
            "_id": "63f72091e7defd222b0bb11b",
            "compName": "PT Ifishdeco Tbk",
            "compAddress": "Site Tinanggea, Kab. Konawe Selatan, Prov. Sulawesi Tenggara",
            "latlong": {
                "lat": -4.40337,
                "lng": 122.169
            },
            "kabkotName": "Kabupaten Konawe Selatan",
            "provName": "Sulawesi Tenggara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a32d",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a32d",
                    "name": "TSS",
                    "calculation": 2.1514751999999997
                },
                {
                    "_id": "65ede71ce18a7a560d39a32d",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63f72232e7defd222b0be44e",
            "compName": "PT. Kemilau Indah Nusantara",
            "compAddress": "Jl. Poros Sangata – Bengalon Km. 32, Simpang Rawa Indah, Kecamatan Bengalon, Kabupaten Kutai Timur",
            "latlong": {
                "lat": 0.724056,
                "lng": 117.661
            },
            "kabkotName": "Kabupaten Kutai Timur",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "63f87890e7defd222b2285cb",
            "compName": "PT Weda Bay Nickel",
            "compAddress": "Site Tanjung Ulie",
            "latlong": {
                "lat": 0.4888777777777778,
                "lng": 127.94395472222223
            },
            "kabkotName": "Kabupaten Halmahera Tengah",
            "provName": "Maluku Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a343",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a343",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a343",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63fc6a2de7defd222b63f458",
            "compName": "PT. Hindoli - Mill Mukut",
            "compAddress": "desa mukut",
            "latlong": {
                "lat": -2.440681,
                "lng": 104.419139
            },
            "kabkotName": "Kabupaten Banyu Asin",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a34b",
                    "name": "COD",
                    "calculation": 141.784
                },
                {
                    "_id": "65ede71ce18a7a560d39a34b",
                    "name": "TSS",
                    "calculation": 38.75659185327161
                },
                {
                    "_id": "65ede71ce18a7a560d39a34b",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63fc6ee2e7defd222b644f77",
            "compName": "PT. Pabrik Kertas Noree Indonesia",
            "compAddress": "Jl. Raya Babelan KM. 7.8, Kabupaten Bekasi, Provinsi Jawa Barat",
            "latlong": {
                "lat": -6.11415,
                "lng": 107.02277
            },
            "kabkotName": "Kabupaten Bekasi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a351",
                    "name": "COD",
                    "calculation": 2.1884452129310348
                },
                {
                    "_id": "65ede71ce18a7a560d39a351",
                    "name": "TSS",
                    "calculation": 0.04403862931034483
                },
                {
                    "_id": "65ede71ce18a7a560d39a351",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63fd626de7defd222b74166a",
            "compName": "PTPN VI (Persero) Unit Aur Gading",
            "compAddress": "Jl Lingkar Barat paal X Kecamatan Kota Baru Jambi 36126",
            "latlong": {
                "lat": -1.86528,
                "lng": 103.027
            },
            "kabkotName": "Kabupaten Batang Hari",
            "provName": "Jambi",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "63fdad2de7defd222b799300",
            "compName": "PT. SINAR BENGKULU SELATAN",
            "compAddress": "Jl. Raya Bengkulu Manna Desa Nanjungan Kecamatan Pino Raya Kabupaten Bengkulu Selatan Provinsi Bengkulu - 38572",
            "latlong": {
                "lat": 422664,
                "lng": 10250581
            },
            "kabkotName": "Kabupaten Bengkulu Selatan",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a356",
                    "name": "COD",
                    "calculation": 18.01135040842845
                },
                {
                    "_id": "65ede71ce18a7a560d39a356",
                    "name": "TSS",
                    "calculation": 7.734735191778159
                },
                {
                    "_id": "65ede71ce18a7a560d39a356",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "63fdaf5fe7defd222b79b9ff",
            "compName": "PT. Kahatex - Sumedang",
            "compAddress": "Jl Raya Rancaekek Km 23, Cintamulya, Jatinangor, Kab.Sumedang - Jawa Barat, 45363",
            "latlong": {
                "lat": -6.951772222222222,
                "lng": 107.785
            },
            "kabkotName": "Kabupaten Sumedang",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a35a",
                    "name": "COD",
                    "calculation": 487.12303470333325
                },
                {
                    "_id": "65ede71ce18a7a560d39a35a",
                    "name": "TSS",
                    "calculation": 57.832778357375474
                },
                {
                    "_id": "65ede71ce18a7a560d39a35a",
                    "name": "NH3N",
                    "calculation": 20.57051797241379
                }
            ]
        },
        {
            "_id": "63fdb31ce7defd222b79fdc5",
            "compName": "PT. Internasional Prima Coal",
            "compAddress": "Kantor Unit Bisnis",
            "latlong": {
                "lat": -0.646944,
                "lng": 117.183894
            },
            "kabkotName": "Kota Samarinda",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a35f",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a35f",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a35f",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64083fd2e7defd222b35fac4",
            "compName": "PT. Indonesia Morowali Industrial Park",
            "compAddress": "Desa Fatufia, Kec Bahodopi, Kab Morowali",
            "latlong": {
                "lat": -2.824056,
                "lng": 122.158472
            },
            "kabkotName": "Kabupaten Morowali",
            "provName": "Sulawesi Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a367",
                    "name": "COD",
                    "calculation": 108.50884335283254
                },
                {
                    "_id": "65ede71ce18a7a560d39a367",
                    "name": "TSS",
                    "calculation": 407.71302278838266
                },
                {
                    "_id": "65ede71ce18a7a560d39a367",
                    "name": "NH3N",
                    "calculation": 62.402532398399025
                }
            ]
        },
        {
            "_id": "64117297273bb80934630f71",
            "compName": "PT Kartika Selabumi Mining",
            "compAddress": "GEDUNG TEMPO SCAN TOWER LT. 32 JL. HR RASUNA SAID KAV. 3-4 RT.005 RW.004, Kel. Kuningan Timur, Kec. Setiabudi, Kota Adm. Jakarta Selatan, Prov. DKI Jakarta",
            "latlong": {
                "lat": -0.288113,
                "lng": 116.58142925
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a371",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a371",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a371",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64117801273bb80934637f39",
            "compName": "PT. Karya Pratama Niagajaya",
            "compAddress": "Jalan Besar KM.97 Tanjung Seri Kecamatan.Laut Tador Kabupaten.Batu Bara",
            "latlong": {
                "lat": 3.32218333,
                "lng": 99.31521389
            },
            "kabkotName": "Kabupaten Batu Bara",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a375",
                    "name": "COD",
                    "calculation": 0.10598607423633152
                },
                {
                    "_id": "65ede71ce18a7a560d39a375",
                    "name": "TSS",
                    "calculation": 0.07173184071047733
                },
                {
                    "_id": "65ede71ce18a7a560d39a375",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6417e29a273bb80934d16b65",
            "compName": "PT Imbang Tata Alam - Meranti Field",
            "compAddress": "Bakrie Tower, Lantai 31 Jl. HR. Rasuna Said Jakarta 12940 Indonesia",
            "latlong": {
                "lat": 1.04908,
                "lng": 102.2574
            },
            "kabkotName": "Kabupaten Kepulauan Meranti",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a379",
                    "name": "COD",
                    "calculation": 296.4254520187566
                },
                {
                    "_id": "65ede71ce18a7a560d39a379",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a379",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "641d0618273bb8093426c3f1",
            "compName": "PT Virtue Dragon Nickel Industry",
            "compAddress": "Kawasan industri desa morosi, kecamatan morosi, kabupaten konawe, provinsi sulawesi tenggara",
            "latlong": {
                "lat": -3.90385,
                "lng": 122.413
            },
            "kabkotName": "Kabupaten Konawe",
            "provName": "Sulawesi Tenggara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a37d",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a37d",
                    "name": "TSS",
                    "calculation": 1.4448795053503611
                },
                {
                    "_id": "65ede71ce18a7a560d39a37d",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "641d135f273bb8093427c46c",
            "compName": "PT. BUKIT MURIA JAYA",
            "compAddress": "Jl. Karawang Spoor RT.013/RW.008, Desa Purwadana, Kecamatan Telukjambe Timur, Kabupaten Karawang",
            "latlong": {
                "lat": -6.28625556,
                "lng": 107.27515
            },
            "kabkotName": "Kabupaten Karawang",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a381",
                    "name": "COD",
                    "calculation": 327.8597725611112
                },
                {
                    "_id": "65ede71ce18a7a560d39a381",
                    "name": "TSS",
                    "calculation": 128.1715449888889
                },
                {
                    "_id": "65ede71ce18a7a560d39a381",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64211ddb273bb809346d79be",
            "compName": "Mont'D or Oil Tungkal, Ltd.",
            "compAddress": "Desa Lubuk Mandarsyah, Kec. Tengah Ilir, Kabupaten Tebo, Jambi 37572",
            "latlong": {
                "lat": -1.42208333,
                "lng": 102.69513889
            },
            "kabkotName": "Kabupaten Tebo",
            "provName": "Jambi",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a386",
                    "name": "COD",
                    "calculation": 5.464719052820196
                },
                {
                    "_id": "65ede71ce18a7a560d39a386",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a386",
                    "name": "NH3N",
                    "calculation": 0.005120442207307062
                }
            ]
        },
        {
            "_id": "64225e60273bb80934835653",
            "compName": "PT. Mustika Agro Sari",
            "compAddress": "Pekanbaru ",
            "latlong": {
                "lat": -0.111889,
                "lng": 101.290972
            },
            "kabkotName": "Kabupaten Kuantan Singingi",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a392",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a392",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a392",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6426881c273bb80934caca52",
            "compName": "PT Artha Tunggal Mandiri",
            "compAddress": "Jl. Pulau Derawan, No. 603 RT. 31, Gedung Ithaca Resources, Tanjung Redeb.",
            "latlong": {
                "lat": 2.242761111,
                "lng": 117.3483694
            },
            "kabkotName": "Kabupaten Berau",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a3b8",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a3b8",
                    "name": "TSS",
                    "calculation": 0.011108066666666668
                },
                {
                    "_id": "65ede71ce18a7a560d39a3b8",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6426934a273bb80934cba3dd",
            "compName": "PT Trimegah Bangun Persada",
            "compAddress": "Desa Kawasi",
            "latlong": {
                "lat": -1.5366,
                "lng": 127.4088
            },
            "kabkotName": "Kabupaten Halmahera Selatan",
            "provName": "Maluku Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a3bf",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a3bf",
                    "name": "TSS",
                    "calculation": 3.240434698224549
                },
                {
                    "_id": "65ede71ce18a7a560d39a3bf",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "642e7807273bb8093457159f",
            "compName": "PT. Anugerah Tani Makmur",
            "compAddress": "km 13 Desa Maredan ",
            "latlong": {
                "lat": 0.536028,
                "lng": 101.605906
            },
            "kabkotName": "Kota Pekanbaru",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a3c6",
                    "name": "COD",
                    "calculation": 32.72287088807517
                },
                {
                    "_id": "65ede71ce18a7a560d39a3c6",
                    "name": "TSS",
                    "calculation": 1.3939944959740584
                },
                {
                    "_id": "65ede71ce18a7a560d39a3c6",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6433bf18273bb80934c8215a",
            "compName": "PT. Behaestex - Cabang Pasuruan",
            "compAddress": "Jl. Mayjen Sungkono No.14, Segoromadu Gresik",
            "latlong": {
                "lat": -7.6310833,
                "lng": 112.7036944
            },
            "kabkotName": "Kabupaten Pasuruan",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a3cf",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a3cf",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a3cf",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6433c026273bb80934c838b9",
            "compName": "PT. Socfindo - Perkebunan Sei Liput",
            "compAddress": "Jl. Medan - Banda Aceh, Kel Desa Kebun Sei Liput, Kec Kejuruan Muda, Kab Aceh Tamiang, Aceh 24447",
            "latlong": {
                "lat": 4.23269,
                "lng": 98.0601
            },
            "kabkotName": "Kabupaten Aceh Tamiang",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a3d8",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a3d8",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a3d8",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6434fc53273bb80934dcf9ff",
            "compName": "PT Winnersumbiri Knitting Factory",
            "compAddress": "JL.KH. AGUS SALIM NO.18, Kel. Poris Plawad, Kec. Cipondoh, Kota Tangerang, Prop. Banten",
            "latlong": {
                "lat": -6.16401,
                "lng": 106.661
            },
            "kabkotName": "Kota Tangerang",
            "provName": "Banten",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a3e0",
                    "name": "COD",
                    "calculation": 7.448978175441668
                },
                {
                    "_id": "65ede71ce18a7a560d39a3e0",
                    "name": "TSS",
                    "calculation": 1.7152423826763887
                },
                {
                    "_id": "65ede71ce18a7a560d39a3e0",
                    "name": "NH3N",
                    "calculation": 0.02400443269861111
                }
            ]
        },
        {
            "_id": "6450703a273bb80934242464",
            "compName": "PT. Mustika Indah Permai",
            "compAddress": "Cyber 2 Tower, Lantai 23 - 26 ",
            "latlong": {
                "lat": -3.71,
                "lng": 103.6405
            },
            "kabkotName": "Kabupaten Lahat",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "64521e6d273bb809344219e8",
            "compName": "PT. SIER (Surabaya Industrial Estate Rungkut)",
            "compAddress": "Jl. Rungkut Indsutri III No 60, Surabaya",
            "latlong": {
                "lat": -7.33789,
                "lng": 112.764
            },
            "kabkotName": "Kota Surabaya",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a3ed",
                    "name": "COD",
                    "calculation": 10.316123229212378
                },
                {
                    "_id": "65ede71ce18a7a560d39a3ed",
                    "name": "TSS",
                    "calculation": 74.71831873917259
                },
                {
                    "_id": "65ede71ce18a7a560d39a3ed",
                    "name": "NH3N",
                    "calculation": 0.20900648162577384
                }
            ]
        },
        {
            "_id": "647ebbb74eb74a749b4195d9",
            "compName": "CITIC Seram Energy, Ltd.",
            "compAddress": "Blok Seram Non Bula, Desa Bula Air, Kecamatan Bula Kabupaten Seram Bagian Timur, Provinsi Maluku",
            "latlong": {
                "lat": -3.1200555555555556,
                "lng": 130.53727777777777
            },
            "kabkotName": "Kabupaten Seram Bagian Timur",
            "provName": "Maluku",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a3f7",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a3f7",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a3f7",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "647ebd1d4eb74a749b41b0b9",
            "compName": "PT Musim Mas - Pelabuhan Indonesia I",
            "compAddress": "Jalan Sulawesi II  Pelabuhan Indonesia I (Pelindo I), Kelurahan Belawan, Kecamatan Medan Belawan",
            "latlong": {
                "lat": 3.785527778,
                "lng": 98.69697222
            },
            "kabkotName": "Kota Medan",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a3ff",
                    "name": "COD",
                    "calculation": 5.191618329650213
                },
                {
                    "_id": "65ede71ce18a7a560d39a3ff",
                    "name": "TSS",
                    "calculation": 0.02854114564672551
                },
                {
                    "_id": "65ede71ce18a7a560d39a3ff",
                    "name": "NH3N",
                    "calculation": 0.17110935939849625
                }
            ]
        },
        {
            "_id": "647ebf394eb74a749b41e8f5",
            "compName": "PT Bara Anugrah Sejahtera",
            "compAddress": "Desa Pulau panggung Kecamatan Tanjung Agung dan Desa Keban Agung Kecamatan Lawang Kidul Kabupaten Muara Enim ",
            "latlong": {
                "lat": -3.822,
                "lng": 103.79077778
            },
            "kabkotName": "Kabupaten Muara Enim",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "647ff9154b37910a7a019fa3",
            "compName": "PT. Alhasanie",
            "compAddress": "Padat Karya RT.4, Sangasanga Muara, Kec. Sangasanga, Kalimantan Timur, Kutai Kartanegara",
            "latlong": {
                "lat": -0.645693,
                "lng": 117
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a422",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a422",
                    "name": "TSS",
                    "calculation": 0.2946653991666668
                },
                {
                    "_id": "65ede71ce18a7a560d39a422",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64803a434b37910a7a067cc9",
            "compName": "PT Wanasari Nusantara (Pabrik Wanasari Nusantara)",
            "compAddress": "JL. OK. M. Jamil NO. 12, Kel. Simpang Tiga, Kec. Bukit Raya, Kota",
            "latlong": {
                "lat": -0.6475,
                "lng": 101.94083
            },
            "kabkotName": "Kabupaten Kuantan Singingi",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a426",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a426",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a426",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64803edc4b37910a7a06e9c5",
            "compName": "PT ECOOILS JAYA INDONESIA - DUMAI",
            "compAddress": "Jalan Raya Lubuk Gaung RT. 09 Nerbit Kecil, Desa/Kelurahan Lubuk Gaung, Kec. Sungai Sembilan, Kota Dumai, Provinsi Riau",
            "latlong": {
                "lat": 1.76333,
                "lng": 101.357
            },
            "kabkotName": "Kota Dumai",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a42a",
                    "name": "COD",
                    "calculation": 3.956621686111111
                },
                {
                    "_id": "65ede71ce18a7a560d39a42a",
                    "name": "TSS",
                    "calculation": 1.1474432608333334
                },
                {
                    "_id": "65ede71ce18a7a560d39a42a",
                    "name": "NH3N",
                    "calculation": 0.029993599999999985
                }
            ]
        },
        {
            "_id": "6493f10523889215c5b3961b",
            "compName": "PT. FIRMAN KETAUN",
            "compAddress": "Desa Tanjung Dalam ",
            "latlong": {
                "lat": -3.173722,
                "lng": 101.769222
            },
            "kabkotName": "Kabupaten Bengkulu Utara",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a42e",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a42e",
                    "name": "TSS",
                    "calculation": 17.81254676891666
                },
                {
                    "_id": "65ede71ce18a7a560d39a42e",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6498ebee23889215c54d2cbd",
            "compName": "PT. BUMI SAWINDO PERMAI",
            "compAddress": "Desa Penyandingan Kecamatan tanjung Agung",
            "latlong": {
                "lat": -3.850278,
                "lng": 103.803611
            },
            "kabkotName": "Kabupaten Muara Enim",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a435",
                    "name": "COD",
                    "calculation": 8.139373130881035
                },
                {
                    "_id": "65ede71ce18a7a560d39a435",
                    "name": "TSS",
                    "calculation": 4.19451419839138
                },
                {
                    "_id": "65ede71ce18a7a560d39a435",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64acb96396ed275ec8dfef91",
            "compName": "PT Manggala Alam Lestari",
            "compAddress": "Desa Sindang Marga",
            "latlong": {
                "lat": -2.1795,
                "lng": 103.786
            },
            "kabkotName": "Kabupaten Musi Banyuasin",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a43f",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a43f",
                    "name": "TSS",
                    "calculation": 1.963169260249042
                },
                {
                    "_id": "65ede71ce18a7a560d39a43f",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64acb98c96ed275ec8dffb31",
            "compName": "PT Buana Bara Ekapratama",
            "compAddress": "Desa Sindang Marga Kec. Bayung Lencir, Kab. Musi Banyuasin, Prov. Sumatera Selatatan",
            "latlong": {
                "lat": -2.16227,
                "lng": 103.757
            },
            "kabkotName": "Kabupaten Musi Banyuasin",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a447",
                    "name": "COD",
                    "calculation": -0.005468000000000004
                },
                {
                    "_id": "65ede71ce18a7a560d39a447",
                    "name": "TSS",
                    "calculation": 0.03829171264367813
                },
                {
                    "_id": "65ede71ce18a7a560d39a447",
                    "name": "NH3N",
                    "calculation": -0.005468000000000004
                }
            ]
        },
        {
            "_id": "64ad0f6796ed275ec8ead960",
            "compName": "PT. TRIARYANI",
            "compAddress": "Dusun V Desa Beringin Makmur II Kec Rawas Ilir Kab Musi Rawas Utara Provinsi Sumatera Selatan",
            "latlong": {
                "lat": -2.492755,
                "lng": 103.123106
            },
            "kabkotName": "Kabupaten Musi Rawas Utara",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "64afb2a296ed275ec83fa383",
            "compName": "PT. Agrindo Indah Persada",
            "compAddress": "Jln. Lintas Sumatera km. 13 Desa Tambang Baru Kecamatan Tabir lintas Kab. Merangin 37353",
            "latlong": {
                "lat": -1.965231,
                "lng": 102.305345
            },
            "kabkotName": "Kabupaten Merangin",
            "provName": "Jambi",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "64afb80c96ed275ec8408249",
            "compName": "PT Bukit Telunjuk",
            "compAddress": "Kecamatan Merapi barat (Desa Merapi, Desa Telatang) dan Merapi Timur (Desa Gunung Kembang) Kabupaten Lahat",
            "latlong": {
                "lat": -3.72339,
                "lng": 103.65627
            },
            "kabkotName": "Kabupaten Lahat",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "5f4e1ca9167c90338b8fe176",
            "compName": "PT Kilang Pertamina International Refinery Unit IV Cilacap",
            "compAddress": "Jl MT Haryono No. 77, Kabupaten Cilacap",
            "latlong": {
                "lat": -7.705861,
                "lng": 108
            },
            "kabkotName": "Kabupaten Cilacap",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a45b",
                    "name": "COD",
                    "calculation": 1393.2864324716957
                },
                {
                    "_id": "65ede71ce18a7a560d39a45b",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a45b",
                    "name": "NH3N",
                    "calculation": 41.95402439873565
                }
            ]
        },
        {
            "_id": "64b8d9cb52ce7941f5b5c2b6",
            "compName": "PT Dian Anggara Persada",
            "compAddress": "Jl. Libo Baru Waduk KM.18",
            "latlong": {
                "lat": 0.982222,
                "lng": 101.043
            },
            "kabkotName": "Kabupaten Siak",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a468",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a468",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a468",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64b8df9252ce7941f5b66bb2",
            "compName": "PT. KALTIM GLOBAL",
            "compAddress": "Desa Suka Baru, Desa Air Putih Kecamatan Marga Sakti Sebelat",
            "latlong": {
                "lat": -3.1376,
                "lng": 101.7467
            },
            "kabkotName": "Kabupaten Bengkulu Utara",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a46d",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a46d",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a46d",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64b8e28352ce7941f5b6bfc8",
            "compName": "PT Guntung Idamannusa PKS 2",
            "compAddress": "Desa Teluk Merbau, Kecamatan Gaung",
            "latlong": {
                "lat": 0.04868056,
                "lng": 103.21533611
            },
            "kabkotName": "Kabupaten Indragiri Hilir",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a475",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a475",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a475",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64b8e68952ce7941f5b74dc3",
            "compName": "PT Jiale Indonesia Textile",
            "compAddress": "Jl. Pecangaan -Bate, No. 288, Ds. Gemulung, Kec. Pecangaan, Kab. Jepara, Jawa Tengah",
            "latlong": {
                "lat": -6.68805556,
                "lng": 110.72527778
            },
            "kabkotName": "Kabupaten Jepara",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a47d",
                    "name": "COD",
                    "calculation": 227.36098197205558
                },
                {
                    "_id": "65ede71ce18a7a560d39a47d",
                    "name": "TSS",
                    "calculation": 17.447636911944443
                },
                {
                    "_id": "65ede71ce18a7a560d39a47d",
                    "name": "NH3N",
                    "calculation": 16.46460641230556
                }
            ]
        },
        {
            "_id": "64b8eb8452ce7941f5b7e564",
            "compName": "PT. Daya Labuhan Indah PKS Wonosari",
            "compAddress": "Desa Sei Tampang Kecamatan Bilah Hilir Kabupaten Labuhan Batu Provinsi Sumatera Utara",
            "latlong": {
                "lat": 2.28575,
                "lng": 100.145
            },
            "kabkotName": "Kabupaten Labuhan Batu",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "64b8f48052ce7941f5b902fd",
            "compName": "PT. TEBO PLASMA INTI LESTARI",
            "compAddress": "Desa Pelayang, Kec. Tebo Tengah",
            "latlong": {
                "lat": -1.44477,
                "lng": 102.54083
            },
            "kabkotName": "Kabupaten Tebo",
            "provName": "Jambi",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a486",
                    "name": "COD",
                    "calculation": 8.774216273812451
                },
                {
                    "_id": "65ede71ce18a7a560d39a486",
                    "name": "TSS",
                    "calculation": 19.7599790864415
                },
                {
                    "_id": "65ede71ce18a7a560d39a486",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64b9f3b152ce7941f5ce7b19",
            "compName": "PT. Dan Liris",
            "compAddress": "Banaran, Grogol, Sukoharjo, Jawa Tengah",
            "latlong": {
                "lat": -7.575098,
                "lng": 110.798561
            },
            "kabkotName": "Kabupaten Sukoharjo",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a48c",
                    "name": "COD",
                    "calculation": 9.31002921187509
                },
                {
                    "_id": "65ede71ce18a7a560d39a48c",
                    "name": "TSS",
                    "calculation": 1.2945824799999988
                },
                {
                    "_id": "65ede71ce18a7a560d39a48c",
                    "name": "NH3N",
                    "calculation": 0.33413951944735226
                }
            ]
        },
        {
            "_id": "64c1ceb0dc30d97862f6e6a3",
            "compName": "PT Baramulti Sugih Sentosa",
            "compAddress": "Jl. Inspeksi 13 Inklaring, RT 01 RW 01 Kel. Kertapati, Kecamatan Kertapati ",
            "latlong": {
                "lat": -3.015841,
                "lng": 104.749526
            },
            "kabkotName": "Kota Palembang",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "64c1d7dadc30d97862faa121",
            "compName": "PT. Multi Sarana Avindo",
            "compAddress": "Jln. Raya Bakungan RT.14 Desa Bakungan Kec. Loa Janan Kab. Kutai Kartanegara",
            "latlong": {
                "lat": -0.594167,
                "lng": 117.04025
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a49a",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a49a",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a49a",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64c1e9aedc30d97862fd172e",
            "compName": "PT Banyan Koalindo Lestari",
            "compAddress": "Desa Beringin Makmur II, Kecamatan Rawas Ilir",
            "latlong": {
                "lat": -2.584361,
                "lng": 103.155217
            },
            "kabkotName": "Kabupaten Musi Rawas Utara",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a4a2",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a4a2",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a4a2",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64c1eaf4dc30d97862fd582c",
            "compName": "PT Obsidian Stainless Steel",
            "compAddress": "Desa Porara, Kecamatan Morosi, Kabupaten Konawe, Sulawesi Tenggara",
            "latlong": {
                "lat": -3.87462,
                "lng": 122.4074
            },
            "kabkotName": "Kabupaten Konawe",
            "provName": "Sulawesi Tenggara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a4aa",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ce18a7a560d39a4aa",
                    "name": "TSS",
                    "calculation": 2.1042696900110873
                },
                {
                    "_id": "65ede71ce18a7a560d39a4aa",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64c38eb4fe8cf3255ee606da",
            "compName": "PT Indonesia Synthetic Textile Mills",
            "compAddress": "Jl. Moch. Toha KM.1 Kel. Pasar Baru Kec. Karawaci",
            "latlong": {
                "lat": -6.16636,
                "lng": 106.625
            },
            "kabkotName": "Kota Tangerang",
            "provName": "Banten",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ce18a7a560d39a4b0",
                    "name": "COD",
                    "calculation": 83.91334015822592
                },
                {
                    "_id": "65ede71ce18a7a560d39a4b0",
                    "name": "TSS",
                    "calculation": 6.62714866632616
                },
                {
                    "_id": "65ede71ce18a7a560d39a4b0",
                    "name": "NH3N",
                    "calculation": 0.767719802709676
                }
            ]
        },
        {
            "_id": "64d1ff1e4d6768752ad317b3",
            "compName": "PT Makmur Lestari Primatama",
            "compAddress": "Citra towers, Kemayoran business district north tower Lt18b/A-E, Jl Benyamin Suaeb, kav. A6 Jakarta Kemayoran 10630",
            "latlong": {
                "lat": -3.29217,
                "lng": 122.272
            },
            "kabkotName": "Kota Jakarta Pusat",
            "provName": "DKI Jakarta",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "64d97e2b928fe441e451e470",
            "compName": "PT. Nusatama Properta Panbil",
            "compAddress": "Panbil Residence Lt.5, Panbil Estate",
            "latlong": {
                "lat": -1.07227,
                "lng": 104.022
            },
            "kabkotName": "Kota Batam",
            "provName": "Kepulauan Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a4c7",
                    "name": "COD",
                    "calculation": 1778.9441528163582
                },
                {
                    "_id": "65ede71de18a7a560d39a4c7",
                    "name": "TSS",
                    "calculation": 1553.9074819668208
                },
                {
                    "_id": "65ede71de18a7a560d39a4c7",
                    "name": "NH3N",
                    "calculation": 145.50847061419753
                }
            ]
        },
        {
            "_id": "64db27444b63956106047f6e",
            "compName": "PT Baramulti Suksessarana, Tbk",
            "compAddress": "Desa Batuah RT 27, Kecamatan Loa Janan",
            "latlong": {
                "lat": 0.725966,
                "lng": 117.049
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a4cf",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a4cf",
                    "name": "TSS",
                    "calculation": 0.4496975459160234
                },
                {
                    "_id": "65ede71de18a7a560d39a4cf",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64dc6f124b639561062ac768",
            "compName": "PT Pada Idi",
            "compAddress": "Desa Luwe Hulu, Kecamatan Lahei, Barito Utara, Kalimantan Tengah",
            "latlong": {
                "lat": 0.7463,
                "lng": 114.963
            },
            "kabkotName": "Kabupaten Barito Utara",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a4d4",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a4d4",
                    "name": "TSS",
                    "calculation": 5.096351896815001
                },
                {
                    "_id": "65ede71de18a7a560d39a4d4",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64e5b58f4fa53b4ecde29c0c",
            "compName": "PT. Socfin Indonesia - Kebun Tanah Gambus",
            "compAddress": "Desa Perkebunan Tanah Gambus Kec Lima Puluh Kab. Batu Bara ",
            "latlong": {
                "lat": 3.20389,
                "lng": 99.40944
            },
            "kabkotName": "Kabupaten Batu Bara",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a4da",
                    "name": "COD",
                    "calculation": 48.90643825714285
                },
                {
                    "_id": "65ede71de18a7a560d39a4da",
                    "name": "TSS",
                    "calculation": 43.81650729107143
                },
                {
                    "_id": "65ede71de18a7a560d39a4da",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64e6bcac98c494408b8bf7d8",
            "compName": "PT. BUMI PRATAMA KHATULISTIWA",
            "compAddress": "Sungai Tempayan",
            "latlong": {
                "lat": 1.09,
                "lng": 109.255
            },
            "kabkotName": "Kabupaten Kubu Raya",
            "provName": "Kalimantan Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a4e1",
                    "name": "COD",
                    "calculation": 30.727524831119958
                },
                {
                    "_id": "65ede71de18a7a560d39a4e1",
                    "name": "TSS",
                    "calculation": 96.35994853277646
                },
                {
                    "_id": "65ede71de18a7a560d39a4e1",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64e6c4f198c494408b8cf058",
            "compName": "PT Singlurus Pratama",
            "compAddress": "Jalan Soekarno Hatta Km.34 RT. 12, Kelurahan Karya Merdeka, Kecamatan Samboja Kabupaten Kutai Kartanegara, Provinsi Kalimantan Timur",
            "latlong": {
                "lat": -1.106222222,
                "lng": 117.0618333
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "64e6e16415f0a80910fd2942",
            "compName": "PT. Kusuma Raya Utama",
            "compAddress": "JL.RE Martadinata no. 86 Pagar Dewa- Bengkulu",
            "latlong": {
                "lat": -3.78634,
                "lng": 102.513324
            },
            "kabkotName": "Kota Bengkulu",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "64e6e31e15f0a80910fd5599",
            "compName": "PT Baramutiara Prima",
            "compAddress": "Desa Teluk Kemang, Kec. Sungai Lilin, Kab. Musi Banyuasin",
            "latlong": {
                "lat": 2.61611111,
                "lng": 104
            },
            "kabkotName": "Kabupaten Musi Banyuasin",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a4ec",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a4ec",
                    "name": "TSS",
                    "calculation": 0.8391954652959771
                },
                {
                    "_id": "65ede71de18a7a560d39a4ec",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64e6e4ec15f0a80910fd7e5c",
            "compName": "PT Era Energi Mandiri",
            "compAddress": "Jalan R. E. Martadinata, Bandar Agung, Kab. Lahat, Sumatera Selatan 31419",
            "latlong": {
                "lat": -3.86719,
                "lng": 103.655
            },
            "kabkotName": "Kabupaten Lahat",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a4f4",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a4f4",
                    "name": "TSS",
                    "calculation": 7.036755303776685
                },
                {
                    "_id": "65ede71de18a7a560d39a4f4",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64e6f8499db0a54e6a9d83f6",
            "compName": "PT. Sinar Pangjaya Mulia Textile Industry",
            "compAddress": "Jl. Mahar Martanegara No. 175, Kel. Utama, Kec. Cimahi Selatan, Kota Cimahi",
            "latlong": {
                "lat": -6.90416,
                "lng": 107.54588
            },
            "kabkotName": "Kota Cimahi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a4fa",
                    "name": "COD",
                    "calculation": 7.403310481321838
                },
                {
                    "_id": "65ede71de18a7a560d39a4fa",
                    "name": "TSS",
                    "calculation": 0.740153146551724
                },
                {
                    "_id": "65ede71de18a7a560d39a4fa",
                    "name": "NH3N",
                    "calculation": 0.10755429597701151
                }
            ]
        },
        {
            "_id": "64e6fac29db0a54e6a9dc1dd",
            "compName": "PT Merbaujaya Indahraya - Sultra",
            "compAddress": "Desa Lerepako, Kecamatan Laeya",
            "latlong": {
                "lat": -4.332582,
                "lng": 122.445407
            },
            "kabkotName": "Kabupaten Konawe Selatan",
            "provName": "Sulawesi Tenggara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "64e81fec9d410a0495222c88",
            "compName": "PT INTISELARAS PERKASA",
            "compAddress": "Jl Sabanar lama no 168 Tanjung Selor Hilir Kab. Bulungan Kalimantan Utara",
            "latlong": {
                "lat": 2.74132,
                "lng": 117.206
            },
            "kabkotName": "Kabupaten Bulungan",
            "provName": "Kalimantan Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a502",
                    "name": "COD",
                    "calculation": 8818.5608842458
                },
                {
                    "_id": "65ede71de18a7a560d39a502",
                    "name": "TSS",
                    "calculation": 2949.3842234593753
                },
                {
                    "_id": "65ede71de18a7a560d39a502",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64e95b36f2b0ab6dd45e6c8d",
            "compName": "PT. Multimas Nabati Asahan (Minyak Goreng)",
            "compAddress": "Jl. Acces Road Dusun IV Tanjung Permai, Kuala Tanjung,  Kec Sei Suka Kab. Batubara, Sumatera Utara ",
            "latlong": {
                "lat": 0.322111,
                "lng": 992.6132
            },
            "kabkotName": "Kabupaten Batu Bara",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a50c",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a50c",
                    "name": "TSS",
                    "calculation": 23.101354840383383
                },
                {
                    "_id": "65ede71de18a7a560d39a50c",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64e961c6f2b0ab6dd45f1dbb",
            "compName": "PT. Umbul Mas Wisesa - PKS",
            "compAddress": "PT. Umbul Mas Wisesa, Desa Tanjung Muia, Kecamatan Kampung Rakyat, Kabupaten Labuhanbatu Selatan, Provinsi Sumatera Utara",
            "latlong": {
                "lat": 2.2102744444444444,
                "lng": 100.26144027777778
            },
            "kabkotName": "Kabupaten Labuhan Batu Selatan",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a513",
                    "name": "COD",
                    "calculation": 364.3345533183477
                },
                {
                    "_id": "65ede71de18a7a560d39a513",
                    "name": "TSS",
                    "calculation": 378.43750168396565
                },
                {
                    "_id": "65ede71de18a7a560d39a513",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64e96491f2b0ab6dd45f676f",
            "compName": "PT BENGKULU SAWIT LESTARI II",
            "compAddress": "Desa Air Teras, Kec.Talo, Kab.Seluma, Bengkulu",
            "latlong": {
                "lat": 4.0714,
                "lng": 102.3739
            },
            "kabkotName": "Kabupaten Bengkulu Selatan",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a51e",
                    "name": "COD",
                    "calculation": 0.6378719097083334
                },
                {
                    "_id": "65ede71de18a7a560d39a51e",
                    "name": "TSS",
                    "calculation": 0.4674789211166668
                },
                {
                    "_id": "65ede71de18a7a560d39a51e",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64ebff32100e79277409df95",
            "compName": "PT. Batamindo Investment Cakrawala",
            "compAddress": "Jl. Rasamala No. 1 Muka Kuning Kota Batam Kepulauan Riau",
            "latlong": {
                "lat": 1.0582222222222222,
                "lng": 104.03333333333333
            },
            "kabkotName": "Kota Batam",
            "provName": "Kepulauan Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a524",
                    "name": "COD",
                    "calculation": 35.5812077741749
                },
                {
                    "_id": "65ede71de18a7a560d39a524",
                    "name": "TSS",
                    "calculation": 5.0586183685112545
                },
                {
                    "_id": "65ede71de18a7a560d39a524",
                    "name": "NH3N",
                    "calculation": 1.8904604086979402
                }
            ]
        },
        {
            "_id": "64ec01d2100e7927740a3188",
            "compName": "PT Guntung Idamannusa",
            "compAddress": "Desa Tanjung Simpang, Kecamatan Pelangiran, Kabupaten Indragiri Hilir",
            "latlong": {
                "lat": 0.160833,
                "lng": 103.2825
            },
            "kabkotName": "Kabupaten Indragiri Hilir",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a528",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a528",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a528",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64ec0a14100e7927740ae593",
            "compName": "PT Kayan Putra Utama Coal",
            "compAddress": "Desa Langap Camp SIDI, Kec. Malinau Selatan, Kab Malinau, Prov Kalimantan Utara",
            "latlong": {
                "lat": 3.09273611,
                "lng": 116.500625
            },
            "kabkotName": "Kabupaten Malinau",
            "provName": "Kalimantan Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a52c",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a52c",
                    "name": "TSS",
                    "calculation": 335.68795416390105
                },
                {
                    "_id": "65ede71de18a7a560d39a52c",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64f5919a206a7a5fb4beab54",
            "compName": "PT. Perkebunan Nusantara IV Unit Ajamu",
            "compAddress": "Desa Perkebunan Ajamu Kecamatan Panai Hulu Kabupaten Labuhanbatu",
            "latlong": {
                "lat": 2.459111,
                "lng": 100.168222
            },
            "kabkotName": "Kabupaten Labuhan Batu",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a531",
                    "name": "COD",
                    "calculation": 0.8374913417973857
                },
                {
                    "_id": "65ede71de18a7a560d39a531",
                    "name": "TSS",
                    "calculation": 6.261393184150325
                },
                {
                    "_id": "65ede71de18a7a560d39a531",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64f7d8a8a0cc125666a69238",
            "compName": "PT. CENTURY TEKSTIL INDUSTRY (CENTEX)",
            "compAddress": "Jl. Raya Bogor Km.27 Jakarta Timur",
            "latlong": {
                "lat": -6.32292,
                "lng": 106.8799
            },
            "kabkotName": "Kota Jakarta Timur",
            "provName": "DKI Jakarta",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a53b",
                    "name": "COD",
                    "calculation": 40.88571297745726
                },
                {
                    "_id": "65ede71de18a7a560d39a53b",
                    "name": "TSS",
                    "calculation": 6.077643422457266
                },
                {
                    "_id": "65ede71de18a7a560d39a53b",
                    "name": "NH3N",
                    "calculation": 0.0859048604551282
                }
            ]
        },
        {
            "_id": "64f924346afc67581aeb1c86",
            "compName": "PT.ROHUL ENERGI INDONESIA",
            "compAddress": "Jl. Tiang Bendera V No. 41-43 Blok N & O, Kelurahan Roa Malaka, Kecamatan Tambora, Jakarta Barat",
            "latlong": {
                "lat": -5.13425,
                "lng": 121.981583
            },
            "kabkotName": "Kabupaten Bombana",
            "provName": "Sulawesi Tenggara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a546",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a546",
                    "name": "TSS",
                    "calculation": 5.53637306923718
                },
                {
                    "_id": "65ede71de18a7a560d39a546",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "64f92d3a6afc67581aec76b6",
            "compName": "PT. Surya Agrolika Reksa",
            "compAddress": "Desa Beringin Jaya",
            "latlong": {
                "lat": -0.13747222222222222,
                "lng": 101.39058333333334
            },
            "kabkotName": "Kabupaten Kuantan Singingi",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "64f9583ab8ec5832683b4d95",
            "compName": "PT. Daya Pratama Lestari",
            "compAddress": "JL. INDUSTRI BATUJAJAR PERMAI II NO 29 DESA LAKSANAMEKAR KECAMATAN PADALARANG",
            "latlong": {
                "lat": -6.88906,
                "lng": 107.482
            },
            "kabkotName": "Kabupaten Bandung Barat",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a54b",
                    "name": "COD",
                    "calculation": 48.23349854044779
                },
                {
                    "_id": "65ede71de18a7a560d39a54b",
                    "name": "TSS",
                    "calculation": 5.145426815299771
                },
                {
                    "_id": "65ede71de18a7a560d39a54b",
                    "name": "NH3N",
                    "calculation": 0.20708348863337045
                }
            ]
        },
        {
            "_id": "64fa857af292c12ffa8f5163",
            "compName": "PT. HARAPAN ANANG BAKRI & SONS",
            "compAddress": "GRAHA KIIC ",
            "latlong": {
                "lat": -6.356639,
                "lng": 107.304278
            },
            "kabkotName": "Kabupaten Karawang",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a552",
                    "name": "COD",
                    "calculation": 147.31755762827808
                },
                {
                    "_id": "65ede71de18a7a560d39a552",
                    "name": "TSS",
                    "calculation": 44.526054593150114
                },
                {
                    "_id": "65ede71de18a7a560d39a552",
                    "name": "NH3N",
                    "calculation": 0.022271300000000018
                }
            ]
        },
        {
            "_id": "65006c243d74fd16eb4f08b8",
            "compName": "PT TH Indo Plantations",
            "compAddress": "KM 14,5 Simpang Kanan Desa Tanjung Simpang Kec. Pelangiran",
            "latlong": {
                "lat": 0.22141,
                "lng": 103.16538
            },
            "kabkotName": "Kabupaten Indragiri Hilir",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "65006da03d74fd16eb4f395d",
            "compName": "PT. Teguhkarsa Wanalestari - Mill Bungaraya",
            "compAddress": "Kampung Buantan Besar, Kecamatan Siak",
            "latlong": {
                "lat": 0.92968,
                "lng": 102.02176
            },
            "kabkotName": "Kabupaten Siak",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a563",
                    "name": "COD",
                    "calculation": 30.194677713231293
                },
                {
                    "_id": "65ede71de18a7a560d39a563",
                    "name": "TSS",
                    "calculation": 1.9880576349355261
                },
                {
                    "_id": "65ede71de18a7a560d39a563",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6500e0673d74fd16eb603cf6",
            "compName": "PT Selaras Mitra Sarimba",
            "compAddress": "Jl. Kelapa Sawit RT-04/02-Desa Sumber Agung Kec. Rimbo Ilir Kab Tebo",
            "latlong": {
                "lat": -1.41138,
                "lng": 102.26566
            },
            "kabkotName": "Kabupaten Tebo",
            "provName": "Jambi",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a56b",
                    "name": "COD",
                    "calculation": 9.431035854096361
                },
                {
                    "_id": "65ede71de18a7a560d39a56b",
                    "name": "TSS",
                    "calculation": 208.49561451546484
                },
                {
                    "_id": "65ede71de18a7a560d39a56b",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6501f0f36f58ba3df95944ee",
            "compName": "PT. Agricinal",
            "compAddress": "Desa Pasar Sebelat Kel. Pasar Sebelat Kab. Bengkulu Utara",
            "latlong": {
                "lat": -3.20264,
                "lng": 101.62506
            },
            "kabkotName": "Kabupaten Bengkulu Utara",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a573",
                    "name": "COD",
                    "calculation": 44.851916926593404
                },
                {
                    "_id": "65ede71de18a7a560d39a573",
                    "name": "TSS",
                    "calculation": 25.06249472527472
                },
                {
                    "_id": "65ede71de18a7a560d39a573",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6502ab2d19d10964c1cc84e1",
            "compName": "PT. SISIRAU",
            "compAddress": "Jln. Medan Banda Aceh, Dusun Kantil, Desa Sidodadi, Kec. Kejuruan Muda, Kab. Aceh Tamiang.",
            "latlong": {
                "lat": 4.1223,
                "lng": 98.0355
            },
            "kabkotName": "Kabupaten Aceh Tamiang",
            "provName": "Aceh",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a584",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a584",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a584",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6502acaa19d10964c1cce038",
            "compName": "PT. SAWIT NUSANTARA INDONESIA",
            "compAddress": "DESA TABA",
            "latlong": {
                "lat": -3.46023,
                "lng": 103.0508
            },
            "kabkotName": "Kabupaten Empat Lawang",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a599",
                    "name": "COD",
                    "calculation": 56.130272660919545
                },
                {
                    "_id": "65ede71de18a7a560d39a599",
                    "name": "TSS",
                    "calculation": 4.650380575325672
                },
                {
                    "_id": "65ede71de18a7a560d39a599",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6502ada119d10964c1cd1729",
            "compName": "PT. Karyaindo Sejatitama",
            "compAddress": "Desa Bukit Beton Kecamatan STL Ulu Terawas",
            "latlong": {
                "lat": -2.999991,
                "lng": 102.790038
            },
            "kabkotName": "Kabupaten Musi Rawas",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a59d",
                    "name": "COD",
                    "calculation": 0.1215120061728395
                },
                {
                    "_id": "65ede71de18a7a560d39a59d",
                    "name": "TSS",
                    "calculation": 0.014107458641975308
                },
                {
                    "_id": "65ede71de18a7a560d39a59d",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6502b4d519d10964c1ceb0af",
            "compName": "PT. Tor Ganda PKS Rantau Kasai",
            "compAddress": "Desa Rantau Kasai - Kec. Tambusai Utara",
            "latlong": {
                "lat": 1.26639,
                "lng": 100.345
            },
            "kabkotName": "Kabupaten Rokan Hulu",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5a5",
                    "name": "COD",
                    "calculation": 1011743.578498732
                },
                {
                    "_id": "65ede71de18a7a560d39a5a5",
                    "name": "TSS",
                    "calculation": 64.93996976507935
                },
                {
                    "_id": "65ede71de18a7a560d39a5a5",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6502c0c019d10964c1d13e34",
            "compName": "PT. Bengkulu Sawit Lestari",
            "compAddress": "Desa Air Sulau, Kec. Kedurang Ilir, Kab. Bengkulu Selatan, Prov. Bengkulu",
            "latlong": {
                "lat": -4.555555555555555,
                "lng": 103.05958333333334
            },
            "kabkotName": "Kabupaten Bengkulu Selatan",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "65080d2542a0ec62fdc15e6a",
            "compName": "PT. Sri Rejeki Isman",
            "compAddress": "JALAN KH SAMANHUDI NO 88 KELURAHAN JETIS, KECAMATAN SUKOHARJO, KABUPATEN SUKOHARJO",
            "latlong": {
                "lat": -7.68678,
                "lng": 110.81911
            },
            "kabkotName": "Kabupaten Sukoharjo",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5ac",
                    "name": "COD",
                    "calculation": 199.9630708411389
                },
                {
                    "_id": "65ede71de18a7a560d39a5ac",
                    "name": "TSS",
                    "calculation": 25.01708287206944
                },
                {
                    "_id": "65ede71de18a7a560d39a5ac",
                    "name": "NH3N",
                    "calculation": 3.5099158885277784
                }
            ]
        },
        {
            "_id": "6508114a42a0ec62fdc27f40",
            "compName": "PT Astaka Dodol",
            "compAddress": "Desa Macang Sakti",
            "latlong": {
                "lat": -2.577711,
                "lng": 103.395194
            },
            "kabkotName": "Kabupaten Musi Banyuasin",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5b0",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a5b0",
                    "name": "TSS",
                    "calculation": 3.8221469447132637
                },
                {
                    "_id": "65ede71de18a7a560d39a5b0",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6509589410072750ff43dcb9",
            "compName": "PT. TALANG JERINJING SAWIT",
            "compAddress": "KM 14 Desa Talang Jerinjing",
            "latlong": {
                "lat": -0.462638,
                "lng": 102.47961
            },
            "kabkotName": "Kabupaten Indragiri Hulu",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5b4",
                    "name": "COD",
                    "calculation": 14.782039257567048
                },
                {
                    "_id": "65ede71de18a7a560d39a5b4",
                    "name": "TSS",
                    "calculation": 0.5618600669731801
                },
                {
                    "_id": "65ede71de18a7a560d39a5b4",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "650b7148eeb7992548371c68",
            "compName": "PT. Cipta Futura",
            "compAddress": "Jalan Abri Manunggal Serasan Desa Ujan Mas Kecamatan Ujan Mas, Kabupaten Muara Enim",
            "latlong": {
                "lat": -3.5005,
                "lng": 103.701
            },
            "kabkotName": "Kabupaten Muara Enim",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5b9",
                    "name": "COD",
                    "calculation": 8.166615499102011
                },
                {
                    "_id": "65ede71de18a7a560d39a5b9",
                    "name": "TSS",
                    "calculation": 0.11928922582614941
                },
                {
                    "_id": "65ede71de18a7a560d39a5b9",
                    "name": "NH3N",
                    "calculation": -0.012746300000000009
                }
            ]
        },
        {
            "_id": "650bfa3756f894628202e945",
            "compName": "PT BORNEO PRIMA",
            "compAddress": "Tumbang Olong/Laas, Ut Murung, Murung Raya, Kalimantan Tengah",
            "latlong": {
                "lat": 0.0926111,
                "lng": 114.36
            },
            "kabkotName": "Kabupaten Murung Raya",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5c0",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a5c0",
                    "name": "TSS",
                    "calculation": 1.955520503528879
                },
                {
                    "_id": "65ede71de18a7a560d39a5c0",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "650c0e6656f894628205f355",
            "compName": "PT. Pabrik Kertas Tjiwi Kimia, Tbk",
            "compAddress": "GEDUNG SINAR MAS LAND PLAZA MENARA 2 LT.9 JL. MH. THAMRIN NO.51 , Kel. Gondangdia, Kec. Menteng, Kota Adm. Jakarta Pusat, Provinsi DKI Jakarta",
            "latlong": {
                "lat": -7.439,
                "lng": 112.451
            },
            "kabkotName": "Kabupaten Sidoarjo",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5c9",
                    "name": "COD",
                    "calculation": 1162.3875815440592
                },
                {
                    "_id": "65ede71de18a7a560d39a5c9",
                    "name": "TSS",
                    "calculation": 1015.0941441476745
                },
                {
                    "_id": "65ede71de18a7a560d39a5c9",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "650d6316f70cba5cde76330d",
            "compName": "PT. OKI Pulp And Paper Mills",
            "compAddress": "Sinarmas Land Plaza, Tower II, Lantai 9 Jl. MH. Thamrin No.51, Kel. Gondangdia, Kec. Menteng, Kota Adm. Jakarta Pusat, Prov. DKI Jakarta",
            "latlong": {
                "lat": -2.74389,
                "lng": 105.277
            },
            "kabkotName": "Kabupaten Ogan Komering Ilir",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5ce",
                    "name": "COD",
                    "calculation": 30189.519909805298
                },
                {
                    "_id": "65ede71de18a7a560d39a5ce",
                    "name": "TSS",
                    "calculation": 3384.5475329227447
                },
                {
                    "_id": "65ede71de18a7a560d39a5ce",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "650d6575f70cba5cde76830a",
            "compName": "PT. Indah Kiat Pulp & Paper - Tangerang Mills",
            "compAddress": "Sinar Mas Land Plaza, Tower II, 5th floor JI. M.H. Thamrin No. 51 Jakarta 10350, Indonesia",
            "latlong": {
                "lat": -6.246,
                "lng": 106.645
            },
            "kabkotName": "Kota Tangerang Selatan",
            "provName": "Banten",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5d4",
                    "name": "COD",
                    "calculation": 364.65831289305487
                },
                {
                    "_id": "65ede71de18a7a560d39a5d4",
                    "name": "TSS",
                    "calculation": 18.784920307149378
                },
                {
                    "_id": "65ede71de18a7a560d39a5d4",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "650d6788f70cba5cde76d3a1",
            "compName": "PD. Baramarta",
            "compAddress": "Jalan Komplek Pangeran Antasari No.36 Kelurahan Jawa, Kecamatan Martapura, Kabupaten Banjar",
            "latlong": {
                "lat": -3.170231,
                "lng": 115.252286
            },
            "kabkotName": "Kabupaten Banjar",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5da",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a5da",
                    "name": "TSS",
                    "calculation": 2.4107210510358676
                },
                {
                    "_id": "65ede71de18a7a560d39a5da",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65124841a6ae2642437d5fc8",
            "compName": "PT. Adimulia Agrolestari",
            "compAddress": "Jl. Kuantan II No. 06",
            "latlong": {
                "lat": -0.10997222222222222,
                "lng": 101.38786111111112
            },
            "kabkotName": "Kota Pekanbaru",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5e0",
                    "name": "COD",
                    "calculation": 68.27987338488175
                },
                {
                    "_id": "65ede71de18a7a560d39a5e0",
                    "name": "TSS",
                    "calculation": 16.349438092485965
                },
                {
                    "_id": "65ede71de18a7a560d39a5e0",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65124a4ea6ae2642437db83c",
            "compName": "PT. Perkebunan Nusantara VII UPKS Talang Sawit",
            "compAddress": "Desa Tanjung Agung Barat Kec. Lais Kab Musi Banyuasin Prov. Sumatera Selatan",
            "latlong": {
                "lat": 25827.7,
                "lng": 1040712.7
            },
            "kabkotName": "Kabupaten Musi Banyuasin",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5e4",
                    "name": "COD",
                    "calculation": 21.576178301666673
                },
                {
                    "_id": "65ede71de18a7a560d39a5e4",
                    "name": "TSS",
                    "calculation": 16.6955987
                },
                {
                    "_id": "65ede71de18a7a560d39a5e4",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65129327a6ae26424389dda7",
            "compName": "PT Tambang Damai",
            "compAddress": "jl. batubara no 8 Samarinda Kaltim",
            "latlong": {
                "lat": 0.23177,
                "lng": 117.26397
            },
            "kabkotName": "Kota Samarinda",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5e9",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a5e9",
                    "name": "TSS",
                    "calculation": 10.880050841346218
                },
                {
                    "_id": "65ede71de18a7a560d39a5e9",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6513ac776c3c9a751554fbf5",
            "compName": "PT. BARA ENERGI LESTARI",
            "compAddress": "Gampong Seumambek,  Kecamatan Suka Makmue, Kabupaten Nagan Raya",
            "latlong": {
                "lat": 1.79851,
                "lng": 98.6891
            },
            "kabkotName": "Kabupaten Aceh Barat",
            "provName": "Aceh",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5f0",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a5f0",
                    "name": "TSS",
                    "calculation": 53.55604007024266
                },
                {
                    "_id": "65ede71de18a7a560d39a5f0",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6513b28ac1629d204ef7f9ab",
            "compName": "PT Agrindo Indah Persada",
            "compAddress": "JL. Besar Bandar Pasir Mandoge, Desa Bandar Pasir Mandoge, Kecamatan Bandar Pasir Mandoge, Kabupaten Asahan, Provinsi Sumatera Utara, 21262",
            "latlong": {
                "lat": 2.78083,
                "lng": 99.39167
            },
            "kabkotName": "Kabupaten Asahan",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "651658d9f06ce5679534fb5c",
            "compName": "PT. Ekamas Fortuna",
            "compAddress": "DESA GAMPINGAN, KECAMATAN PAGAK, KABUPATEN MALANG",
            "latlong": {
                "lat": -8.19218,
                "lng": 112.555
            },
            "kabkotName": "Kabupaten Malang",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5f6",
                    "name": "COD",
                    "calculation": 465.6885588158611
                },
                {
                    "_id": "65ede71de18a7a560d39a5f6",
                    "name": "TSS",
                    "calculation": 63.61843605691399
                },
                {
                    "_id": "65ede71de18a7a560d39a5f6",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65167403f06ce5679538d75a",
            "compName": "PT. Indah Kiat Pulp & Paper Tbk. Serang Mill",
            "compAddress": "Sinar Mas land Plaza Tower II lantai 7 Jl MH Thamrin No.51 Gondangdia Menteng jakarta Pusat",
            "latlong": {
                "lat": -6.131,
                "lng": 106.2876
            },
            "kabkotName": "Kabupaten Serang",
            "provName": "Banten",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5fa",
                    "name": "COD",
                    "calculation": 3135.1668643729254
                },
                {
                    "_id": "65ede71de18a7a560d39a5fa",
                    "name": "TSS",
                    "calculation": 457.42804512735154
                },
                {
                    "_id": "65ede71de18a7a560d39a5fa",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6516857145fd0a500b92d968",
            "compName": "PT. INDAH KIAT PULP AND PAPER TBK - PERAWANG MILL",
            "compAddress": "Sinar Mas Land Plaza Menara 2 Lantai 9, Jalan M.H. Thamrin Nomor 51, Desa/Kelurahan Gondangdia, Kec. Menteng, Kota Adm. Jakarta Pusat, Provinsi DKI Jakarta,",
            "latlong": {
                "lat": -0.674389,
                "lng": 101.64
            },
            "kabkotName": "Kabupaten Siak",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a5fe",
                    "name": "COD",
                    "calculation": 104327.30391550403
                },
                {
                    "_id": "65ede71de18a7a560d39a5fe",
                    "name": "TSS",
                    "calculation": 11348.507364228819
                },
                {
                    "_id": "65ede71de18a7a560d39a5fe",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "651781f29a22fe71d499cfe2",
            "compName": "PT Kemilau Permata Sawit - Sijunjung",
            "compAddress": "Jorong Koto Ranah, Kenagarian Muaro Takung, Kecamatan Kamang Baru",
            "latlong": {
                "lat": 0.9055,
                "lng": 101.401
            },
            "kabkotName": "Kabupaten Sijunjung",
            "provName": "Sumatera Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "651b6a29d4d4ad2cfe04b62e",
            "compName": "PT. Welarco Subur Jaya II",
            "compAddress": "Jl. Raya Bakungan RT.14 Desa Bakungan Kec. Loa Janan Kab. Kutai Kartanegara Propinsi Kalimantan Timur",
            "latlong": {
                "lat": -0.69902777,
                "lng": 117.06875
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a603",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a603",
                    "name": "TSS",
                    "calculation": 51.861436960593736
                },
                {
                    "_id": "65ede71de18a7a560d39a603",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "651b6fdad4d4ad2cfe059cee",
            "compName": "PT. Palma Mas Sejati",
            "compAddress": "DESA TALANG EMPAT KEC. KARANG TINGGI KABUPATEN BENGKULU TENGAH",
            "latlong": {
                "lat": -3.7784258333333334,
                "lng": 102.36779694444444
            },
            "kabkotName": "Kabupaten Bengkulu Tengah",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a607",
                    "name": "COD",
                    "calculation": 3.537349125624646
                },
                {
                    "_id": "65ede71de18a7a560d39a607",
                    "name": "TSS",
                    "calculation": 0.657298761582765
                },
                {
                    "_id": "65ede71de18a7a560d39a607",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "651ce2ccc5f25e1a44239f74",
            "compName": "PT. Musi Banyuasin Indah",
            "compAddress": "Dusun III, Desa Talang Leban, Kecamatan Batang Hari Leko, Kabupaten Musi Banyuasin Indah",
            "latlong": {
                "lat": -2.6303,
                "lng": 103.827
            },
            "kabkotName": "Kabupaten Musi Banyuasin",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "651ceeccd345306af1e99069",
            "compName": "PT. Pindo Deli Pulp And Paper Mills - 1",
            "compAddress": "Gedung Sinar Mas Land Plaza Menara 2 Lantai 9, Jl. MH. Thamrin No. 51, Kel. Gondangdia, Kec. Menteng, Kota Adm. Jakarta Pusat, Prov. DKI Jakarta",
            "latlong": {
                "lat": -6.312,
                "lng": 107.313
            },
            "kabkotName": "Kabupaten Karawang",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a60c",
                    "name": "COD",
                    "calculation": 1953.2942132530684
                },
                {
                    "_id": "65ede71de18a7a560d39a60c",
                    "name": "TSS",
                    "calculation": 915.122247827174
                },
                {
                    "_id": "65ede71de18a7a560d39a60c",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "651e36cb004eda3602ee596a",
            "compName": "PT. LONTAR PAPYRUS PULP And PAPER INDUSTRY,LDT",
            "compAddress": "Jl. Ir. H. Juanda No. 14, Kel. Simpang III Sipin, Kec. Kotabaru, Kota Jambi, Prov. Jambi",
            "latlong": {
                "lat": -1.00236,
                "lng": 103.087
            },
            "kabkotName": "Kabupaten Tanjung Jabung Barat",
            "provName": "Jambi",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "651e3d48004eda3602ef8126",
            "compName": "PT. Agro Palindo Sakti 2 - POM",
            "compAddress": "Desa Mandong, Kecamatan Tayan Hulu, Kabupaten Sanggau",
            "latlong": {
                "lat": 0.302836,
                "lng": 110.19976
            },
            "kabkotName": "Kabupaten Sanggau",
            "provName": "Kalimantan Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a611",
                    "name": "COD",
                    "calculation": 4.172287992414668
                },
                {
                    "_id": "65ede71de18a7a560d39a611",
                    "name": "TSS",
                    "calculation": 1.9806920236621297
                },
                {
                    "_id": "65ede71de18a7a560d39a611",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "651f5cb944df5650ea467f23",
            "compName": "PT Agrindo Indah Persada - Bengkulu",
            "compAddress": "DESA TUMBUAN, KEC LUBUK SANDI, KAB SELUMA",
            "latlong": {
                "lat": -4.15083333,
                "lng": 102.60083333
            },
            "kabkotName": "Kabupaten Seluma",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "651f660044df5650ea47c22c",
            "compName": "PT. Angso Duo Sawit",
            "compAddress": "Jl. Raya Tanjung Pauh 32, Kel. Tanjung Pauh Km. 32, Kec. Mestong, Kab. Muaro Jambi, Prop. Jambi",
            "latlong": {
                "lat": -1.785,
                "lng": 103.497
            },
            "kabkotName": "Kabupaten Muaro Jambi",
            "provName": "Jambi",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a617",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a617",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a617",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "651f7145a83b3836fc7245d9",
            "compName": "PT Almharig",
            "compAddress": "Desa Langkema Kec Kabaena  Selatan Kab Bombana ",
            "latlong": {
                "lat": -5.29917,
                "lng": 121.828
            },
            "kabkotName": "Kabupaten Bombana",
            "provName": "Sulawesi Tenggara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "651f766fa83b3836fc730399",
            "compName": "PT. Sopanusa Tissue & Packaging Saranasukses",
            "compAddress": "Jalan Raya Ngoro 100, km 6 Desa Mandura Manggunggajah, Kec. Ngoro",
            "latlong": {
                "lat": -7.55767,
                "lng": 112.614
            },
            "kabkotName": "Kabupaten Mojokerto",
            "provName": "Jawa Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a622",
                    "name": "COD",
                    "calculation": 41.857344571805555
                },
                {
                    "_id": "65ede71de18a7a560d39a622",
                    "name": "TSS",
                    "calculation": 5.1856044893031825
                },
                {
                    "_id": "65ede71de18a7a560d39a622",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "651f8ca935ec29658857b01d",
            "compName": "PT Kemilau Permata Sawit - Pesisir Selatan",
            "compAddress": "Nagari Kubu Tapan, Kecamatan Ranah Ampek Hulu",
            "latlong": {
                "lat": -2.20430556,
                "lng": 101.09080556
            },
            "kabkotName": "Kabupaten Pesisir Selatan",
            "provName": "Sumatera Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6524a8ee5b34185b625efce1",
            "compName": "PT. Socfin Indonesia PKS Bangun Bandar ",
            "compAddress": "Jl Bangun Bandar Desa Martebing Kec Dolok Masihul Kab Deli Serdang Sumatera Utara",
            "latlong": {
                "lat": 3.33497,
                "lng": 99.04778
            },
            "kabkotName": "Kabupaten Deli Serdang",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a62e",
                    "name": "COD",
                    "calculation": 18.89398358596781
                },
                {
                    "_id": "65ede71de18a7a560d39a62e",
                    "name": "TSS",
                    "calculation": 10.214854772608026
                },
                {
                    "_id": "65ede71de18a7a560d39a62e",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6524a9515b34185b625f14e6",
            "compName": "PT Socfin Indonesia - Kebun Lae Butar - Singkil",
            "compAddress": "Perkebunan Lae Butar, Gunung Meriah Kab. Aceh Singkil . Aceh - 24784",
            "latlong": {
                "lat": 2.394326,
                "lng": 97.9576
            },
            "kabkotName": "Kabupaten Aceh Singkil",
            "provName": "Aceh",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a636",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a636",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a636",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6524a9fc5b34185b625f37af",
            "compName": "PT Socfin Indonesia kebun Negeri Lama",
            "compAddress": "Kec. Kota Negeri Lama, Bilah HIlir, Stasiun Rantau Prapat Kab. Labuhan Batu, Sumatera Utara ",
            "latlong": {
                "lat": 2.31583,
                "lng": 100.06795
            },
            "kabkotName": "Kabupaten Labuhan Batu",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a63a",
                    "name": "COD",
                    "calculation": 3.700496176002416
                },
                {
                    "_id": "65ede71de18a7a560d39a63a",
                    "name": "TSS",
                    "calculation": 0.8650727180193237
                },
                {
                    "_id": "65ede71de18a7a560d39a63a",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6524aac55b34185b625f58b5",
            "compName": "PT. Socfin Indonesia Kebun Aek Loba ",
            "compAddress": "Aek Loba, Padang Pulau, Aek Korsik Kec Pulau Rakyat, Bandar Pulau Kab Asahan",
            "latlong": {
                "lat": 2.6575,
                "lng": 99.6169
            },
            "kabkotName": "Kabupaten Asahan",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a63e",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a63e",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a63e",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6525f08679ad745dea78d99c",
            "compName": "PT Central Cipta Murdaya",
            "compAddress": "JL. CIKINI NO 78 JAKARTA PUSAT",
            "latlong": {
                "lat": 4.08308333333333,
                "lng": 117
            },
            "kabkotName": "Kota Jakarta Pusat",
            "provName": "DKI Jakarta",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a647",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a647",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a647",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6528f3ba5c5217371037e993",
            "compName": "PT. BUKIT MURIA JAYA",
            "compAddress": "Jl. Karawang Spoor RT.013/RW.008, Desa Purwadana, Kecamatan Telukjambe Timur, Kabupaten Karawang",
            "latlong": {
                "lat": -6.28625556,
                "lng": 107.27515
            },
            "kabkotName": "Kabupaten Karawang",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "652d2abb0bb59a3fa5f1643f",
            "compName": "PT MITRA SETIA TANAH BUMBU",
            "compAddress": "Desa Mangkalapi, Kec. Kusan Hulu, Kab. Tanah Bumbu, Prov. Kalimantan Selatan",
            "latlong": {
                "lat": -3.46389,
                "lng": 115.587
            },
            "kabkotName": "Kabupaten Tanah Bumbu",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6535d31c5f59ac156e93c81e",
            "compName": "PT. SERASAN SEKUNDANG SAWITMAS",
            "compAddress": "Desa Lecah, Kec. Lubai Ulu, Kab. Muara Enim",
            "latlong": {
                "lat": -3.89,
                "lng": 104.09222222222222
            },
            "kabkotName": "Kabupaten Muara Enim",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a650",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a650",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a650",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6535ddedbe295d36a9a208e9",
            "compName": "PT Batutua Kharisma Permai",
            "compAddress": "Desa Lurang dan Uhak, Kecamatan Wetar, Kabupaten Maluku Barat Daya, Provinsi Maluku",
            "latlong": {
                "lat": -7.69669,
                "lng": 126.32247
            },
            "kabkotName": "Kabupaten Maluku Barat Daya",
            "provName": "Maluku",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "653a160ef5b0715b7f20b0da",
            "compName": "PT. Perkebunan Nusantara VII (Persero) Unit PKS Sungai Lengi",
            "compAddress": "Jl. Inovasi Desa Panang Jaya, Kec. Gunung Megang, Kabupaten Muara Enim",
            "latlong": {
                "lat": -3.5519,
                "lng": 103.84611
            },
            "kabkotName": "Kabupaten Muara Enim",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a657",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a657",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a657",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "653b24e4e82a8859d3c50a7a",
            "compName": "PT Anugrah Harisma Barakah",
            "compAddress": "Site Malapulu, Desa Pongkalaero, Kec. Kabaena Selatan, Kab. Bombana, Sulawesi Tenggara",
            "latlong": {
                "lat": -5.42342,
                "lng": 121.92633
            },
            "kabkotName": "Kabupaten Bombana",
            "provName": "Sulawesi Tenggara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a65d",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a65d",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a65d",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "653f75d9deaf55685969a4ac",
            "compName": "PT Bencoolen Mining",
            "compAddress": "Kec. Marga Sakti Sebelat dan Kec.  Ulok Kupai kab. Bengkulu Utara Provinsi Bengkulu",
            "latlong": {
                "lat": null,
                "lng": null
            },
            "kabkotName": "Kabupaten Bengkulu Utara",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6541df011086603826a28759",
            "compName": "PT. RANA WASTU KENCANA",
            "compAddress": "Raya Tebas No. 88, Sambas, Kalimantan Barat",
            "latlong": {
                "lat": 1.14262,
                "lng": 109.31983
            },
            "kabkotName": "Kabupaten Sambas",
            "provName": "Kalimantan Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a667",
                    "name": "COD",
                    "calculation": 180.66022441514772
                },
                {
                    "_id": "65ede71de18a7a560d39a667",
                    "name": "TSS",
                    "calculation": 104.05568528866995
                },
                {
                    "_id": "65ede71de18a7a560d39a667",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65483c9fd3a6c977c8ab7b14",
            "compName": "PT. PP. Pati Sari",
            "compAddress": "Dusun Lama Desa Selamat Kecamatan Tenggulun Kabupaten Aceh Tamiang",
            "latlong": {
                "lat": 4.085056,
                "lng": 97.960833
            },
            "kabkotName": "Kabupaten Aceh Tamiang",
            "provName": "Aceh",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a670",
                    "name": "COD",
                    "calculation": 27.152218502873563
                },
                {
                    "_id": "65ede71de18a7a560d39a670",
                    "name": "TSS",
                    "calculation": 7.09235435249042
                },
                {
                    "_id": "65ede71de18a7a560d39a670",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65483eefd3a6c977c8abe10e",
            "compName": "PT Timah Investasi Mineral",
            "compAddress": "Jl.Pondok Indah Lere’ea Kel.Sikeli Kec.Bombana, Provinsi Sulawesi Tenggara 93781",
            "latlong": {
                "lat": -5.26168,
                "lng": 121.820036666667
            },
            "kabkotName": "Kabupaten Bombana",
            "provName": "Sulawesi Tenggara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a67a",
                    "name": "COD",
                    "calculation": -0.15390400000000012
                },
                {
                    "_id": "65ede71de18a7a560d39a67a",
                    "name": "TSS",
                    "calculation": 2401.426659192118
                },
                {
                    "_id": "65ede71de18a7a560d39a67a",
                    "name": "NH3N",
                    "calculation": -0.15390400000000012
                }
            ]
        },
        {
            "_id": "65483fb2d3a6c977c8ac0209",
            "compName": "PT Indonesia Weda Bay Industrial Park",
            "compAddress": "Tanjung Ulie, Desa Lelilef Sawai, Kecamatan Weda Tengah, Kabupaten Halmahera Tengah",
            "latlong": {
                "lat": 0.478611,
                "lng": 128.009
            },
            "kabkotName": "Kabupaten Halmahera Tengah",
            "provName": "Maluku Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a684",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a684",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a684",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6548485a2f7bf816f9450b59",
            "compName": "PT Tambang Bumi Sulawesi",
            "compAddress": "Ds Puununu, Kec Kabaena Selatan, kab Bombana, Prov Sulawesi Tenggara",
            "latlong": {
                "lat": -5.36894,
                "lng": 121.865
            },
            "kabkotName": "Kabupaten Bombana",
            "provName": "Sulawesi Tenggara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "65484a7f2f7bf816f945785c",
            "compName": "PT Injatama",
            "compAddress": "KEM TOWER, Lantai 7 Unit C-D, ",
            "latlong": {
                "lat": -3.24226,
                "lng": 101.84619
            },
            "kabkotName": "Kota Jakarta Pusat",
            "provName": "DKI Jakarta",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a68e",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a68e",
                    "name": "TSS",
                    "calculation": 416.43619417450003
                },
                {
                    "_id": "65ede71de18a7a560d39a68e",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65484b592f7bf816f9459ef4",
            "compName": "PT. Sapta Sentosa Jaya Abadi",
            "compAddress": "Desa Lubuk Pinang",
            "latlong": {
                "lat": -2.384336,
                "lng": 101.163108
            },
            "kabkotName": "Kabupaten Mukomuko",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a697",
                    "name": "COD",
                    "calculation": 2430.90351838613
                },
                {
                    "_id": "65ede71de18a7a560d39a697",
                    "name": "TSS",
                    "calculation": 1809.2843317157756
                },
                {
                    "_id": "65ede71de18a7a560d39a697",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "654853172f7bf816f9471fe4",
            "compName": "PT. SUNGAI BAHAR PASIFIK UTAMA",
            "compAddress": "Desa Kunangan Kecamatan  Taman Rajo ",
            "latlong": {
                "lat": -1.53749,
                "lng": 103.63258
            },
            "kabkotName": "Kabupaten Muaro Jambi",
            "provName": "Jambi",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a69c",
                    "name": "COD",
                    "calculation": 58.86652951817684
                },
                {
                    "_id": "65ede71de18a7a560d39a69c",
                    "name": "TSS",
                    "calculation": 50.05949758868436
                },
                {
                    "_id": "65ede71de18a7a560d39a69c",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6548614be56c863af7d57815",
            "compName": "PT Bara Adhipratama",
            "compAddress": "Desa Bukit Berlian kec. Ulok Kupai",
            "latlong": {
                "lat": -3.217794,
                "lng": 101.833762
            },
            "kabkotName": "Kabupaten Bengkulu Utara",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a6a2",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a6a2",
                    "name": "TSS",
                    "calculation": 0.020228804208333347
                },
                {
                    "_id": "65ede71de18a7a560d39a6a2",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "654872a393021a5f46c133ef",
            "compName": "PT. Baramega Citra Mulia Persada",
            "compAddress": "Ruko Jhonlin Indah Blok C No. 04 ",
            "latlong": {
                "lat": -3.21833,
                "lng": 116.0125
            },
            "kabkotName": "Kabupaten Kota Baru",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a6b0",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a6b0",
                    "name": "TSS",
                    "calculation": 33.20409481532567
                },
                {
                    "_id": "65ede71de18a7a560d39a6b0",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65488c8c8c4a042abebce69f",
            "compName": "PT. Cahaya Sawit Lestari",
            "compAddress": "Desa Pulau Panggung, Kecamatan Talang Empat",
            "latlong": {
                "lat": -3.802233,
                "lng": 102.396542
            },
            "kabkotName": "Kabupaten Bengkulu Tengah",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "65488da38c4a042abebd41c3",
            "compName": "PT. SANDABI INDAH LESTARI",
            "compAddress": "Desa Lubuk Banyau Kec. Padang Jaya ",
            "latlong": {
                "lat": -3.314837,
                "lng": 102.083411
            },
            "kabkotName": "Kabupaten Bengkulu Utara",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "65489c6e4649964b989523dc",
            "compName": "PT MULTIKARYA HASILPRIMA",
            "compAddress": "Kawasan Industri & Pergudangan Marunda Center Blok G No.1B, Kelurahan Segara Makmur, Kecamatan Tarumajaya, Kabupaten Bekasi, Provinsi Jawa Barat, Kode Pos : 17211",
            "latlong": {
                "lat": -6.09334,
                "lng": 106.981
            },
            "kabkotName": "Kabupaten Bekasi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a6ba",
                    "name": "COD",
                    "calculation": 1.803757566097778
                },
                {
                    "_id": "65ede71de18a7a560d39a6ba",
                    "name": "TSS",
                    "calculation": 1.3979546042133328
                },
                {
                    "_id": "65ede71de18a7a560d39a6ba",
                    "name": "NH3N",
                    "calculation": 0.025513032888888895
                }
            ]
        },
        {
            "_id": "6549892e4649964b98b53156",
            "compName": "PT Sinar Jaya Sultra Utama",
            "compAddress": "Desa Waturambaha",
            "latlong": {
                "lat": -3.374666667,
                "lng": 122.3315278
            },
            "kabkotName": "Kabupaten Konawe Utara",
            "provName": "Sulawesi Tenggara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a6c2",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a6c2",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a6c2",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65518b1cbee0e70e824a7611",
            "compName": "PT. Cakrawala Dinamika Energi",
            "compAddress": "Rukan The Golf Blok L No. 055 Telp: (+6221) 22338024  Kel. Kamal Muara, Kec. Penjaringan  Golf Island - Pantai Indah Kapuk – Jakarta Utara – 14470",
            "latlong": {
                "lat": -3.2855555555555553,
                "lng": 101.95108333333333
            },
            "kabkotName": "Kota Jakarta Utara",
            "provName": "DKI Jakarta",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6551cac92c5a0a1c4fa0022b",
            "compName": "PT. Kelantan Sakti",
            "compAddress": "Desa Cinta jaya, Kecamatan Pedamaran Kabupaten Ogan Komering Ilir Sumatera Selatan",
            "latlong": {
                "lat": -3.50795833,
                "lng": 104.94140278
            },
            "kabkotName": "Kabupaten Ogan Komering Ilir",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "65556f905321701a90274c84",
            "compName": "PT KUTAI ENERGI",
            "compAddress": "Desa Tani Harapan Kecamatan Loa Janan",
            "latlong": {
                "lat": -0.7322527777777778,
                "lng": 117.11427222222221
            },
            "kabkotName": "Kabupaten Kutai Kartanegara",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a6d0",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a6d0",
                    "name": "TSS",
                    "calculation": 2.8991322475749555
                },
                {
                    "_id": "65ede71de18a7a560d39a6d0",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65558e5268bdc570744e257c",
            "compName": "PT. Sari Warna Asli Unit I",
            "compAddress": "DESA KEMIRI, KECAMATAN KEBAKKRAMAT, KABUPATEN KARANGGANYAR",
            "latlong": {
                "lat": -7.52778,
                "lng": 110.883
            },
            "kabkotName": "Kabupaten Karanganyar",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a6db",
                    "name": "COD",
                    "calculation": 36.72543664733376
                },
                {
                    "_id": "65ede71de18a7a560d39a6db",
                    "name": "TSS",
                    "calculation": 11.391114092303452
                },
                {
                    "_id": "65ede71de18a7a560d39a6db",
                    "name": "NH3N",
                    "calculation": 0.7041169660307471
                }
            ]
        },
        {
            "_id": "6555cfd43e45ef233e14b2d7",
            "compName": "PT. AGRI ANDALAS",
            "compAddress": "Jl. P. Natadirja No. 1 Kel. Jalan Gedang Kec. Gading Cempaka Bengkulu",
            "latlong": {
                "lat": -4.00028,
                "lng": 102.431
            },
            "kabkotName": "Kota Bengkulu",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a6e0",
                    "name": "COD",
                    "calculation": 55.681127261880356
                },
                {
                    "_id": "65ede71de18a7a560d39a6e0",
                    "name": "TSS",
                    "calculation": 21.003172264273505
                },
                {
                    "_id": "65ede71de18a7a560d39a6e0",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6556c41846dc1860e29205ea",
            "compName": "PT. SANDABI INDAH LESTARI PKS KETAHUN",
            "compAddress": "Desa Bukit Indah Kecamatan Ketahun",
            "latlong": {
                "lat": -3.364889,
                "lng": 101.828583
            },
            "kabkotName": "Kabupaten Bengkulu Utara",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "655aaf2a32961c3b5d11d404",
            "compName": "PT. Tekindo Energi",
            "compAddress": "Infiniti Office, Arcade Business Center 6th Floor Unit 6-03",
            "latlong": {
                "lat": 0.58164,
                "lng": 127
            },
            "kabkotName": "Kota Jakarta Utara",
            "provName": "DKI Jakarta",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "655ab4b132961c3b5d12dff2",
            "compName": "PT Cereno Energi Selaras",
            "compAddress": "Rukan The Golf Blok L No. 055 Kel. Kamal Muara Kec. Penjaringan Golf Island-Pantai Indah Kapuk-14470",
            "latlong": {
                "lat": -3.315,
                "lng": 101.949167
            },
            "kabkotName": "Kota Jakarta Utara",
            "provName": "DKI Jakarta",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "655b0dfbe43357724105b952",
            "compName": "PT. SENTOSA SEJAHTERA SEJATI",
            "compAddress": "SP. III, Desa Bumi Mulya, Kecamatan  \t\tPenarik, Kabupaten Mukomuko,  \t\tProvinsi Bengkulu",
            "latlong": {
                "lat": -2.66438,
                "lng": 101.301
            },
            "kabkotName": "Kabupaten Mukomuko",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a6e8",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a6e8",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a6e8",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "655c0226e43357724149c45f",
            "compName": "PT. Perkebunan Nusantara XIII - PKS Gn. Meliau",
            "compAddress": "Desa Meliau Hilir, Kec. Meliau",
            "latlong": {
                "lat": -0.10575,
                "lng": 110.2942
            },
            "kabkotName": "Kabupaten Sanggau",
            "provName": "Kalimantan Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a6ec",
                    "name": "COD",
                    "calculation": 175.62715814338154
                },
                {
                    "_id": "65ede71de18a7a560d39a6ec",
                    "name": "TSS",
                    "calculation": 119.4882809839822
                },
                {
                    "_id": "65ede71de18a7a560d39a6ec",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "655ec249487cc74327f554eb",
            "compName": "PT. PRIMATEXCO INDONESIA",
            "compAddress": "Jl. Jenderal Urip Sumoharjo Sambong Batang 51212, Jawa Tengah - Indonesia",
            "latlong": {
                "lat": -6.91958,
                "lng": 109.751
            },
            "kabkotName": "Kabupaten Batang",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6561dc1d9bdd241599c9b241",
            "compName": "PT Kawasan Industri Kujang Cikampek",
            "compAddress": "Jalan Jenderal Achmad Yani Nomor 39, Dawuan, Kecamatan Cikampek, Kabupaten Karawang",
            "latlong": {
                "lat": -6.405388888888889,
                "lng": 107.43566666666668
            },
            "kabkotName": "Kabupaten Karawang",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a6f2",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a6f2",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a6f2",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6561dcda9bdd241599c9d85b",
            "compName": "PT Energi Batubara Lestari",
            "compAddress": "Jl. Kesumagiri RT 002/RW 001, Desa/Kelurahan Bungur, Kec. Bungur, Kab. Tapin, Provinsi Kalimantan Selatan",
            "latlong": {
                "lat": -2.978138888888889,
                "lng": 115.19977777777778
            },
            "kabkotName": "Kabupaten Tapin",
            "provName": "Kalimantan Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a6f6",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a6f6",
                    "name": "TSS",
                    "calculation": 0.28180079083968246
                },
                {
                    "_id": "65ede71de18a7a560d39a6f6",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6561df0c9bdd241599ca42da",
            "compName": "PT. Antang Ganda Utama - PPKS Butong",
            "compAddress": "Desa Butong, Kec. Teweh Selatan, Kab. Barito Utara, Prov. Kalimantan Tengah",
            "latlong": {
                "lat": -0.11,
                "lng": 114
            },
            "kabkotName": "Kabupaten Barito Utara",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "6567ac1acae882365cc5a364",
            "compName": "PT Duta Bara Utama",
            "compAddress": "Jl. Jendral Sudirman (Simpang Islamic center Muara Enim) kecamatan Muara enim, kelurahan Muara Enim, Kabupaten Muara Enim, Provinsi Sumatera Selatan",
            "latlong": {
                "lat": -3.65372,
                "lng": 103.80122
            },
            "kabkotName": "Kabupaten Muara Enim",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a6fb",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a6fb",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a6fb",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6575ed06f45107f6049c23bf",
            "compName": "PT Bara Prima Pratama",
            "compAddress": "Desa Batuampar Kel. Selensen Kec. Kemuning",
            "latlong": {
                "lat": -0.9581388888888889,
                "lng": 102.71544444444444
            },
            "kabkotName": "Kabupaten Indragiri Hilir",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a70f",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a70f",
                    "name": "TSS",
                    "calculation": 0.018727489002028395
                },
                {
                    "_id": "65ede71de18a7a560d39a70f",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6576635e14b21c5ed2fbb977",
            "compName": "PT Gorby Putra Utama ",
            "compAddress": "Dusun Terentang Desa Beringin Makmur II Kec. Rawas Ilir, Kab. Musi Rawas Utara Prov.Sumatera Selatan ",
            "latlong": {
                "lat": -2.518730556,
                "lng": 103.1542028
            },
            "kabkotName": "Kabupaten Musi Rawas",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a719",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a719",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a719",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "657668ed54512519f735da5b",
            "compName": "PT. Sanggam Kahuripan Indonesia",
            "compAddress": "Desa Salimbatu, Kecamatan Tanjung Palas Tengah, Kabupaten Bulungan, Provinsi Kalimnatan Utara",
            "latlong": {
                "lat": -3.11306,
                "lng": 117.261
            },
            "kabkotName": "Kabupaten Bulungan",
            "provName": "Kalimantan Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "657bc02df2f4b87010432a8b",
            "compName": "PT. ARGO PANTES, TBK.",
            "compAddress": "Jl. MH Thamrin KM 4, CIkokol, Tangerang, Banten",
            "latlong": {
                "lat": -6.207666666666666,
                "lng": 106.62194444444444
            },
            "kabkotName": "Kota Tangerang",
            "provName": "Banten",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71de18a7a560d39a724",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a724",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71de18a7a560d39a724",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65935e69efa34e109f50d47b",
            "compName": "PT. Telen Orbit Prima",
            "compAddress": "Desa buhut, kabupaten kapuas,provinsi kalimantan tengah",
            "latlong": {
                "lat": 1.12366944,
                "lng": 114.4852917
            },
            "kabkotName": "Kabupaten Kapuas",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ee18a7a560d39a752",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ee18a7a560d39a752",
                    "name": "TSS",
                    "calculation": 53.27154026310164
                },
                {
                    "_id": "65ede71ee18a7a560d39a752",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "6593b2f83b566b679a5cd849",
            "compName": "PT Kawasan Industri Dumai",
            "compAddress": "Jl. Pulau Sumatera No. 1 Kawasan Indusri Dumai, Kel. Pelintung, Kec. Medang Kampai Kota Dumai Prov. Riau",
            "latlong": {
                "lat": 1.64467,
                "lng": 101.63792
            },
            "kabkotName": "Kota Dumai",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ee18a7a560d39a756",
                    "name": "COD",
                    "calculation": 235.77748131999994
                },
                {
                    "_id": "65ede71ee18a7a560d39a756",
                    "name": "TSS",
                    "calculation": 62.12213059041668
                },
                {
                    "_id": "65ede71ee18a7a560d39a756",
                    "name": "NH3N",
                    "calculation": 0.0017889251458333155
                }
            ]
        },
        {
            "_id": "659b489400129234752418c9",
            "compName": "PT Bhumireksa Nusasejati - Teluk Bakau Factory",
            "compAddress": "KM 00 Desa Rotan Semelur, Kec. Pelangiran Kab. Indragiri Hilir",
            "latlong": {
                "lat": 0.25722222,
                "lng": 103.58963889
            },
            "kabkotName": "Kabupaten Indragiri Hilir",
            "provName": "Riau",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "659b58e904770954e92b1220",
            "compName": "PT. Perkebunan Nusantara XIII  PKS. Parindu",
            "compAddress": "Dusun Sengawan Hilir Desa Binjai Kecamatan Tayan Hulu.",
            "latlong": {
                "lat": -0.24875,
                "lng": 110.30063
            },
            "kabkotName": "Kabupaten Sanggau",
            "provName": "Kalimantan Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ee18a7a560d39a75b",
                    "name": "COD",
                    "calculation": 557.7238204517854
                },
                {
                    "_id": "65ede71ee18a7a560d39a75b",
                    "name": "TSS",
                    "calculation": 379.62462669634635
                },
                {
                    "_id": "65ede71ee18a7a560d39a75b",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "659f454380cf061b2e2a0491",
            "compName": "PT. Tamtama Perkasa",
            "compAddress": "Desa IPU Kecamatan Lahei",
            "latlong": {
                "lat": -0.903333,
                "lng": 114.912
            },
            "kabkotName": "Kabupaten Barito Utara",
            "provName": "Kalimantan Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ee18a7a560d39a761",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ee18a7a560d39a761",
                    "name": "TSS",
                    "calculation": 137.48579471394362
                },
                {
                    "_id": "65ede71ee18a7a560d39a761",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65ae210a8cea470eeb2fa502",
            "compName": "PT. Dharmasraya Lestarindo",
            "compAddress": "NAGARI KOTO PADANG KECAMATAN KOTO BARU",
            "latlong": {
                "lat": -1.07919,
                "lng": 101.667
            },
            "kabkotName": "Kabupaten Dharmasraya",
            "provName": "Sumatera Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "65b064ce31bf7814a8aad333",
            "compName": "PT Sepanjang Inti Surya Utama 2",
            "compAddress": "DESA MALENGGANG, KEC. SEKAYAM, KAB. SANGGAU, PROV. KALIMANTAN BARAT",
            "latlong": {
                "lat": 0.8054,
                "lng": 110.771
            },
            "kabkotName": "Kabupaten Sanggau",
            "provName": "Kalimantan Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "65b211c03bf631339f078199",
            "compName": "PT Kawasan Industri Kendal",
            "compAddress": "Jl.Raya Arteri KM.19 Kec.Brangsong",
            "latlong": {
                "lat": -6.928611,
                "lng": 110.271667
            },
            "kabkotName": "Kabupaten Kendal",
            "provName": "Jawa Tengah",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "65b30112af143e072f5c6746",
            "compName": "PT Kaltim Jaya Bara",
            "compAddress": "Desa Labanan Jaya, Kecamatan Teluk Bayur, Kabupaten Berau, Provinsi Kalimantan Timur",
            "latlong": {
                "lat": 2.09030556,
                "lng": 117.31822222
            },
            "kabkotName": "Kabupaten Berau",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ee18a7a560d39a76c",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ee18a7a560d39a76c",
                    "name": "TSS",
                    "calculation": 2.803951368472223
                },
                {
                    "_id": "65ede71ee18a7a560d39a76c",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65b75740c9b43c1037d61566",
            "compName": "PT. Perkebunan Nusantara VII (Persero) Unit Talo Pino",
            "compAddress": "Jalan Pring Baru Desa Pering Baru Kec. Talo Kecil Kab. Seluma",
            "latlong": {
                "lat": -4.50222,
                "lng": 102.891
            },
            "kabkotName": "Kabupaten Seluma",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "65c05cac405f557259fed7cc",
            "compName": "PT LEUWIJAYA UTAMA TEXTILE",
            "compAddress": "Cimahi",
            "latlong": {
                "lat": -6.908888888888889,
                "lng": 107.54527777777777
            },
            "kabkotName": "Kota Cimahi",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ee18a7a560d39a771",
                    "name": "COD",
                    "calculation": 2145.5945560000005
                },
                {
                    "_id": "65ede71ee18a7a560d39a771",
                    "name": "TSS",
                    "calculation": 213.50213878693054
                },
                {
                    "_id": "65ede71ee18a7a560d39a771",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65c07f34f905db435ae85a5c",
            "compName": "PT. Stargate Pasific Resources",
            "compAddress": "Molore, Kecamatan Langgikima, Kabupaten Konawe Utara, Sulawesi Tenggara 93352",
            "latlong": {
                "lat": -3.316222222222222,
                "lng": 122.28356111111111
            },
            "kabkotName": "Kabupaten Konawe Utara",
            "provName": "Sulawesi Tenggara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ee18a7a560d39a775",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ee18a7a560d39a775",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ee18a7a560d39a775",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65c18c47d769a02c4a86b92d",
            "compName": "PT. Prima Lazuardi Nusantara",
            "compAddress": "Baturaja",
            "latlong": {
                "lat": -4.07687,
                "lng": 104.156
            },
            "kabkotName": "Kabupaten Ogan Komering Ulu",
            "provName": "Sumatera Selatan",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ee18a7a560d39a779",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ee18a7a560d39a779",
                    "name": "TSS",
                    "calculation": 15.104764267241386
                },
                {
                    "_id": "65ede71ee18a7a560d39a779",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65c32c3e1c68a104cc64e72d",
            "compName": "PT Pipit Mutiara Raya",
            "compAddress": "JL IMAM BONJOL PANTAI SIMPANG KADIR ",
            "latlong": {
                "lat": 4.09167,
                "lng": 117.266
            },
            "kabkotName": "Kabupaten Nunukan",
            "provName": "Kalimantan Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ee18a7a560d39a77e",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ee18a7a560d39a77e",
                    "name": "TSS",
                    "calculation": 0.02737748167182434
                },
                {
                    "_id": "65ede71ee18a7a560d39a77e",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65c96c6b4d6d280d00afebf2",
            "compName": "PT Primarajuli Sukses",
            "compAddress": "Jl. Aria Jaya Santika No. 21, Pasir Bolang, Kec. Tigaraksa, Kab. Tangerang, Banten",
            "latlong": {
                "lat": -6.214301,
                "lng": 106.4760903
            },
            "kabkotName": "Kabupaten Tangerang",
            "provName": "Banten",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,NH3N,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ee18a7a560d39a782",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ee18a7a560d39a782",
                    "name": "TSS",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ee18a7a560d39a782",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65d08554bb634544ea2a8dd8",
            "compName": "PT Bina Insan Sukses Mandiri",
            "compAddress": "LINGGANG MARIMUN",
            "latlong": {
                "lat": -0.065944,
                "lng": 115.780944
            },
            "kabkotName": "Kabupaten Kutai Barat",
            "provName": "Kalimantan Timur",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ee18a7a560d39a788",
                    "name": "COD",
                    "calculation": 0
                },
                {
                    "_id": "65ede71ee18a7a560d39a788",
                    "name": "TSS",
                    "calculation": 34.146967638408356
                },
                {
                    "_id": "65ede71ee18a7a560d39a788",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65d29ed6b67fd02e65e474db",
            "compName": "PT. Lingga Tiga Sawit",
            "compAddress": "Dusun Janji Lobi Desa Lingga Tiga Kecamatan Bilah Hulu, Kabupaten Labuhan Batu Provinsi Sumatera Utara Kode Pos 21451",
            "latlong": {
                "lat": 2.0308611111111112,
                "lng": 99.88097222222221
            },
            "kabkotName": "Kabupaten Labuhan Batu",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "65dbe047e34d521ef502a30a",
            "compName": "PT Socfin Indonesia kebun Mata Pao",
            "compAddress": "Desa Mata Pao",
            "latlong": {
                "lat": 3.53,
                "lng": 99.09
            },
            "kabkotName": "Kabupaten Serdang Bedagai",
            "provName": "Sumatera Utara",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ee18a7a560d39a78e",
                    "name": "COD",
                    "calculation": 26.8987407921451
                },
                {
                    "_id": "65ede71ee18a7a560d39a78e",
                    "name": "TSS",
                    "calculation": 12.377542995232126
                },
                {
                    "_id": "65ede71ee18a7a560d39a78e",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65dbe1e2e34d521ef502de46",
            "compName": "PT. Socfindo - Perkebunan Seunagan",
            "compAddress": "Desa Purwodadi",
            "latlong": {
                "lat": 4.05938,
                "lng": 96.2608
            },
            "kabkotName": "Kabupaten Nagan Raya",
            "provName": "Aceh",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ee18a7a560d39a795",
                    "name": "COD",
                    "calculation": 7.213855163015872
                },
                {
                    "_id": "65ede71ee18a7a560d39a795",
                    "name": "TSS",
                    "calculation": 48.93236881131814
                },
                {
                    "_id": "65ede71ee18a7a560d39a795",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65dbe29de34d521ef5030024",
            "compName": "PT. Socfindo - Perkebunan Seumanyam",
            "compAddress": "Desa Simpang Deli Kilang",
            "latlong": {
                "lat": 3.96361,
                "lng": 96.5667
            },
            "kabkotName": "Kabupaten Nagan Raya",
            "provName": "Aceh",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        },
        {
            "_id": "65dbeb8be7755c36c096289c",
            "compName": "PT. Pindo Deli Pulp and Paper Mills 3",
            "compAddress": "sinar Mas Land Plaza Tower II, 9th floor jl MH thamrinNo 51 Jakarta 10350, Indonesia",
            "latlong": {
                "lat": -6.38833,
                "lng": 107.222
            },
            "kabkotName": "Kabupaten Karawang",
            "provName": "Jawa Barat",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,COD,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": [
                {
                    "_id": "65ede71ee18a7a560d39a7a3",
                    "name": "COD",
                    "calculation": 365.35092429119
                },
                {
                    "_id": "65ede71ee18a7a560d39a7a3",
                    "name": "TSS",
                    "calculation": 45.45791332330602
                },
                {
                    "_id": "65ede71ee18a7a560d39a7a3",
                    "name": "NH3N",
                    "calculation": 0
                }
            ]
        },
        {
            "_id": "65e81ff546579b34de983cac",
            "compName": "PT. INTI BARA PERDANA",
            "compAddress": "Jalan Raya Sungai Rupat RT 039 RW 07 Kelurahan Pagar Dewa, Kecamatan Selebar Kota Bengkulu",
            "latlong": {
                "lat": -3.75818,
                "lng": 102.510465
            },
            "kabkotName": "Kabupaten Bengkulu Tengah",
            "provName": "Bengkulu",
            "activationLogger": "Terkoneksi",
            "parameterLogger": "pH,,TSS,,Debit",
            "titikPenaatan": "",
            "beban_sensor": []
        }
    ]
}
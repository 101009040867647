export default [
   {
    izin_pembuangan_air_limbah:'12121',
    pejabat_penerbit_izin:'tes',
    nomor_izin_pembuangan_air_limbah:'2454',
    tanggal_izin_terbit:'2000-10-12',
    berlaku:'2001-10-12',
    sumber_air_limbah:'tes',
    jenis_teknologi_pengolahan_limbah_air:'test',
    jenis_air_limbah:'tes lagi',
    debit:'2000',
    kapasitas_produksi_sesuai_izin:'tes tes',
    kapasitas_produksi_sesuai_nyatanya:'tes',
    frekuensi_pembuangan_air_limbah:'100'
   }
];

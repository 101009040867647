<template>
  <div class="wallpaper">
    <!-- Header -->
    <div class="header  py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">Kementerian Lingkungan Hidup dan Kehutanan</h1>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center" >
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0" style="overflow: hidden;">
            <img src="../../../public/img/brand/klhk.png" alt="" style="position: absolute;bottom: -100px;right: -200px;width:100%;opacity: 0.5;">
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-white mb-4">
                <small>SPARING</small>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="Email"
                              :rules="{required: true, email: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              v-model="model.userEmail">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Kata sandi"
                              :rules="{required: true, min: 3}"
                              prepend-icon="ni ni-lock-circle-open"
                              append-icon="ni ni-lock-circle-open"
                              :type="isShowPassword ? 'text' : 'password'"
                              placeholder="Kata Sandi"
                              v-model="model.userPassword">
                              <template #append>
                                <span
                                  @click="isShowPassword = !isShowPassword"
                                  style="cursor: pointer"
                                >
                                  {{ isShowPassword ? "Sembunyikan" : "Lihat" }}
                                </span>
                              </template>
                  </base-input>

                  <div class="text-center">
                    <base-button type="primary"  native-type="submit" class="my-4 w-100">MASUK</base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import API from '../../api/base_url.js'
import Swal from 'sweetalert2/dist/sweetalert2';
import axios from 'axios';

  export default {
    data() {
      return {
      isShowPassword: false,
        model: {
          userEmail: "@menklhk.com",
          userPassword: ""
          // rememberMe: false
        }
      };
    },
    methods: {
      onSubmit() {
        let payload = {
          email:this.model.userEmail,
          password:this.model.userPassword
        }
        axios.post('https://api.dev-klhk.rnbteknologiindonesia.com/api/v1/auth/login', payload)
          .then(({data}) => {
            localStorage.setItem('token', data.data.access_token)
            localStorage.setItem('refresh_token',data.data.refresh_token)
            localStorage.setItem('email',data.data.user_name)
            localStorage.setItem('as',data.data.role_name)
            this.$router.push({name:'Dashboard layout'})
          })
          .catch((err) => {
            if (err.message == 'Network Error') {
              Swal.fire("Error",'Gagal Autentikasi','error')
            }

            let pesan = "Maaf, terjadi kesalahan"
            console.log(err.response.data.meta.errors[0].substring(0,2));
            if (err.response.data.meta.errors[0].substring(0,2) == 'Ma') {
              pesan = err.response.data.meta.errors[0]
            }

            if (err.response.data.meta.errors[0].substring(0,2) == 'no' ) {
              pesan = 'Akun tidak ditemukan'
            }

            if (err.response.data.meta.errors[0].substring(0,2) == 'cr' ) {
              pesan = 'Password salah'
            }



            Swal.fire({
              icon: 'error',
              title: "Autentikasi Gagal",
              text: pesan,
            })
          })  
      }
    }
  };
</script>

<style>
.wallpaper {  
  background-image: url("../../../public/img/theme/img-background-full.png");
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}
</style>
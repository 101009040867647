<template>
    <div class="hfull">
      <div class="p-3" style="display: flex; justifycontent: right">
        <button class="btn btn-danger" @click="close">X</button>
      </div>
      <div class="header pt-lg-5">
        <b-container>
          <div class="header-body text-center mb-7">
            <b-row class="justify-content-center">
              <img
                src="../../../../public/img/brand/img-logo-color.png"
                alt="klhk"
                class="mb-2"
              />
              <b-col md="12">
                <h2 class="text-white">
                  PENDAFTARAN SISTEM PEMANTAUAN KUALITAS AIR LIMBAH SECARA TERUS
                  MENERUS DAN DALAM JARINGAN (SPARING) BAGI PENANGGUNG JAWAB USAHA
                  DAN/ATAU KEGIATAN
                </h2>
                <p class="text-lead text-white">
                  Pendaftaran adalah fasilitas untuk pencatatan secara ONLINE dan
                  validasi bagi penanggung jawab usaha dan/atau kegiatan yang
                  bertujuan untuk mendapatkan USER NAME, PASSWORD, UID (User
                  Identification).
                </p>
              </b-col>
            </b-row>
            <button class="btn btn-primary" @click="showAnnoncementModal = true">
              LIHAT MEKANISME
            </button>
          </div>
        </b-container>
      </div>
     

    <!-- Loading Screen -->
    <Modal :show="isLoading" modalContentClasses="popup-modal-backgrounds" modalClasses="modal-dialog-scrollable"
      size="lg" style="background-color: rgba(0, 0, 0, 0.8)">
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4">
            <div class="row">
              <div class="col-lg-12 text-center" style="position: relative">
                <img src="img/brand/img-logo-color.png" alt="" />
                <img src="/img/brand/klhk.png" alt="" style="
                    position: absolute;
                    right: 0;
                    top: -200px;
                    opacity: 0.2;
                  " />
                <h1>Sedang Proses...</h1>
                <h5>Harap tunggu beberapa saat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Modal Untuk Menampilkan Lihat Mekanisme -->
    <Modal
      :show="showAnnoncementModal"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showAnnoncementModal = false"
      size="xl"
    >
      <template v-slot:header>
        <div class="mdl-header d-flex">
          <img
            src="../../../../public/img/brand/img-logo-color.png"
            alt="klhk"
            class="mb-2"
          />
          <h3 class="ml-3">
            Kementerian <br />
            Lingkungan Hidup dan Kehutanan <br />
            Republik Indonesia
          </h3>
        </div>
      </template>
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <div class="p-4" style="overflow-y: scroll; height: 450px">
            <h2 class="text-justify"></h2>
            <h2 class="text-justify">
              PENDAFTARAN SISTEM PEMANTAUAN KUALITAS AIR LIMBAH SECARA TERUS
              MENERUS DAN DALAM JARINGAN (SPARING) BAGI PENANGGUNG JAWAB USAHA
              DAN/ATAU KEGIATAN
            </h2>
            <p class="mb-2 text-justify">
              Pendaftaran adalah fasilitas untuk pencatatan secara ONLINE dan
              validasi bagi penanggung jawab usaha dan/atau kegiatan yang
              bertujuan untuk mendapatkan USER NAME, PASSWORD, UID (User
              Identification).
              <br />Mekanisme pendaftaran yaitu:
            </p>
            <ol>
              <li>
                Pendaftaran WAJIB dilakukan oleh penanggung jawab usaha dan/atau
                kegiatan (industri), tidak diperbolehkan bagi penyedia peralatan
                & jasa alat sensor pemantauan online.
              </li>
              <li>
                Penanggung jawab usaha dan/atau kegiatan WAJIB melaksanakan
                pendaftaran dan mengisi semua form serta bukti.
              </li>
              <li>
                Pengisian form pendaftaran dan bukti yaitu:
                <ol type="a">
                  <li>Pengisian umum</li>
                  <li>
                    Pengisian dan penyampaian bukti persyaratan identifikasi
                    sumber pencemar
                  </li>
                  <li>Pengisian dan penyampaian bukti persyaratan teknis</li>
                </ol>
              </li>
              <li>
                Hasil jawaban dari permohonan pendaftaran SPARING melalui fitur
                pendaftaran paling lambat dikirim 14 hari kerja setelah
                dikirimkan oleh penanggung jawab usaha dan/atau kegiatan.
              </li>
              <li>
                USER NAME, PASSWORD, UID (User Identification) berupa TANDA
                TERIMA ELEKTRONIK akan dikirimkan ke alamat email yang
                didaftarkan pada form pendaftaran melalui email
                sparing.menlhk@gmail.com jika seluruh isian lengkap dan
                dinyatakan valid oleh Admin SPARING KLHK.
              </li>
              <li>
                Bukti dan data dinyatakan valid melalui bukti persyaratan
                identifikasi sumber pencemar dan persyaratan teknis SPARING
                sesuai dengan Peraturan Menteri Lingkungan Hidup Nomor
                P.93/MENLHK/SETJEN/KUM.1/8/2018 tentang Pemantauan Kualitas Air
                Limbah Secara Terus Menerus Dan Dalam Jaringan Bagi Usaha
                Dan/Atau Kegiatan jo Peraturan Menteri Lingkungan Hidup Nomor
                P.80/MENLHK/SETJEN/KUM.1/10/2019.
              </li>
              <li>
                Jika isian tidak lengkap dan terdapat bukti yang tidak valid
                maka Admin SPARING KLHK akan menyampaikan melalui email yang
                terdaftar. Penanggung jawab usaha dan/atau kegiatan WAJIB
                melengkapi sesuai dengan hasil evaluasi.
              </li>
              <li>
                Kebijakan pengunduran waktu pemasangan dan pengoperasian SPARING
                sesuai Surat Edaran Menteri Lingkungan Hidup dan Kehutanan Nomor
                SE.5/Menlhk/Setjen/KUM.1/6/2020 tidak berlaku lagi.
              </li>
            </ol>

            <br />
            <span>
              Tim SPARING<br />
              Direktorat Pengendalian Pencemaran air<br />
              JL.DI Panjaitan Kav. 24 Kebon Nanas Jakarta Timur<br />
              Gedung B lantai 5<br />
              Email: sparing.menlhk@gmail.com<br />
              Fathia Rizki 0813 10837646 ( hanya melayani via pesan WA/SMS, jam
              pelayanan hari kerja 08.00-15.00 WIB)<br />
            </span>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="showAnnoncementModal = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

   

    <!-- Main -->
    <b-container>
      <div class="card">
        <div>
          <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
            <div>
              <div class="p-5">
                <div>
                  <div>
                    <div>
                      <div style="margin-bottom: 30px;display: flex;justify-content: space-between;">
                        <div style="display:flex;flex-direction: column;line-height:0">
                          <h3>Perubahan Data Sparing - Pengolahan Air Kembali</h3> 
                          <span><small></small></span>
                        </div>
                        <!-- <base-button
                          size="md"
                          type="secondary"
                          @click="showFormTitikPenataanModal"
                        >
                          <i class="ni ni-archive-2"></i>Tambah titik penaatan
                        </base-button> -->
                      </div>
                      <button class="btn btn-primary" @click="changeUpdateSection('umum')"><i class="ni ni-badge"></i> Perusahaan</button>
                      <button class="btn btn-primary" @click="changeUpdateSection('akun')"><i class="ni ni-badge"></i> Akun</button>
                      <button class="btn btn-primary" @click="changeUpdateSection('main')"><i class="ni ni-folder-17"></i> Waste Recycle</button>

                    </div>
                  </div>
                </div>

                <div v-if="updateSection == 'umum'">
                  <h3 class="my-5 text-center">Informasi Perusahaan</h3>

                    <div class="form-group row mt-5">
                        <label class="col-lg-3 col-form-label form-control-label">Nomor SIUP</label>
                        <div class="col-lg-9">
                          <base-input
                            name="Nomor SIUP"
                            v-model="form.umum.numberSIUP"
                            rules="required"
                          ></base-input>
                        </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label form-control-label">Permohonan Koneksi</label>
                          <div class="col-lg-9">
                            <base-input name="File Permohonan Koneksi">
                              <file-input
                                accept="application/pdf"
                                ref="company_connection_request_file"
                                @change="
                                  uploadFileBlob(formPublic,'company_connection_request_file')
                                "
                              ></file-input>
                            </base-input>
                            <small v-if="formPublic.company_connection_request_file" class="d-block mt-3 mb-2 text-justify">
                              <a :href="getBlobsFile(formPublic.company_connection_request_file)" target="_blank"
                                class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                            </small>

                            <small class="d-block text-justify">
                              Surat permohonan pendaftaran koneksi sparing
                              kepada Direktur Pengendalian Pencemaran Air.
                            </small>
                          </div>
                        </div>

                        <!-- 3. Nama Perusahaan -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Nama Perusahaan</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Nama Perusahaan"
                              rules="required"
                              v-model="form.umum.compName"
                            ></base-input>
                            <small class="d-block mt--4 text-justify">
                              Kata dan tanda baca wajib sama dengan Nama
                              Perusahaan pada SIMPEL PPA
                            </small>
                          </div>
                        </div>

                        <!-- 4. Jenis Industri -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Jenis Industri</label
                          >
                          <div class="col-lg-9">
                            <base-input name="Jenis Industri" rules="required">
                              <el-select v-model="form.umum.compType">
                                <el-option
                                  v-for="option in provideSelect.companyType"
                                  :key="option.id"
                                  :label="option.name"
                                  :value="option.id"
                                ></el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
                        <!-- jika Tambang -->
                        <div
                          v-if="checkCompType(form.umum.compType)"
                          class="p-3 card border-0"
                          style="background: #ededed"
                        >
                          <div
                            class="mb-5 card-header px-4"
                            style="background: #ededed"
                          >
                            <!-- Detail -->
                          </div>
                          <div class="card-body p-3">
                            <div class="form-group row">
                              <label
                                class="col-lg-3 col-form-label form-control-label"
                                >Status Tambang</label
                              >
                              <div class="col-lg-9">
                                <base-input
                                  name="Status Tambang"
                                  rules="required"
                                >
                                  <el-select
                                    v-model="form.umum.ifTambang.status"
                                  >
                                    <el-option
                                      label="Aktif"
                                      value="Aktif"
                                    ></el-option>
                                    <el-option
                                      label="Reklamasi"
                                      value="Reklamasi"
                                    ></el-option>
                                    <el-option
                                      label="Pasca Tambang"
                                      value="Pasca Tambang"
                                    ></el-option>
                                  </el-select>
                                </base-input>
                              </div>
                            </div>

                            <div class="form-group row">
                              <label
                                class="col-lg-3 col-form-label form-control-label"
                                >Jenis Kegiatan</label
                              >
                              <div class="col-lg-9">
                                <base-input
                                  name="Jenis Kegiatan"
                                  rules="required"
                                >
                                  <el-select
                                    v-model="form.umum.ifTambang.jenKegiatan"
                                  >
                                    <el-option
                                      label="Pengolahan"
                                      value="Pengolahan"
                                    ></el-option>
                                    <el-option
                                      label="Penambangan"
                                      value="Penambangan"
                                    ></el-option>
                                    <el-option
                                      label="Pengolahan & Penambangan"
                                      value="Pengolahan & Penambangan"
                                    ></el-option>
                                  </el-select>
                                </base-input>
                              </div>
                            </div>

                            <div class="form-group row">
                              <label
                                class="col-lg-3 col-form-label form-control-label"
                              >
                                Rencana penetapan titik penaatan
                                <i>(complience point)</i> sampai dengan 5 tahun
                                kedepan (dokumen berupa tabel perencanaan)
                              </label>
                              <div class="col-lg-9">
                                <base-input
                                  name="Rencana penetapan titik penaatan"
                                >
                                  <file-input
                                    accept="application/pdf"
                                    ref="company_mine_compliance_point_file"
                                    @change="
                                      uploadFileBlob(formPublic,
                                        'company_mine_compliance_point_file'
                                      )
                                    "
                                  ></file-input>
                                </base-input>
                                <small v-if="formPublic.company_mine_compliance_point_file"
                                    class="d-block mt-3 mb-2 text-justify">
                                    <a :href="getBlobsFile(formPublic.company_mine_compliance_point_file)"
                                      target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                </small>
                              </div>
                            </div>

                            <div class="form-group row">
                              <label
                                class="col-lg-3 col-form-label form-control-label"
                                >Kondisi jaringan internet/GSM pada lokasi
                                remotearea</label
                              >
                              <div class="col-lg-9">
                                <html-editor
                                  v-model="form.umum.ifTambang.kondisiInternet"
                                ></html-editor>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- End of Jika Tambang -->

                        <!-- 5. Nama penanggung Jawab SIUP -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Nama Penanggung Jawab SIUP</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Nama Penanggung Jawab SIUP"
                              rules="required"
                              v-model="form.umum.nameSIUP"
                            ></base-input>
                          </div>
                        </div>

                        <!-- 6. Lampiran SIUP -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Lampiran SIUP</label
                          >
                          <div class="col-lg-9">
                            <base-input name="Lampiran SIUP">
                              <file-input
                                accept="application/pdf"
                                ref="company_siup_file"
                                @change="uploadFileBlob(formPublic, 'company_siup_file')"
                              ></file-input>
                            </base-input>
                            <small v-if="formPublic.company_siup_file"
                                class="d-block mt-3 mb-2 text-justify">
                                <a :href="getBlobsFile(formPublic.company_siup_file)"
                                  target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                            </small>

                            <span v-if="form.umum.filedownload"
                              >Data anda Sebelumnya :
                              <a
                                :href="form.umum.filedownload"
                                target="blank"
                                style="color: blue"
                                >klik untuk mendownload</a
                              ></span
                            >
                          </div>
                        </div>

                        <!-- 7. Alamat perusahaan -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Alamat Perusahaan</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Alamat Perusahaan"
                              rules="required"
                              v-model="form.umum.compAddress"
                            ></base-input>
                            <small class="d-block mt--3 text-justify">
                              Bagi Industri yang memiliki alamat berbeda antara
                              kantor pusat dan kantor kegiatan (Unit
                              Bisnis/site), Mohon diisi alamat kantor unit
                              bisnis
                            </small>
                          </div>
                        </div>

                        <!-- 8. Jalan -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Jalan</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Jalan"
                              rules="required"
                              v-model="form.umum.compStreet"
                            ></base-input>
                          </div>
                        </div>

                        <!-- 9. Provinsi -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Provinsi</label
                          >
                          <div class="col-lg-9">
                            <base-input name="Provinsi" rules="required">
                              <el-select
                                v-model="form.umum.compProvince"
                                @change="
                                  getCityDepentOnProvince(
                                    form.umum.compProvince
                                  )
                                "
                                filterable
                              >
                                <el-option
                                  v-for="option in provideSelect.provinsi"
                                  :key="option.id"
                                  :label="option.name"
                                  :value="option.id"
                                ></el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>

                        <!-- 10. Kabupaten kota -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Kabupaten / Kota</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Kabupaten / Kota"
                              rules="required"
                            >
                              <el-select
                                v-model="form.umum.compCity"
                                filterable
                              >
                                <el-option
                                  v-for="option in provideSelect.kabupatenKota"
                                  :key="option.id"
                                  :label="option.name"
                                  :value="option.id"
                                ></el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>

                        <!-- 11. Telepon Kantor -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Telepon Kantor</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Telepon Kantor"
                              rules="required"
                              v-model="form.umum.compPhone"
                              type="tel"
                            />
                          </div>
                        </div>

                        <!-- 12. Email kantor -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Email Kantor</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Email Kantor"
                              rules="required"
                              v-model="form.umum.compMail"
                              type="email"
                              autocomplete="username email"
                            />
                          </div>
                        </div>

                       

                        <button class="btn btn-warning w-100 mt-5" @click="changeCompanyInformations">UBAH INFORMASI</button>

                        <!-- <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Confirm Password</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Confirm Password"
                              rules="required"
                              v-model="form.akun.personConfirmPassword"
                              type="password"
                            />
                          </div>
                        </div> -->
                </div>

                <div v-if="updateSection == 'akun'">
                  <h3 class="my-5 text-center">Informasi Pengguna</h3>
                    <div class="form-group row">
                      <label
                        class="col-lg-3 col-form-label form-control-label"
                        >Nama Kontak Person</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Nama Kontak Person"
                          rules="required"
                          v-model="form.akun.personName"
                        ></base-input>
                        <small class="d-block mt--3 text-justify">
                          Personil yang menangani pengolahan air limbah dan
                          terkait data SPARING
                        </small>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label
                        class="col-lg-3 col-form-label form-control-label"
                        >Nomor Handphone</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Nomor Handphone"
                          rules="required"
                          v-model="form.akun.personPhone"
                          type="tel"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label
                        class="col-lg-3 col-form-label form-control-label"
                        >Email Kontak Person</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Email Kontak Person"
                          rules="required"
                          v-model="form.akun.personMail"
                          type="email"
                          autocomplete="username email"
                        />
                        <base-input
                          name="Email Kontak Person ID"
                          type="hidden"
                          v-model="form.akun.personMailID"
                        ></base-input>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Password</label>
                      <div class="col-lg-7">
                        <base-input
                          name="Password"
                          rules="required"
                          v-model="form.akun.personPassword"
                          :type="isShow ? 'text':'password'"
                        />
                      </div>
                      <div class="col-lg-2">
                        <button class="btn btn-primary" @click="typePasswordButton">{{ isShow?'Hide':'Show' }}</button>
                      </div>
                    </div>

                    <button @click="changeAccountInformations()" class="btn btn-warning w-100 mt-5">UBAH INFORMASI</button>
                </div>
              
                <div v-if="updateSection == 'main'">
                    <h3 class="my-5 text-center">Waste Recycle</h3>
                    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                      <b-form role="form" @submit.prevent="handleSubmit(submitRegisterWaste)">
                        <!-- Debit Penggunaan air -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Penggunaan Air</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Penggunaan Air" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitPenggunaan.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Penggunaan Air" rules="required">
                              <el-select v-model="form.prosesProduksi.debitPenggunaan.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Penggunaan Air" placeholder="Keterangan (Proses dan Teknologi yang digunakan) " rules="required" v-model=" form.prosesProduksi.debitPenggunaan.keterangan"/>
                          </div>
                        </div>
    
    
                        <!-- Debit air limbah total -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Limbah Total</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Limbah Total" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitLimbahTotal.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Limbah Total" rules="required">
                              <el-select v-model="form.prosesProduksi.debitLimbahTotal.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Air Limbah Total" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitLimbahTotal.keterangan"/>
                          </div>
                        </div>
    
    
                        <!-- Debit air reuse -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Reuse</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Reuse" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitReuse.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Reuse" rules="required">
                              <el-select v-model="form.prosesProduksi.debitReuse.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Air Reuse" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitReuse.keterangan"/>
                          </div>
                        </div>
    
                        <!-- Debit air recycle -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Recycle</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Recycle" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitRecycle.value"></base-input>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Recycle" rules="required">
                              <el-select v-model="form.prosesProduksi.debitRecycle.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Air Recycle" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitRecycle.keterangan"/>
                          </div>
                        </div>
    
                        <!-- Debit air Recovery -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Recovery</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Recovery" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitRecovery.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Recovery" rules="required">
                              <el-select v-model="form.prosesProduksi.debitRecovery.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitRecovery.keterangan"/>
                          </div>
                        </div>
    
    
                        <!-- Debit air limbah diolah IPAL -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Limbah Diolah IPAL</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Limbah Diolah IPAL" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitOlahIPAL.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Limbah Diolah IPAL" rules="required">
                              <el-select v-model="form.prosesProduksi.debitOlahIPAL.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Air Limbah Diolah IPAL" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitOlahIPAL.keterangan"
                            />
                          </div>
                        </div>
    
                        <!-- Unggah Neraca air dan unggaj air limbah -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Unggah Neraca Air Dan Unggah Air Limbah</label>
                          <div class="col-lg-10">
                            <base-input  name="File Neraca Air Dan Unggah Air Limbah">
                              <file-input
                                accept="application/pdf"
                                ref="water_and_wastewater_balance_file"
                                @change="uploadFileBlob(file.pengolahan_kembali,'water_and_wastewater_balance_file')"
                              ></file-input>
                            </base-input>
                            <small v-if="file.pengolahan_kembali.water_and_wastewater_balance_file"
                                class="d-block mt-3 mb-2 text-justify">
                                <a :href="getBlobsFile(file.pengolahan_kembali.water_and_wastewater_balance_file)"
                                  target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                            </small>
                          </div>
                        </div>

                        <div style="display: flex;justify-content: center;">
                          <button class="btn btn-warning w-50 mt-5" type="submit">PERBAHARUI</button>
                        </div>
                      </b-form>
                    </validation-observer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
      
     
    </div>
  </template>
  
  <script>
import API from "@/api/base_url.js";
import FileInput from "@/components/Inputs/FileInput";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  Select,
  Option,
  Table,
  TableColumn,
  Collapse,
  CollapseItem,
} from "element-ui";
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import PendaftaranSparingTable from "../../Tables/PendaftaranSparing.vue";
import BaseHeader from "@/components/BaseHeader";
import SparingTmpTable from "../../Tables/SparingTmpTable.vue";
import SparingTmpPenaatanTable from "../../Tables/SparingTmpPenaatan.vue";
import SparingTmpDataLoggerTable from "../../Tables/SparingTmpDataLoggerTable.vue";
import { BasePagination } from "@/components";
import clientPaginationMixin from "../../Tables/PaginatedTables/clientPaginationMixin";
import moment from "moment";
import baseURL from "@/api/base_url_backend.js";


export default {
  name: "pendaftaranSparing",
  mixins: [clientPaginationMixin],
  components: {
    SparingTmpDataLoggerTable,
    SparingTmpTable,
    SparingTmpPenaatanTable,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    BaseHeader,
    FileInput,
    HtmlEditor,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    PendaftaranSparingTable,
    BasePagination,
  },
  mounted() {
    // this.getDataForOldLater();
    this.getCompanyType()
    this.getProvince()
    this.getExistData()
  },
  data() {
    return {
    wasteRecycleId:"",
    file: {
        pengolahan_kembali: {
        water_and_wastewater_balance_file: ''
        }
    },
    form_permits: {
      id:"",
      attachment_file_id: "",
      issuing_authority: "",
      no: "",
      publication_date: "",
      expiration_date: ""
    },
    formPublic: {
      company_connection_request_file:"",
      company_mine_compliance_point_file:"",
      company_siup_file: "",
    },
    isShow:false,
    allowedCompTypes: [
    "Pertambangan Emas dan Tembaga",
    "Pertambangan Batu Bara",
    "Pertambangan Nikel",
      ],
      station: {
        spot_name: "",
        waste_receiver: "",
        das: "",
        longitude: 0,
        latitude: 0,
        processing_technique: "",
        waste_type: "",
        debit_value: 0,
        debit_unit: "",
        capacity_permit: 0,
        capacity_value: 0,
        frequency: {
          bulan: 0,
          detail_eng_ipal: "",
          hari: 0,
          ipal_file_id: "",
          jam: 0,
          latar_belakang: "",
          logbook_file_id: "",
          metode_ukur: "",
          type: ""
        },
        tech_requirement: {
          dukungan_brand_file_id: "",
          info_alat: "",
          kalibrasi_awal_file_id: "",
          kalibrasi_rutin_file_id: "",
          kinerja_file_id: "",
          kontak_kerjasama_file_id: "",
          lulus_uji_file_id: "",
          nama_penyedia: "",
          no_surat: "",
          pernyataan_vendor_file_id: "",
          tipe_uji: ""
        },
        logger: {
          brand: "",
          mac: "",
          model: "",
          sn: "",
          sensors: Array.from({ length: 5 }, (_, index) => ({
            bmal_max: 0,
            bmal_measure: "",
            bmal_min: 0,
            bmal_unit: "",
            brand: "",
            brochure_file_id: "",
            calibration_date: "",
            calibration_method: "",
            calibration_schedule: "",
            is_active: false,
            max: 0,
            min: 0,
            probe: "",
            type: index === 0 ? "pH" : index === 1 ? "COD" : index === 2 ? "TSS" : index === 3 ? "NH3N" : "Debit"
          }))
        }
      },
      isHidden: null,
      mainIdWillUpdate: "",
      updateSection: "",
      dataExist: [],
      dataExistSensors: [],
      getStationForUpdate:"",
      newPayloadTmp: [],
      editLogger: false,
      editLoggerID: "",
      tableLoggerSensor: [],
      loggerRegistered: [],
      pendaftaranRubahSensorID: "",
      rubahSensorPendaftaran: false,
      populatedSensorExisting: [],
      fileSuratKeteranganLulusUji: 0,
      fileHasilKalibrasiSaatAwalPemasanganAlat: 0,
      fileHasilKalibrasiAlatRutin: 0,
      fileHasilUjiKinerjaDariLabotarium: 0,
      fileSuratDukunganBrandSensor: 0,
      fileSuratPernyataan: 0,
      fileKontakKerjaSama: 0,
      fileBrosur: 0,
      sensorTmpData: [],
      testingbro: "",
      getLoggerHasSubmit: [],
      getReturnedIdCreated: "",
      indexSensor: null,
      getFormSensor: null,
      loggerFormInput: new FormData(),
      sensorFormInput: new FormData(),
      // triggerModal
      showUpdateFormPenataanFormModal: false,
      showFormLoggerSensorFormModal: false,
      showFormLoggerSensorConnectingFormModal: false,
      showFormModal: false,
      showFormModalWithExistPermission: false,
      showMainWizardForm: false,
      showMainWizardPointForm: false,
      showSecondaryWizardForm: false,
      showThirdWizardForm: false,
      showFormPenataanFormModal: false,
      isLoading:false,
      // table temporary created
      tableTitikPenaatan: [
        {
          prop: "spotLoc[0]",
          label: "Lintang",
          minWidth: 150,
        },
        {
          prop: "spotLoc[1]",
          label: "Bujur",
          minWidth: 160,
        },
      ],

      // catch form temp table
      titikPenaatanPopUpForm: {
        namaTitikPenaatan: "",
        penerimaAirLimbah: "",
        das: "",
        lintang: "",
        bujur: "",
      },
      loggerSensorPopUpForm: {
        brand: "",
        model: "",
        sn: "",
        mac: "",
        lulusUji: "",
        infoAlat: "",
        namaPenyedia: "",
        noSurat: "",
        suratketerangan: "",
      },
      loggerSensorIndex: {},
      newsensor: [],
      // catch form temp edit table
      titikPenaatanPopUpEditForm: {
        id: "",
        spotName: "",
        spotLoc: "",
        penerimaAirLimbah: "",
        das: "",
      },
      // main payload
      sensorsInit: Array.from({ length: 5 }, (_, index) => ({
            bmal_max: 0,
            bmal_measure: "",
            bmal_min: 0,
            bmal_unit: "",
            brand: "",
            brochure_file_id: "",
            calibration_date: new Date(),
            calibration_method: "",
            calibration_schedule: "",
            is_active: false,
            max: 0,
            min: 0,
            probe: "",
            type: index === 0 ? "pH" : index === 1 ? "COD" : index === 2 ? "TSS" : index === 3 ? "NH3N" : "Debit"
        })),
      form: {
        user: "",
        akun: {
          personName: null,
          personAddress: "",
          personPhone: null,
          personMail: null,
          personMailID: null,
          personPassword: null,
          personConfirmPassword: null,
        },
        umum: {
          requestConnect: "",
          filedownload: "",
          compName: null,
          compNameID: null,
          compType: null,
          compAddress: null,
          compStreet: null,
          compCity: null,
          compProvince: null,
          compPhone: null,
          compMail: null,
          numberSIUP: null,
          nameSIUP: null,
          ifTambang: {
            status: "",
            jenKegiatan: "",
            kondisiInternet: "",
          },
          rencanaFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          siupFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          compPermohonanKoneksi: {
            path: "",
            upload: 0,
            progress: 0,
          },
          updatedAt: 0,
          createdAt: null,
        },
        uid: "",
        prosesProduksi: {
          debitPenggunaan: {
            value: null,
            satuan: null,
            keterangan: null,
          },
          debitLimbahTotal: {
            value: null,
            satuan: null,
            keterangan: null,
          },
          debitReuse: {
            value: null,
            satuan: null,
            keterangan: null,
          },
          debitRecycle: {
            value: null,
            satuan: null,
            keterangan: null,
          },
          debitRecovery: {
            value: null,
            satuan: null,
            keterangan: null,
          },
          debitOlahIPAL: {
            value: null,
            satuan: null,
            keterangan: null,
          },
          fileNeracaAirLimbah: {
            path: "",
            upload: 0,
            progress: 0,
          },
        },
        teknis: {
          permitFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          uuid: "1234-5678-90",
          permitPerson: null,
          permitNumber: null,
          permitDatePublish: this.getFormayYear(new Date()),
          permitDateExpire: this.getFormayYear(new Date()),
          sparing_air_limbah_file:"",
          wasteSource: null,
          spotCount: null,
          spotList: [],
          processingTechnique: null,
          wasteType: null,
          debit: null,
          satuanDebit: "m3/detik",
          capacityPermit: null,
          capacityActual: null,
          frequenceDet: "",
        },
        validitas: {
          infoAlat: null,
          lulusUji: null,
          namaPenyedia: null,
          noSurat: null,
          jadwalKalibrasi: null,
          kontrak: {
            path: "",
            upload: 0,
            progress: 0,
          },
          kinerjaFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          konektifitasFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          kalibrasiFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          kalibrasiRutinFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          validitasFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
        },
        frekuensi: {
          latarBelakang: "",
          detEngIPAL: "",
          fotoIPAL: {
            path: "",
            upload: 0,
            progress: 0,
          },
          jam: null,
          hari: null,
          bulan: null,
          metUkurDebit: "",
          logBook: {
            path: "",
            upload: 0,
            progress: 0,
          },
        },
        logger: [],
        sensor: [
          {
            name: "pH",
            label: "pH",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "COD",
            label: "COD",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "TSS",
            label: "TSS",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "NH3N",
            label: "NH3N",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "Debit",
            label: "Debit",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
        ],
        sensorDone: false,
        surat: {
          dukunganBrand: {
            path: "",
            upload: 0,
            progress: 0,
          },
          pernyataanVendor: {
            path: "",
            upload: 0,
            progress: 0,
          },
        },
      },
      // createPermissionLater
      permissionLaterForm: new FormData(),
      permissionLaterFormExisting: [],
      permissionLaterFormExistingSelected: "",
      // another
      debits: [],
      selectedRows: [],
      totalCount: 0,
      activeName: 1,
      isFormSuratIzinShow: false,
      selects: {
        configs: {
          flatpickr: {
            allowInput: true,
            altFormat: "Y-m-d",
            altInput: false,
            dateFormat: "Y-m-d",
            mode: "single",
          },
        },
        options: {
          role: [
            {
              label: "Pusat",
              value: "admin",
            },
            {
              label: "Provinsi",
              value: "prov",
            },
            {
              label: "Kab/Kot",
              value: "kabkot",
            },
            {
              label: "Industri",
              value: "comp",
            },
          ],
          type: [
            { name: "Semua", value: "" },
            { name: "Industri Rayon", value: "Industri Rayon" },
            { name: "Industri Pulp/Kertas", value: "Industri Pulp/Kertas" },
            {
              name: "Industri Petrokimia Hulu",
              value: "Industri Petrokimia Hulu",
            },
            {
              name: "Industri Oleokimia Dasar",
              value: "Industri Oleokimia Dasar",
            },
            { name: "Industri Minyak Sawit", value: "Industri Minyak Sawit" },
            { name: "Pengolahan Minyak Bumi", value: "Pengolahan Minyak Bumi" },
            {
              name: "Eksplorasi dan Produksi Migas",
              value: "Eksplorasi dan Produksi Migas",
            },
            {
              name: "Pertambangan Emas dan Tembaga",
              value: "Pertambangan Emas dan Tembaga",
            },
            { name: "Pertambangan Batu Bara", value: "Pertambangan Batu Bara" },
            { name: "Industri Tekstil", value: "Industri Tekstil" },
            { name: "Pertambangan Nikel", value: "Pertambangan Nikel" },
            { name: "Kawasan Industri", value: "Kawasan Industri" },
          ],
          prov: [],
          city: [],
          comp: [
            {
              label: "Semua",
              value: 0,
            },
            {
              label: "Alpha",
              mail: "alpha",
              value: 1,
            },
            {
              label: "Bravo",
              mail: "bravo",
              value: 2,
            },
            {
              label: "Charlie",
              mail: "charlie",
              value: 3,
            },
            {
              label: "Delta",
              mail: "delta",
              value: 4,
            },
          ],
          emsg: [
            "Data sensor pH melebihi ambang batas!",
            "Data sensor COD melebihi ambang batas!",
            "Data sensor TSS melebihi ambang batas!",
            "Data sensor NH3N melebihi ambang batas!",
            "Data sensor debit melebihi ambang batas!",
          ],
          parameter: [
            {
              label: "pH",
              value: "ph",
            },
            {
              label: "COD",
              value: "cod",
            },
            {
              label: "TSS",
              value: "tss",
            },
            {
              label: "NH3N",
              value: "nh3n",
            },
            {
              label: "Debit",
              value: "debit",
            },
          ],
        },
        type: null,
        prov: null,
        city: null,
        comp: null,
        time: null,
      },
      provideSelect: {
        provinsi: [],
        kabupatenKota: [],
        daftarPerusahaan: [],
        tmp: [],
        uploadChoosen: null,
      },
      showFormRegisterNormally: false,
      showFormRegisterWaste: false,
      cekStatusForm: false,
      isNormalOrWaste: false,
      showForm: false,
      showAnnoncementModal: false,
      showAllButtonModal: false,
      isShowModalForceUpdate: false,
      showNoteModal: false,
      updateNote: "",
      forceUpdateData: null,
      edit: false,
      editID: "",
      isEditData: 0,
      isSectionPendaftaran: 0,
      uploading: false,
      isDataCorrect: true,
      res: { comp: null, user: null, logger: null },
      temptSelect: null,
      debitUnit: ["m3/detik", "m3/menit", "m3/jam", "m3/hari", "m3/bulan"],
      countTitik: 1,
      countWizard: 1,
      formData: new FormData(),
      formDataSparing: new FormData(),
    };
  },
  methods: {
    changeCompanyInformations() {
      let payload = {
        address: this.form.umum.compAddress,
        email: this.form.umum.compMail,
        kabkot_id: this.form.umum.compCity,
        koneksi_file_id: this.formPublic.company_connection_request_file,
        mine: {
          activity_type: this.form.umum.ifTambang.jenKegiatan,
          network_cond: this.form.umum.ifTambang.kondisiInternet,
          rencana_file_id: this.formPublic.company_mine_compliance_point_file,
          status: this.form.umum.ifTambang.status
        },
        name: this.form.umum.compName,
        phone: this.form.umum.compPhone,
        prov_id: this.form.umum.compProvince,
        siup_file_id: this.formPublic.company_siup_file,
        siup_no: this.form.umum.numberSIUP,
        siup_pj: this.form.umum.nameSIUP,
        street: this.form.umum.compStreet,
        type_id: this.form.umum.compType,
        user_id: this.form.user
      }

      Swal.fire({
        title: "Perbaharui Informasi Perusahaan?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "PERBAHARUI",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          API.put(`auth/register/sparing/comp/${this.form.umum.compNameID}`, payload, { headers })
            .then(({ data }) => {
              Swal.fire("Memperbaharui Data Berhasil","Data Informasi Perusahaan Berhasil diperbaharui","success");
              this.$router.push("/sparing/pendaftaran-pengolahan-air-kembali");
            })
            .catch((err) => {
              Swal.fire("Gagal Memperbaharui", "terjadi kesalahan", "error");
            });
        }
      });
    },
    changeAccountInformations() {
      let payload = {
        email: this.form.akun.personMail,
        name: this.form.akun.personName,
        password: this.form.akun.personPassword,
        phone: this.form.akun.personPhone
      }

      Swal.fire({
        title: "Perbaharui Informasi Akun?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "PERBAHARUI",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          API.put(`auth/register/sparing/user/${this.form.user}`, payload, { headers })
            .then(({ data }) => {
              Swal.fire("Memperbaharui Data Berhasil","Data Informasi Akun Berhasil diperbaharui","success");
              this.$router.push("/sparing/pendaftaran-pengolahan-air-kembali");
            })
            .catch((err) => {
              Swal.fire("Gagal Memperbaharui", "terjadi kesalahan", "error");
            });
        }
      });
    },
    submitRegisterWaste() {
        Swal.fire({
          title: "Perbaharui Pemanfaatan air kembali?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "KIRIM",
          cancelButtonText: "BATAL"
        }).then((result) => {
          if (result.isConfirmed) {
            let payload = {
                attachment_file_id: this.file.pengolahan_kembali.water_and_wastewater_balance_file,
                debit_limbah_total: {
                  desc: this.form.prosesProduksi.debitLimbahTotal.keterangan ,
                  unit: this.form.prosesProduksi.debitLimbahTotal.satuan,
                  value: +this.form.prosesProduksi.debitLimbahTotal.value
                },
                debit_olah_ipal: {
                  desc: this.form.prosesProduksi.debitOlahIPAL.keterangan,
                  unit: this.form.prosesProduksi.debitOlahIPAL.satuan,
                  value: +this.form.prosesProduksi.debitOlahIPAL.value
                },
                debit_penggunaan: {
                  desc: this.form.prosesProduksi.debitPenggunaan.keterangan,
                  unit: this.form.prosesProduksi.debitPenggunaan.satuan,
                  value: +this.form.prosesProduksi.debitPenggunaan.value
                },
                debit_recovery: {
                  desc: this.form.prosesProduksi.debitRecovery.keterangan,
                  unit: this.form.prosesProduksi.debitRecovery.satuan,
                  value: +this.form.prosesProduksi.debitRecovery.value
                },
                debit_recycle: {
                  desc: this.form.prosesProduksi.debitRecycle.keterangan,
                  unit: this.form.prosesProduksi.debitRecycle.satuan ,
                  value: +this.form.prosesProduksi.debitRecycle.value 
                },
                debit_reuse: {
                  desc: this.form.prosesProduksi.debitReuse.keterangan,
                  unit: this.form.prosesProduksi.debitReuse.satuan ,
                  value: +this.form.prosesProduksi.debitReuse.value
                },
                user_id: this.form.user
            }

            let headers = {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }

            console.log(payload);

            API.put(`auth/register/waste-recycle/${this.$route.params.id}`, payload, { headers })
              .then(({data:content}) => {
                Swal.fire('Data Berhasil diperbaharui', '', 'success')
                this.resetForm()
                this.$router.push("/sparing/pendaftaran-pengolahan-air-kembali");

                this.formPublic = {
                  company_connection_request_file:"",
                  company_mine_compliance_point_file:"",
                  company_siup_file: "",
                }
              })
              .catch((err) => {
                Swal.fire('Pendaftaran Gagal', 'gagal mendaftarkan', 'error')
              })
          }
        });
      },
    // end of action

    typePasswordButton() {
      this.isShow = !this.isShow 
    },
    getProvince() {
        API.get(`provinces`)
          .then(({ data: content }) => {
          this.provideSelect.provinsi = content.data
          })
          .catch((err) => {
            Swal.fire("Gagal Mendapatkan Data Provinsi", "", "error");
          });
      },
      getCityDepentOnProvince(data) {
        this.form.umum.compCity = []
        this.provideSelect.kabupatenKota = [];
        API.get(`kabkots?prov_id=${data}`)
          .then(({ data: content }) => {
            this.provideSelect.kabupatenKota = content.data;
          })
          .catch((err) => {
            console.log(err);
          });
      },
    getCompanyType() {
        API.get(`comp-types`)
          .then(({ data: content }) => {
          this.provideSelect.companyType = content.data
          console.log(content.data);
          })
          .catch((err) => {
            Swal.fire("Gagal Mendapatkan Jenis Perusahaan", "", "error");
          });
      },
    checkCompType(compType) {
        // Ambil label berdasarkan compType
        const label = this.provideSelect.companyType.find(
          (option) => option.id === compType
        )?.name;
        if (label) {
          console.log(label);
          return this.allowedCompTypes.includes(label);
        }
      },
    // start upload
    uploadFileBlob(form, field) {
          const fileInput = this.$refs[field];
          const file = fileInput.files[0];

          const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
          if (!allowedTypes.includes(file.type)) {
            Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
            return;
          }

          const maxSize = 5 * 1024 * 1024;
          if (file.size > maxSize) {
            Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
            return;
          }

          const formData = new FormData();
          formData.append("file", file);
          this.isLoading = true;
          API.post(`blobs/upload/public`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((res) => {
              let data = res.data.data;
              let value = data.id;
              this.$set(form, field, value);
              this.isLoading = false;
            })
            .catch((err) => {
              this.isLoading = false;
              Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
            });
    },
    uploadPermitFileBlob(form, field) {

      // ${defaults.baseURL}/file
      const fileInput = this.$refs[field];
      const file = fileInput.files[0];

      // Validasi jenis file (hanya PDF dan gambar)
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
        return;
      }

      // Validasi ukuran file (tidak lebih dari 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB dalam bytes
      if (file.size > maxSize) {
        Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
        return;
      }


      const formData = new FormData();
      formData.append("file", file);
      this.isLoading = true;
      API.post(`blobs/upload/public`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          let data = res.data.data;
          let value = data.id;
          this.$set(form, field, value);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
        });
      },
    uploadFileSensorBlob(form,field,ref) {
      // ${defaults.baseURL}/file
      const fileInput = this.$refs[ref][0];

      const file = fileInput.files[0];

      // Validasi jenis file (hanya PDF dan gambar)
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
        return;
      }

      // Validasi ukuran file (tidak lebih dari 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB dalam bytes
      if (file.size > maxSize) {
        Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
        return;
      }


      const formData = new FormData();
      formData.append("file", file);
      this.isLoading = true;
      API.post(`blobs/upload/public`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          let data = res.data.data;
          let value = data.id;
          this.$set(form, field, value);
          console.log(this.$set(form, field, value))
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
        });
    },
    getBlobsFile(filename) {
      let url = `${baseURL}blobs/download/${filename}`
      return url
    },
    resetAllFileInputs(refs) {
      refs.forEach(ref => this.resetFileInput(ref));
    },
    resetForm(formData) {
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          if (typeof formData[key] === 'object' && formData[key] !== null) {
            if (formData[key].hasOwnProperty('path')) {
              formData[key].path = "";
            } else if (formData[key].hasOwnProperty('file')) {
              formData[key].file = null;
            } else {
              this.resetForm(formData[key]); // Panggil secara rekursif untuk objek dalamnya
            }
          } else {
            formData[key] = ""; // Atur ulang nilai properti jika bukan objek
          }
        }
      }
    },
    resetFileInput(ref) {
    if (this.$refs[ref]) {
      // this.$refs.lulus_uji_file_id.files = []
      this.$refs[ref].files = []
      const inputElement = this.$refs[ref].$el.querySelector('input[type="file"]');
      inputElement.value = ''
    }
    },
    resetAllFileInputs(refs) {
      refs.forEach(ref => this.resetFileInput(ref));
    },
    // end of upload
    toggleAccordion(type, id, index) {
        // this.getDataTitikPenaatanDetail(id)
        if (type == 'Logger') {
          this.isOpenLogger = !this.isOpenLogger
          this.isOpenSensor = !this.isOpenSensor
        } 

        if (this.isHidden == null) {
          console.log(index);
          this.isHidden = index
        } else {
          this.isHidden = null
        }
      },
    changeUpdateSection(val) {
      this.updateSection = val
    },
    onUpdateSparing() {
      console.log('update');
      Swal.fire({
        title: "Ubah Data?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "UBAH",
        cancelButtonText: "BATAL"
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          let payload = {

          }

          API.put(`sparing/${this.$route.params.id}`, payload, { headers })
          .then(({ data: content }) => {
            Swal.fire("Perubahan Data Berhasil", "", "success");
            this.$router.push("/sparing");
          })
          .catch((err) => {
            Swal.fire("Gagal Merubah Data", "", "error");
          });
        }
      });
    },
    getExistData() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(`auth/register/waste-recycle/${this.$route.params.id}`, { headers })
      .then(({ data: content }) => {
        this.getCityDepentOnProvince(content.data.company.prov_id)

        this.form.user = content.data.user.id
        this.wasteRecycleId = this.$route.params.id
        this.form.akun = {
          personName: content.data.user.name,
          personAddress: "",
          personPhone: content.data.user.phone,
          personMail: content.data.user.email,
          personMailID: null,
          personPassword: content.data.user.password,
          personConfirmPassword: null,
        }

        this.form.umum = {
          requestConnect: "",
          filedownload: "",
          compName: content.data.company.name,
          compNameID: content.data.company.id,
          compType: content.data.company.type_id,
          compAddress: content.data.company.address,
          compStreet: content.data.company.street,
          compCity: content.data.company.kabkot_id,
          compProvince: content.data.company.prov_id,
          compPhone: content.data.company.phone,
          compMail: content.data.company.email,
          numberSIUP: content.data.company.siup_no,
          nameSIUP: content.data.company.siup_pj,
          ifTambang: {
            status: content.data.company.mine.status,
            jenKegiatan: content.data.company.mine.activity_type,
            kondisiInternet: content.data.company.mine.network_cond,
          },
          rencanaFile: {
            path: content.data.company.mine.rencana_file_id,
            upload: 0,
            progress: 0,
          },
          siupFile: {
            path: content.data.company.siup_file_id,
            upload: 0,
            progress: 0,
          },
          compPermohonanKoneksi: {
            path: content.data.company.koneksi_file_id,
            upload: 0,
            progress: 0,
          },
          updatedAt: 0,
          createdAt: null,
        },

        this.formPublic = {
          company_connection_request_file:content.data.company.koneksi_file_id,
          company_mine_compliance_point_file:content.data.company.mine.rencana_file_id,
          company_siup_file: content.data.company.siup_file_id,
        }

        this.form.prosesProduksi = {
          debitPenggunaan: {
            value: content.data.waste_recycle.debit_penggunaan.value,
            satuan: content.data.waste_recycle.debit_penggunaan.unit,
            keterangan: content.data.waste_recycle.debit_penggunaan.desc,
          },
          debitLimbahTotal: {
            value: content.data.waste_recycle.debit_limbah_total.value,
            satuan: content.data.waste_recycle.debit_limbah_total.unit,
            keterangan: content.data.waste_recycle.debit_limbah_total.desc,
          },
          debitReuse: {
            value: content.data.waste_recycle.debit_reuse.value,
            satuan: content.data.waste_recycle.debit_reuse.unit,
            keterangan: content.data.waste_recycle.debit_reuse.desc,
          },
          debitRecycle: {
            value: content.data.waste_recycle.debit_recycle.value,
            satuan: content.data.waste_recycle.debit_recycle.unit,
            keterangan: content.data.waste_recycle.debit_recycle.desc,
          },
          debitRecovery: {
            value: content.data.waste_recycle.debit_recovery.value,
            satuan: content.data.waste_recycle.debit_recovery.unit,
            keterangan: content.data.waste_recycle.debit_recovery.desc,
          },
          debitOlahIPAL: {
            value: content.data.waste_recycle.debit_olah_ipal.value,
            satuan: content.data.waste_recycle.debit_olah_ipal.unit,
            keterangan: content.data.waste_recycle.debit_olah_ipal.desc,
          },
          fileNeracaAirLimbah: {
            path: content.data.waste_recycle.attachment_file_id,
            upload: 0,
            progress: 0,
          },
        }

        this.file.pengolahan_kembali.water_and_wastewater_balance_file = content.data.waste_recycle.attachment_file_id
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Gagal Memuat Data", "", "error");
      });
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formatDatePayload(val) {
      const time = moment(val).format('YYYY-MM-DD');
      return time;
    },
    submitStation() {

      Swal.fire({
        title: "Simpan Titik Penaatan ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "SIMPAN",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {

          let tmpSensor = []
          for (let i = 0; i < this.form.sensor.length; i++) {
            tmpSensor.push({
              bmal_max: +this.form.sensor[i].maxMeasure,
              bmal_measure: this.form.sensor[i].metodePengukuran,
              bmal_min: +this.form.sensor[i].minMeasure,
              bmal_unit: this.form.sensor[i].satuanDebit,
              brand: this.form.sensor[i].brand,
              brochure_file_id: "/storage/2020-09-17T09:08:10.726ZBrosur pH and ORP.pdf",
              calibration_date: this.formatDatePayload(this.form.sensor[i].tanggalKalibrasi),
              calibration_method: this.form.sensor[i].metodeKalibrasi,
              calibration_schedule: +this.form.sensor[i].jadwalKalibrasi,
              is_active: this.form.sensor[i].active ? 1 : 0,
              max: +this.form.sensor[i].max,
              min: +this.form.sensor[i].min,
              probe: this.form.sensor[i].probe,
              type: this.form.sensor[i].label
            })
            
          }


          this.newPayloadTmp.push(
            {
              namaTitikPenaatan: this.titikPenaatanPopUpForm.namaTitikPenaatan,
              wasteSource: this.form.teknis.wasteSource,
              namaTitikPenaatan: this.titikPenaatanPopUpForm.namaTitikPenaatan,
              lintang: this.titikPenaatanPopUpForm.lintang,
              bujur: this.titikPenaatanPopUpForm.bujur,
              das: this.titikPenaatanPopUpForm.das,
              processingTechnique: this.form.teknis.processingTechnique,
              wasteType: this.form.teknis.wasteType,
              debit: this.form.teknis.debit,
              satuanDebit: this.form.teknis.satuanDebit,
              capacityPermit: this.form.teknis.capacityPermit,
              capacityActual: this.form.teknis.capacityActual,
              frequenceDet: this.form.teknis.frequenceDet,
              jam: this.form.frekuensi.jam,
              minggu: this.form.frekuensi.hari,
              bulan: this.form.frekuensi.bulan,
              latarBelakang: this.form.frekuensi.latarBelakang,
              detEngIPAL: this.form.frekuensi.detEngIPAL,
              metUkurDebit: this.form.frekuensi.metUkurDebit,
              lulusUji: this.loggerSensorPopUpForm.lulusUji,
              noSurat: this.loggerSensorPopUpForm.noSurat,
              namaPenyedia: this.loggerSensorPopUpForm.namaPenyedia,
              infoAlat: this.loggerSensorPopUpForm.infoAlat,
              brand: this.loggerSensorPopUpForm.brand,
              model: this.loggerSensorPopUpForm.model,
              mac: this.loggerSensorPopUpForm.mac,
              sn: this.loggerSensorPopUpForm.sn,
              sensor: tmpSensor
            }
          )

        this.resetForm()        

        this.showFormPenataanFormModal = false
        Swal.fire({
          title: "Berhasil",
          text: "titik penaatan disimpan",
          icon: "success",
        });
        }
      });
    },
    updateStation() {
      let indexToUpdate = this.newPayloadTmp.findIndex(
        (tableRow) => tableRow.namaTitikPenaatan === this.getStationForUpdate
      );


      Swal.fire({
        title: "Perbaharui Titik Penaatan ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "PERBAHARUI",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
              this.newPayloadTmp[indexToUpdate].namaTitikPenaatan = this.titikPenaatanPopUpForm.namaTitikPenaatan,
              this.newPayloadTmp[indexToUpdate].wasteSource = this.form.teknis.wasteSource,
              this.newPayloadTmp[indexToUpdate].namaTitikPenaatan = this.titikPenaatanPopUpForm.namaTitikPenaatan,
              this.newPayloadTmp[indexToUpdate].lintang = this.titikPenaatanPopUpForm.lintang,
              this.newPayloadTmp[indexToUpdate].bujur = this.titikPenaatanPopUpForm.bujur,
              this.newPayloadTmp[indexToUpdate].das = this.titikPenaatanPopUpForm.das,
              this.newPayloadTmp[indexToUpdate].processingTechnique = this.form.teknis.processingTechnique,
              this.newPayloadTmp[indexToUpdate].wasteType = this.form.teknis.wasteType,
              this.newPayloadTmp[indexToUpdate].debit = this.form.teknis.debit,
              this.newPayloadTmp[indexToUpdate].satuanDebit = this.form.teknis.satuanDebit,
              this.newPayloadTmp[indexToUpdate].capacityPermit = this.form.teknis.capacityPermit,
              this.newPayloadTmp[indexToUpdate].capacityActual = this.form.teknis.capacityActual,
              this.newPayloadTmp[indexToUpdate].frequenceDet = this.form.teknis.frequenceDet,
              this.newPayloadTmp[indexToUpdate].jam = this.form.frekuensi.jam,
              this.newPayloadTmp[indexToUpdate].minggu = this.form.frekuensi.minggu,
              this.newPayloadTmp[indexToUpdate].bulan = this.form.frekuensi.bulan,
              this.newPayloadTmp[indexToUpdate].latarBelakang = this.form.frekuensi.latarBelakang,
              this.newPayloadTmp[indexToUpdate].detEngIPAL = this.form.frekuensi.detEngIPAL,
              this.newPayloadTmp[indexToUpdate].metUkurDebit = this.form.frekuensi.metUkurDebit,
              this.newPayloadTmp[indexToUpdate].lulusUji = this.loggerSensorPopUpForm.lulusUji,
              this.newPayloadTmp[indexToUpdate].noSurat = this.loggerSensorPopUpForm.noSurat,
              this.newPayloadTmp[indexToUpdate].namaPenyedia = this.loggerSensorPopUpForm.namaPenyedia,
              this.newPayloadTmp[indexToUpdate].infoAlat = this.loggerSensorPopUpForm.infoAlat,
              this.newPayloadTmp[indexToUpdate].brand = this.loggerSensorPopUpForm.brand,
              this.newPayloadTmp[indexToUpdate].model = this.loggerSensorPopUpForm.model,
              this.newPayloadTmp[indexToUpdate].mac = this.loggerSensorPopUpForm.mac,
              this.newPayloadTmp[indexToUpdate].sn = this.loggerSensorPopUpForm.sn,
              this.newPayloadTmp[indexToUpdate].sensor = this.form.sensor

              // NANTI BALIK KESINI YA

          this.showFormPenataanFormModal = false
          Swal.fire({
            title: "Berhasil",
            text: "Berhasil Memperbaharui titik penaatan",
            icon: "success",
          });
        }
      });
    },
    populateUpdateStation(val) {
          this.showFormPenataanFormModal = true
          this.getStationForUpdate = val.namaTitikPenaatan

          this.titikPenaatanPopUpForm.namaTitikPenaatan = val.namaTitikPenaatan
          this.titikPenaatanPopUpForm.lintang = val.lintang
          this.titikPenaatanPopUpForm.bujur = val.bujur
          this.titikPenaatanPopUpForm.das = val.das
          this.form.teknis.processingTechnique = val.processingTechnique
          this.form.teknis.wasteType = val.wasteType
          this.form.teknis.debit = val.debit
          this.form.teknis.satuanDebit = val.satuanDebit
          this.form.teknis.capacityPermit = val.capacityPermit
          this.form.teknis.capacityActual = val.capacityActual
          this.form.teknis.frequenceDet = val.frequenceDet
          this.form.frekuensi.jam = val.jam
          this.form.frekuensi.minggu = val.minggu
          this.form.frekuensi.bulan = val.bulan
          this.form.frekuensi.latarBelakang = val.latarBelakang
          this.form.frekuensi.detEngIPAL = val.detEngIPAL
          this.form.frekuensi.metUkurDebit = val.metUkurDebit
          this.loggerSensorPopUpForm.lulusUji = val.lulusUji
          this.loggerSensorPopUpForm.noSurat = val.noSurat
          this.loggerSensorPopUpForm.namaPenyedia = val.namaPenyedia
          this.loggerSensorPopUpForm.infoAlat = val.infoAlat
          this.loggerSensorPopUpForm.brand = val.brand
          this.loggerSensorPopUpForm.model = val.model
          this.loggerSensorPopUpForm.mac = val.mac
          this.loggerSensorPopUpForm.sn = val.sn
    },
    finalSubmit(id) {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      if (this.updateSection == 'main') {
        console.log('main', id);
        Swal.fire({
        title: `Perbaharui Surat Izin ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "YA",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            detail: {
              permit_id: this.permissionLaterFormExistingSelected,
            }
          }

          API.put(`sparing/${id}`,  payload, { headers })
          .then(({ data: content }) => {
            Swal.fire({title: "Berhasil Memperbaharui Surat Izin",text: "",icon: "success"});
            this.getExistData()
            this.updateSection = ""
            this.$router.push('/sparing')
          })
          .catch((err) => {
            console.log(err);
            Swal.fire("Memperbaharui Data Gagal","","error");
          });
        }
      });
      }

      if (this.updateSection == 'station') {
        Swal.fire({
        title: `Perbaharui Titik Penaatan ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "YA",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            capacity_permit: +id.capacity_permit,
            capacity_value: +id.capacity_value,
            das: id.das,
            debit_unit: id.debit_unit,
            debit_value: +id.debit_value,
            frequency: {
              bulan: +id.frequency.bulan,
              detail_eng_ipal: id.frequency.detail_eng_ipal,
              hari: +id.frequency.hari,
              ipal_file_id: id.frequency.ipal_file_id,
              jam: +id.frequency.jam,
              latar_belakang: id.frequency.latar_belakang,
              logbook_file_id: id.frequency.logbook_file_id,
              metode_ukur: id.frequency.metode_ukur,
              type: id.frequency.type
            },
            tech_requirement:{
              dukungan_brand_file_id:id.tech_requirement.dukungan_brand_file_id,
              info_alat:id.tech_requirement.info_alat,
              kalibrasi_awal_file_id: id.tech_requirement.kalibrasi_awal_file_id,
              kalibrasi_rutin_file_id: id.tech_requirement.kalibrasi_rutin_file_id,
              kinerja_file_id: id.tech_requirement.kinerja_file_id,
              kontak_kerjasama_file_id: id.tech_requirement.kontak_kerjasama_file_id,
              lulus_uji_file_id: id.tech_requirement.lulus_uji_file_id,
              nama_penyedia: id.tech_requirement.nama_penyedia,
              no_surat: id.tech_requirement.no_surat,
              pernyataan_vendor_file_id: id.tech_requirement.pernyataan_vendor_file_id,
              tipe_uji: id.tech_requirement.tipe_uji
            },
            latitude: +id.latitude,
            longitude: +id.longitude,
            processing_technique: id.processing_technique,
            spot_name: id.spot_name,
            waste_receiver: id.waste_receiver,
            waste_source: id.waste_source,
            waste_type: id.waste_type
          }

          API.put(`stations/${id.id}`,  payload, { headers })
          .then(({ data: content }) => {
            Swal.fire({title: "Berhasil Memperbaharui Titik Penaatan",text: "",icon: "success"});
            this.getExistData()
            this.updateSection = ""
            this.$router.push('/sparing')
          })
          .catch((err) => {
            console.log(err);
            Swal.fire("Memperbaharui Data Gagal","","error");
          });
        }
      });
      }

      if (this.updateSection == 'logger') {
        console.log(id);
        Swal.fire({
        title: `Perbaharui Logger ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "YA",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            brand: id.brand,
            mac: id.mac,
            model: id.model,
            sn: id.sn,
          }

          API.put(`loggers/${id.id}`,  payload, { headers })
          .then(({ data: content }) => {
            Swal.fire({title: "Berhasil Memperbaharui Logger",text: "",icon: "success"});
            this.getExistData()
            this.updateSection = ""
            this.$router.push('/sparing')
          })
          .catch((err) => {
            console.log(err);
            Swal.fire("Memperbaharui Data Gagal","","error");
          });
        }
      });
      }

      if (this.updateSection == 'sensor') {
        Swal.fire({
        title: `Perbaharui Sensor ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "YA",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {

          id.sensors.forEach(sensor => {
              sensor.bmal_min = Number(sensor.bmal_min);
              sensor.bmal_max = Number(sensor.bmal_max);
              sensor.min = Number(sensor.min);
              sensor.max = Number(sensor.max);
              sensor.calibration_schedule = Number(sensor.calibration_schedule)
              sensor.is_active = sensor.is_active ? true : false 
          });

          
          const modifiedSensors = id.sensors.map(sensor => {
              // Convert is_active from boolean to number
              return {
                  ...sensor,
                  is_active: sensor.is_active ? 1 : 0
              };
          });
          
        
          let payload = {
            sensors:  modifiedSensors
          }

          API.put(`loggers/${id.id}`,  payload, { headers })
          .then(({ data: content }) => {
            Swal.fire({title: "Berhasil Memperbaharui Sensor",text: "",icon: "success"});
            this.updateSection = ""
            this.getExistData()
            this.$router.push('/sparing')
          })
          .catch((err) => {
            console.log(err);
            Swal.fire("Memperbaharui Data Gagal","","error");
          });
        }
      });
      }


      // old
      // let stationContent = []
      // for (let i = 0; i < this.newPayloadTmp.length; i++) {
      //   stationContent.push({
      //     capacity_permit: +this.newPayloadTmp[i].capacityPermit,
      //     capacity_value: +this.newPayloadTmp[i].capacityActual,
      //     das: this.newPayloadTmp[i].das,
      //     debit_unit: this.newPayloadTmp[i].satuanDebit,
      //     debit_value: +this.newPayloadTmp[i].debit,
      //     frequency: {
      //       bulan: +this.newPayloadTmp[i].bulan,
      //       detail_eng_ipal: this.newPayloadTmp[i].detEngIPAL,
      //       hari: +this.newPayloadTmp[i].minggu,
      //       ipal_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       jam: +this.newPayloadTmp[i].jam,
      //       latar_belakang: this.newPayloadTmp[i].latarBelakang,
      //       logbook_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       metode_ukur: this.newPayloadTmp[i].metUkurDebit,
      //       type: this.newPayloadTmp[i].frequenceDet
      //     },
      //     latitude: +this.newPayloadTmp[i].lintang,
      //     logger: {
      //       brand: this.newPayloadTmp[i].brand,
      //       mac: this.newPayloadTmp[i].mac,
      //       model: this.newPayloadTmp[i].model,
      //       sn: this.newPayloadTmp[i].sn,
      //       sensors: this.newPayloadTmp[i].sensor,
      //     },
      //     longitude: +this.newPayloadTmp[i].bujur,
      //     processing_technique: this.newPayloadTmp[i].processingTechnique,
      //     spot_name: this.newPayloadTmp[i].namaTitikPenaatan,
      //     tech_requirement: {
      //       dukungan_brand_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       info_alat: this.newPayloadTmp[i].infoAlat,
      //       kalibrasi_awal_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       kalibrasi_rutin_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       kinerja_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       kontak_kerjasama_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       lulus_uji_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       nama_penyedia: this.newPayloadTmp[i].namaPenyedia,
      //       no_surat: this.newPayloadTmp[i].noSurat,
      //       pernyataan_vendor_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       tipe_uji: this.newPayloadTmp[i].lulusUji
      //     },
      //     waste_receiver: "Laut",
      //     waste_source: this.newPayloadTmp[i].wasteSource,
      //     waste_type: this.newPayloadTmp[i].wasteType
      //   })
      // }

      // let payload = {
      //       detail: {
      //         permit_id: this.permissionLaterFormExistingSelected,
      //         total_station: stationContent.length
      //       },
      //       station: stationContent
      // }

      // Swal.fire({
      //   title: `Ubah SPARING?`,
      //   text: "",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#29664A",
      //   cancelButtonColor: "#d33",
      //   confirmButtonText: "DAFTAR",
      //   cancelButtonText: "BATAL",
      // }).then((result) => {
      //   if (result.isConfirmed) {
         

      //     if (!this.permissionLaterFormExistingSelected) {
      //       Swal.fire({
      //       title: "Nomor Surat wajib di isi",
      //       text: "Pastikan anda mengisi nomor surat dengan memilih yang sudah ada atau membuat permohonan surat baru",
      //       icon: "error",
      //     });
      //     } else if (!this.form.teknis.wasteSource) {
      //       Swal.fire({
      //       title: "Sumber Air Limbah wajib di isi",
      //       text: "Pastikan anda mengisi data sumber air limbah",
      //       icon: "error",
      //       })
      //     } else if (!this.permissionLaterFormExistingSelected && !this.form.teknis.wasteSource) {
      //       Swal.fire({
      //       title: "Nomor Surat dan Sumber Air Limbah wajib di isi",
      //       text: "Pastikan anda melengkapi data",
      //       icon: "error",
      //       })
      //     } else {
      //       let headers = {
      //         Authorization: `Bearer ${localStorage.getItem("token")}`,
      //       };


      //       API.post(`sparing`, payload, { headers })
      //       .then(({ data: content }) => {
      //         console.log(content,'BERHASIL');
      //         this.newPayloadTmp = []
      //         this.$router.push('/sparing')
      //           Swal.fire({
      //           title: "Berhasil Mendaftar",
      //           text: "Admin SPARING akan meninjau permintaan pendaftaran SPARING anda",
      //           icon: "success",
      //         });

      //       })
      //       .catch((err) => {
      //         console.log(err);
      //         Swal.fire("Pendaftaran Gagal", "gagal mendaftarkan", "error");
      //       });
      //     }
      //   }
      // });

    },
    resetForm() {
          this.getStationForUpdate = ""
          this.titikPenaatanPopUpForm.namaTitikPenaatan = ""
          // this.form.teknis.wasteSource = ""
          this.titikPenaatanPopUpForm.namaTitikPenaatan = ""
          this.titikPenaatanPopUpForm.lintang = ""
          this.titikPenaatanPopUpForm.bujur = ""
          this.titikPenaatanPopUpForm.das = ""
          this.form.teknis.processingTechnique = ""
          this.form.teknis.wasteType = ""
          this.form.teknis.debit = ""
          this.form.teknis.satuanDebit = ""
          this.form.teknis.capacityPermit = ""
          this.form.teknis.capacityActual = ""
          this.form.teknis.frequenceDet = ""
          this.form.frekuensi.jam = ""
          this.form.frekuensi.minggu = ""
          this.form.frekuensi.bulan = ""
          this.form.frekuensi.latarBelakang = ""
          this.form.frekuensi.detEngIPAL = ""
          this.form.frekuensi.metUkurDebit = ""
          this.loggerSensorPopUpForm.lulusUji = ""
          this.loggerSensorPopUpForm.noSurat = ""
          this.loggerSensorPopUpForm.namaPenyedia = ""
          this.loggerSensorPopUpForm.infoAlat = ""
          this.loggerSensorPopUpForm.brand = ""
          this.loggerSensorPopUpForm.model = ""
          this.loggerSensorPopUpForm.mac = ""
          this.loggerSensorPopUpForm.sn = ""
          this.form.sensor = [
          {
            name: "pH",
            label: "pH",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "COD",
            label: "COD",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "TSS",
            label: "TSS",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "NH3N",
            label: "NH3N",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "Debit",
            label: "Debit",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
        ]
    },
    showFormTitikPenataanModal() {
      this.showFormPenataanFormModal = true;
      this.resetForm()
    },
    willDeleteStation(row) {
      Swal.fire({
        title: `Hapus Titik Penaatan ${row.namaTitikPenaatan} ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "HAPUS",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let indexToDelete = this.newPayloadTmp.findIndex(
            (tableRow) => tableRow.namaTitikPenaatan === row.namaTitikPenaatan
          );
          if (indexToDelete >= 0) {
            this.newPayloadTmp.splice(indexToDelete, 1);
          }
    
          this.resetForm()
          this.showFormPenataanFormModal = false;

          Swal.fire({
            title: "Berhasil Menghapus",
            text: "Titik Penaatan telah dihapus dari Pendaftaran SPARING",
            icon: "success",
          });
        }
      });
    },
    // NEW
    getLoggerRegistered(id) {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(
        `companies/sparings/compliance-points/loggers?nama_titik_penaatan=${id}`,
        { headers }
      )
        .then(({ data: content }) => {
          console.log(content.data, "uhuuu");
          this.loggerRegistered = content.data;
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(
            "Pencarian data gagal",
            "harap periksa koneksi internet anda",
            "error"
          );
        });
    },
    deleteLoggerRegistered(row) {
      Swal.fire({
        title: "Hapus Logger ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "HAPUS",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          API.delete(
            `companies/sparings/compliance-points/detail/${row.detail_id}`,
            { headers }
          )
            .then(({ data }) => {
              Swal.fire({
                title: "Berhasil Menghapus",
                text: "Logger telah dihapus dari Pendaftaran SPARING",
                icon: "success",
              });

              this.getListDataTitikPenaatanForCreateLogger();
            })
            .catch((err) => {
              Swal.fire("Gagal Menghapus", "", "error");
            });
        }
      });
    },
    actionUpdateLoggerRegistered(row) {
      Swal.fire({
        title: "Perbaharui Logger ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "PERBAHARUI",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loggerFormInput.append("id", row);
          this.loggerFormInput.append(
            "info_alat",
            this.loggerSensorPopUpForm.infoAlat
          );
          this.loggerFormInput.append(
            "logger_brand",
            this.loggerSensorPopUpForm.brand
          );
          this.loggerFormInput.append(
            "logger_mac_address",
            this.loggerSensorPopUpForm.mac
          );
          this.loggerFormInput.append(
            "logger_model",
            this.loggerSensorPopUpForm.model
          );
          this.loggerFormInput.append(
            "logger_serial_number",
            this.loggerSensorPopUpForm.sn
          );
          this.loggerFormInput.append(
            "lulus_uji_konektivitas",
            this.loggerSensorPopUpForm.lulusUji
          );
          this.loggerFormInput.append(
            "sparing_compliance_point_id",
            this.loggerSensorIndex.id
          );
          this.loggerFormInput.append(
            "nama_penyedia_barang_dan_jasa",
            this.loggerSensorPopUpForm.namaPenyedia
          );
          this.loggerFormInput.append(
            "nomor_surat_lulus_uji_konektivitas",
            this.loggerSensorPopUpForm.noSurat
          );

          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          API.put(
            "companies/sparings/compliance-points/detail",
            this.loggerFormInput,
            { headers }
          )
            .then(({ data }) => {
              Swal.fire(
                "Logger Berhasil diperbaharui",
                "Data Berhasil didaftarkan",
                "success"
              );

              this.getListDataTitikPenaatanForCreateLogger();

              this.loggerFormInput = new FormData();
              this.loggerSensorPopUpForm = {
                brand: "",
                model: "",
                sn: "",
                mac: "",
                lulusUji: "",
                infoAlat: "",
                namaPenyedia: "",
                noSurat: "",
                suratketerangan: "",
              };

              this.loggerFormInput = new FormData();

              this.showFormLoggerSensorFormModal = false;

              this.fileSuratKeteranganLulusUji++;
              this.fileHasilKalibrasiSaatAwalPemasanganAlat++;
              this.fileHasilKalibrasiAlatRutin++;
              this.fileHasilUjiKinerjaDariLabotarium++;
              this.fileSuratDukunganBrandSensor++;
              this.fileSuratPernyataan++;
              this.fileKontakKerjaSama++;
            })
            .catch((err) => {
              console.log(err);
              Swal.fire("Pendaftaran Gagal", "terjadi kesalahan", "error");
            });
        }
      });
    },
    cancelUpdateLogger() {},
    pendaftaranRubahSensor(row) {
      this.pendaftaranRubahSensorID = row.id;
      this.rubahSensorPendaftaran = true;
      this.form.sensor[0].active = false;
      this.form.sensor[1].active = false;
      this.form.sensor[2].active = false;
      this.form.sensor[3].active = false;
      this.form.sensor[4].active = false;
      let sensorName = "";

      // reset - nanti akan dibuat clean code
      this.form.sensor[0].brand = "";
      this.form.sensor[0].probe = "";
      this.form.sensor[0].minMeasure = "";
      this.form.sensor[0].maxMeasure = "";
      this.form.sensor[0].min = "";
      this.form.sensor[0].max = "";
      this.form.sensor[0].metodePengukuran = "";
      this.form.sensor[0].jadwalKalibrasi = "";
      this.form.sensor[0].tanggalKalibrasi = "";
      this.form.sensor[0].metodeKalibrasi = "";
      this.form.sensor[0].satuanDebit = "";
      // 2
      this.form.sensor[1].brand = "";
      this.form.sensor[1].probe = "";
      this.form.sensor[1].minMeasure = "";
      this.form.sensor[1].maxMeasure = "";
      this.form.sensor[1].min = "";
      this.form.sensor[1].max = "";
      this.form.sensor[1].metodePengukuran = "";
      this.form.sensor[1].jadwalKalibrasi = "";
      this.form.sensor[1].tanggalKalibrasi = "";
      this.form.sensor[1].metodeKalibrasi = "";
      this.form.sensor[1].satuanDebit = "";
      // 3
      this.form.sensor[2].brand = "";
      this.form.sensor[2].probe = "";
      this.form.sensor[2].minMeasure = "";
      this.form.sensor[2].maxMeasure = "";
      this.form.sensor[2].min = "";
      this.form.sensor[2].max = "";
      this.form.sensor[2].metodePengukuran = "";
      this.form.sensor[2].jadwalKalibrasi = "";
      this.form.sensor[2].tanggalKalibrasi = "";
      this.form.sensor[2].metodeKalibrasi = "";
      this.form.sensor[2].satuanDebit = "";
      // 4
      this.form.sensor[3].brand = "";
      this.form.sensor[3].probe = "";
      this.form.sensor[3].minMeasure = "";
      this.form.sensor[3].maxMeasure = "";
      this.form.sensor[3].min = "";
      this.form.sensor[3].max = "";
      this.form.sensor[3].metodePengukuran = "";
      this.form.sensor[3].jadwalKalibrasi = "";
      this.form.sensor[3].tanggalKalibrasi = "";
      this.form.sensor[3].metodeKalibrasi = "";
      this.form.sensor[3].satuanDebit = "";
      // 5
      this.form.sensor[4].brand = "";
      this.form.sensor[4].probe = "";
      this.form.sensor[4].minMeasure = "";
      this.form.sensor[4].maxMeasure = "";
      this.form.sensor[4].min = "";
      this.form.sensor[4].max = "";
      this.form.sensor[4].metodePengukuran = "";
      this.form.sensor[4].jadwalKalibrasi = "";
      this.form.sensor[4].tanggalKalibrasi = "";
      this.form.sensor[4].metodeKalibrasi = "";
      this.form.sensor[4].satuanDebit = "";
      this.populatedBrosurExisting = "";

      // reset - nanti akan dibuat clean code

      if (row.sensor_type == "pH") {
        sensorName = "0";
      } else if (row.sensor_type == "COD") {
        sensorName = "1";
      } else if (row.sensor_type == "TSS") {
        sensorName = "2";
      } else if (row.sensor_type == "NH3N") {
        sensorName = "3";
      } else if (row.sensor_type == "Debit") {
        sensorName = "4";
      }

      console.log(row, "-------------->");
      console.log(sensorName, "-------------->");

      this.getFormSensor = sensorName;

      this.form.sensor[sensorName].active = true;
      this.form.sensor[sensorName].brand = row.brand;
      this.form.sensor[sensorName].probe = row.probe;
      this.form.sensor[sensorName].minMeasure = row.range_min;
      this.form.sensor[sensorName].maxMeasure = row.range_max;
      // baku mutu
      this.form.sensor[sensorName].min = row.bmal_min;
      this.form.sensor[sensorName].max = row.bmal_max;
      this.form.sensor[sensorName].metodePengukuran = row.metode_pengukuran;
      this.form.sensor[sensorName].jadwalKalibrasi = row.jadwal_kalibrasi;
      this.form.sensor[sensorName].tanggalKalibrasi = row.tanggal_kalibrasi;
      this.form.sensor[sensorName].metodeKalibrasi = row.metode_kalibrasi;
      this.form.sensor[sensorName].satuanDebit = row.satuanDebit;
      this.populatedBrosurExisting = row.brosur_path;
    },
    pendaftaranHapusSensor(row) {
      Swal.fire({
        title: "Hapus Sensor ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "HAPUS",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          API.delete(`companies/sparings/compliance-points/sensor/${row.id}`, {
            headers,
          })
            .then(({ data }) => {
              Swal.fire({
                title: "Berhasil Menghapus",
                text: "Sensor telah dihapus dari Titik Penaatan ini",
                icon: "success",
              });

              this.form.sensor[0].active = false;
              this.form.sensor[1].active = false;
              this.form.sensor[2].active = false;
              this.form.sensor[3].active = false;
              this.form.sensor[4].active = false;

              this.fillDataSensor(this.idForAddSensorNew);
            })
            .catch((err) => {
              Swal.fire("Gagal Menghapus", "", "error");
            });
        }
      });
    },
    closeCreatePermissionLaterWithExistingPermission() {
      this.permissionLaterFormExistingSelected = "";
      this.showFormModalWithExistPermission = false;
    },
    goToLastWizard() {
      Swal.fire({
        title: "Lanjut Mengisi Logger ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "LANJUT",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          this.showSecondaryWizardForm = false;

          setTimeout(() => {
            this.showThirdWizardForm = true;
          }, 1000);
        }
      });
    },
    goToFinishWizard() {
      Swal.fire({
        title: "Apakah Anda Yakin?",
        text: "Jika Menekan selesai , seluruh pendaftaran SPARING sudah dinyatakan selesai",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "SELESAI",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            "Pendaftaran Selesai",
            "jika ada yang terlewatkan , anda dapat merubahnya di halaman depan Sparing",
            "success"
          );
          this.showThirdWizardForm = false;
        }
      });
    },
    // Function to get old master permit for reuse again to register
    getDataForOldLater() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get("permits", { headers })
        .then(({ data: content }) => {
          if (content.data == null) {
            this.permissionLaterFormExisting = [];
          } else {
            this.permissionLaterFormExisting = content.data;
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(
            "Pencarian data gagal",
            "harap periksa koneksi internet anda",
            "error"
          );
        });
    },
    // Function to create new master permit
    createPermissionLater() {

      if (!this.form.teknis.permitDateExpire || !this.form.teknis.permitPerson || !this.form.teknis.permitNumber || !this.form.teknis.permitDatePublish) {
        return Swal.fire("Data belum lengkap",'Pastikan anda melengkapi semua form','error')
      }

      Swal.fire({
        title: "Menambahkan Surat Izin?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "TAMBAH",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          let payload = {
            attachment_file_id: this.form.teknis.sparing_air_limbah_file,
            expiration_date: this.form.teknis.permitDateExpire,
            issuing_authority: this.form.teknis.permitPerson,
            no: this.form.teknis.permitNumber,
            publication_date: this.form.teknis.permitDatePublish
          }
         
          API.post("permits", payload, {headers})
            .then(({ data }) => {
              this.form.teknis.permitDateExpire = ""
              this.form.teknis.permitPerson = ""
              this.form.teknis.permitNumber = ""
              this.form.teknis.permitDatePublish = ""

              this.getDataForOldLater()
              this.showFormModal = false;
              Swal.fire("Surat izin ditambahkan","pendaftaran surat izin berhasil","success");

            })
            .catch((err) => {
              console.log(err, "ini error");
              Swal.fire("Pendaftaran Gagal","harap telah mengisi semua data atau periksa koneksi internet anda","error");
            });
            }
          });
    },
    // Function to create permit using id return function getDataForOldLater
    createPermissionLaterWithExistingPermission() {
      console.log(this.permissionLaterFormExistingSelected);
      this.showFormModalWithExistPermission = false;

      if (this.permissionLaterFormExistingSelected) {
        this.form.uid = this.permissionLaterFormExistingSelected;
        // go to new modal
        setTimeout(() => {
          this.permissionLaterFormExistingSelected = "";
          this.showMainWizardForm = true;
        }, 500);
      }
    },
    /**
     * 1. Submit Data titik penaatan Wizard 1 (submitDataTitikPenaatan)
     * 2. Submit Detail titik penaatan Wizard 2 (submitDataTitikPenaatanPlace)
     * 3. Submit Logger Wizard 3 (submitLogger)
     * 4. Submit Sensor Wizard 4 (submitSensor)
     */
    submitDataTitikPenaatan() {
      this.formDataSparing.append("debit", this.form.teknis.debit);
      this.formDataSparing.append(
        "frekuensi_pembuangan_air_limbah",
        this.form.teknis.frequenceDet
      );
      this.formDataSparing.append(
        "frekuensi_per_hari",
        this.form.frekuensi.jam
      );
      this.formDataSparing.append(
        "frekuensi_per_minggu",
        this.form.frekuensi.hari
      );
      this.formDataSparing.append(
        "frekuensi_per_tahun",
        this.form.frekuensi.bulan
      );
      this.formDataSparing.append(
        "jenis_air_limbah",
        this.form.teknis.wasteType
      );
      this.formDataSparing.append(
        "jenis_teknologi_pengolahan_air_limbah",
        this.form.teknis.processingTechnique
      );
      this.formDataSparing.append(
        "kapasitas_produksi_senyatanya",
        this.form.teknis.capacityPermit
      );
      this.formDataSparing.append(
        "kapasitas_produksi_sesuai_izin",
        this.form.teknis.capacityActual
      );
      this.formDataSparing.append("sparing_permit_id", this.form.uid);
      this.formDataSparing.append(
        "sumber_air_limbah",
        this.form.teknis.wasteSource
      );
      this.formDataSparing.append(
        "frekuensi_detail_engineering_ipal",
        this.form.frekuensi.detEngIPAL
      );
      this.formDataSparing.append(
        "frekuensi_latar_belakang",
        this.form.frekuensi.latarBelakang
      );
      this.formDataSparing.append(
        "frekuensi_metode_pengukuran_debit",
        this.form.frekuensi.metUkurDebit
      );

      Swal.fire({
        title: "Tambah Pendaftaran Sparing ?",
        text: "Jika Ya , anda akan melanjutkan ke pengisian data lainnya",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "TAMBAH",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          API.post("companies/sparings", this.formDataSparing, { headers })
            .then(({ data }) => {
              this.getReturnedIdCreated = data.data.id;
              Swal.fire(
                "Pendaftaran Berhasil",
                "Lanjut mengisi titik penaatan",
                "success"
              );
              this.showMainWizardForm = false;
              this.resetPayloadDataTitikPenaatan();
              setTimeout(() => {
                this.showMainWizardPointForm = true;
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
              Swal.fire("Pendaftaran Gagal", "terjadi kesalahan", "error");
            });
        }
      });
    },
    submitDataTitikPenaatanPlace() {
      let result_number_compliance_point_data = [];
      for (let i = 0; i < this.form.teknis.spotList.length; i++) {
        result_number_compliance_point_data.push({
          bujur: this.form.teknis.spotList[i].spotLoc[1],
          das: this.form.teknis.spotList[i].das,
          lintang: this.form.teknis.spotList[i].spotLoc[0],
          nama_badan_air_penerima_air_limbah:
            this.form.teknis.spotList[i].penerimaAirLimbah,
          nama_titik_penaatan: this.form.teknis.spotList[i].spotName,
          sparing_id: this.getReturnedIdCreated,
        });
      }

      let payload = result_number_compliance_point_data;

      Swal.fire({
        title: "Lanjut Mengisi Sensor ?",
        text: "Jika Ya , anda hanya dapat memperbaharui data logger lewat menu Rubah Data",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "LANJUT",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          API.post("companies/sparings/compliance-points", payload, { headers })
            .then(({ data }) => {
              Swal.fire(
                "Titik Penaatan Berhasil Ditambahkan",
                "Lanjut mengisi Logger setiap titik penaatan",
                "success"
              );
              this.resetPayloadDataTitikPenaatan();
              // this.getLoggerRegistered()
              this.showMainWizardPointForm = false;

              setTimeout(() => {
                this.getListDataTitikPenaatanForCreateLogger();
                this.showSecondaryWizardForm = true;
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
              Swal.fire(
                "Pendaftaran Titik penaatan Gagal",
                "terjadi kesalahan",
                "error"
              );
            });
        }
      });
    },
    getListDataTitikPenaatanForCreateLogger() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(
        `companies/sparings/compliance-points/loggers?sparing_id=${this.getReturnedIdCreated}`,
        { headers }
      )
        .then(({ data: content }) => {
          console.log(content.data, "yyyyyyyyyyyyyyyyy");
          this.getLoggerHasSubmit = content.data;
        })
        .catch((err) => {
          Swal.fire("Gagal mendapatkan data", "terjadi kesalahan", "error");
        });
    },
    submitLogger() {
      this.loggerFormInput.append(
        "info_alat",
        this.loggerSensorPopUpForm.infoAlat
      );
      this.loggerFormInput.append(
        "logger_brand",
        this.loggerSensorPopUpForm.brand
      );
      this.loggerFormInput.append(
        "logger_mac_address",
        this.loggerSensorPopUpForm.mac
      );
      this.loggerFormInput.append(
        "logger_model",
        this.loggerSensorPopUpForm.model
      );
      this.loggerFormInput.append(
        "logger_serial_number",
        this.loggerSensorPopUpForm.sn
      );
      this.loggerFormInput.append(
        "lulus_uji_konektivitas",
        this.loggerSensorPopUpForm.lulusUji
      );
      this.loggerFormInput.append(
        "sparing_compliance_point_id",
        this.loggerSensorIndex.id
      );

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.post(
        "companies/sparings/compliance-points/detail",
        this.loggerFormInput,
        { headers }
      )
        .then(({ data }) => {
          Swal.fire(
            "Logger Berhasil ditambah",
            "Data Berhasil didaftarkan",
            "success"
          );

          this.loggerFormInput = new FormData();

          this.loggerSensorPopUpForm = {
            brand: "",
            model: "",
            sn: "",
            mac: "",
            lulusUji: "",
            infoAlat: "",
            namaPenyedia: "",
            noSurat: "",
            suratketerangan: "",
          };

          this.loggerFormInput = new FormData();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire("Pendaftaran Gagal", "terjadi kesalahan", "error");
        });
    },
    uploadSubmitLogger(category, field) {
      this.loggerFormInput.append(field, this.$refs[field].files[0]);
    },
    submitSensor() {
      this.sensorFormInput = new FormData();
      console.log("do");

      if (this.rubahSensorPendaftaran) {
        // PUT

        let sensorName = "";

        if (this.getFormSensor == 0) {
          sensorName = "pH";
        } else if (this.getFormSensor == 1) {
          sensorName = "COD";
        } else if (this.getFormSensor == 2) {
          sensorName = "TSS";
        } else if (this.getFormSensor == 3) {
          sensorName = "NH3N";
        } else if (this.getFormSensor == 4) {
          sensorName = "Debit";
        }

        console.log(this.getFormSensor, "getFormSensor");
        console.log(this.form.sensor[this.getFormSensor], "ALL");

        this.sensorFormInput.append(
          "bmal_max",
          this.form.sensor[this.getFormSensor].max
        );
        this.sensorFormInput.append(
          "bmal_min",
          this.form.sensor[this.getFormSensor].min
        );
        this.sensorFormInput.append(
          "brand",
          this.form.sensor[this.getFormSensor].brand
        );
        this.sensorFormInput.append("id", this.pendaftaranRubahSensorID);
        this.sensorFormInput.append(
          "jadwal_kalibrasi",
          this.form.sensor[this.getFormSensor].jadwalKalibrasi
        );
        this.sensorFormInput.append(
          "metode_kalibrasi",
          this.form.sensor[this.getFormSensor].metodeKalibrasi
        );
        this.sensorFormInput.append(
          "metode_pengukuran",
          this.form.sensor[this.getFormSensor].metodePengukuran
        );
        this.sensorFormInput.append(
          "probe",
          this.form.sensor[this.getFormSensor].probe
        );
        this.sensorFormInput.append(
          "range_max",
          this.form.sensor[this.getFormSensor].maxMeasure
        );
        this.sensorFormInput.append(
          "range_min",
          this.form.sensor[this.getFormSensor].minMeasure
        );
        this.sensorFormInput.append(
          "tanggal_kalibrasi",
          this.form.sensor[this.getFormSensor].tanggalKalibrasi
        );

        if (sensorName == "Debit") {
          this.sensorFormInput.append(
            "bmal_unit",
            this.form.sensor[this.getFormSensor].satuanDebit
          );
        }

        Swal.fire({
          title: "Merubah Data Sensor ?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "RUBAH",
          cancelButtonText: "BATAL",
        }).then((result) => {
          if (result.isConfirmed) {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            };

            API.put(
              "companies/sparings/compliance-points/sensor",
              this.sensorFormInput,
              { headers }
            )
              .then(({ data }) => {
                // ini rubah dinamis
                this.fillDataSensor(this.idForAddSensorNew);
                Swal.fire("Sensor Berhasil diperbaharui", "", "success");
                this.resetPayloadSensor();
              })
              .catch((err) => {
                Swal.fire("Gagal Memperbaharui", "terjadi kesalahan", "error");
                // paksa reset data update
              });

            this.fileBrosur++;
            this.rubahSensorPendaftaran = false;
          }
        });
      } else {
        // POST

        let sensorName = "";

        if (this.getFormSensor == 0) {
          sensorName = "pH";
        } else if (this.getFormSensor == 1) {
          sensorName = "COD";
        } else if (this.getFormSensor == 2) {
          sensorName = "TSS";
        } else if (this.getFormSensor == 3) {
          sensorName = "NH3N";
        } else if (this.getFormSensor == 4) {
          sensorName = "Debit";
        }

        this.sensorFormInput.append(
          "bmal_max",
          this.form.sensor[this.getFormSensor].max
        );
        this.sensorFormInput.append(
          "bmal_min",
          this.form.sensor[this.getFormSensor].min
        );
        this.sensorFormInput.append(
          "brand",
          this.form.sensor[this.getFormSensor].brand
        );
        this.sensorFormInput.append(
          "jadwal_kalibrasi",
          this.form.sensor[this.getFormSensor].jadwalKalibrasi
        );
        this.sensorFormInput.append(
          "metode_kalibrasi",
          this.form.sensor[this.getFormSensor].metodeKalibrasi
        );
        this.sensorFormInput.append(
          "metode_pengukuran",
          this.form.sensor[this.getFormSensor].metodePengukuran
        );
        this.sensorFormInput.append(
          "probe",
          this.form.sensor[this.getFormSensor].probe
        );
        this.sensorFormInput.append(
          "range_max",
          this.form.sensor[this.getFormSensor].maxMeasure
        );
        this.sensorFormInput.append(
          "range_min",
          this.form.sensor[this.getFormSensor].minMeasure
        );
        this.sensorFormInput.append("sensor_type", sensorName);
        this.sensorFormInput.append(
          "sparing_compliance_point_id",
          this.loggerSensorIndex.id
        );
        this.sensorFormInput.append(
          "tanggal_kalibrasi",
          this.form.sensor[this.getFormSensor].tanggalKalibrasi
        );

        if (sensorName == "Debit") {
          this.sensorFormInput.append(
            "bmal_unit",
            this.form.sensor[this.getFormSensor].satuanDebit
          );
        }

        Swal.fire({
          title: "Tambahkan Sensor ?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "TAMBAH",
          cancelButtonText: "BATAL",
        }).then((result) => {
          if (result.isConfirmed) {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            };

            API.post(
              "companies/sparings/compliance-points/sensor",
              this.sensorFormInput,
              { headers }
            )
              .then(({ data }) => {
                // ini rubah dinamis
                this.fillDataSensor(this.idForAddSensorNew);
                Swal.fire(
                  "Sensor Berhasil ditambahkan",
                  "Data Berhasil didaftarkan",
                  "success"
                );
                this.resetPayloadSensor();
              })
              .catch((err) => {
                Swal.fire("Pendaftaran Gagal", "terjadi kesalahan", "error");
              });

            this.fileBrosur++;
          }
        });
      }
    },
    closeSubmitSensor() {
      this.sensorTmpData = [];
      this.showFormLoggerSensorConnectingFormModal = false;
    },
    uploadDataTitikPenaatan(category, field) {
      this.formDataSparing.append(field, this.$refs[field].files[0]);
    },

    fillDataLogger(row) {
      console.log(row);

      if (row.logger_id == null) {
        // create new
        this.loggerSensorPopUpForm.infoAlat = "";
        this.loggerSensorPopUpForm.brand = "";
        this.loggerSensorPopUpForm.mac = "";
        this.loggerSensorPopUpForm.model = "";
        this.loggerSensorPopUpForm.sn = "";
        this.loggerSensorPopUpForm.lulusUji = "";
        this.loggerSensorIndex.id = "";
        this.loggerSensorPopUpForm.namaPenyedia = "";
        this.loggerSensorPopUpForm.noSurat = "";
        this.editLogger = false;
        this.editLoggerID = "";
      } else {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        API.get(
          `companies/sparings/compliance-points/detail/${row.detail_id}`,
          { headers }
        )
          .then(({ data: content }) => {
            this.loggerSensorPopUpForm.infoAlat = content.data.info_alat;
            this.loggerSensorPopUpForm.brand = content.data.logger_brand;
            this.loggerSensorPopUpForm.mac = content.data.logger_mac_address;
            this.loggerSensorPopUpForm.model = content.data.logger_model;
            this.loggerSensorPopUpForm.sn = content.data.logger_serial_number;
            this.loggerSensorPopUpForm.lulusUji =
              content.data.lulus_uji_konektivitas;
            this.loggerSensorIndex.id = row.detail_id;
            this.loggerSensorPopUpForm.namaPenyedia =
              content.data.nama_penyedia_barang_dan_jasa;
            this.loggerSensorPopUpForm.noSurat =
              content.data.nomor_surat_lulus_uji_konektivitas;
            this.editLogger = true;
            this.editLoggerID = row.detail_id;
          })
          .catch((err) => {
            console.log(err);
            Swal.fire(
              "Pencarian data gagal",
              "harap periksa koneksi internet anda",
              "error"
            );
          });
      }

      this.getLoggerRegistered(row.id);
      this.loggerFormInput = new FormData();

      this.loggerSensorIndex = row;
      this.showFormLoggerSensorFormModal = true;
    },
    fillDataSensor(row) {
      this.loggerSensorIndex = row;

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      this.idForAddSensorNew = row;

      API.get(
        `companies/sparings/compliance-points/sensor?sparing_compliance_point_id=${row.id}`,
        { headers }
      )
        .then(({ data: content }) => {
          if (content.data == null) {
            this.populatedSensorExisting = [];
          } else {
            this.populatedSensorExisting = content.data;
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(
            "Pencarian data gagal",
            "harap periksa koneksi internet anda",
            "error"
          );
        });
      this.showFormLoggerSensorConnectingFormModal = true;
    },
    resetPayloadDataTitikPenaatan() {
      this.form.teknis.debit = "";
      this.form.teknis.frequenceDet = "";
      this.form.teknis.wasteType = "";
      this.form.teknis.processingTechnique = "";
      this.form.teknis.capacityPermit = "";
      this.form.teknis.capacityActual = "";
      this.form.uid = "";
      this.form.teknis.wasteSource = "";
      this.form.teknis.spotList = [];
    },
    resetPayloadSensor() {
      this.sensorFormInput = new FormData();
      this.form.sensor = [
        {
          name: "pH",
          label: "pH",
          active: false,
          brand: null,
          probe: null,
          brosurFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          min: 0,
          max: 0,
          minMeasure: 0,
          maxMeasure: 0,
          metodePengukuran: null,
          jadwalKalibrasi: null,
          tanggalKalibrasi: new Date(),
          metodeKalibrasi: null,
        },
        {
          name: "COD",
          label: "COD",
          active: false,
          brand: null,
          probe: null,
          brosurFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          min: 0,
          max: 0,
          minMeasure: 0,
          maxMeasure: 0,
          metodePengukuran: null,
          jadwalKalibrasi: null,
          tanggalKalibrasi: new Date(),
          metodeKalibrasi: null,
        },
        {
          name: "TSS",
          label: "TSS",
          active: false,
          brand: null,
          probe: null,
          brosurFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          min: 0,
          max: 0,
          minMeasure: 0,
          maxMeasure: 0,
          metodePengukuran: null,
          jadwalKalibrasi: null,
          tanggalKalibrasi: new Date(),
          metodeKalibrasi: null,
        },
        {
          name: "NH3N",
          label: "NH3N",
          active: false,
          brand: null,
          probe: null,
          brosurFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          min: 0,
          max: 0,
          minMeasure: 0,
          maxMeasure: 0,
          metodePengukuran: null,
          jadwalKalibrasi: null,
          tanggalKalibrasi: new Date(),
          metodeKalibrasi: null,
        },
        {
          name: "Debit",
          label: "Debit",
          active: false,
          brand: null,
          probe: null,
          brosurFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          min: 0,
          max: 0,
          minMeasure: 0,
          maxMeasure: 0,
          metodePengukuran: null,
          jadwalKalibrasi: null,
          tanggalKalibrasi: new Date(),
          metodeKalibrasi: null,
        },
      ];
    },

    showSensorModal(index) {
      this.rubahSensorPendaftaran = false;
      this.form.sensor[0].active = false;
      this.form.sensor[1].active = false;
      this.form.sensor[2].active = false;
      this.form.sensor[3].active = false;
      this.form.sensor[4].active = false;

      console.log("clicked");
      console.log(index, "--------------?s");
      this.form.sensor[index].active = true;
      this.indexSensor = index;

      // reset
      this.form.sensor[index].brand = "";
      this.form.sensor[index].probe = "";
      this.form.sensor[index].minMeasure = "";
      this.form.sensor[index].maxMeasure = "";
      // baku mutu
      this.form.sensor[index].min = "";
      this.form.sensor[index].max = "";
      this.form.sensor[index].metodePengukuran = "";
      this.form.sensor[index].jadwalKalibrasi = "";
      this.form.sensor[index].tanggalKalibrasi = "";
      this.form.sensor[index].metodeKalibrasi = "";
      this.form.sensor[index].satuanDebit = "";
      this.populatedBrosurExisting = "";
    },

    // routing
    showNewFormForRegister() {
      this.showFormModal = true;
    },
    goBack() {
      this.$router.push("/sparing");
    },

    async showOldFormForRegister() {
      try {
        this.getDataForOldLater();
        this.showFormModalWithExistPermission = true;
      } catch (error) {
        console.log(error);
      }
    },
    // table method
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    // delete temporary data titik penaatan
    
    // fill temporary data logger dan sensor

    // edit temporary data titik penaatan
    willEdit(row) {
      console.log(row);
      let indexToEdit = this.form.teknis.spotList.findIndex(
        (tableRow) => tableRow.spotName === row.spotName
      );

      this.titikPenaatanPopUpEditForm.id = indexToEdit;
      this.titikPenaatanPopUpEditForm.spotName =
        this.form.teknis.spotList[indexToEdit].spotName;
      this.titikPenaatanPopUpEditForm.spotLoc =
        this.form.teknis.spotList[indexToEdit].spotLoc;
      this.titikPenaatanPopUpEditForm.das =
        this.form.teknis.spotList[indexToEdit].das;
      this.titikPenaatanPopUpEditForm.penerimaAirLimbah =
        this.form.teknis.spotList[indexToEdit].penerimaAirLimbah;
      this.showUpdateFormPenataanFormModal = true;
    },
    updateFormPenaatanModal() {
      this.showUpdateFormPenataanFormModal = false;
      this.form.teknis.spotList[this.titikPenaatanPopUpEditForm.id].spotName =
        this.titikPenaatanPopUpEditForm.spotName;
      this.form.teknis.spotList[this.titikPenaatanPopUpEditForm.id].spotLoc =
        this.titikPenaatanPopUpEditForm.spotLoc;
      this.form.teknis.spotList[this.titikPenaatanPopUpEditForm.id].das =
        this.titikPenaatanPopUpEditForm.das;
      this.form.teknis.spotList[
        this.titikPenaatanPopUpEditForm.id
      ].penerimaAirLimbah = this.titikPenaatanPopUpEditForm.penerimaAirLimbah;
    },
    // tambah titik penaatan
    tambahDataPenataan() {
      this.showFormPenataanFormModal = false;
      console.log("add penataan");
      this.form.teknis.spotList.push({
        spotName: this.titikPenaatanPopUpForm.namaTitikPenaatan,
        spotLoc: [
          this.titikPenaatanPopUpForm.lintang,
          this.titikPenaatanPopUpForm.bujur,
        ],
        penerimaAirLimbah: this.titikPenaatanPopUpForm.penerimaAirLimbah,
        das: this.titikPenaatanPopUpForm.das,
        // explore
        brand: "?",
        model: "?",
        sn: "?",
        mac: "?",
        // persyaratann teknis here
        infoAlat: null,
        lulusUji: null,
        namaPenyedia: null,
        noSurat: null,
        // disini buat sensor nanti
        sensor: [
          {
            name: "pH",
            label: "pH",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: null,
            max: null,
            minMeasure: null,
            maxMeasure: null,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
          },
          {
            name: "COD",
            label: "COD",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: null,
            max: null,
            minMeasure: null,
            maxMeasure: null,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
          },
          {
            name: "TSS",
            label: "TSS",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: null,
            max: null,
            minMeasure: null,
            maxMeasure: null,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
          },
          {
            name: "NH3N",
            label: "NH3N",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: null,
            max: null,
            minMeasure: null,
            maxMeasure: null,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
          },
          {
            name: "Debit",
            label: "Debit",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: null,
            max: null,
            minMeasure: null,
            maxMeasure: null,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
          },
        ],
      });

      this.titikPenaatanPopUpForm.namaTitikPenaatan = "";
      this.titikPenaatanPopUpForm.lintang = "";
      this.titikPenaatanPopUpForm.bujur = "";
      this.titikPenaatanPopUpForm.penerimaAirLimbah = "";
      this.titikPenaatanPopUpForm.das = "";
    },
    // tambah logger sensor
    tambahDataLoggerSensor() {
      // this.submitLogger()

      if (this.editLogger) {
        // edit
        this.actionUpdateLoggerRegistered(this.editLoggerID);
        console.log("EDIT BOS");

        this.editLogger = false;
        this.editLoggerID = "";
      } else {
        // createNew

        Swal.fire({
          title: "Tambah Logger",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "TAMBAH",
          cancelButtonText: "BATAL",
        }).then((result) => {
          if (result.isConfirmed) {
            this.loggerFormInput.append(
              "info_alat",
              this.loggerSensorPopUpForm.infoAlat
            );
            this.loggerFormInput.append(
              "logger_brand",
              this.loggerSensorPopUpForm.brand
            );
            this.loggerFormInput.append(
              "logger_mac_address",
              this.loggerSensorPopUpForm.mac
            );
            this.loggerFormInput.append(
              "logger_model",
              this.loggerSensorPopUpForm.model
            );
            this.loggerFormInput.append(
              "logger_serial_number",
              this.loggerSensorPopUpForm.sn
            );
            this.loggerFormInput.append(
              "lulus_uji_konektivitas",
              this.loggerSensorPopUpForm.lulusUji
            );
            this.loggerFormInput.append(
              "sparing_compliance_point_id",
              this.loggerSensorIndex.id
            );
            this.loggerFormInput.append(
              "nama_penyedia_barang_dan_jasa",
              this.loggerSensorPopUpForm.namaPenyedia
            );
            this.loggerFormInput.append(
              "nomor_surat_lulus_uji_konektivitas",
              this.loggerSensorPopUpForm.noSurat
            );

            let headers = {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            };

            API.post(
              "companies/sparings/compliance-points/detail",
              this.loggerFormInput,
              { headers }
            )
              .then(({ data }) => {
                Swal.fire(
                  "Logger Berhasil dibuat",
                  "Data Berhasil didaftarkan",
                  "success"
                );
                // this.getLogerRegistered(this.loggerSensorIndex.id)

                this.getListDataTitikPenaatanForCreateLogger();

                this.loggerFormInput = new FormData();
                this.loggerSensorPopUpForm = {
                  brand: "",
                  model: "",
                  sn: "",
                  mac: "",
                  lulusUji: "",
                  infoAlat: "",
                  namaPenyedia: "",
                  noSurat: "",
                  suratketerangan: "",
                };

                this.loggerFormInput = new FormData();

                this.showFormLoggerSensorFormModal = false;

                this.fileSuratKeteranganLulusUji++;
                this.fileHasilKalibrasiSaatAwalPemasanganAlat++;
                this.fileHasilKalibrasiAlatRutin++;
                this.fileHasilUjiKinerjaDariLabotarium++;
                this.fileSuratDukunganBrandSensor++;
                this.fileSuratPernyataan++;
                this.fileKontakKerjaSama++;
              })
              .catch((err) => {
                console.log(err);
                Swal.fire("Pendaftaran Gagal", "terjadi kesalahan", "error");
              });
          }
        });
      }

      console.log("RUN");

      // console.log("add Logger Sensor");

      // console.log(this.loggerSensorIndex);
      // let indexToEdit = this.form.teknis.spotList.findIndex(
      //   (tableRow) => tableRow.spotName === this.loggerSensorIndex.spotName
      // );

      // persyaratan teknis
      // this.form.teknis.spotList[indexToEdit].lulusUji = this.loggerSensorPopUpForm.lulusUji
      // this.form.teknis.spotList[indexToEdit].infoAlat = this.loggerSensorPopUpForm.infoAlat
      // this.form.teknis.spotList[indexToEdit].namaPenyedia = this.loggerSensorPopUpForm.namaPenyedia
      // this.form.teknis.spotList[indexToEdit].noSurat = this.loggerSensorPopUpForm.noSurat

      // logger
      // this.form.teknis.spotList[indexToEdit].brand = this.loggerSensorPopUpForm.brand
      // this.form.teknis.spotList[indexToEdit].model = this.loggerSensorPopUpForm.model
      // this.form.teknis.spotList[indexToEdit].sn = this.loggerSensorPopUpForm.sn
      // this.form.teknis.spotList[indexToEdit].mac = this.loggerSensorPopUpForm.mac

      // this.form.teknis.spotList[indexToEdit].sensor = [
      //   {
      //     "name": "PH",
      //     "brand": this.form.sensor[0].brand,
      //     "probe": this.form.sensor[0].probe,
      //     "sensor_range_min": +this.form.sensor[0].min,
      //     "sensor_range_max": +this.form.sensor[0].max,
      //     "quality_standard_min": +this.form.sensor[0].minMeasure,
      //     "quality_standard_max": +this.form.sensor[0].maxMeasure,
      //     "measurement_method": this.form.sensor[0].metodePengukuran,
      //     "calibration_schedule": this.form.sensor[0].jadwalKalibrasi,
      //     "calibration_date": this.form.sensor[0].tanggalKalibrasi,
      //     "calibration_method": this.form.sensor[0].metodeKalibrasi
      //   },
      //   {
      //     "name": "COD",
      //     "brand": this.form.sensor[1].brand,
      //     "probe": this.form.sensor[1].probe,
      //     "sensor_range_min": +this.form.sensor[1].min,
      //     "sensor_range_max": +this.form.sensor[1].max,
      //     "quality_standard_min": +this.form.sensor[1].minMeasure,
      //     "quality_standard_max": +this.form.sensor[1].maxMeasure,
      //     "measurement_method": this.form.sensor[1].metodePengukuran,
      //     "calibration_schedule": this.form.sensor[1].jadwalKalibrasi,
      //     "calibration_date": this.form.sensor[1].tanggalKalibrasi,
      //     "calibration_method": this.form.sensor[1].metodeKalibrasi
      //   },
      //   {
      //     "name": "TSS",
      //     "brand": this.form.sensor[2].brand,
      //     "probe": this.form.sensor[2].probe,
      //     "sensor_range_min": +this.form.sensor[2].min,
      //     "sensor_range_max": +this.form.sensor[2].max,
      //     "quality_standard_min": +this.form.sensor[2].minMeasure,
      //     "quality_standard_max": +this.form.sensor[2].maxMeasure,
      //     "measurement_method": this.form.sensor[2].metodePengukuran,
      //     "calibration_schedule": this.form.sensor[2].jadwalKalibrasi,
      //     "calibration_date": this.form.sensor[2].tanggalKalibrasi,
      //     "calibration_method": this.form.sensor[2].metodeKalibrasi
      //   },
      //   {
      //     "name": "NH3N",
      //     "brand": this.form.sensor[3].brand,
      //     "probe": this.form.sensor[3].probe,
      //     "sensor_range_min": +this.form.sensor[3].min,
      //     "sensor_range_max": +this.form.sensor[3].max,
      //     "quality_standard_min": +this.form.sensor[3].minMeasure,
      //     "quality_standard_max": +this.form.sensor[3].maxMeasure,
      //     "measurement_method": this.form.sensor[3].metodePengukuran,
      //     "calibration_schedule": this.form.sensor[3].jadwalKalibrasi,
      //     "calibration_date": this.form.sensor[3].tanggalKalibrasi,
      //     "calibration_method": this.form.sensor[3].metodeKalibrasi
      //   },
      //   {
      //     "name": "DEBIT",
      //     "brand": this.form.sensor[4].brand,
      //     "probe": this.form.sensor[4].probe,
      //     "sensor_range_min": +this.form.sensor[4].min,
      //     "sensor_range_max": +this.form.sensor[4].max,
      //     "quality_standard_min": +this.form.sensor[4].minMeasure,
      //     "quality_standard_max": +this.form.sensor[4].maxMeasure,
      //     "measurement_method": this.form.sensor[4].metodePengukuran,
      //     "calibration_schedule": this.form.sensor[4].jadwalKalibrasi,
      //     "calibration_date": this.form.sensor[4].tanggalKalibrasi,
      //     "calibration_method": this.form.sensor[4].metodeKalibrasi
      //   },
      // ]

      // this.form.sensor = [
      //     {
      //       name: "pH",
      //       label: "pH",
      //       active: false,
      //       brand: null,
      //       probe: null,
      //       brosurFile: {
      //         path: "",
      //         upload: 0,
      //         progress: 0,
      //       },
      //       min: null,
      //       max: null,
      //       minMeasure: null,
      //       maxMeasure: null,
      //       metodePengukuran: null,
      //       jadwalKalibrasi: null,
      //       tanggalKalibrasi: new Date(),
      //       metodeKalibrasi: null,
      //     },
      //     {
      //       name: "COD",
      //       label: "COD",
      //       active: false,
      //       brand: null,
      //       probe: null,
      //       brosurFile: {
      //         path: "",
      //         upload: 0,
      //         progress: 0,
      //       },
      //       min: null,
      //       max: null,
      //       minMeasure: null,
      //       maxMeasure: null,
      //       metodePengukuran: null,
      //       jadwalKalibrasi: null,
      //       tanggalKalibrasi: new Date(),
      //       metodeKalibrasi: null,
      //     },
      //     {
      //       name: "TSS",
      //       label: "TSS",
      //       active: false,
      //       brand: null,
      //       probe: null,
      //       brosurFile: {
      //         path: "",
      //         upload: 0,
      //         progress: 0,
      //       },
      //       min: null,
      //       max: null,
      //       minMeasure: null,
      //       maxMeasure: null,
      //       metodePengukuran: null,
      //       jadwalKalibrasi: null,
      //       tanggalKalibrasi: new Date(),
      //       metodeKalibrasi: null,
      //     },
      //     {
      //       name: "NH3N",
      //       label: "NH3N",
      //       active: false,
      //       brand: null,
      //       probe: null,
      //       brosurFile: {
      //         path: "",
      //         upload: 0,
      //         progress: 0,
      //       },
      //       min: null,
      //       max: null,
      //       minMeasure: null,
      //       maxMeasure: null,
      //       metodePengukuran: null,
      //       jadwalKalibrasi: null,
      //       tanggalKalibrasi: new Date(),
      //       metodeKalibrasi: null,
      //     },
      //     {
      //       name: "Debit",
      //       label: "Debit",
      //       active: false,
      //       brand: null,
      //       probe: null,
      //       brosurFile: {
      //         path: "",
      //         upload: 0,
      //         progress: 0,
      //       },
      //       min: null,
      //       max: null,
      //       minMeasure: null,
      //       maxMeasure: null,
      //       metodePengukuran: null,
      //       jadwalKalibrasi: null,
      //       tanggalKalibrasi: new Date(),
      //       metodeKalibrasi: null,
      //     },
      // ]

      // this.loggerSensorPopUpForm.lulusUji = ""
      // this.loggerSensorPopUpForm.infoAlat = ""
      // this.loggerSensorPopUpForm.namaPenyedia = ""
      // this.loggerSensorPopUpForm.noSurat = ""

      // this.loggerSensorPopUpForm.brand = ""
      // this.loggerSensorPopUpForm.model = ""
      // this.loggerSensorPopUpForm.sn = ""
      // this.loggerSensorPopUpForm.mac = ""

      // this.loggerSensorIndex = {}

      // console.log("END RUN");
    },
    tutupDataLoggerSensor() {
      this.testingbro = "";
      this.showFormLoggerSensorFormModal = false;
      this.loggerFormInput = new FormData();

      this.fileSuratKeteranganLulusUji++;
      this.fileHasilKalibrasiSaatAwalPemasanganAlat++;
      this.fileHasilKalibrasiAlatRutin++;
      this.fileHasilUjiKinerjaDariLabotarium++;
      this.fileSuratDukunganBrandSensor++;
      this.fileSuratPernyataan++;
      this.fileKontakKerjaSama++;
    },
    // old
    getFormayYear(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    showFormTitikLoggerSensorModal() {
      this.showFormLoggerSensorFormModal = true;
    },
    
    uploadFilePermissionLater(category, field) {
      this.permissionLaterForm.append(field, this.$refs[field].files[0]);
    },

    handleEditForm() {
      this.showFormEditModal = true;
    },
    showStep() {
      this.isFormSuratIzinShow = true;
    },
    showIsNormalOrWaste() {
      this.cekStatusForm = false;
      // this.isNormalOrWaste = true
      Swal.fire({
        title: "Pilih tipe pendaftaran",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Pendaftaran Normal",
        denyButtonText: `Pendaftaran khusus yang memanfaatkan air limbah`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire("Pendaftaran Normal", "", "success");
          this.showFormRegisterWaste = false;
          this.showFormRegisterNormally = true;
        } else if (result.isDenied) {
          Swal.fire("Pendaftaran khusus air limbah", "", "success");
          this.showFormRegisterNormally = false;
          this.showFormRegisterWaste = true;
        }
      });
    },
    showStatusForm() {
      this.isNormalOrWaste = false;
      this.cekStatusForm = true;
      this.showFormRegisterWaste = false;
      this.showFormRegisterNormally = false;
    },
    // ======================================================== SUBMIT ====================================================
    submitWizard() {
      console.log("register submit");
      this.showFormRegisterNormally = false;

      let result_number_compliance_point_data = [];
      for (let i = 0; i < this.form.teknis.spotList.length; i++) {
        result_number_compliance_point_data.push({
          name: this.form.teknis.spotList[i].spotName,
          latitude: this.form.teknis.spotList[i].spotLoc[0],
          longitude: this.form.teknis.spotList[i].spotLoc[1],
          waste_water_receiving_agency:
            this.form.teknis.spotList[i].penerimaAirLimbah,
          das: this.form.teknis.spotList[i].das,
          status: "AC",
          technical_requirement: {
            connectivity_test_type: this.form.teknis.spotList[i].lulusUji,
            connectivity_test_vendor: this.form.teknis.spotList[i].namaPenyedia,
            connectivity_test_number: this.form.teknis.spotList[i].noSurat,
            connectivity_test_tool_info: this.form.teknis.spotList[i].infoAlat,
          },
          data_logger: [
            {
              brand: this.form.teknis.spotList[i].brand,
              model: this.form.teknis.spotList[i].model,
              serial_number: this.form.teknis.spotList[i].sn,
              mac_address: this.form.teknis.spotList[i].mac,
              sensor: this.form.teknis.spotList[i].sensor,
            },
          ],
        });
        console.log(
          this.form.teknis.spotList[i].sensor,
          "--------------------------------->"
        );
      }

      let payload = {
        polluted_sources: [
          {
            company_document_file_uid: this.form.uid,
            waste_water_sources: this.form.teknis.wasteSource,
            type_waste_water_treatment_technology:
              this.form.teknis.processingTechnique,
            type_waste_water: this.form.teknis.wasteType,
            debit_value: this.form.teknis.debit,
            debit_unit: this.form.teknis.satuanDebit,
            permitted_production_capacity: this.form.teknis.capacityPermit,
            actual_production_capacity: this.form.teknis.capacityActual,
            frequency_wastewater_disposal: {
              type: this.form.teknis.frequenceDet,
              background: this.form.frekuensi.latarBelakang,
              detail_engineering_ipal: this.form.frekuensi.detEngIPAL,
              day: this.form.frekuensi.jam,
              month: this.form.frekuensi.hari,
              year: this.form.frekuensi.bulan,
              measurement_method: this.form.frekuensi.metUkurDebit,
            },
            number_compliance_point_num:
              result_number_compliance_point_data.length + "",
            number_compliance_point_data: result_number_compliance_point_data,
          },
        ],
      };

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.post("web/master/registration/sparing-data", payload, { headers })
        .then(({ data }) => {
          console.log(data.registration_uid);
          Swal.fire(
            "Pendaftaran Berhasil",
            "Data Berhasil didaftarkan",
            "success"
          );
          // this.resetPayload(); --> apus
          this.showMainWizardForm = false;
          // this.$router.push("/sparing_main");

          return data.registration_uid;
        })
        .then((val) => {
          this.uploadFile(val, "sendNow");
        })
        .catch((err) => {
          console.log(err);
          Swal.fire("Pendaftaran Gagal", "terjadi kesalahan", "error");
        });
    },
    submit() {
      console.log("oke");
    },
    close() {
      this.$router.push("/Home");
    },
    resetPayload() {
      this.newsensor = [];
      this.form.uid = "";
      console.log("=========Akun");
      this.form.akun.personName = "";
      this.form.akun.personPhone = "";
      this.form.akun.personMail = "";
      this.form.akun.personMailID = "";
      this.form.akun.personPassword = "";
      this.form.akun.personConfirmPassword = "";
      console.log("=========Umum");
      this.form.umum.compName = "";
      this.form.umum.compNameID = "";
      this.form.umum.compType = "";
      this.form.umum.compAddress = "";
      this.form.umum.compStreet = "";
      this.form.umum.compCity = "";
      this.form.umum.compProvince = "";
      this.form.umum.compPhone = "";
      this.form.umum.compMail = "";
      this.form.umum.nameSIUP = "";
      this.form.umum.ifTambang.status = "";
      this.form.umum.ifTambang.jenKegiatan = "";
      this.form.umum.ifTambang.kondisiInternet = "";
      console.log("==================Identifikasi Sumber Pencemaran");
      this.form.teknis.permitPerson = "";
      this.form.teknis.permitNumber = "";
      this.form.teknis.permitDatePublish = "";
      this.form.teknis.permitDateExpire = "";
      this.form.teknis.wasteSource = "";
      this.form.teknis.spotCount = "";
      this.form.teknis.spotList = "";
      this.form.teknis.processingTechnique = "";
      this.form.teknis.wasteType = "";
      this.form.teknis.debit = "";
      this.form.teknis.satuanDebit = "";
      this.form.teknis.capacityPermit = "";
      this.form.teknis.capacityActual = "";
      this.form.teknis.frequenceDet = "";
      this.form.frekuensi.jam = "";
      this.form.frekuensi.hari = "";
      this.form.frekuensi.bulan = "";
      this.form.frekuensi.metUkurDebit = "";
      this.form.frekuensi.latarBelakang = "";
      this.form.frekuensi.detEngIPAL = "";
      console.log("==================Persyaratan Teknis");
      this.form.validitas.infoAlat = "";
      this.form.validitas.lulusUji = "";
      this.form.validitas.namaPenyedia = "";
      this.form.validitas.noSurat = "";
      this.form.validitas.jadwalKalibrasi = "";
      console.log("==================Form Logger");
      // this.form.logger[0] = "";
      console.log("==================Sensor");
      this.form.sensor = "";
    },
    
    // new
    addSensor() {
      this.permissionList.push({
        permitFile: {
          path: "",
          upload: 0,
          progress: 0,
        },
        permitPerson: null,
        permitNumber: null,
        permitDatePublish: new Date(),
        permitDateExpire: new Date(),
        wasteSource: null,
        spotCount: 1,
        spotList: [
          {
            spotName: "",
            spotLoc: [null, null],
            penerimaAirLimbah: "",
            das: "",
          },
        ],
        processingTechnique: null,
        wasteType: null,
        debit: null,
        satuanDebit: null,
        capacityPermit: null,
        capacityActual: null,
        frequenceDet: "",
      });
      this.check();
    },
    addTitik(index) {
      this.permissionList[index].spotList.push({
        spotName: "",
        spotLoc: [null, null],
        penerimaAirLimbah: "",
        das: "",
      });

      this.permissionList[index].spotCount =
        this.permissionList[index].spotList.length;
      this.check();
    },
    removeSensor(index) {
      this.permissionList.splice(index, 1);
    },
    check() {
      console.log(this.totalCount, "INI DIA");
    },
    
    submitForm() {
      console.log(this.submitData());
      // this.submitFile()
      // this.submitData()
      satuanDebit;
      let payload = {
        polluted_sources: {
          // permit_issuance_officer: this.form.teknis.permitPerson,
          // waste_water_disposal_permit_number: this.form.teknis.permitNumber,
          // issue_permit_date: this.form.teknis.permitDatePublish,
          // expired_permit_date: this.form.teknis.permitDateExpire,
          // waste_water_sources: this.form.teknis.wasteSource,
          // type_waste_water_treatment_technology:
          //   this.form.teknis.processingTechnique,
          // type_waste_water: this.form.teknis.wasteType,
          // debit_value: this.form.teknis.debit,
          // debit_unit: this.form.teknis.satuanDebit,
          // permitted_production_capacity: this.form.teknis.capacityPermit,
          // actual_production_capacity: this.form.teknis.capacityActual,
          number_compliance_point_num: this.form.teknis.spotList.length,
          number_compliance_point_data: dataTitikPenataan,
          frequency_wastewater_disposal: {
            // background: this.form.frekuensi.latarBelakang,
            // detail_engineering_ipal: this.form.frekuensi.detEngIPAL,
            // day: this.form.frekuensi.jam,
            // month: this.form.frekuensi.hari,
            // year: this.form.frekuensi.bulan,
            // measurement_method: this.form.frekuensi.metUkurDebit,
          },
          data_logger: [
            {
              // brand: this.form.logger[0].brand,
              // model: this.form.logger[0].model,
              // serial_number: this.form.logger[0].sn,
              // mac_address: this.form.logger[0].mac,
              // sensor: this.form.sensor,
            },
          ],
        },
        technical_requirements: {
          // connectivity_test_type: this.form.validitas.lulusUji,
          // connectivity_test_vendor: this.form.validitas.namaPenyedia,
          // connectivity_test_number: this.form.validitas.noSurat,
          // connectivity_test_tool_info: this.form.validitas.infoAlat,
        },
        waste_use: [
          {
            name: "Debit Air",
            value: "12452",
            unit: "m3/h",
            information: "Sata",
          },
        ],
      };
    },
    uploadSensorFile(category, field) {
      this.sensorFormInput.append(field, this.$refs[field][0].files[0]);
    },
  },
  watch: {
    // wizard one
    countWizard() {
      if (this.countWizard < 1) {
        return 0;
      }
      this.permissionList.spotCount = this.countWizard;
      let array = [];
      for (let i = 0; i < this.countWizard; i++) {
        array.push({
          permitFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          permitPerson: null,
          permitNumber: null,
          permitDatePublish: new Date(),
          permitDateExpire: new Date(),
          wasteSource: null,
          spotCount: 1,
          spotList: [
            {
              spotName: "",
              spotLoc: [null, null],
              penerimaAirLimbah: "",
              das: "",
            },
          ],
          processingTechnique: null,
          wasteType: null,
          debit: null,
          satuanDebit: null,
          capacityPermit: null,
          capacityActual: null,
          frequenceDet: "",
        });
      }
      this.permissionList = array;
      this.check();
      return array.length;
    },
  },
  computed: {
    frequencyText() {
      if (this.form.teknis.isContinue) return "Terus Menerus / Kontinyu";
      else return "Tidak Rutin / Intermiten";
    },
    sensorStatus(index) {
      if (this.form.sensor[index]) return "Digunakan";
      else return "Tidak digunakan";
    },
  },
};
</script>
<style>
.el-table .el-table__header-wrapper thead th .cell {
  justify-content: flex-start;
}

.myshadow {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.step-progress .bar {
  background-color: #1b674c !important;
}

.step-button {
  display: inline-block;
  font-weight: 600;
  color: #1b674c;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.step-button-next {
  background-color: #1b674c !important;
  color: #fff;
}

.step-pills .step-item.active {
  border: 1px solid #1b674c !important;
}

.step-pills .step-item {
  margin-right: 5px;
}

.step-item {
  position: relative;
  width: 200px;
  height: 100px;
}

.tabLabel {
  position: absolute;
  bottom: 10px;
  left: 10px;
  line-height: 17px;
}

.popup-modal-background {
  background: #fff;
}

.mdl-contentpopup {
  background: #fff;
  overflow-y: auto;
  max-height: 90%;
}
</style>
<template>
    <div class="wallpaper hfull">
      <!-- Header -->
      <div class="header  py-7 py-lg-8 pt-lg-9">
        <b-container >
          <div class="header-body text-center mb-7">
            <b-row class="justify-content-center">
              <b-col xl="5" lg="6" md="8" class="px-5">
                <h1 class="text-white">Pertanyaan Umum</h1>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>
      <!-- Page content -->
      <b-container class="mt--8 pb-5">
        <b-row class="justify-content-center">
          <b-col md="12">
            <div class="card bg-white border-0">
              <div class="p-4">
                <h2 class="text-justify">Pertanyaan Umum Dan Jawaban</h2>
                <div v-for="(pertanyaanUmum, index) in data" :key="index">
                  <h4 class="mt-6 mb-4 text-center">{{ getCategoryName(index) }}</h4>
                  <Collapse v-model="activeName" accordion>
                    <CollapseItem v-for="faq in pertanyaanUmum" :name="faq.id" :key="faq.id">
                      <template #title>
                        <b v-html="faq.question"></b>
                      </template>
                      <div v-html="faq.answer"></div>
                    </CollapseItem>
                  </Collapse>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  <script>
import { Collapse, CollapseItem } from "element-ui";
import API from '../../api/base_url';


export default {
  components: { Collapse, CollapseItem },
  data() {
    return {
      activeName: "1",
      data: [],
      listCategoryFaq: [],
      dummyList:  [
        {
            "_id": "62cc12d10f1a8501ae31d248",
            "categoryFaq": "62c71d563a4938737ccc3c91",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Melakukan pengecekan jenis industri wajib SPARING sesuai PermenLHK No P.80/2019 (dapat dilihat pada fitur<strong> Peraturan).</strong></p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>1. Bagaimana mengetahui apakah perusahaan saya adalah industri wajib SPARING?</strong></p>",
            "created_time": 1657541329,
            "status": true,
            "__v": 0,
            "updated_time": 1658722908
        },
        {
            "_id": "62cc13090f1a8501ae31e515",
            "categoryFaq": "62c71d563a4938737ccc3c91",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Bagi jenis industri wajib SPARING yang seluruh air limbah digunakan ulang (3R) maka tidak wajib memasang dan mengoperasikan SPARING. Dibuktikan dengan Persetujuan Lingkungan atau UKL/UPL yang menyatakan industri menggunakan ulang (3R) dan neraca air.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>2. Bagaimana jika perusahaan saya melaksanakan pemanfaatan air limbah untuk seluruh air limbah yang dihasilkan?</strong></p>",
            "created_time": 1657541385,
            "status": true,
            "__v": 0,
            "updated_time": 1658723062
        },
        {
            "_id": "62cc13360f1a8501ae31f572",
            "categoryFaq": "62c71d563a4938737ccc3c91",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Melakukan perhitungan total debit (m3/hari) berdasarkan debit maksimum yang tercantum di dalam izin/persetujuan teknis pembuangan air limbah ke badan air untuk seluruh titik penaataan. Jika total debit ≥1.000 m3/hari maka wajib memasang dan mengoperasikan SPARING, sedangkan total debit &lt; 1.000 m3/hari tidak wajib memasang dan mengoperasikan SPARING.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>3. Bagaimana jika perusahaan saya termasuk jenis industri tekstil?</strong></p>",
            "created_time": 1657541430,
            "status": true,
            "__v": 0,
            "updated_time": 1658723066
        },
        {
            "_id": "62cc16000f1a8501ae328fb6",
            "categoryFaq": "62c71d563a4938737ccc3c91",
            "jawaban": "<ul><li>Melaksanakan pemanfaatan air limbah pada tanah (land aplikasi) dibuktikan dengan izin pemanfaatan air limbah pada tanah, maka tidak wajib memasang dan mengoperasikan SPARING</li><li>Melakukan pembuangan air limbah ke badan air maka wajib memasang dan mengoperasikan SPARING</li></ul>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>4. Bagaimana jika perusahaan saya termasuk jenis industri sawit?</strong></p>",
            "created_time": 1657542144,
            "status": true,
            "__v": 0,
            "updated_time": 1658723070
        },
        {
            "_id": "62cc165b0f1a8501ae329d39",
            "categoryFaq": "62c71d563a4938737ccc3c91",
            "jawaban": "<ul><li>Memiliki izin injeksi air limbah ke formasi batuan, tidak wajib memasang dan mengoperasikan SPARING.</li><li>Diwajibkan hanya untuk eksplorasi dan produksi minyak dan gas bumi dari proses produksi migas di daratan (on shore).</li></ul>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>5. Bagaimana jika perusahaan saya termasuk jenis industri eksplorasi dan produksi minyak dan gas bumi?</strong></p>",
            "created_time": 1657542235,
            "status": true,
            "__v": 0,
            "updated_time": 1658723073
        },
        {
            "_id": "62cc16a20f1a8501ae32a395",
            "categoryFaq": "62c71d563a4938737ccc3c91",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Industri yang berada dalam kawasan industri, dinyatakan bahwa pengolahan air limbah berada di pihak kawasan dalam kontrak kerja sama pihak kawasan / estate regulation, maka tidak wajib memasang dan mengoperasikan SPARING</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>6. Bagaimana jika perusahaan saya berada dalam kawasan industri?</strong></p>",
            "created_time": 1657542306,
            "status": true,
            "__v": 0,
            "updated_time": 1658723076
        },
        {
            "_id": "62cc16d90f1a8501ae32a4d2",
            "categoryFaq": "62c71d563a4938737ccc3c91",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Industri yang seluruh air limbah diserahkan ke pihak ketiga wajib membuktikan dengan kontrak kerja sama pengelolaan air limbah dengan pihak ketiga, maka tidak wajib memasang dan mengoperasikan SPARING.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>7. Bagaimana jika seluruh air limbah diserahkan pihak ketiga?</strong></p>",
            "created_time": 1657542361,
            "status": true,
            "__v": 0,
            "updated_time": 1658723079
        },
        {
            "_id": "62cc17150f1a8501ae32a9cf",
            "categoryFaq": "62c71d563a4938737ccc3c91",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Jika usaha dan/atau kegiatan memilki lebih dari 1 titik penaatan maka titik penaatan yang dipasang Sparing merupakan titik penaatan yang memiliki beban terbesar.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>8. Bagaimana untuk usaha dan/atau kegiatan yang memiliki lebih dari 1 titik penaatan?</strong></p>",
            "created_time": 1657542421,
            "status": true,
            "__v": 0,
            "updated_time": 1658723081
        },
        {
            "_id": "62cc17780f1a8501ae32af20",
            "categoryFaq": "62c72034fc06bd8faa27c9fd",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Pastikan perusahaan Saudara termasuk dalam Industri wajib SPARING, maka langkah yang harus dilakukan adalah:</p><ul><li>Melakukan pemilihan alat sensor, smart data logger yang telah lulus uji konektivitas dan uji kelaikan/uji validasi.</li><li>Melakukan pemilihan spesifikasi alat sensor berupa rentang pengukuran dan akurasi bagi parameter yang diwajibkan sesuai dengan PermenLHK No P.80/2019.</li></ul>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>9. Jika perusahaan ingin melakukan pemasangan SPARING, langkah apa yang harus dilakukan?</strong></p>",
            "created_time": 1657542520,
            "status": true,
            "__v": 0,
            "updated_time": 1658723085
        },
        {
            "_id": "62cc17b60f1a8501ae32b3cb",
            "categoryFaq": "62c72034fc06bd8faa27c9fd",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Apabila belum memiliki alat, perusahaan dapat membeli alat sensor, smart data logger, sistem kelistrikan secara mandiri atau bekerja sama dengan perusahaan penyedia barang dan jasa alat sensor yang telah lulus uji konektivitas (dapat dilihat pada fitur Peraturan).</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>10. Bagaimana jika perusahaan saya belum memiliki alat?</strong></p>",
            "created_time": 1657542582,
            "status": true,
            "__v": 0,
            "updated_time": 1658723093
        },
        {
            "_id": "62cc1aab0f1a8501ae32d53b",
            "categoryFaq": "62c72034fc06bd8faa27c9fd",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Beberapa penyedia barang dan jasa alat sensor yang telah lulus uji konektivitas (dapat dilihat pada fitur <strong>Peraturan</strong>).</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>11. Perusahaan penyedia barang dan jasa alat sensor mana saja yang telah lulus uji konektivitas dan memiliki Surat Keterangan Lulus Uji Konektivitas dari KLHK?</strong></p>",
            "created_time": 1657543339,
            "status": true,
            "__v": 0,
            "updated_time": 1673408230
        },
        {
            "_id": "62cc1b0a0f1a8501ae32d98a",
            "categoryFaq": "62c72034fc06bd8faa27c9fd",
            "jawaban": "<ul><li>Industri melakukan uji kelaikan alat sensor kepada Laboratorium Lingkungan yang ditunjuk oleh KLHK.</li><li>Industri dapat bekerja sama dengan penyedia barang dan jasa alat sensor untuk membantu uji kelaikan alat sensor kepada Laboratorium Lingkungan yang ditunjuk oleh KLHK.</li><li>Industri melakukan kalibrasi alat sensor kepada laboratorium kalibrasi yang terakreditasi KAN.</li><li>Industri dapat bekerja sama dengan penyedia barang dan jasa alat sensor untuk membantu uji kalibrasi</li></ul>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>12. Bagaimana langkah selanjutnya setelah melakukan pemilihan alat sensor yang telah lulus uji konektivitas?&nbsp;</strong></p>",
            "created_time": 1657543434,
            "status": true,
            "__v": 0,
            "updated_time": 1658723105
        },
        {
            "_id": "62cc1c780f1a8501ae32fa5c",
            "categoryFaq": "62c72076fc06bd8faa27ca1f",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Agar bisa dikoneksikan dengan server KLHK perusahaan harus memiliki username, password dan UID (unit identification). Username, password dan UID (unit identification) dapat diperoleh setelah melakukan <strong>pendaftaran</strong> di laman sparing.ppkl.menlhk.go.id (fitur <strong>Pendaftaran</strong>) dan diverifikasi (validasi pendaftaran) oleh tim SPARING KLHK. Tanda terima pendaftaran berisi username, password dan UID (unit identification) akan di email melalui email yang telah didaftarkan.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>13. Bagaimana cara mengkonekasikan alat sensor ke pusat data KLHK?</strong></p>",
            "created_time": 1657543800,
            "status": true,
            "__v": 0,
            "updated_time": 1658723108
        },
        {
            "_id": "62cc1cb60f1a8501ae32fcd0",
            "categoryFaq": "62c72076fc06bd8faa27ca1f",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Persyaratan yang harus dipenuhi agar dapat terkoneksi ke Pusat Data KLHK dapat dilihat di laman sparing.ppkl.menlhk.go.id (fitur<strong> Pendaftaran</strong>)</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>14. Apa saja berkas yang dibutuhkan untuk melakukan pendaftaran?</strong></p>",
            "created_time": 1657543862,
            "status": true,
            "__v": 0,
            "updated_time": 1658723121
        },
        {
            "_id": "62cc1d060f1a8501ae33000b",
            "categoryFaq": "62c72076fc06bd8faa27ca1f",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Apabila perusahaan terkendala pandemi Covid-19, maka dapat menyampaikan dokumen sesuai dengan Surat Edaran Menteri. Perusahaan dapat mengunjungi laman sparing.ppkl.menlhk.go.id (fitur <strong>Pelaporan Terkendala Pandemi Covid-19</strong>) untuk melakukan pelaporan kemajuan pemasangan alat SPARING.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>15. Bagaimana jika perusahaan belum dapat melaksanakan SPARING sesuai dengan yang ditetapkan PERMENLHK yaitu pada tanggal 31 Agustus 2020 karena terkendala Pandemi COVID-19?</strong></p>",
            "created_time": 1657543942,
            "status": true,
            "__v": 0,
            "updated_time": 1658723127
        },
        {
            "_id": "62cc1d420f1a8501ae330116",
            "categoryFaq": "62c72076fc06bd8faa27ca1f",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Untuk sensor debit dilakukan kalibrasi oleh laboratorium kalibrasi terakreditasi.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>16. Apakah sensor debit dilakukan uji kelaikan/uji validasi?</strong></p>",
            "created_time": 1657544002,
            "status": true,
            "__v": 0,
            "updated_time": 1658723149
        },
        {
            "_id": "62cc1d770f1a8501ae3302d1",
            "categoryFaq": "62c72076fc06bd8faa27ca1f",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Melaksanakan kalibrasi sesuai dengan spesifikasi alat sensor dan perawatan berkala</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>17. Setelah pengoperasian alat sensor, kegiatan apa lagi yang harus dilakukan?</strong></p>",
            "created_time": 1657544055,
            "status": true,
            "__v": 0,
            "updated_time": 1658723146
        },
        {
            "_id": "62cc1db00f1a8501ae3304fd",
            "categoryFaq": "62c72097fc06bd8faa27ca34",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Industri melakukan pendaftaran kemudian diverifikasi (validasi pendaftaran) oleh tim SPARING KLHK.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>18. Bagaiamana mendapatkan username password dan UID (User Identification?</strong></p>",
            "created_time": 1657544112,
            "status": true,
            "__v": 0,
            "updated_time": 1658723144
        },
        {
            "_id": "62cc1de20f1a8501ae33066a",
            "categoryFaq": "62c72097fc06bd8faa27ca34",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Industri melakukan pendaftaran pada fitur pendaftaran melalui website sparing.ppkl.menlhk.go.id.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>19. Bagaiamana melakukan proses pendafataran?</strong></p>",
            "created_time": 1657544162,
            "status": true,
            "__v": 0,
            "updated_time": 1658723142
        },
        {
            "_id": "62cc1e9a0f1a8501ae330be6",
            "categoryFaq": "62c72097fc06bd8faa27ca34",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Industri/penangung jawab usaha dan atau kegiatan</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>20. Siapa yang melakukan pendaftaran?</strong></p>",
            "created_time": 1657544346,
            "status": true,
            "__v": 0,
            "updated_time": 1658723139
        },
        {
            "_id": "62cc36d00f1a8501ae37aefb",
            "categoryFaq": "62c72097fc06bd8faa27ca34",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Jika tidak dapat mengunggah dokumen maka:</p><ul><li>Lakukan pengecekan jaringan internet, pastikan tidak dibatasi</li><li>Lakukan pengecekan internet, apakah di private? Jika iya, maka ganti dengan jaringan internet umum</li></ul>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>21. Apa yang harus dilakukan jika terkendala dalam mengunggah dokumen di laman SPARING?</strong></p>",
            "created_time": 1657550544,
            "status": true,
            "__v": 0,
            "updated_time": 1658723190
        },
        {
            "_id": "62cc37050f1a8501ae37c2e9",
            "categoryFaq": "62c72097fc06bd8faa27ca34",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Jika tidak dapat klik Simpan, maka pastikan mengisi semua kolom.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>22. Apa yang harus dilakukan jika tidak dapat klik Simpan?</strong></p>",
            "created_time": 1657550597,
            "status": true,
            "__v": 0,
            "updated_time": 1658723186
        },
        {
            "_id": "62cc37350f1a8501ae37d6fd",
            "categoryFaq": "62c72097fc06bd8faa27ca34",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Setelah melakukan pendaftaran KLHK akan memberikan notifikasi jawaban dari permohonan pendaftaran SPARING melalui email yang terdaftar paling lambat dikirim 7 hari kerja setelah dikirimkan oleh penanggung jawab usaha dan/atau kegiatan.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>23. Apabila telah melakukan pendaftaran langkah apa yang selanjutnya dilakukan?</strong></p>",
            "created_time": 1657550645,
            "status": true,
            "__v": 0,
            "updated_time": 1658723183
        },
        {
            "_id": "62cc377c0f1a8501ae37f215",
            "categoryFaq": "62c720a1fc06bd8faa27caf8",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Uji konektivitas akan dilakukan setiap tahun atau sesuai dengan kebutuhan. Informasi akan diadakannya uji konektivitas akan disampaikan melalui website ppkl.menlhk.go.id, aplikasi SIMPEL dan aplikasi SPARING.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>24. Kapan jadwal diadakan uji konektivitas?</strong></p>",
            "created_time": 1657550716,
            "status": true,
            "__v": 0,
            "updated_time": 1658723179
        },
        {
            "_id": "62cc37d60f1a8501ae3815a4",
            "categoryFaq": "62c720a1fc06bd8faa27caf8",
            "jawaban": "<ul><li>Melakukan pendaftaran secara online, link pendaftaran akan diberitahukan di berita uji konekstivitas.</li><li>KLHK akan merespon dalam 3x24 jam berupa surat elektronik atau email yang memuat:</li></ul><ol><li>Nomer register industri dan/atau penyedia barang dan jasa alat SPARING (UID)</li><li>Jadwal pelaksanaan uji konektivitas</li><li>Mekanisme pengiriman data</li></ol><ul><li>Pelaksanaan Uji Konektivitas</li><li>Berdasarkan serangkaian tahapan uji yang dilakukan, jika test berhasil dilakukan maka penanggung jawab usaha dan/atau kegiatan atau penyedia barang dan jasa alat SPARING dinyatakan telah lulus uji konektivitas dan mendapatkan Surat Lulus Uji Konektivitas dari KLHK.</li></ul>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>25. Bagaimana tahapan uji konektivitas?</strong></p>",
            "created_time": 1657550806,
            "status": true,
            "__v": 0,
            "updated_time": 1673408449
        },
        {
            "_id": "62cc381a0f1a8501ae382f50",
            "categoryFaq": "62c720a1fc06bd8faa27caf8",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">&nbsp;Industri dan penyedia barang dan jasa alat sensor</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>26. Siapa yang dapat mengikuti uji konektivitas?</strong></p>",
            "created_time": 1657550874,
            "status": true,
            "__v": 0,
            "updated_time": 1673408441
        },
        {
            "_id": "62cc38420f1a8501ae3841d2",
            "categoryFaq": "62c720b5fc06bd8faa27cb02",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Pengiriman notifikasi melebihi baku mutu (sebagai early warning system) berfungsi untuk mengetahui secara cepat dan rill jika terjadi hasil pemantauan air limbah yang melebihi baku mutu agar segera dapat melakukan perbaikan.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>27. Apakah fungsi early warning system?</strong></p>",
            "created_time": 1657550914,
            "status": true,
            "__v": 0,
            "updated_time": 1658723169
        },
        {
            "_id": "62cc38940f1a8501ae38624a",
            "categoryFaq": "62c720d0fc06bd8faa27cb12",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Kondisi tidak normal meliputi:</p><ul><li>Penghentian sementara dan penyalaan kembali operasi produksi</li><li>Kalibrasi peralatan</li><li>Kondisi lain yang menyebabkan SPARING tidak dapat digunakan secara optimal</li></ul>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>28. Kondisi apa saja yang menyebabkan kondisi tidak normal?</strong></p>",
            "created_time": 1657550996,
            "status": true,
            "__v": 0,
            "updated_time": 1658723165
        },
        {
            "_id": "62cc3b930f1a8501ae39c08c",
            "categoryFaq": "62c720d0fc06bd8faa27cb12",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Kondisi lain yang menyebabkan SPARING tidak dapat digunakan secara optimal diantaranya:</p><ul><li>perbaikan IPAL dan pada saat startup dan shutdown IPAL,</li><li>perbaikan produksi,</li><li>gangguan supply listrik,</li><li>perbaikan/kalibrasi peralatan SPARING insidentil,</li><li>pada saat curah hujan ekstrim dengan rerata jauh di atas rerata tahunan.</li><li>gangguan keamanan seperti pencurian dan kerusuhan</li><li>gangguan binatang yang mengakibatkan kerusakan peralatan SPARING</li><li>kondisi darurat dan/atau bencana alam yang meliputi banjir, gempa bumi, gunung meletus, serta kondisi darurat lainnya yang ditetapkan oleh peraturan perundangan.</li></ul>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>29. Kondisi apa saja yang menyebabkan SPARING tidak dapat digunakan secara optimal?</strong></p>",
            "created_time": 1657551763,
            "status": true,
            "__v": 0,
            "updated_time": 1658723162
        },
        {
            "_id": "62cc3bdd0f1a8501ae39e45b",
            "categoryFaq": "62c720d0fc06bd8faa27cb12",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Dalam hal terjadi kondisi tidak normal, hasil pemantauan kualitas air limbah dapat melebihi baku mutu air limbah paling banyak 5% (lima persen) dari data rata-rata harian pemantauan selama 1 (satu) bulan berturut-turut. Berkenaan dengan kondisi tidak normal dan darurat tersebut maka Penanggungjawab usaha/kegiatan dalam jangka waktu 3 x 24 (tiga kali dua puluh empat) jam setelah mengalami kondisi tidak normal (abnormal) atau darurat diwajibkan melaporkan secara online melalui website SPARING fitur pelaporan kondisi tidak normal.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>30. Kapan harus melakukan pelaporan jika hasil pemantauan kualitas air limbah melebihi baku mutu air limbah akibat kondisi tidak normal?</strong></p>",
            "created_time": 1657551837,
            "status": true,
            "__v": 0,
            "updated_time": 1658723155
        },
        {
            "_id": "62cc3c1b0f1a8501ae3a0221",
            "categoryFaq": "62c720d8fc06bd8faa27cb1e",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Walaupun menggunakan Vsat, data tersebut dapat langsung terkirim ke server KLHK tanpa masuk ke user.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>31. Jika tidak ada jaringan, maka user menggunakan Vsat. Apakah data akan masuk dahulu ke user?</strong></p>",
            "created_time": 1657551899,
            "status": true,
            "__v": 0,
            "updated_time": 1658723206
        },
        {
            "_id": "62cc3c470f1a8501ae3a1462",
            "categoryFaq": "62c720d8fc06bd8faa27cb1e",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Industri menyampaikan bukti bahwa air limbah tidak dibuang secara terus menerus (continue) pada fitur pendaftaran. Bukti akan diverifikasi oleh KLHK, jika dapat diterima maka pusat data KLHK akan menyesuaikan perhitungan data pelaporan dan baku mutu.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>32. Bagaimana jika industri tidak mengeluarkan air limbah setiap jam?</strong></p>",
            "created_time": 1657551943,
            "status": true,
            "__v": 0,
            "updated_time": 1658723209
        },
        {
            "_id": "62cc3c850f1a8501ae3a320c",
            "categoryFaq": "62c720d8fc06bd8faa27cb1e",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Alat sensor dan data logger termasuk sistem telemetri (jaringan internet, GSM, radio, satelit) akan dipasang di sekitar effluent IPAL (titik penaatan), yang biasanya berada di luar hazard area. Jika diharuskan dipasang di area hazard, maka perlu dicari alat yang memenuhi persyaratan tersebut.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>33. Bagaimana jika berada di hazard area dan harus memasang alat SPARING, namun GSM tidak boleh masuk?</strong></p>",
            "created_time": 1657552005,
            "status": true,
            "__v": 0,
            "updated_time": 1658723200
        },
        {
            "_id": "62cc3cb20f1a8501ae3a478b",
            "categoryFaq": "62c720d8fc06bd8faa27cb1e",
            "jawaban": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Tidak ada budget threshold dari KLHK untuk proses pengadaan sepanjang alat sesuai dengan spesifikasi yang tercantum pada Permen LHK No. 80/2019.</p>",
            "pertanyaan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\"><strong>34. Apakah ada budget threshold dari KLHK untuk proses pengadaan?</strong></p>",
            "created_time": 1657552050,
            "status": true,
            "__v": 0,
            "updated_time": 1673408151
        }
      ],
      dummyCategory: [
    {
        "_id": "62c71d563a4938737ccc3c91",
        "name": " A. Jenis Industri Wajib SPARING",
        "status": "true",
        "created_time": 1657214498,
        "updated_time": null,
        "__v": 0
    },
    {
        "_id": "62c72034fc06bd8faa27c9fd",
        "name": " B. Pemasangan SPARING",
        "status": "true",
        "created_time": 1657214498,
        "updated_time": null,
        "__v": 0
    },
    {
        "_id": "62c72076fc06bd8faa27ca1f",
        "name": " C.Pengoperasian SPARING",
        "status": "true",
        "created_time": 1657214498,
        "updated_time": null,
        "__v": 0
    },
    {
        "_id": "62c72097fc06bd8faa27ca34",
        "name": " D. Pendaftaran",
        "status": "true",
        "created_time": 1657214498,
        "updated_time": null,
        "__v": 0
    },
    {
        "_id": "62c720a1fc06bd8faa27caf8",
        "name": " E. Uji Konektivitas",
        "status": "true",
        "created_time": 1657214498,
        "updated_time": null,
        "__v": 0
    },
    {
        "_id": "62c720b5fc06bd8faa27cb02",
        "name": " F. Fitur Early Warning System",
        "status": "true",
        "created_time": 1657214498,
        "updated_time": null,
        "__v": 0
    },
    {
        "_id": "62c720d0fc06bd8faa27cb12",
        "name": " G. Pelaporan Kondisi Tidak Normal",
        "status": "true",
        "created_time": 1657214498,
        "updated_time": null,
        "__v": 0
    },
    {
        "_id": "62c720d8fc06bd8faa27cb1e",
        "name": " H. Lain-lain",
        "status": "true",
        "created_time": 1657214498,
        "updated_time": null,
        "__v": 0
    }
      ]
    };
  },
  created() {
    this.getFaqList();
    this.getFaqCategoryList();
  },
  methods: {
    async getFaqList() {
      const { data } = await API.get(`faqs`);
      this.data = this.groupByCategory(data.data);
    },
    groupByCategory(data) {
    return data.reduce((prev, curr) => {
      (prev[curr['faq_category_id']] = prev[curr['faq_category_id']] || []).push(curr);
      return prev;
    }, {});
    },
    getCategoryName(id){
      console.log(this.listCategoryFaq,'-------->');
        let category = this.listCategoryFaq.filter((item) => item.id == id);
        return category.length ? category[0].name : "";
      },
    async getFaqCategoryList() {
      const { data } = await API.get(`faq-types`);
      this.listCategoryFaq = data.data
    },
    },
};
</script>
  <style>
    .hfull {
      min-height: 100vh;
    }
  </style>
  
<template>
  <div class="hfull">
    <div class="p-3" style="display: flex; justifycontent: right">
      <button class="btn btn-danger" @click="close">X</button>
    </div>
    <div class="header pt-lg-5">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <img
              src="../../../../public/img/brand/img-logo-color.png"
              alt="klhk"
              class="mb-2"
            />
            <b-col md="12">
              <h2 class="text-white">
                PENDAFTARAN SISTEM PEMANTAUAN KUALITAS AIR LIMBAH SECARA TERUS
                MENERUS DAN DALAM JARINGAN (SPARING) BAGI PENANGGUNG JAWAB USAHA
                DAN/ATAU KEGIATAN
              </h2>
              <p class="text-lead text-white">
                Pendaftaran adalah fasilitas untuk pencatatan secara ONLINE dan
                validasi bagi penanggung jawab usaha dan/atau kegiatan yang
                bertujuan untuk mendapatkan USER NAME, PASSWORD, UID (User
                Identification).
              </p>
            </b-col>
          </b-row>
          <button class="btn btn-primary" @click="showAnnoncementModal = true">
            LIHAT MEKANISME
          </button>
        </div>
      </b-container>
    </div>

    <b-container class="mt-5 pb-5">
      <b-row class="justify-content-center">
        <b-col md="12">
          <b-card class="p-5">
            <h3 class="my-5 text-center">Informasi Umum</h3>
            <div>
              <div class="p-3">
                <section>
                  <validation-observer
                    v-slot="{ handleSubmit }"
                    ref="formValidator"
                  >
                    <b-form role="form" @submit.prevent="handleSubmit(registerNow)">

                      <div v-show="!isRegistered">
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label form-control-label">ID Pendaftaran</label>
                          <div class="col-lg-6">
                            <base-input
                              name="Id Pendaftaran"
                              v-model="idCekStatus"
                            ></base-input>
                            <small class="d-block mt--3 text-justify" v-if="!isRegistered">
                              Telusuri Riwayat Pendaftaran Sparing anda
                            </small>
                          </div>
                          <div class="col-lg-3" v-if="!isRegistered">
                            <button
                              type="button"
                              class="btn btn-primary w-100"
                              @click="cekSiupNumber(idCekStatus)"
                            >
                              <i class="ni ni-user-run"></i> CEK STATUS
                            </button>
                          </div>
                        </div>


                        <div v-if="showHistory">
                            <ValidationHistorys :data="histories" :is-loading-data="isLoadingData" @on-change-tab="getBackData" />
                        </div>


                        <p class="text-center">ATAU</p>
                        <hr />
                        <div class="col-lg-12 mb-3">
                          <button
                            type="button"
                            class="btn btn-secondary w-100"
                            @click="editCompanyForm"
                          >
                            Perubahan Data
                          </button>
                        </div>
                        <div class="col-lg-12">
                          <button
                            type="button"
                            class="btn btn-primary w-100"
                            @click="newCompanyForm"
                          >
                            Pendaftaran Baru
                          </button>
                        </div>
                      </div>

                      <div v-if="isRegistered">
                        <div class="form-group row">
                        <label class="col-lg-3 col-form-label form-control-label">Nomor SIUP <span v-if="!isRegistered">/ NIB Pendaftaran</span></label>
                        <div class="col-lg-7">
                          <base-input
                            name="Nomor SIUP"
                            v-model="form.umum.numberSIUP"
                            rules="required"
                          ></base-input>
                          <small class="d-block mt--3 text-justify" v-if="!isRegistered">
                            Jika anda sudah pernah terdaftar sebelumnya, Anda
                            tidak perlu mengisi form ini dan akan diarahkan ke
                            halaman Login
                          </small>
                        </div>
                        <!-- <div class="col-lg-2" v-if="!isRegistered">
                          <button
                            type="button"
                            class="btn btn-primary w-100"
                            @click="cekSiupNumber(form.umum.numberSIUP)"
                          >
                            <i class="ni ni-user-run"></i> CEK
                          </button>
                        </div> -->
                        </div>

                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label form-control-label">Permohonan Koneksi</label>
                          <div class="col-lg-9">
                            <base-input name="File Permohonan Koneksi">
                              <file-input
                                accept="application/pdf"
                                ref="company_connection_request_file"
                                @change="
                                  uploadFileBlob(formPublic,'company_connection_request_file')
                                "
                              ></file-input>
                            </base-input>
                            <small v-if="formPublic.company_connection_request_file" class="d-block mt-3 mb-2 text-justify">
                              <a :href="getBlobsFile(formPublic.company_connection_request_file)" target="_blank"
                                class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                            </small>

                            <small class="d-block text-justify">
                              Surat permohonan pendaftaran koneksi sparing
                              kepada Direktur Pengendalian Pencemaran Air.
                            </small>
                            <span v-if="form.umum.requestConnect"
                              >Data anda Sebelumnya :
                              <a
                                :href="form.umum.requestConnect"
                                target="blank"
                                style="color: blue"
                                >klik untuk mendownload</a
                              ></span
                            >
                          </div>
                        </div>

                        <!-- 3. Nama Perusahaan -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Nama Perusahaan</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Nama Perusahaan"
                              rules="required"
                              v-model="form.umum.compName"
                            ></base-input>
                            <small class="d-block mt--4 text-justify">
                              Kata dan tanda baca wajib sama dengan Nama
                              Perusahaan pada SIMPEL PPA
                            </small>
                          </div>
                        </div>

                        <!-- 4. Jenis Industri -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Jenis Industri</label
                          >
                          <div class="col-lg-9">
                            <base-input name="Jenis Industri" rules="required">
                              <el-select v-model="form.umum.compType">
                                <el-option
                                  v-for="option in provideSelect.companyType"
                                  :key="option.id"
                                  :label="option.name"
                                  :value="option.id"
                                ></el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
                        <!-- jika Tambang -->
                        <div
                          v-if="checkCompType(form.umum.compType)"
                          class="p-3 card border-0"
                          style="background: #ededed"
                        >
                          <div
                            class="mb-5 card-header px-4"
                            style="background: #ededed"
                          >
                            <!-- Detail -->
                          </div>
                          <div class="card-body p-3">
                            <div class="form-group row">
                              <label
                                class="col-lg-3 col-form-label form-control-label"
                                >Status Tambang</label
                              >
                              <div class="col-lg-9">
                                <base-input
                                  name="Status Tambang"
                                  rules="required"
                                >
                                  <el-select
                                    v-model="form.umum.ifTambang.status"
                                  >
                                    <el-option
                                      label="Aktif"
                                      value="Aktif"
                                    ></el-option>
                                    <el-option
                                      label="Reklamasi"
                                      value="Reklamasi"
                                    ></el-option>
                                    <el-option
                                      label="Pasca Tambang"
                                      value="Pasca Tambang"
                                    ></el-option>
                                  </el-select>
                                </base-input>
                              </div>
                            </div>

                            <div class="form-group row">
                              <label
                                class="col-lg-3 col-form-label form-control-label"
                                >Jenis Kegiatan</label
                              >
                              <div class="col-lg-9">
                                <base-input
                                  name="Jenis Kegiatan"
                                  rules="required"
                                >
                                  <el-select
                                    v-model="form.umum.ifTambang.jenKegiatan"
                                  >
                                    <el-option
                                      label="Pengolahan"
                                      value="Pengolahan"
                                    ></el-option>
                                    <el-option
                                      label="Penambangan"
                                      value="Penambangan"
                                    ></el-option>
                                    <el-option
                                      label="Pengolahan & Penambangan"
                                      value="Pengolahan & Penambangan"
                                    ></el-option>
                                  </el-select>
                                </base-input>
                              </div>
                            </div>

                            <div class="form-group row">
                              <label
                                class="col-lg-3 col-form-label form-control-label"
                              >
                                Rencana penetapan titik penaatan
                                <i>(complience point)</i> sampai dengan 5 tahun
                                kedepan (dokumen berupa tabel perencanaan)
                              </label>
                              <div class="col-lg-9">
                                <base-input
                                  name="Rencana penetapan titik penaatan"
                                >
                                  <file-input
                                    accept="application/pdf"
                                    ref="company_mine_compliance_point_file"
                                    @change="uploadFileBlob(formPublic, 'company_mine_compliance_point_file')"
                                  ></file-input>
                                </base-input>
                                <small v-if="formPublic.company_mine_compliance_point_file"
                                    class="d-block mt-3 mb-2 text-justify">
                                    <a :href="getBlobsFile(formPublic.company_mine_compliance_point_file)"
                                      target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                </small>
                              </div>
                            </div>

                            <div class="form-group row">
                              <label
                                class="col-lg-3 col-form-label form-control-label"
                                >Kondisi jaringan internet/GSM pada lokasi
                                remotearea</label
                              >
                              <div class="col-lg-9">
                                <html-editor
                                  v-model="form.umum.ifTambang.kondisiInternet"
                                ></html-editor>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- End of Jika Tambang -->

                        <!-- 5. Nama penanggung Jawab SIUP -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Nama Penanggung Jawab SIUP</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Nama Penanggung Jawab SIUP"
                              rules="required"
                              v-model="form.umum.nameSIUP"
                            ></base-input>
                          </div>
                        </div>

                        <!-- 6. Lampiran SIUP -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Lampiran SIUP</label
                          >
                          <div class="col-lg-9">
                            <base-input name="Lampiran SIUP">
                              <file-input
                                accept="application/pdf"
                                ref="company_siup_file"
                                @change="uploadFileBlob(formPublic,'company_siup_file')"
                              ></file-input>
                            </base-input>
                            <small v-if="formPublic.company_siup_file"
                                class="d-block mt-3 mb-2 text-justify">
                                <a :href="getBlobsFile(formPublic.company_siup_file)"
                                  target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                            </small>
                          </div>
                        </div>

                        <!-- 7. Alamat perusahaan -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Alamat Perusahaan</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Alamat Perusahaan"
                              rules="required"
                              v-model="form.umum.compAddress"
                            ></base-input>
                            <small class="d-block mt--3 text-justify">
                              Bagi Industri yang memiliki alamat berbeda antara
                              kantor pusat dan kantor kegiatan (Unit
                              Bisnis/site), Mohon diisi alamat kantor unit
                              bisnis
                            </small>
                          </div>
                        </div>

                        <!-- 8. Jalan -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Jalan</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Jalan"
                              rules="required"
                              v-model="form.umum.compStreet"
                            ></base-input>
                          </div>
                        </div>

                        <!-- 9. Provinsi -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Provinsi</label
                          >
                          <div class="col-lg-9">
                            <base-input name="Provinsi" rules="required">
                              <el-select
                                v-model="form.umum.compProvince"
                                @change="
                                  getCityDepentOnProvince(
                                    form.umum.compProvince
                                  )
                                "
                                filterable
                              >
                                <el-option
                                  v-for="option in provideSelect.provinsi"
                                  :key="option.id"
                                  :label="option.name"
                                  :value="option.id"
                                ></el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>

                        <!-- 10. Kabupaten kota -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Kabupaten / Kota</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Kabupaten / Kota"
                              rules="required"
                            >
                              <el-select
                                v-model="form.umum.compCity"
                                filterable
                              >
                                <el-option
                                  v-for="option in provideSelect.kabupatenKota"
                                  :key="option.id"
                                  :label="option.name"
                                  :value="option.id"
                                ></el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>

                        <!-- 11. Telepon Kantor -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Telepon Kantor</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Telepon Kantor"
                              rules="required"
                              v-model="form.umum.compPhone"
                              type="tel"
                            />
                          </div>
                        </div>

                        <!-- 12. Email kantor -->
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Email Kantor</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Email Kantor"
                              rules="required"
                              v-model="form.umum.compMail"
                              type="email"
                              autocomplete="username email"
                            />
                          </div>
                        </div>

                        <hr>
                        <h3 class="my-5 text-center">Informasi Pengguna</h3>
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Nama Kontak Person</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Nama Kontak Person"
                              rules="required"
                              v-model="form.akun.personName"
                            ></base-input>
                            <small class="d-block mt--3 text-justify">
                              Personil yang menangani pengolahan air limbah dan
                              terkait data SPARING
                            </small>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Nomor Handphone</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Nomor Handphone"
                              rules="required"
                              v-model="form.akun.personPhone"
                              type="tel"
                            />
                          </div>
                        </div>

                        <!-- <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Alamat</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Alamat user"
                              rules="required"
                              v-model="form.akun.personAddress"
                              type="tel"
                            />
                          </div>
                        </div> -->

                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Email Kontak Person</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Email Kontak Person"
                              rules="required"
                              v-model="form.akun.personMail"
                              type="email"
                              autocomplete="username email"
                            />
                            <base-input
                              name="Email Kontak Person ID"
                              type="hidden"
                              v-model="form.akun.personMailID"
                            ></base-input>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Password</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Password"
                              rules="required"
                              v-model="form.akun.personPassword"
                              type="password"
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Confirm Password</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Confirm Password"
                              rules="required"
                              v-model="form.akun.personConfirmPassword"
                              type="password"
                            />
                          </div>
                        </div>

                        <!-- <div style="display: flex; justify-content: center">
                          <button class="btn btn-primary mt-5 w-50" type="submit">
                            DAFTAR
                          </button>
                        </div> -->



                        <hr>
                         <!-- Main -->
                         <div class="card">
                          <div>
                            <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
                              <div>
                                <div class="p-5">
                                  <!-- Master Permit -->
                                  <div>
                                    <h3 class="text-center">Dokumen Perizinan</h3>
                                    <div class="form-group row mt-4">
                                      <label class="col-lg-3 col-form-label form-control-label"
                                        >Izin pembuangan air limbah ke Badan air / Persetujuan teknis</label
                                      >
                                      <div class="col-lg-9">
                                        <base-input name="Izin Pembuangan Air Limbah ke Media Air">
                                          <file-input accept="application/pdf" ref="attachment_file_id" @change="
                                            uploadFileBlob(form_permits, 'attachment_file_id')
                                            "></file-input>
                                        </base-input>
                                        <small v-if="form_permits.attachment_file_id"
                                            class="d-block mt-3 mb-2 text-justify">
                                            <a :href="getBlobsFile(form_permits.attachment_file_id)"
                                              target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                        </small>
                                      </div>
                                    </div>

                                    <!-- Pejabat penerbit izin -->
                                    <div class="form-group row">
                                      <label class="col-lg-3 col-form-label form-control-label"
                                        >Pejabat Penerbit Izin</label
                                      >
                                      <div class="col-lg-9">
                                        <base-input
                                          name="Pejabat Penerbit Izin"
                                          rules="required"
                                          v-model="form_permits.issuing_authority"
                                        ></base-input>
                                        <small class="d-block mt--3 text-justify">
                                          Contoh Kepala Dinas Modal dan Pelayanan Terpadu Satu Pintu
                                          (DPM-PTSP) Provinsi DKI Jakarta
                                        </small>
                                      </div>
                                    </div>

                                    <!-- Nomor Izin Pembuangan Air Limbah -->
                                    <div class="form-group row">
                                      <label class="col-lg-3 col-form-label form-control-label mt--2"
                                        >Nomor Izin Pembuangan Air Limbah (Definitif)</label
                                      >
                                      <div class="col-lg-9">
                                        <base-input
                                          name="Nomor Izin Pembuangan Air Limbah (Definitif)"
                                          rules="required"
                                          v-model="form_permits.no"
                                        ></base-input>
                                        <small class="d-block mt--3 text-justify">
                                          Diisi nomor izin pembuangan air limbah, bukan surat
                                          pemenuhan komitmen. Contoh : Nomor :
                                          503/KEP.B75BFFFC-PTSP/2019.
                                        </small>
                                      </div>
                                    </div>

                                    <!-- Tanggal Izin Terbit -->
                                    <div class="form-group row">
                                      <label class="col-lg-3 col-form-label form-control-label"
                                        >Tanggal Izin Terbit</label
                                      >
                                      <div class="col-lg-9">
                                        <base-input
                                          class="m-0"
                                          rules="required"
                                          name="Tanggal Izin Terbit"
                                        >
                                          <flat-picker
                                            name="Tanggal Izin Terbit"
                                            rules="required"
                                            slot-scope="{ focus, blur }"
                                            @on-open="focus"
                                            @on-close="blur"
                                            :config="selects.configs.flatpickr"
                                            class="form-control datepicker"
                                            v-model="form_permits.publication_date"
                                          ></flat-picker>
                                        </base-input>
                                      </div>
                                    </div>

                                    <!-- Berlaku -->
                                    <div class="form-group row">
                                      <label class="col-lg-3 col-form-label form-control-label"
                                        >Berlaku</label
                                      >
                                      <div class="col-lg-9">
                                        <base-input class="m-0" rules="required" name="Berlaku">
                                          <flat-picker
                                            name="Berlaku"
                                            rules="required"
                                            slot-scope="{ focus, blur }"
                                            @on-open="focus"
                                            @on-close="blur"
                                            :config="selects.configs.flatpickr"
                                            class="form-control datepicker"
                                            v-model="form_permits.expiration_date"
                                          ></flat-picker>
                                        </base-input>
                                      </div>
                                    </div>

                                    <div class="form-group row">
                                      <label class="col-lg-3 col-form-label form-control-label">Sumber Air Limbah</label>
                                      <div class="col-lg-9">
                                        <base-input name="Sumber Air Limbah" v-model="form.waste_source"></base-input>
                                        <small class="d-block mt--3 text-justify">
                                          Air Limbah proses produksi/air limbah utilitas/air limbah
                                          domestik/air limbah pencucian atau pengolahan mineral/air
                                          limbah penambangan mineral/air limbah pencucian atau
                                          pengolahan batu bara/air limbah penambangan batu bara.
                                        </small>
                                      </div>
                                    </div>
                                  </div>

                                 










































                                  <div>
                                    <h3 class="my-5 text-center">Titik Penaatan</h3>
                                    <div>
                                      <div class="mt-5">
                                        <div style="margin-bottom: 30px;display: flex;justify-content: space-between;">
                                          <div style="display:flex;flex-direction: column;line-height:0">
                                            <h3>Mendaftarkan Titik Penaatan</h3>
                                            <span><small>Identifikasi Sumber Pencemar, Persyaratan Teknis, Logger, Sensor</small></span>
                                          </div>
                                          <base-button size="md" type="secondary" @click="showFormTitikPenataanModal">
                                            <i class="ni ni-archive-2"></i>Tambah titik penaatan
                                          </base-button>
                                        </div>
                                        <!-- TABLE HERE -->
                                        <template>
                                          <div class="content">
                                            <div>
                                              <el-table :data="newPayloadTmp" row-key="index" header-row-class-name="thead-light"
                                                @sort-change="sortChange" @selection-change="selectionChange">
                                                <el-table-column min-width="180px" label="Nama Titik Penaatan">
                                                  <div slot-scope="action">
                                                    <b>{{ action.row.spot_name }}</b>
                                                  </div>
                                                </el-table-column>

                                                <el-table-column min-width="180px" label="Lintang">
                                                  <div slot-scope="action">
                                                    <b>{{ action.row.latitude }}</b>
                                                  </div>
                                                </el-table-column>

                                                <el-table-column min-width="180px" label="Bujur">
                                                  <div slot-scope="action">
                                                    <b>{{ action.row.longitude }}</b>
                                                  </div>
                                                </el-table-column>


                                                <el-table-column min-width="180px" label="AKSI">
                                                  <div slot-scope="action">
                                                    <base-button @click="populateUpdateStation(action.row)" type="warning" size="sm" icon>
                                                      RUBAH
                                                    </base-button>
                                                    <base-button @click="willDeleteStation(action.row)" type="danger" size="sm" icon>
                                                      HAPUS
                                                    </base-button>
                                                  </div>
                                                </el-table-column>
                                              </el-table>
                                            </div>
                                          </div>
                                        </template>
                                        <!-- END OF TABLE HERE -->
                                      </div>
                                    </div>
                                  </div>

                                  <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                                    <b-form role="form" @submit.prevent="handleSubmit(!getStationForUpdate ? submitStation : updateStation)"
                                      ref="formStation">
                                      <div v-show="showFormPenataanFormModal">
                                        <div class="mt-5">
                                          <h3 class="text-center mb-3">Identifikasi Sumber Pencemar</h3>
                                          <div>
                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Nama Titik Penaatan</label>
                                              <div class="col-lg-9">
                                                <base-input name="Nama Titik Penaatan" v-model="station.spot_name"
                                                  :rules="{ required: true }" />
                                                <small class="d-block mt--3 text-justify">
                                                  Nama titik penaatan yang akan didaftarkan
                                                  untuk SPARING WAJIB sama dengan titik penaatan
                                                  aktif pada SIMPEL PPA
                                                </small>
                                              </div>
                                            </div>

                                           

                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Lintang</label>
                                              <div class="col-lg-9">
                                                <base-input name="Lintang" type="text" v-model="station.latitude"
                                                  :rules="{ required: true , min_value: -90, max_value: 90 }" />
                                                <small class="d-block mt--3 text-justify">Contoh: -7.77751</small>
                                                <br />
                                                <small class="d-block mt--3 text-justify">Titik penaatan yang akan didaftarkan untuk
                                                  SPARING.</small>
                                              </div>
                                            </div>

                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Bujur</label>
                                              <div class="col-lg-9">
                                                <base-input name="Bujur" type="text" v-model="station.longitude"
                                                  :rules="{ required: true , min_value: -180, max_value: 180 }" />
                                                <small class="d-block mt--3 text-justify">Contoh: 110.37804</small>
                                                <br />
                                                <small class="d-block mt--3 text-justify">Titik penaatan yang akan didaftarkan untuk
                                                  SPARING.</small>
                                              </div>
                                            </div>

                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Nama Badan Air Penerima Air
                                                Limbah</label>
                                              <div class="col-lg-9">
                                                <base-input name="Nama Badan Air Penerima Air Limbah" v-model="station.waste_receiver"
                                                  :rules="{ required: true }" />
                                              </div>
                                            </div>

                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">DAS</label>
                                              <div class="col-lg-9">
                                                <base-input name="DAS" v-model="station.das" :rules="{ required: true }" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <!-- Jenis teknologi pengolahan air limbah -->
                                        <div class="form-group row">
                                          <label class="col-lg-3 col-form-label form-control-label mt--2">Jenis Teknologi Pengolahan
                                            AirLimbah</label>
                                          <div class="col-lg-9">
                                            <base-input name="Jenis Teknologi Pengolahan Air Limbah" v-model="station.processing_technique"
                                              :rules="{ required: true }" />
                                            <small class="d-block mt--3 text-justify">
                                              Disesuaikan dengan Izin pembuangan air limbah atau izin
                                              lingkungan/AMDAL/UKL UPL/RKL RPL. Contoh: Aerob,
                                              Anaerob, dll.
                                            </small>
                                          </div>
                                        </div>

                                        <!-- Jenis air limbah -->
                                        <div class="form-group row">
                                          <label class="col-lg-3 col-form-label form-control-label">Jenis Air Limbah</label>
                                          <div class="col-lg-9">
                                            <base-input name="Jenis Air Limbah" v-model="station.waste_type" :rules="{ required: true }" />
                                            <small class="d-block mt--3 text-justify">
                                              Contoh: air terproduksi, air limbah proses, air limbah
                                              lokasi penambangan, dll.
                                            </small>
                                          </div>
                                        </div>

                                        <!-- Debit -->
                                        <div class="form-group row">
                                          <label class="col-lg-3 col-form-label form-control-label">Debit</label>
                                          <div class="col-lg-9 d-flex px-0">
                                            <div class="col-6" id="debit">
                                              <base-input name="debit" type="number" v-model="station.debit_value"
                                                :rules="{ required: true }" />
                                              <small class="d-block mt--3 text-justify">Debit Maksimum yang wajib di dalam izin</small>
                                            </div>
                                            <div class="col-6">
                                              <base-input name="Satuan debit" :rules="{ required: true }">
                                                <el-select v-model="station.debit_unit">
                                                  <el-option v-for="(item, index) in [
                                                    'm/3 detik',
                                                    'm/3 menit',
                                                    'm/3 jam',
                                                    'm/3 hari',
                                                    'm3 bulan',
                                                  ]" :key="index" :label="item" :value="item" />
                                                </el-select>
                                              </base-input>
                                              <small class="d-block mt--3 text-justify">nama satuan</small>
                                            </div>
                                          </div>
                                        </div>

                                        <!-- Kapasitas produksi sesuai izin -->
                                        <div class="form-group row">
                                          <label class="col-lg-3 col-form-label form-control-label">Kapasitas Produksi Sesuai Izin</label>
                                          <div class="col-lg-9">
                                            <base-input name="Kapasitas Produksi Sesuai Izin" type="number"
                                              v-model="station.capacity_permit" :rules="{ required: true }" />
                                            <small class="d-block mt--3 text-justify">Izin Lingkungan/AMDAL/UKL UPL/RKL RPL</small>
                                          </div>
                                        </div>

                                        <!-- Kapasitas produksi senyatanya -->
                                        <div class="form-group row">
                                          <label class="col-lg-3 col-form-label form-control-label">Kapasitas Produksi Senyatanya</label>
                                          <div class="col-lg-9">
                                            <base-input name="Kapasitas Produksi Senyatanya" type="number" v-model="station.capacity_value"
                                              :rules="{ required: true }" />
                                            <small class="d-block mt--3 text-justify">
                                              Pencatatan logbook bulanan selama 1 atau 2 tahun
                                              terakhir. Kapasitas produksi rata-rata bulanan.
                                            </small>
                                          </div>
                                        </div>

                                        <!-- Frequensi pembuangan air limbah -->
                                        <div class="form-group row">
                                          <label class="col-lg-3 col-form-label form-control-label">Frekuensi Pembuangan Air Limbah</label>
                                          <div class="col-lg-9">
                                            <base-input name="Per tahun" :rules="{ required: true }">
                                              <el-select v-model="station.frequency.type">
                                                <el-option label="Terus Menerus/Kontinyu" value="Terus Menerus/Kontinyu"></el-option>
                                                <el-option label="Tidak Rutin/Intermiten" value="Tidak Rutin/Intermiten"></el-option>
                                              </el-select>
                                            </base-input>
                                          </div>
                                        </div>

                                        <hr />
                                        <h3 class="text-center mb-3">Persyaratan Teknis</h3>

                                        <!-- Detail frekuensi pembuangan air limbah -->
                                        <div v-if="station.frequency.type === 'Terus Menerus/Kontinyu'" class="card bg-white border-0">
                                          <div class="card-header px-4">
                                            Detail Frekuensi Pembuangan Air Limbah
                                          </div>
                                          <div class="card-body p-4">
                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Per hari</label>
                                              <div class="col-lg-9">
                                                <base-input name="Per hari">
                                                  <el-select v-model="station.frequency.jam">
                                                    <el-option v-for="item in 24" :key="item + ' jam'" :label="item + ' jam'"
                                                      :value="item"></el-option>
                                                  </el-select>
                                                </base-input>
                                              </div>
                                            </div>

                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Per minggu</label>
                                              <div class="col-lg-9">
                                                <base-input name="Per minggu">
                                                  <el-select v-model="station.frequency.hari">
                                                    <el-option v-for="item in 7" :key="item + ' hari'" :label="item + ' hari'"
                                                      :value="item"></el-option>
                                                  </el-select>
                                                </base-input>
                                              </div>
                                            </div>

                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Per tahun</label>
                                              <div class="col-lg-9">
                                                <base-input name="Per tahun">
                                                  <el-select v-model="station.frequency.bulan">
                                                    <el-option v-for="item in 12" :key="item + ' bulan'" :label="item + ' bulan'"
                                                      :value="item"></el-option>
                                                  </el-select>
                                                </base-input>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div v-if="station.frequency.type === 'Tidak Rutin/Intermiten'" class="card bg-white border-0">
                                          <div class="card-header px-4">Detail Frekuensi Pembuangan Air Limbah</div>
                                          <div class="card-body p-4">
                                            <!-- Latar belakang -->
                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Latar Belakang</label>
                                              <div class="col-lg-9">
                                                <html-editor v-model="station.frequency.latar_belakang"></html-editor>
                                                <small class="d-block mt--3 text-justify">
                                                  Penjelasan (deskripsi) dilakukan pembuangan air
                                                  limbah secara intermiten
                                                </small>
                                              </div>
                                            </div>

                                            <!-- Detail engineering IPAL -->
                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Detail Engineering IPAL</label>
                                              <div class="col-lg-9">
                                                <html-editor v-model="station.frequency.detail_eng_ipal"></html-editor>
                                              </div>
                                            </div>

                                            <!-- foto ipal -->
                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Foto IPAL</label>
                                              <div class="col-lg-9">
                                                <base-input name="Foto IPAL">
                                                  <file-input :disabled="uploading" accept="image/x-png, image/jpeg, application/pdf"
                                                    ref="ipal_file_id"
                                                    @change="uploadFileBlob(station.frequency, 'ipal_file_id')"></file-input>
                                                </base-input>
                                                <small v-if="station.frequency.ipal_file_id" class="d-block mt-3 mb-2 text-justify">
                                                  <a :href="getBlobsFile(station.frequency.ipal_file_id)" target="_blank"
                                                    class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                                </small>
                                                <small class="d-block mt-3 text-justify">Bangunan IPAL dan outlet.</small>
                                              </div>
                                            </div>

                                            <!-- perhari -->
                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Per hari</label>
                                              <div class="col-lg-9">
                                                <base-input name="Per hari">
                                                  <el-select v-model="station.frequency.jam">
                                                    <el-option v-for="item in 24" :key="item + ' jam'" :label="item + ' jam'"
                                                      :value="item"></el-option>
                                                  </el-select>
                                                </base-input>
                                              </div>
                                            </div>

                                            <!-- perminggu -->
                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Per minggu</label>
                                              <div class="col-lg-9">
                                                <base-input name="Per minggu">
                                                  <el-select v-model="station.frequency.hari">
                                                    <el-option v-for="item in 7" :key="item + ' hari'" :label="item + ' hari'"
                                                      :value="item"></el-option>
                                                  </el-select>
                                                </base-input>
                                              </div>
                                            </div>

                                            <!-- pertahun -->
                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Per tahun</label>
                                              <div class="col-lg-9">
                                                <base-input name="Per tahun">
                                                  <el-select v-model="station.frequency.bulan">
                                                    <el-option v-for="item in 12" :key="item + ' bulan'" :label="item + ' bulan'"
                                                      :value="item"></el-option>
                                                  </el-select>
                                                </base-input>
                                              </div>
                                            </div>

                                            <!-- metode pengukuran debit -->
                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Metode Pengukuran Debit</label>
                                              <div class="col-lg-9">
                                                <html-editor v-model="station.frequency.metode_ukur"></html-editor>
                                                <small class="d-block mt--3 text-justify">Metode pengukuran debit yang dilakukan secara
                                                  manual.</small>
                                              </div>
                                            </div>

                                            <!-- Logbook pembuangan air -->
                                            <div class="form-group row">
                                              <label class="col-lg-3 col-form-label form-control-label">Logbook Pembuangan Air
                                                Limbah</label>
                                              <div class="col-lg-9">
                                                <base-input name="Logbook">
                                                  <file-input accept="application/pdf" ref="logbook_file_id"
                                                    @change="uploadFileBlob(station.frequency, 'logbook_file_id')"></file-input>
                                                </base-input>
                                                <small v-if="station.frequency.logbook_file_id" class="d-block mt-3 mb-2 text-justify">
                                                  <a :href="getBlobsFile(station.frequency.logbook_file_id)" target="_blank"
                                                    class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                                </small>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <!-- Logger -->
                                        <div>
                                          <div>
                                            <form>
                                              <div class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">Lulus Uji Konektifitas</label>
                                                <div class="col-lg-9">
                                                  <base-input name="Lulus Uji Konektifitas">
                                                    <el-select v-model="station.tech_requirement.tipe_uji">
                                                      <el-option label="Individu" value="Individu"></el-option>
                                                      <el-option label="Penyedia Barang dan Jasa Alat Sparing"
                                                        value="Penyedia Barang dan Jasa Alat Sparing"></el-option>
                                                    </el-select>
                                                  </base-input>
                                                  <small class="d-block mt--3 text-justify">
                                                    Individu dipilih bagi industri yang memasang dan
                                                    mengoperasikan tanpa bekerja sama dengan
                                                    penyedia barang/jasa alat sensor dan logger.
                                                  </small>
                                                </div>
                                              </div>

                                              <!-- Nomor surat lulus uji konektifitas -->
                                              <div v-if="station.tech_requirement.tipe_uji === 'Individu'" class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">Nomor Surat Lulus Uji
                                                  Konektivitas</label>
                                                <div class="col-lg-9">
                                                  <base-input name="Brand" v-model="station.tech_requirement.no_surat" />
                                                  <small class="d-block mt--3 text-justify">Nomor surat Lulus Uji Konektivitas diterbitkan
                                                    olehKLHK</small>
                                                </div>
                                              </div>

                                              <!-- Surat keterangan lulus uji konektifitas -->
                                              <div class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">Surat Keterangan Lulus Uji
                                                  Konektivitas</label>
                                                <div class="col-lg-9">
                                                  <base-input name="Lulus Uji Konektivitas">
                                                    <file-input accept="application/pdf" ref="lulus_uji_file_id"
                                                      @change="uploadFileBlob(station.tech_requirement, 'lulus_uji_file_id')" />
                                                  </base-input>
                                                  <small v-if="station.tech_requirement.lulus_uji_file_id"
                                                    class="d-block mt-3 mb-2 text-justify">
                                                    <a :href="getBlobsFile(station.tech_requirement.lulus_uji_file_id)" target="_blank"
                                                      class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                                  </small>
                                                  <small class="d-block mt-3 text-justify">Wajib yang masih berlaku</small>
                                                </div>
                                              </div>

                                              <!-- Nama penyedia barang dan jasa -->
                                              <div v-if="station.tech_requirement.tipe_uji === 'Penyedia Barang dan Jasa Alat Sparing'"
                                                class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">Nama Penyedia Barang dan
                                                  Jasa</label>
                                                <div class="col-lg-9">
                                                  <base-input name="Brand" v-model="station.tech_requirement.nama_penyedia" />
                                                  <small class="d-block mt--3 text-justify">Surat Keterangan Lulus Uji Konektivitas.</small>
                                                </div>
                                              </div>

                                              <!-- Kontrak kerja sama -->
                                              <div v-if="station.tech_requirement.tipe_uji === 'Penyedia Barang dan Jasa Alat Sparing'"
                                                class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">Kontrak Kerjasama</label>
                                                <div class="col-lg-9">
                                                  <base-input name="Surat Keterangan Lulus Uji Konektivitas">
                                                    <file-input accept="application/pdf" ref="kontak_kerjasama_file_id"
                                                      @change="uploadFileBlob(station.tech_requirement, 'kontak_kerjasama_file_id')"></file-input>
                                                  </base-input>
                                                  <small v-if="station.tech_requirement.kontak_kerjasama_file_id"
                                                    class="d-block mt-3 mb-2 text-justify">
                                                    <a :href="getBlobsFile(station.tech_requirement.kontak_kerjasama_file_id)"
                                                      target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                                  </small>
                                                </div>
                                              </div>

                                              <!-- Info Alat -->
                                              <div v-if="station.tech_requirement.tipe_uji !== null" class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">Info Alat</label>
                                                <div class="col-lg-9">
                                                  <base-input name="Per tahun" rules="required">
                                                    <el-select v-model="station.tech_requirement.info_alat">
                                                      <el-option label="Baru" value="Baru"></el-option>
                                                      <el-option label="Lama" value="Lama"></el-option>
                                                    </el-select>
                                                  </base-input>
                                                  <small class="d-block mt--3 text-justify">
                                                    Alat lama adalah sensor yang sudah terpasang
                                                    sebelum Permen LH 93/2018.
                                                  </small>
                                                </div>
                                              </div>

                                              <div v-if="station.tech_requirement.tipe_uji !== null" class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">Hasil Kalibrasi Saat Awal
                                                  Pemasangan Alat</label>
                                                <div v-if="station.tech_requirement.tipe_uji !== null" class="col-lg-9">
                                                  <base-input name="Hasil Kalibrasi Saat Awal Pemasangan Alat">
                                                    <file-input accept="application/pdf" ref="kalibrasi_awal_file_id" @change="
                                                      uploadFileBlob(station.tech_requirement, 'kalibrasi_awal_file_id')
                                                      "></file-input>
                                                  </base-input>
                                                  <small v-if="station.tech_requirement.kalibrasi_awal_file_id"
                                                    class="d-block mt-3 mb-2 text-justify">
                                                    <a :href="getBlobsFile(station.tech_requirement.kalibrasi_awal_file_id)" target="_blank"
                                                      class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                                  </small>
                                                  <small class="d-block mt-3 text-justify">Sertifikat hasil uji kalibrasi.</small>
                                                </div>
                                              </div>

                                              <!-- Hasil kalibrasi alat rutin -->
                                              <div v-if="station.tech_requirement.tipe_uji !== null" class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">Hasil Kalibrasi Alat Rutin</label>
                                                <div class="col-lg-9">
                                                  <base-input name="Hasil Kalibrasi Alat Rutin">
                                                    <file-input accept="application/pdf" ref="kalibrasi_rutin_file_id" @change="
                                                      uploadFileBlob(station.tech_requirement, 'kalibrasi_rutin_file_id')
                                                      "></file-input>
                                                  </base-input>
                                                  <small v-if="station.tech_requirement.kalibrasi_rutin_file_id"
                                                    class="d-block mt-3 mb-2 text-justify">
                                                    <a :href="getBlobsFile(station.tech_requirement.kalibrasi_rutin_file_id)"
                                                      target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                                  </small>
                                                  <small class="d-block mt-3 text-justify">Sertifikat hasil uji kalibrasi.</small>
                                                </div>
                                              </div>

                                              <!-- Hasil uji kinerja dari labotarium yang ditunjuk oleh KLHK -->
                                              <div v-if="station.tech_requirement.tipe_uji !== null" class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">
                                                  Hasil Uji Kinerja Dari Laboratorium Yang Ditunjuk
                                                  Oleh KLHK
                                                </label>
                                                <div class="col-lg-9">
                                                  <base-input name="Hasil Uji Kinerja Dari Laboratorium Yang Ditunjuk Oleh KLHK">
                                                    <file-input accept="application/pdf" ref="kinerja_file_id" @change="
                                                      uploadFileBlob(station.tech_requirement, 'kinerja_file_id')
                                                      "></file-input>
                                                  </base-input>
                                                  <small v-if="station.tech_requirement.kinerja_file_id"
                                                    class="d-block mt-3 mb-2 text-justify">
                                                    <a :href="getBlobsFile(station.tech_requirement.kinerja_file_id)" target="_blank"
                                                      class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                                  </small>
                                                  <small class="d-block mt-3 text-justify">
                                                    Uji kinerja alat wajib dilaksanakan di
                                                    laboratorium yang ditunjuk oleh KLHK cq. Pusat
                                                    Standarisasi Lingkungan dan Kehutanan.
                                                    Laboratorium dimaksud adalah laboratorium yang
                                                    melaksanakan pengujian kinerja alat pemantauan
                                                    online air sesuai prosedur dalam petunjuk teknis
                                                    Uji Kinerja Alat Pemantauan Online untuk Air
                                                    mengacu kepada SNI ISO 15839:2003.
                                                  </small>
                                                </div>
                                              </div>

                                              <!-- Surat dukungan dari brand sensor -->
                                              <div v-if="station.tech_requirement.tipe_uji !== null" class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">Surat Dukungan Dari Brand
                                                  Sensor</label>
                                                <div class="col-lg-9">
                                                  <base-input name="Surat Dukungan Dari Brand Sensor">
                                                    <file-input accept="application/pdf" ref="dukungan_brand_file_id" @change="
                                                      uploadFileBlob(station.tech_requirement, 'dukungan_brand_file_id')
                                                      "></file-input>
                                                  </base-input>
                                                  <small v-if="station.tech_requirement.dukungan_brand_file_id"
                                                    class="d-block mt-3 mb-2 text-justify">
                                                    <a :href="getBlobsFile(station.tech_requirement.dukungan_brand_file_id)" target="_blank"
                                                      class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                                  </small>
                                                  <small class="d-block mt-3 text-justify">Upload Surat Dukungan Dari Brand Sensor</small>
                                                </div>
                                              </div>

                                              <!-- Surat pernyataan -->
                                              <div v-if="station.tech_requirement.tipe_uji !== null" class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">Surat Pernyataan</label>
                                                <div class="col-lg-9">
                                                  <base-input name="Surat Pernyataan dari Vendor Sensor">
                                                    <file-input accept="application/pdf" ref="pernyataan_vendor_file_id" @change="
                                                      uploadFileBlob(station.tech_requirement, 'pernyataan_vendor_file_id')
                                                      "></file-input>
                                                  </base-input>
                                                  <small v-if="station.tech_requirement.pernyataan_vendor_file_id"
                                                    class="d-block mt-3 mb-2 text-justify">
                                                    <a :href="getBlobsFile(station.tech_requirement.pernyataan_vendor_file_id)"
                                                      target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                                  </small>
                                                  <small class="d-block mt-3 text-justify">
                                                    Khusus untuk perusahaan atau vendor yang merakit
                                                    alat sensor,
                                                    <b>secara mandiri</b> menyampaikan surat
                                                    pernyataan.
                                                  </small>
                                                </div>
                                              </div>

                                              <!-- ======================================= LOGGER ======================================= -->
                                              <hr />
                                              <h3 class="text-center mb-3">Logger</h3>

                                              <!-- Brand -->
                                              <div class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">Brand</label>
                                                <div class="col-lg-9">
                                                  <base-input name="Brand Logger" v-model="station.logger.brand"
                                                    :rules="{ required: true }" />
                                                  <small class="d-block mt--3 text-justify">
                                                    Jika menggunakan data logger Lokal (Rakitan
                                                    Lokal), disampaikan spesifikasinya
                                                  </small>
                                                </div>
                                              </div>

                                              <!-- Model -->
                                              <div class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">Model</label>
                                                <div class="col-lg-9">
                                                  <base-input name="Model" v-model="station.logger.model" :rules="{ required: true }" />
                                                </div>
                                              </div>

                                              <!-- Serial number -->
                                              <div class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">Serial Number</label>
                                                <div class="col-lg-9">
                                                  <base-input name="Serial Number" v-model="station.logger.sn" :rules="{ required: true }" />
                                                </div>
                                              </div>

                                              <!-- Mac Address -->
                                              <div class="form-group row">
                                                <label class="col-lg-3 col-form-label form-control-label">MAC Address</label>
                                                <div class="col-lg-9">
                                                  <base-input name="MAC Address" v-model="station.logger.mac" :rules="{ required: true }" />
                                                  <small class="d-block mt--3 text-justify">Contoh: OA:0E:AA:8R:77</small>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>

                                        <!-- Sensor -->
                                        <hr />
                                        <h3 class="text-center mb-3">Sensor</h3>

                                        <div class="card bg-white border-0">
                                          <div class="card-body p-4">
                                            <div v-for="(item, index) in station.logger.sensors" :key="item.type">
                                              <div class="form-group row">
                                                <label class="col-lg-2 col-form-label form-control-label">{{ item.type }}</label>
                                                <div class="col-lg-10">
                                                  <base-checkbox v-model="station.logger.sensors[index].is_active" class="mt-2 mb-3">
                                                    {{
                                                      (station.logger.sensors[index].is_active == 1
                                                      ? "Digunakan"
                                                      : "Tidak digunakan")
                                                    }}
                                                  </base-checkbox>
                                                  <small class="d-block mt--3 text-justify">
                                                    Aktifkan checkbox jika sensor digunakan untuk
                                                    mengisi data sensor
                                                  </small>
                                                </div>
                                              </div>
                                              <template v-if="station.logger.sensors[index].is_active">
                                                <div class="row">
                                                  <div class="col-lg-2"></div>
                                                  <div class="form-group col-lg-10 p-0">
                                                    <label class="col-12 col-form-label form-control-label py-0">Brand</label>
                                                    <div class="col-12">
                                                      <base-input rules="required" :name="`brand ${station.logger.sensors[index].name}`"
                                                        v-model="station.logger.sensors[index].brand" />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-lg-2"></div>
                                                  <div class="form-group col-lg-10 p-0">
                                                    <label class="col-12 col-form-label form-control-label py-0">Probe</label>
                                                    <div class="col-12">
                                                      <base-input :name="`Probe ${station.logger.sensors[index].name}`" rules="required"
                                                        v-model="station.logger.sensors[index].probe" />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-lg-2"></div>
                                                  <div class="form-group col-lg-10 p-0">
                                                    <label class="col-11 col-form-label form-control-label py-0">Brosur</label>
                                                    <div class="col-12">
                                                      <base-input :name="`Brosur ${station.logger.sensors[index].type}`">
                                                        <file-input accept="application/pdf" :ref="'brochure_file_id_' + index"
                                                          @change="uploadFileSensorBlob(station.logger.sensors[index],'brochure_file_id',`brochure_file_id_${index}`)" />
                                                      </base-input>
                                                      <small v-if="station.logger.sensors[index].brochure_file_id
                                                        " class="d-block mt-3 text-justify">
                                                        <a :href="getBlobsFile(station.logger.sensors[index].brochure_file_id)"
                                                          target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                                      </small>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-lg-2"></div>
                                                  <div class="form-group col-lg-10">
                                                    <label class="col-11 col-form-label form-control-label px-0">Range Pengukuran
                                                      Sensor</label>
                                                    <div class="row">
                                                      <div class="col-6">
                                                        <div class="form-group row">
                                                          <div class="col-lg-12">
                                                            <base-input
                                                              :name="`Range Pengukuran Sensor ${station.logger.sensors[index].type} Minimal`"
                                                              rules="required" type="number" v-model="station.logger.sensors[index].min" />
                                                            <small class="d-block mt--4 text-justify">Range Minimum</small>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="col-6">
                                                        <div class="form-group row">
                                                          <div class="col-lg-12">
                                                            <base-input rules="required"
                                                              :name="`Range Pengukuran Sensor ${station.logger.sensors[index].type} Maksimal`"
                                                              type="number" v-model="station.logger.sensors[index].max" />
                                                            <small class="d-block mt--4 text-justify">Range Maximum</small>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-lg-2"></div>
                                                  <div class="form-group col-lg-10">
                                                    <label class="col-11 col-form-label form-control-label px-0">Baku Mutu Air Limbah Sesuai
                                                      Izin</label>
                                                    <div v-if="item.type === 'pH'" class="row">
                                                      <div class="col-6">
                                                        <div class="form-group row">
                                                          <div class="col-lg-12">
                                                            <base-input :name="`Baku Mutu ${station.logger.sensors[index].type} Minimal`"
                                                              rules="required" type="number"
                                                              v-model="station.logger.sensors[index].bmal_min" />
                                                            <small class="d-block mt--4 text-justify">BMAL Minimum</small>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="col-6">
                                                        <div class="form-group row">
                                                          <div class="col-lg-12">
                                                            <base-input type="number"
                                                              :name="`Baku Mutu ${station.logger.sensors[index].type} Maksimal`"
                                                              rules="required" v-model="station.logger.sensors[index].bmal_max" />
                                                            <small class="d-block mt--4 text-justify">BMAL Maximum</small>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div v-else-if="item.type === 'Debit'" class="row">
                                                      <div class="col-12">
                                                        <div class="form-group row">
                                                          <div class="col-lg-9">
                                                            <base-input type="number" rules="required" name="Debit maksimum"
                                                              v-model="station.logger.sensors[index].bmal_max" />
                                                            <small class="d-block mt--4 text-justify">Range Maximum</small>
                                                          </div>
                                                          <div class="col-3">
                                                            <base-input name="Satuan sensor debit" rules="required">
                                                              <el-select v-model="station.logger.sensors[index].bmal_unit
                                                                ">
                                                                <el-option v-for="(unit, index) in debitUnit" :key="index" :label="unit"
                                                                  :value="unit" />
                                                              </el-select>
                                                            </base-input>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div v-else class="row">
                                                      <div class="col-12">
                                                        <div class="form-group row">
                                                          <div class="col-lg-12">
                                                            <base-input type="number" rules="required"
                                                              :name="`${station.logger.sensors[index].type} maksimum`"
                                                              v-model="station.logger.sensors[index].bmal_max" />
                                                            <small class="d-block mt--4 text-justify">BMAL Maximum</small>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-lg-2"></div>
                                                  <div class="form-group col-lg-10 p-0">
                                                    <label class="col-12 col-form-label form-control-label py-0">Metode Pengukuran</label>
                                                    <div class="col-12">
                                                      <base-input rules="required"
                                                        :name="`Metode Pengukuran ${station.logger.sensors[index].type}`"
                                                        v-model="station.logger.sensors[index].bmal_measure" />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-lg-2"></div>
                                                  <div class="form-group col-lg-10 p-0">
                                                    <label class="col-12 col-form-label form-control-label py-0">
                                                      Jadwal Kalibrasi
                                                    </label>
                                                    <div class="col-12">
                                                      <base-input rules="required"
                                                        :name="`Jadwal Kalibrasi ${station.logger.sensors[index].type}`">
                                                        <el-select v-model="station.logger.sensors[index].calibration_schedule" filterable>
                                                          <el-option v-for="bulan in 60" :key="bulan" :label="`${bulan} Bulan`"
                                                            :value="`${bulan}`" />
                                                        </el-select>
                                                      </base-input>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-lg-2"></div>
                                                  <div class="form-group col-lg-10 p-0">
                                                    <label class="col-12 col-form-label form-control-label py-0">Tanggal Kalibrasi</label>
                                                    <div class="col-12">
                                                      <base-input class="m-0" rules="required"
                                                        :name="`Tanggal Kalibrasi ${station.logger.sensors[index].type}`">
                                                        <flat-picker :config="selects.configs.flatpickr" class="form-control datepicker"
                                                          v-model="station.logger.sensors[index].calibration_date
                                                            " />
                                                      </base-input>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-lg-2"></div>
                                                  <div class="form-group col-lg-10 p-0">
                                                    <label class="col-12 col-form-label form-control-label py-0">Metode Kalibrasi</label>
                                                    <div class="col-12">
                                                      <base-input rules="required"
                                                        :name="`Metode Kalibrasi ${station.logger.sensors[index].type}`"
                                                        v-model="station.logger.sensors[index].calibration_method" />
                                                    </div>
                                                  </div>
                                                </div>
                                              </template>
                                            </div>
                                            <base-input :rules="{ required: { allowFalse: false } }" name="Sensor">
                                              <base-checkbox v-model="sensorDone">Data sensor sudah benar</base-checkbox>
                                            </base-input>
                                          </div>
                                        </div>

                                        <div style="display: flex;justify-content: center;align-items: center;">
                                          <button class="btn btn-primary mt-5 w-25" native-type="submit"
                                            v-if="!getStationForUpdate">SELESAI</button>
                                          <button class="btn btn-warning mt-5 w-25" native-type="submit"
                                            v-if="getStationForUpdate">PERBAHARUI</button>
                                          <button class="btn btn-danger mt-5 w-25" @click="showFormPenataanFormModal = false">BATAL</button>
                                        </div>
                                      </div>
                                    </b-form>
                                  </validation-observer>

                                  <div style="display:flex;justify-content: center;align-items: center;margin-top: 50px;" v-if="!showFormPenataanFormModal">
                                    <button class="btn btn-primary" @click="finalSubmit" >SIMPAN</button>
                                    <button class="btn btn-danger" @click="cancelSubmit">BATAL</button>
                                  </div>
                                  <div class="mt-3">
                                    <p><b><small>*Tombol Kirim Pendaftaran hanya tersedia jika anda sudah mengisi minimal 1 titik
                                          Penaatan</small></b></p>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-form>
                  </validation-observer>
                </section>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>


    <!-- Modal Untuk Menampilkan Lihat Mekanisme -->
    <Modal
      :show="showAnnoncementModal"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showAnnoncementModal = false"
      size="xl"
    >
      <template v-slot:header>
        <div class="mdl-header d-flex">
          <img
            src="../../../../public/img/brand/img-logo-color.png"
            alt="klhk"
            class="mb-2"
          />
          <h3 class="ml-3">
            Kementerian <br />
            Lingkungan Hidup dan Kehutanan <br />
            Republik Indonesia
          </h3>
        </div>
      </template>
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <div class="p-4" style="overflow-y: scroll; height: 450px">
            <h2 class="text-justify"></h2>
            <h2 class="text-justify">
              PENDAFTARAN SISTEM PEMANTAUAN KUALITAS AIR LIMBAH SECARA TERUS
              MENERUS DAN DALAM JARINGAN (SPARING) BAGI PENANGGUNG JAWAB USAHA
              DAN/ATAU KEGIATAN
            </h2>
            <p class="mb-2 text-justify">
              Pendaftaran adalah fasilitas untuk pencatatan secara ONLINE dan
              validasi bagi penanggung jawab usaha dan/atau kegiatan yang
              bertujuan untuk mendapatkan USER NAME, PASSWORD, UID (User
              Identification).
              <br />Mekanisme pendaftaran yaitu:
            </p>
            <ol>
              <li>
                Pendaftaran WAJIB dilakukan oleh penanggung jawab usaha dan/atau
                kegiatan (industri), tidak diperbolehkan bagi penyedia peralatan
                & jasa alat sensor pemantauan online.
              </li>
              <li>
                Penanggung jawab usaha dan/atau kegiatan WAJIB melaksanakan
                pendaftaran dan mengisi semua form serta bukti.
              </li>
              <li>
                Pengisian form pendaftaran dan bukti yaitu:
                <ol type="a">
                  <li>Pengisian umum</li>
                  <li>
                    Pengisian dan penyampaian bukti persyaratan identifikasi
                    sumber pencemar
                  </li>
                  <li>Pengisian dan penyampaian bukti persyaratan teknis</li>
                </ol>
              </li>
              <li>
                Hasil jawaban dari permohonan pendaftaran SPARING melalui fitur
                pendaftaran paling lambat dikirim 14 hari kerja setelah
                dikirimkan oleh penanggung jawab usaha dan/atau kegiatan.
              </li>
              <li>
                USER NAME, PASSWORD, UID (User Identification) berupa TANDA
                TERIMA ELEKTRONIK akan dikirimkan ke alamat email yang
                didaftarkan pada form pendaftaran melalui email
                sparing.menlhk@gmail.com jika seluruh isian lengkap dan
                dinyatakan valid oleh Admin SPARING KLHK.
              </li>
              <li>
                Bukti dan data dinyatakan valid melalui bukti persyaratan
                identifikasi sumber pencemar dan persyaratan teknis SPARING
                sesuai dengan Peraturan Menteri Lingkungan Hidup Nomor
                P.93/MENLHK/SETJEN/KUM.1/8/2018 tentang Pemantauan Kualitas Air
                Limbah Secara Terus Menerus Dan Dalam Jaringan Bagi Usaha
                Dan/Atau Kegiatan jo Peraturan Menteri Lingkungan Hidup Nomor
                P.80/MENLHK/SETJEN/KUM.1/10/2019.
              </li>
              <li>
                Jika isian tidak lengkap dan terdapat bukti yang tidak valid
                maka Admin SPARING KLHK akan menyampaikan melalui email yang
                terdaftar. Penanggung jawab usaha dan/atau kegiatan WAJIB
                melengkapi sesuai dengan hasil evaluasi.
              </li>
              <li>
                Kebijakan pengunduran waktu pemasangan dan pengoperasian SPARING
                sesuai Surat Edaran Menteri Lingkungan Hidup dan Kehutanan Nomor
                SE.5/Menlhk/Setjen/KUM.1/6/2020 tidak berlaku lagi.
              </li>
            </ol>

            <br />
            <span>
              Tim SPARING<br />
              Direktorat Pengendalian Pencemaran air<br />
              JL.DI Panjaitan Kav. 24 Kebon Nanas Jakarta Timur<br />
              Gedung B lantai 5<br />
              Email: sparing.menlhk@gmail.com<br />
              Fathia Rizki 0813 10837646 ( hanya melayani via pesan WA/SMS, jam
              pelayanan hari kerja 08.00-15.00 WIB)<br />
            </span>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="showAnnoncementModal = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Loading Screen -->
    <Modal :show="isLoading" modalContentClasses="popup-modal-backgrounds" modalClasses="modal-dialog-scrollable"
      size="lg" style="background-color: rgba(0, 0, 0, 0.8)">
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4">
            <div class="row">
              <div class="col-lg-12 text-center" style="position: relative">
                <img src="img/brand/img-logo-color.png" alt="" />
                <img src="/img/brand/klhk.png" alt="" style="
                    position: absolute;
                    right: 0;
                    top: -200px;
                    opacity: 0.2;
                  " />
                <h1>Sedang Proses...</h1>
                <h5>Harap tunggu beberapa saat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

   
  </div>
</template>

<script>
import baseURL from "@/api/base_url_backend.js";
import ValidationHistorys from "./HistorySparing.vue";

import API from "@/api/base_url.js";
import FileInput from "@/components/Inputs/FileInput";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  Select,
  Option,
  Table,
  TableColumn,
  Collapse,
  CollapseItem,
} from "element-ui";
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import PendaftaranSparingTable from "../../Tables/PendaftaranSparing.vue";
import BaseHeader from "@/components/BaseHeader";
import SparingTmpTable from "../../Tables/SparingTmpTable.vue";
import SparingTmpPenaatanTable from "../../Tables/SparingTmpPenaatan.vue";
import SparingTmpDataLoggerTable from "../../Tables/SparingTmpDataLoggerTable.vue";
import { BasePagination } from "@/components";
import clientPaginationMixin from "../../Tables/PaginatedTables/clientPaginationMixin";
import moment from "moment";

export default {
  name: "pendaftaranSparing",
  mixins: [clientPaginationMixin],
  components: {
    ValidationHistorys,
    SparingTmpDataLoggerTable,
    SparingTmpTable,
    SparingTmpPenaatanTable,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    BaseHeader,
    FileInput,
    HtmlEditor,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    PendaftaranSparingTable,
    BasePagination,
  },
  mounted() {
    this.getCompanyType()
    this.getProvince()
  },
  data() {
    return {
      isLoading:false,
      formPublic: {
          company_connection_request_file:"",
          company_mine_compliance_point_file:"",
          company_siup_file: "",
      },
      isLoadingData: false,
      histories: [],
      listDataHistory: [],
      idCekStatus:"",
      showHistory:false,
      allowedCompTypes: [
        "Pertambangan Emas dan Tembaga",
        "Pertambangan Batu Bara",
        "Pertambangan Nikel",
      ],
      userInput: {
        company_connection_request_file: "",
        company_siup_file: "",
        company_mine_compliance_point_file: "",
        company_address: "",
        company_address_alternative: "",
        company_email: "",
        company_name: "",
        company_phone: "",
        company_province_id: "",
        company_regency_id: "",
        company_siup_number: "",
        company_siup_on_behalf: "",
        company_type_id: "",
        user_email: "",
        user_name: "",
        user_password: "",
        company_mine_activity_type: "",
        company_mine_network_status: "",
        company_mine_status: "",
        user_address: "",
        user_phone: "",
        user_confirm_password: "",
      },
      isRegistered: false,
      newPayloadTmp: [],
      showFormModal: false,
      showFormPenataanFormModal: false,
      // main payload
      form: {
        permit_id: "",
        waste_source: "",
        file_id: "",
        akun: {
          personName: null,
          personAddress: "",
          personPhone: null,
          personMail: null,
          personMailID: null,
          personPassword: null,
          personConfirmPassword: null,
        },
        umum: {
          requestConnect: "",
          filedownload: "",
          compName: null,
          compNameID: null,
          compType: null,
          compAddress: null,
          compStreet: null,
          compCity: null,
          compProvince: null,
          compPhone: null,
          compMail: null,
          numberSIUP: null,
          nameSIUP: null,
          ifTambang: {
            status: "",
            jenKegiatan: "",
            kondisiInternet: "",
          },
          rencanaFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          siupFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          compPermohonanKoneksi: {
            path: "",
            upload: 0,
            progress: 0,
          },
          updatedAt: 0,
          createdAt: null,
        },
        teknis: {
          permitFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          uuid: "1234-5678-90",
          permitPerson: null,
          permitNumber: null,
          permitDatePublish: this.getFormayYear(new Date()),
          permitDateExpire: this.getFormayYear(new Date()),
          wasteSource: null,
          spotCount: null,
          spotList: [],
          processingTechnique: null,
          wasteType: null,
          debit: null,
          satuanDebit: "m3/detik",
          capacityPermit: null,
          capacityActual: null,
          frequenceDet: "",
        },
        sensorDone: false,
       
      },
      provideSelect: {
        provinsi: [],
        kabupatenKota: [],
        daftarPerusahaan: [],
        tmp: [],
        uploadChoosen: null,
        companyType: [],
      },
      // createPermissionLater
      permissionLaterFormExisting: [],
      selectedRows: [],
      selects: {
        configs: {
          flatpickr: {
            allowInput: true,
            altFormat: "Y-m-d",
            altInput: false,
            dateFormat: "Y-m-d",
            mode: "single"
          }
        }
      },
      showAnnoncementModal: false,
      sensorDone: false,
      uploading: false,
      getStationForUpdate: false,
      debitUnit: ["m3/detik", "m3/menit", "m3/jam", "m3/hari", "m3/bulan"],
      form_permits: {
        attachment_file_id: "",
        issuing_authority: "",
        no: "",
        publication_date: "",
        expiration_date: ""
      },
      station: {
        waste_source: "",
        spot_name: "",
        waste_receiver: "",
        das: "",
        longitude: 0,
        latitude: 0,
        processing_technique: "",
        waste_type: "",
        debit_value: 0,
        debit_unit: "",
        capacity_permit: 0,
        capacity_value: 0,
        frequency: {
          bulan: 0,
          detail_eng_ipal: "",
          hari: 0,
          ipal_file_id: "",
          jam: 0,
          latar_belakang: "",
          logbook_file_id: "",
          metode_ukur: "",
          type: ""
        },
        tech_requirement: {
          dukungan_brand_file_id: "",
          info_alat: "",
          kalibrasi_awal_file_id: "",
          kalibrasi_rutin_file_id: "",
          kinerja_file_id: "",
          kontak_kerjasama_file_id: "",
          lulus_uji_file_id: "",
          nama_penyedia: "",
          no_surat: "",
          pernyataan_vendor_file_id: "",
          tipe_uji: ""
        },
        logger: {
          brand: "",
          mac: "",
          model: "",
          sn: "",
          sensors: Array.from({ length: 5 }, (_, index) => ({
            bmal_max: 0,
            bmal_measure: "",
            bmal_min: 0,
            bmal_unit: "",
            brand: "",
            brochure_file_id: "",
            calibration_date: "",
            calibration_method: "",
            calibration_schedule: "",
            is_active: false,
            max: 0,
            min: 0,
            probe: "",
            type: index === 0 ? "pH" : index === 1 ? "COD" : index === 2 ? "TSS" : index === 3 ? "NH3N" : "Debit"
          }))
        }
      }
    };
  },
  methods: {
      seeRiwayatValidasi() {
        this.getBackData('validasi')
        this.showAnnoncementModalHistory = true
      },
      async getValidationHistory() {
        let tmp = this.listDataHistory.approval_histories
        this.histories = []


        if (tmp == null || tmp == undefined) {
          this.histories = []
        } else {
          for (let i = 0; i < tmp.length; i++) {
            this.histories.push({
              id:i+1,
              user_name:tmp[i].user_name,
              created_time_user: this.formatDate(tmp[i].registration_time),
              keterangan: tmp[i].keterangan,
              admin_name:tmp[i].admin_name,
              created_time: this.formatDate(tmp[i].approval_time),
              status:tmp[i].status
            })
          }
        }

        tmp = []
        return  this.histories
        
      },
      
      async getChangesData() {
        console.log("Change Data");

        let tmp = this.listDataHistory.field_histories

        if (tmp == null) {
          this.histories = []
        } else {
          for (let i = 0; i < tmp.length; i++) {
            this.histories.push({
              id:i+1,
              // no:1,
              name:tmp[i].field_name,
              updatedAt:this.formatDate(tmp[i].created_at),
              oldData:tmp[i].old_value,
              newData:tmp[i].new_value,
            })
          }
        }

        return  this.histories
      },

      async getChangesDocument() {
        console.log("Change Dokumen");
        
        let tmp = this.listDataHistory.doc_histories

        if (tmp == null) {
          this.histories = []
        } else {
          for (let i = 0; i < tmp.length; i++) {
            this.histories.push({
              no: 1,
              documentName: tmp[i].field_name,
              documentType: 'doc.tipe_dokumen',
              updatedAt: this.formatDate(tmp[i].created_at),
              DocumentPreviously: tmp[i].old_value,
              newDocument: tmp[i].new_value,
            })
          }
        }

        return  this.histories
      },

      getBackData(type) {
          if (type === 'validasi') {
              this.histories = []

              setTimeout(() => {
                this.getValidationHistory()
              }, 100);
          }

          if (type === 'perubahanData') {
              this.histories = []

              setTimeout(() => {
                this.getChangesData()
              }, 100);
          }

          if (type === 'perubahanDokumen') {
              this.histories = []

              setTimeout(() => {
                this.getChangesDocument()
              }, 100);
          }
      },
      getCompanyType() {
        API.get(`comp-types`)
          .then(({ data: content }) => {
          this.provideSelect.companyType = content.data
          console.log(content.data);
          })
          .catch((err) => {
            Swal.fire("Gagal Mendapatkan Jenis Perusahaan", "", "error");
          });
      },
      getProvince() {
        API.get(`provinces`)
          .then(({ data: content }) => {
          this.provideSelect.provinsi = content.data
          })
          .catch((err) => {
            Swal.fire("Gagal Mendapatkan Data Provinsi", "", "error");
          });
      },
      getCityDepentOnProvince(data) {
        this.form.umum.compCity = []
        this.provideSelect.kabupatenKota = [];
        API.get(`kabkots?prov_id=${data}`)
          .then(({ data: content }) => {
            this.provideSelect.kabupatenKota = content.data;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      editCompanyForm() {
        this.showHistory = false
        this.idCekStatus = ""

        Swal.fire({
          title: "ID Pendaftaran Sparing",
          input: "text",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: "CARI",
          cancelButtonText: "BATAL",
          showLoaderOnConfirm: true,
          preConfirm: async (id) => {
            try {

              API.get(`auth/register/sparing/${id}`)
              .then(({data:content}) => {
                this.listData = content.data
                this.isLoading = false
              
                this.$router.push(`/sparing/perubahan/${id}`);

                // Swal.showValidationMessage(`Data ditemukan`);
                Swal.fire({
                  title: "Data Ditemukan",
                  icon: 'success',
                  text: "Data Pendaftaran SPARING tersedia untuk dilakukan perubahan data."
                });
                return "Data ditemukan"
              })
              .catch((err) => {
                console.log(err);

                if (err.response.data == '404 page not found\n') {
                  // Swal.showValidationMessage(`Server gagal Merespon`);
                  Swal.fire({
                    title: "Data Tidak Ditemukan",
                    icon: 'info',
                    text: "Tidak ditemukan Pendaftaran Sparing dengan ID yang anda cari."
                  });
                  return "Server gagal Merespon"
                } else if (err.response.data.meta.message == 'Sparing not found') {
                  // Swal.showValidationMessage(`Data tidak ditemukan`);
                  Swal.fire({
                    title: "Data Tidak Ditemukan",
                    icon: 'info',
                    text: "Tidak ditemukan Pendaftaran Sparing dengan ID yang anda cari."
                  });
                  return "Data tidak ditemukan"
                } else {
                  // Swal.showValidationMessage(`Server gagal Merespon`);
                  Swal.fire({
                    title: "Data Tidak Ditemukan",
                    icon: 'info',
                    text: "Tidak ditemukan Pendaftaran Sparing dengan ID yang anda cari."
                  });
                  return "Server gagal Merespon"
                }
              })

            } catch (error) {
              Swal.showValidationMessage(`Request failed: ${error}`);
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          console.log(result);
          if (result.isConfirmed) {
            Swal.fire({
              title: '',
            });
          }
        });
      },
      newCompanyForm() {
        this.idCekStatus = ""
        this.showHistory = false
        this.isRegistered = true;
      },
      checkCompType(compType) {
        // Ambil label berdasarkan compType
        const label = this.provideSelect.companyType.find(
          (option) => option.id === compType
        )?.name;
        if (label) {
          console.log(label);
          return this.allowedCompTypes.includes(label);
        }
      },
      registerNow() {
        Swal.fire({
          title: "Daftarkan Perusahaan?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#29664A",
          cancelButtonColor: "#d33",
          confirmButtonText: "DAFTAR",
          cancelButtonText: "BATAL",
        }).then((result) => {
          if (result.isConfirmed) {
            let payload = {
              company: {
                address: this.form.umum.compAddress,
                email: this.form.umum.compMail,
                kabkot_id: this.form.umum.compCity,
                koneksi_file_id: this.userInput.company_connection_request_file,
                mine: {
                  activity_type: this.form.umum.ifTambang.jenKegiatan,
                  network_cond: this.form.umum.ifTambang.kondisiInternet,
                  rencana_file_id:
                    this.userInput.company_mine_compliance_point_file,
                  status: this.form.umum.ifTambang.status,
                },
                name: this.form.umum.compName,
                phone: this.form.umum.compPhone,
                prov_id: this.form.umum.compProvince,
                siup_file_id: this.userInput.company_siup_file,
                siup_no: this.form.umum.numberSIUP,
                siup_pj: this.form.umum.nameSIUP,
                street: this.form.umum.compStreet,
                type_id: this.form.umum.compType,
              },
              user: {
                email: this.form.akun.personMail,
                name: this.form.akun.personName,
                password: this.form.akun.personPassword,
                phone: this.form.akun.personPhone,
              },
            };

            // console.log(payload, "-->");
            API.post(`auth/register`, payload)
              .then(({ data: content }) => {
                Swal.fire({
                  title: "Perusahaan didaftarkan",
                  text: "Setelah proses validasi dari admin kami , anda dapat masuk kedalam dashboard SPARING, Terimakasih",
                  icon: "success",
                });

                this.resetPayload();
                this.$router.push("/login");
              })
              .catch((err) => {
                Swal.fire("Gagal Mendaftarkan", "", "error");
              });
          }
        });
      },
      uploadFile(field) {
        // ${defaults.baseURL}/file
        const fileInput = this.$refs[field];
        const file = fileInput.files[0];
        const formData = new FormData();
        formData.append("file", file);
        this.uploading = true;
        API.post(`blobs/upload/public`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            let data = res.data.data;
            let value = data.id;
            this.$set(this.userInput, field, value);

            // console.log(`${data}:`, this.userInput[field]);
            this.uploading = false;
          })
          .catch((err) => {
            this.uploading = false;
            Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
          });
      },
      submit() {
        if (
          this.form.akun.personPassword == this.form.akun.personConfirmPassword
        ) {
          // validation
          if (true) {
            Swal.fire(
              "Data anda belum lengkap",
              "Pastikan anda mengisi semua form",
              "error"
            );
          } else {
            this.formData.append("company_type_id", this.form.umum.compType);
            this.formData.append(
              "company_mine_status",
              this.form.umum.ifTambang.status
            );
            this.formData.append(
              "company_mine_activity_type",
              this.form.umum.ifTambang.jenKegiatan
            );
            this.formData.append(
              "company_mine_network_status",
              this.form.umum.ifTambang.kondisiInternet
            );
            this.formData.append("company_name", this.form.umum.compName);
            this.formData.append(
              "company_siup_number",
              this.form.umum.numberSIUP
            );
            this.formData.append(
              "company_siup_on_behalf",
              this.form.umum.nameSIUP
            );
            this.formData.append("company_phone", this.form.umum.compPhone);
            this.formData.append("company_email", this.form.umum.compMail);
            this.formData.append("company_address", this.form.umum.compAddress);
            this.formData.append(
              "company_address_alternative",
              this.form.umum.compStreet
            );
            this.formData.append("company_regency_id", this.form.umum.compCity);
            this.formData.append(
              "company_province_id",
              this.form.umum.compProvince
            );
            // user
            this.formData.append("user_name", this.form.akun.personName);
            this.formData.append("user_phone", this.form.akun.personPhone);
            this.formData.append("user_email", this.form.akun.personMail);
            this.formData.append("user_address", this.form.akun.personAddress);
            this.formData.append("user_password", this.form.akun.personPassword);

            // let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JpemVkIjp0cnVlLCJjb21wYW55X2lkIjowLCJ1c2VyX2lkIjoxfQ.vLPBMm2zF0YKwPVNElPyREN-rh1uoB85plHJv3gnUbw'

            // let headers = {
            //   Authorization: `Bearer ${token}`,
            //   "Content-Type": "multipart/form-data"
            // }

            if (this.hasUploadAttachment) {
              API.post(`auth/register`, this.formData)
                .then(({ data: content }) => {
                  console.log(content.data);
                  Swal.fire(
                    "Pendaftaran Berhasil",
                    "Data Berhasil didaftarkan",
                    "success"
                  );
                  this.resetPayload();
                  this.formData = new FormData();
                  this.hasUploadAttachment = false;
                  this.$router.push("/login");
                })
                .catch((err) => {
                  this.formData = new FormData();

                  Swal.fire("Gagal Mendaftarkan", "", "error");
                });
            } else {
              this.formData = new FormData();
              Swal.fire("Gagal Mendaftarkan", "", "error");
            }
          }
        } else {
          Swal.fire(
            "Password bermasalah",
            "Maaf, Password anda tidak sesuai dengan konfirmasi password",
            "error"
          );
        }
      },
      cekSiupNumber(siupNumber) {
        this.showHistory = false
        API.get(`auth/register/sparing/${siupNumber}/check-status`)
          .then(({ data }) => {
            this.listDataHistory = data.data

            this.showHistory = true
            // this.seeRiwayatValidasi()
            this.getValidationHistory()
            // if (data.data.is_registered) {
            //   Swal.fire(
            //     "SIUP Terdaftar",
            //     "Anda dapat menggunakan data anda sebelumnya",
            //     "info"
            //   );
            //   this.$router.push("/login");
            // } else {
            //   Swal.fire("Data tidak ditemukan", "", "info");
            // }
          })
          .catch((err) => {
            Swal.fire("Data tidak ditemukan","Anda belum memiliki pendaftaran Sparing","info");
          });
      },
      resetPayload() {
        // akun
        this.form.akun.personName = "";
        this.form.akun.personPhone = "";
        this.form.akun.personMail = "";
        this.form.akun.personAddress = "";
        this.form.akun.personMailID = "";
        this.form.akun.personPassword = "";
        this.form.akun.personConfirmPassword = "";
        // informasi umum
        this.form.umum.compName = "";
        this.form.umum.compNameID = "";
        this.form.umum.compType = "";
        this.form.umum.compAddress = "";
        this.form.umum.compStreet = "";
        this.form.umum.compCity = "";
        this.form.umum.compProvince = "";
        this.form.umum.compPhone = "";
        this.form.umum.compMail = "";
        this.form.umum.numberSIUP = "";
        this.form.umum.nameSIUP = "";
        this.form.umum.ifTambang.status = "";
        this.form.umum.ifTambang.jenKegiatan = "";
        this.form.umum.ifTambang.kondisiInternet = "";
        this.newsensor = [];
        this.form.uid = "";
        console.log("=========Akun");
        this.form.akun.personName = "";
        this.form.akun.personPhone = "";
        this.form.akun.personMail = "";
        this.form.akun.personMailID = "";
        this.form.akun.personPassword = "";
        this.form.akun.personConfirmPassword = "";
        console.log("=========Umum");
        this.form.umum.compName = "";
        this.form.umum.compNameID = "";
        this.form.umum.compType = "";
        this.form.umum.compAddress = "";
        this.form.umum.compStreet = "";
        this.form.umum.compCity = "";
        this.form.umum.compProvince = "";
        this.form.umum.compPhone = "";
        this.form.umum.compMail = "";
        this.form.umum.nameSIUP = "";
        this.form.umum.ifTambang.status = "";
        this.form.umum.ifTambang.jenKegiatan = "";
        this.form.umum.ifTambang.kondisiInternet = "";
        console.log("==================Identifikasi Sumber Pencemaran");
        this.form.teknis.permitPerson = "";
        this.form.teknis.permitNumber = "";
        this.form.teknis.permitDatePublish = "";
        this.form.teknis.permitDateExpire = "";
        this.form.teknis.wasteSource = "";
        this.form.teknis.spotCount = "";
        this.form.teknis.spotList = "";
        this.form.teknis.processingTechnique = "";
        this.form.teknis.wasteType = "";
        this.form.teknis.debit = "";
        this.form.teknis.satuanDebit = "";
        this.form.teknis.capacityPermit = "";
        this.form.teknis.capacityActual = "";
        this.form.teknis.frequenceDet = "";
        this.form.frekuensi.jam = "";
        this.form.frekuensi.hari = "";
        this.form.frekuensi.bulan = "";
        this.form.frekuensi.metUkurDebit = "";
        this.form.frekuensi.latarBelakang = "";
        this.form.frekuensi.detEngIPAL = "";
        console.log("==================Persyaratan Teknis");
        this.form.validitas.infoAlat = "";
        this.form.validitas.lulusUji = "";
        this.form.validitas.namaPenyedia = "";
        this.form.validitas.noSurat = "";
        this.form.validitas.jadwalKalibrasi = "";
        console.log("==================Form Logger");
        // this.form.logger[0] = "";
        console.log("==================Sensor");
        this.form.sensor = "";
      },
      // routing
      goBack() {
        this.$router.push("/sparing");
      },
      // old
      getFormayYear(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
      },
      close() {
        this.$router.push("/pilih-pendaftaran");
      },
      // pendaftaran depan
      //------- FUNCTION MODAL --------------------------------
      showFormTitikPenataanModal() {
        this.showFormPenataanFormModal = true;
        this.getStationForUpdate = false;
        // this.resetForm()
      },

      //---------- FUNCTION PERMITS --------------------------------
      createPermissionLater() {
        if (!this.validateForm(this.form_permits)) {
          return;
        }

        Swal.fire({
          title: "Menambahkan Surat Izin?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "TAMBAH",
          cancelButtonText: "BATAL",
        }).then((result) => {
          if (result.isConfirmed) {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            };


            API.post("permits", this.form_permits, { headers })
              .then(({ data }) => {
                this.resetForm(this.form_permits)
                this.getDataForOldLater()
                this.showFormModal = false;
                Swal.fire("Surat izin ditambahkan", "pendaftaran surat izin berhasil", "success");
              })
              .catch((err) => {
                console.log(err, "ini error");
                Swal.fire("Pendaftaran Gagal", "harap telah mengisi semua data atau periksa koneksi internet anda", "error");
              });
          }
        });
      },

    getDataForOldLater() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get("permits", { headers })
        .then(({ data: content }) => {
          if (content.data == null) {
            this.permissionLaterFormExisting = [];
          } else {
            this.permissionLaterFormExisting = content.data;
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(
            "Pencarian data gagal",
            "harap periksa koneksi internet anda",
            "error"
          );
        });
    },

    //---------- FUNCTION STATIONS --------------------------------
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    submitStation() {
      Swal.fire({
        title: "Simpan Titik Penaatan ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "SIMPAN",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {

          let payload = { ...this.station };
          let tmpSensor = []
          let lastIndex = this.newPayloadTmp.length > 0 ? this.newPayloadTmp[this.newPayloadTmp.length - 1].index : 0;

          // if (payload.frequency.type == "Terus Menerus/Kontinyu") {
          //   delete payload.frequency.detail_eng_ipal;
          //   delete payload.frequency.latar_belakang;
          //   delete payload.frequency.ipal_file_id;
          //   delete payload.frequency.metode_ukur;
          //   delete payload.frequency.logbook_file_id;
          // }

          // if (payload.tech_requirement.tipe_uji == "Individu") {
          //   delete payload.tech_requirement.nama_penyedia;
          //   delete payload.tech_requirement.kontak_kerjasama_file_id;
          // }
          // else if (payload.tech_requirement.tipe_uji == "Penyedia Barang dan Jasa Alat Sparing") {
          //   delete payload.tech_requirement.no_surat;
          //   delete payload.tech_requirement.nama_penyedia;
          // }
          

          for (let i = 0; i < payload.logger.sensors.length; i++) {
            tmpSensor.push({
              bmal_max: parseFloat(+payload.logger.sensors[i].bmal_max),
              bmal_measure: payload.logger.sensors[i].bmal_measure,
              bmal_min: parseFloat(+payload.logger.sensors[i].bmal_min),
              bmal_unit: payload.logger.sensors[i].bmal_unit,
              brand: payload.logger.sensors[i].brand,
              brochure_file_id: payload.logger.sensors[i].brochure_file_id,
              calibration_date: this.formatDatePayload(payload.logger.sensors[i].calibration_date),
              calibration_method: payload.logger.sensors[i].calibration_method,
              calibration_schedule: +payload.logger.sensors[i].calibration_schedule,
              is_active: payload.logger.sensors[i].is_active ? true : false,
              max: parseFloat(+payload.logger.sensors[i].max),
              min: parseFloat(+payload.logger.sensors[i].min),
              probe: payload.logger.sensors[i].probe,
              type: payload.logger.sensors[i].type
            })
          }

          payload.index = ++lastIndex;
          payload.logger.sensors = tmpSensor
          payload.debit_value = parseFloat(payload.debit_value)
          payload.capacity_value = parseFloat(payload.capacity_value)
          payload.longitude = parseFloat(payload.longitude)
          payload.latitude = parseFloat(payload.latitude)
          payload.debit_value = parseFloat(payload.debit_value)
          payload.capacity_permit = parseFloat(payload.capacity_permit)
          payload.frequency.bulan = parseFloat(payload.frequency.bulan)
          payload.frequency.hari = parseFloat(payload.frequency.hari)
          payload.frequency.jam = parseFloat(payload.frequency.jam)

          this.newPayloadTmp.push(payload);

          this.showFormPenataanFormModal = false;

          this.resetFormStation()

        }
      })
    },
    populateUpdateStation(row) {
      this.showFormPenataanFormModal = true
      this.getStationForUpdate = true
      this.station = { ...row }
      console.log(row)
    },
    updateStation() {

      const index = this.newPayloadTmp.findIndex(item => {
        return item.index === this.station.index;
      });

      Swal.fire({
        title: "Perbaharui Titik Penaatan ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "PERBAHARUI",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          // this.newPayloadTmp[index] = { ...this.station }
          this.$set(this.newPayloadTmp, index, { ...this.station });
          this.showFormPenataanFormModal = false;
          this.resetFormStation()
        }
      })
    },

    willDeleteStation(row) {
      console.log(row,'--->');
      console.log(this.newPayloadTmp,'uhuu');
      Swal.fire({
        title: `Hapus Titik Penaatan ${row.spot_name} ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "HAPUS",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          const index = this.newPayloadTmp.findIndex(item => {
            return item.index === row.index;
            // return item.index === this.station.index;
          });

          console.log(index,'-->');
          if (index >= 0) {
            this.newPayloadTmp.splice(index, 1);
          }

          // this.resetFormStation()
          this.showFormPenataanFormModal = false;

          Swal.fire({
            title: "Berhasil Menghapus",
            text: "Titik Penaatan telah dihapus dari Pendaftaran SPARING",
            icon: "success",
          });
        }
      });
    },
    cancelSubmit() {
      this.isRegistered = false
    },
    finalSubmit() {
      const modifiedPayload = this.newPayloadTmp.map(station => {
        // Iterate through sensors array in each station
        const modifiedSensors = station.logger.sensors.map(sensor => {
            // Convert is_active from boolean to number
            return {
                ...sensor,
                is_active: sensor.is_active ? 1 : 0
            };
        });

        // Return the modified station object
        return {
            ...station,
            waste_source:this.form.waste_source,
            logger: {
                ...station.logger,
                sensors: modifiedSensors
            }
        };
      });
      let payload = {
        company: {
          address: this.form.umum.compAddress,
          email: this.form.umum.compMail,
          kabkot_id: this.form.umum.compCity,
          koneksi_file_id: this.formPublic.company_connection_request_file,
          mine: {
            activity_type: this.form.umum.ifTambang.jenKegiatan,
            network_cond: this.form.umum.ifTambang.kondisiInternet,
            rencana_file_id:this.formPublic.company_mine_compliance_point_file,
            status: this.form.umum.ifTambang.status,
          },
          name: this.form.umum.compName,
          phone: this.form.umum.compPhone,
          prov_id: this.form.umum.compProvince,
          siup_file_id: this.formPublic.company_siup_file,
          siup_no: this.form.umum.numberSIUP,
          siup_pj: this.form.umum.nameSIUP,
          street: this.form.umum.compStreet,
          type_id: this.form.umum.compType,
        },
        user: {
          email: this.form.akun.personMail,
          name: this.form.akun.personName,
          password: this.form.akun.personPassword,
          phone: this.form.akun.personPhone,
        },
        permit: {
          attachment_file_id: this.form_permits.attachment_file_id,
          expiration_date: this.form_permits.expiration_date,
          issuing_authority: this.form_permits.issuing_authority,
          no: this.form_permits.no,
          publication_date: this.form_permits.publication_date
        },
        sparing: {
          detail: {
            permit_id: this.form.permit_id,
            total_station: this.newPayloadTmp.length
          },
          station: modifiedPayload
        }
      }

      console.log(payload);

      Swal.fire({
        title: `Mendaftarkan SPARING?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "DAFTAR",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          if (!this.form.waste_source) {
            Swal.fire({
            title: "Sumber Air Limbah wajib di isi",
            text: "Pastikan anda mengisi data sumber air limbah",
            icon: "error",
            })
          }  else {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            };


            API.post(`auth/register/sparing`, payload, { headers })
            .then(({ data: content }) => {
              console.log(content,'BERHASIL');
              this.newPayloadTmp = []
              this.formPublic = {
                company_connection_request_file:"",
                company_mine_compliance_point_file:"",
                company_siup_file: "",
              }
              this.$router.push('/login')
                Swal.fire({
                title: "Berhasil Mendaftar",
                text: `Berikut adalah ID Pendaftaran Anda ${content.data.sparing_id}, Admin akan memverifikasi akun Anda terlebih dahulu agar Anda dapat masuk kedalam sistem`,
                icon: "success",
              });

            })
            .catch((err) => {
              console.log(err);
              Swal.fire("Pendaftaran Gagal", "gagal mendaftarkan", "error");
            });
          }
        }
      });
    },
    //---------HELPER FUNCTION --------------------------------

    //VALIDATION FUNCTION --------------------------------
    validateForm(formData) {
      let isValid = true;

      // Periksa setiap properti dalam objek formData
      for (const key in formData) {
        if (!formData[key]) {
          // Jika ada properti yang kosong, tandai formulir sebagai tidak valid
          isValid = false;
        }
      }

      // Jika formulir tidak valid, tampilkan pesan kesalahan
      if (!isValid) {
        Swal.fire("Data belum lengkap", "Pastikan Anda melengkapi semua form", "error");
      }

      // Kembalikan status validasi formulir
      return isValid;
    },
    goBack() {
      this.$router.push("/sparing");
    },
    uploadFileBlob(form, field) {

      // ${defaults.baseURL}/file
      const fileInput = this.$refs[field];
      const file = fileInput.files[0];

      // Validasi jenis file (hanya PDF dan gambar)
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
        return;
      }

      // Validasi ukuran file (tidak lebih dari 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB dalam bytes
      if (file.size > maxSize) {
        Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
        return;
      }


      const formData = new FormData();
      formData.append("file", file);
      this.isLoading = true;
      API.post(`blobs/upload/public`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          let data = res.data.data;
          let value = data.id;
          this.$set(form, field, value);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
        });
    },
    uploadFileSensorBlob(form,field,ref) {

      // ${defaults.baseURL}/file
      const fileInput = this.$refs[ref][0];
      
      const file = fileInput.files[0];

      // Validasi jenis file (hanya PDF dan gambar)
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
        return;
      }

      // Validasi ukuran file (tidak lebih dari 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB dalam bytes
      if (file.size > maxSize) {
        Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
        return;
      }


      const formData = new FormData();
      formData.append("file", file);
      this.isLoading = true;
      API.post(`blobs/upload/public`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          let data = res.data.data;
          let value = data.id;
          this.$set(form, field, value);
          console.log(this.$set(form, field, value))
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
        });
    },
    resetForm(formData) {
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          if (typeof formData[key] === 'object' && formData[key] !== null) {
            if (formData[key].hasOwnProperty('path')) {
              formData[key].path = "";
            } else if (formData[key].hasOwnProperty('file')) {
              formData[key].file = null;
            } else {
              this.resetForm(formData[key]); // Panggil secara rekursif untuk objek dalamnya
            }
          } else {
            formData[key] = ""; // Atur ulang nilai properti jika bukan objek
          }
        }
      }
    },
    resetFormStation() {
      this.station = {
        spot_name: "",
        waste_receiver: "",
        das: "",
        longitude: 0,
        latitude: 0,
        processing_technique: "",
        waste_type: "",
        debit_value: 0,
        debit_unit: "",
        capacity_permit: 0,
        capacity_value: 0,
        frequency: {
          bulan: 0,
          detail_eng_ipal: "",
          hari: 0,
          ipal_file_id: "",
          jam: 0,
          latar_belakang: "",
          logbook_file_id: "",
          metode_ukur: "",
          type: ""
        },
        tech_requirement: {
          dukungan_brand_file_id: "",
          info_alat: "",
          kalibrasi_awal_file_id: "",
          kalibrasi_rutin_file_id: "",
          kinerja_file_id: "",
          kontak_kerjasama_file_id: "",
          lulus_uji_file_id: "",
          nama_penyedia: "",
          no_surat: "",
          pernyataan_vendor_file_id: "",
          tipe_uji: ""
        },
        logger: {
          brand: "",
          mac: "",
          model: "",
          sn: "",
          sensors: Array.from({ length: 5 }, (_, index) => ({
            bmal_max: 0,
            bmal_measure: "",
            bmal_min: 0,
            bmal_unit: "",
            brand: "",
            brochure_file_id: "",
            calibration_date: "",
            calibration_method: "",
            calibration_schedule: "",
            is_active: false,
            max: 0,
            min: 0,
            probe: "",
            type: index === 0 ? "pH" : index === 1 ? "COD" : index === 2 ? "TSS" : index === 3 ? "NH3N" : "Debit"
          }))
        }
      };
      this.resetAllFileInputs([
        'ipal_file_id',
        'logbook_file_id',
        'dukungan_brand_file_id',
        'kalibrasi_awal_file_id',
        'kalibrasi_rutin_file_id',
        'kinerja_file_id',
        'kontak_kerjasama_file_id',
        'lulus_uji_file_id',
        'pernyataan_vendor_file_id'
      ]);
    },
    getBlobsFile(filename) {
      let url = `${baseURL}blobs/download/${filename}`
      return url
    },
    formatDate(timestamp) {
      if (timestamp == 0) {
        return "-"
      }
      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY');
      return humanDate;
    },
    formatDatePayload(val) {
      const time = moment(val).format('YYYY-MM-DD');
      return time;
    },
    resetFileInput(ref) {
      if (this.$refs[ref]) {
        // this.$refs.lulus_uji_file_id.files = []
        this.$refs[ref].files = []
        const inputElement = this.$refs[ref].$el.querySelector('input[type="file"]');
        inputElement.value = ''
      }
    },
    resetAllFileInputs(refs) {
      refs.forEach(ref => this.resetFileInput(ref));
    }
  },
  watch: {
  
  },
  computed: {
   
  },
};
</script>

<style>
.el-table .el-table__header-wrapper thead th .cell {
  justify-content: flex-start;
}

.myshadow {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.step-progress .bar {
  background-color: #1b674c !important;
}

.step-button {
  display: inline-block;
  font-weight: 600;
  color: #1b674c;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.step-button-next {
  background-color: #1b674c !important;
  color: #fff;
}

.step-pills .step-item.active {
  border: 1px solid #1b674c !important;
}

.step-pills .step-item {
  margin-right: 5px;
}

.step-item {
  position: relative;
  width: 200px;
  height: 100px;
}

.tabLabel {
  position: absolute;
  bottom: 10px;
  left: 10px;
  line-height: 17px;
}

.popup-modal-background {
  background: #fff;
}

.mdl-contentpopup {
  background: #fff;
  overflow-y: auto;
  max-height: 90%;
}
</style>

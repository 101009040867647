<template>
    <div id="validation-history">
      <p v-if="tab === 0" class="font-weight-bold mb-2">
        Riwayat Pengajuan dan Validasi
      </p>
      <p v-if="tab === 1" class="font-weight-bold mb-2">
        History Perubahan Data
      </p>
      <p v-if="tab === 2" class="font-weight-bold mb-2">
        History Perubahan Dokumen
      </p>
      <div class="d-flex justify-content-between align-items-center mb-2">
  
        <div>
          <!-- <template v-if="this.$route.path === '/admin/detail/validasi'"> -->
          <template >
            <button type="button" :class="[ 'btn btn-primary mr-0 border-right-0', { 'tab-inactive-color': tab !== 0 },]" @click=" tab = 0; $emit('on-change-tab', 'validasi');">
              Riwayat Pengajuan dan Validasi
            </button>
            <button type="button" :class="['btn btn-primary mr-0 border-right-0 border-left-0', { 'tab-inactive-color': tab !== 1 },]" @click=" tab = 1; $emit('on-change-tab', 'perubahanData');">
              History Perubahan Data
            </button>
            <button type="button" :class="[ 'btn btn-primary mr-0 border-left-0', { 'tab-inactive-color': tab !== 2 },]" @click=" tab = 2; $emit('on-change-tab', 'perubahanDokumen');">
              History Perubahan Dokumen
            </button>
          </template>
        </div>
      </div>
  
      <div v-if="isLoadingData" class="text-center">loading...</div>
  
      <div v-if="data.length && !isLoadingData">
        <el-table v-if="tab === 0" :data="data" row-key="id" header-row-class-name="thead-light" lazy>
            <el-table-column label="No" width="50" type="index" />

            <el-table-column label="Daftar Pengajuan">
              <el-table-column prop="user_name" label="User" width="100" />
              <el-table-column label="Tanggal" width="90">
                <template v-slot="{ row }">
                {{ row.created_time_user }}
                </template>
              </el-table-column>
            </el-table-column>
             
            <el-table-column label="Status Validasi">
                <el-table-column prop="admin_name" label="admin" width="100" />
                <el-table-column label="Tanggal" width="90">
                  <template v-slot="{ row }">
                    {{ row.created_time }}
                  </template>
                </el-table-column>

                <el-table-column prop="status" label="Status" width="90" />
            </el-table-column>

            <el-table-column prop="keterangan" label="Keterangan">
              <template v-slot="{ row }">
                <div v-html="row.keterangan"></div>
              </template>
            </el-table-column>
        </el-table>
  
        <el-table v-if="tab === 1" :data="data" row-key="id" header-row-class-name="thead-light" lazy>
          <el-table-column label="No" width="50" type="index" />
          <el-table-column prop="name" label="Nama" width="200"></el-table-column>

          <el-table-column prop="updatedAt" label="Tanggal Modifikasi" width="100">
            <template v-slot="{ row }">
              {{ row.updatedAt }}
            </template>
          </el-table-column>
  
          <el-table-column label="Riwayat Perubahan" class-name="center-header">
            <el-table-column label="Data Lama">
              <template v-slot="{ row }">
                  <span>{{ row.oldData }}</span>
              </template>
            </el-table-column>
    
            <el-table-column label="Data Setelah dirubah">
              <template v-slot="{ row }">
                  <span>{{ row.newData }}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
  
        <el-table v-if="tab === 2" :data="data" lazy>
          <el-table-column prop="documentType" label="Diubah Pada" width="100">
            <template v-slot="{ row }">
              <!-- {{ formatDate(row.updatedAt,a "DD-MM-YYYY") }} --> {{ row.updatedAt }}
            </template>
          </el-table-column>
          
          <el-table-column
            prop="documentName"
            label="Nama Dokumen"
          ></el-table-column>
  
          <el-table-column label="Unduh Dokumen Lama">
            <template v-slot="{ row }">
              <a :href="`https://${row.DocumentPreviously}`" target="blank" class="text-white">
                <button class="btn btn-sm btn-primary" type="button">
                  Lihat File Lama
                </button>
              </a>
            </template>
          </el-table-column>
  
          <el-table-column label="Unduh Dokumen Baru">
            <template v-slot="{ row }">
              <a :href="`https://${row.newDocument}`" target="blank" class="text-white">
                <button class="btn btn-sm btn-primary" type="button">
                  Lihat File Baru
                </button>
              </a>
            </template>
          </el-table-column>
        </el-table>
      </div>
  
      <div v-if="!data.length && !isLoadingData" class="text-center">
        Belum ada riwayat validasi
      </div>
    </div>
  </template>
  <script>
  import moment from "moment";
  import { Table, TableColumn, Select, Option } from "element-ui";
  import { BasePagination } from "@/components";
  import clientPaginationMixin from "@/components/clientPaginationMixins";
  
  export default {
    name: "ValidationHistory",
    mixins: [clientPaginationMixin],
    components: {
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    props: {
      isLoadingData: [Boolean],
      data: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        tab: 0,
        baseURL: "http://localhost:3000",
        sortDate: false,
        tableData: [],
        selectedRows: [],
      };
    },
    methods: {
      formatText(text) {
        // return text
  
        try {
          const parsedData = JSON.parse(text);
          let data = Object.keys(parsedData).map((key) => ({
            key,
            value: parsedData[key],
          }));
  
          let formattedText = '';
          
          for (let i = 0; i < data.length; i++) {
            formattedText += `${data[i].key} : ${data[i].value}`;
            if (i < data.length - 1) {
              formattedText += ', '; // Add a comma between items
            }
          }
          
  
          return `${formattedText}`;
        } catch (error) {
          console.error('Error parsing JSON:', error);
          return [];
        }
  
      },
      downloadDoc(path) {
        window.open(`${baseURL}/${path}`, "_blank");
      },
      formatDate(timestamp, format) {
        return moment.unix(timestamp).format(format);
      },
      selectionChange(selectedRows) {
        this.selectedRows = selectedRows;
      },
    },
    created() {
      this.tableData = this.data;
    },
    watch: {
      // prevent data not update to tableData
      data: function (value) {
        this.tableData = value;
      },
    },
  };
  </script>
  <style lang="scss">
  #validation-history {
    .el-table {
      th {
        padding: 0 10px;
      }
      td {
        padding: 0 10px;
        vertical-align: top;
      }
      .el-table__row {
        &:hover {
          background: transparent;
          cursor: unset;
        }
      }
      .cell {
        word-break: normal;
        text-align: justify;
      }
    }
  }
  
  .border-left-0 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .border-right-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .border-0 {
    border-radius: 0;
  }
  
  .tab-inactive-color {
    background-color: #91a3a0;
    border-color: #91a3a0;
  }

  .el-table .el-table__header-wrapper thead th .cell {
    justify-content: center!important;
  }
  </style>
  